<app-card header="Asociación a grupo empresarial">
  <div class="label-search-group">
    Busca por nombre comercial, razón social o RUC del grupo
  </div>
  <div>
    <div class="pt-3 input-wrapper">
      <form [formGroup]="formGroup">
        <p-autoComplete
          formControlName="commercialGroupId"
          styleClass="input-autoComplete"
          [(ngModel)]="selectedItem"
          (onSelect)="onComercialGroupSelect($event)"
          [suggestions]="commercialGroupSuggestions"
          (completeMethod)="search($event)"
          field="comercialName"
        >
          <ng-template let-group pTemplate="item" styleClass="items-search">
            <div styleClass="items-search">
              <div>
                <strong>{{ group.comercialName }}</strong>
              </div>
              <div>RUC: ({{ group.identification }})</div>
            </div>
          </ng-template>
        </p-autoComplete>
      </form>
      <i
        *ngIf="showSearchIcon && !selectedItem"
        class="material-icons material-icons-outlined icon-autocomplete"
        aria-hidden="true"
        >search</i
      ><!-- Compliant icon fonts usage -->
      <i
        *ngIf="showCleanIcon"
        class="material-icons material-icons-filled icon-autocomplete"
        (click)="cleanSelectedItem()"
        aria-hidden="true"
        >cancel</i
      ><!-- Compliant icon fonts usage -->
    </div>
    <div [ngSwitch]="optionsFeedback">
      <div *ngSwitchCase="'NOT_FOUND'" class="info-message shadow-1">
        <p>
          Sin resultados. Puede que el grupo que buscas no exista, intenta
          creando uno
          <a
            [routerLink]="[relativeRoute + '/step-initial']"
            style="cursor: pointer"
            >aquí</a
          >
        </p>
      </div>
      <div *ngSwitchCase="'ERROR'" class="info-message shadow-1">
        <p>
          Hubo un problema con el servicio de consulta. Por favor, intenta
          nuevamente más tarde.
        </p>
      </div>
      <p-skeleton *ngSwitchCase="'LOADING'" width="50%" height="30px" />
    </div>
  </div>

  <div>
    <small *ngIf="!isValidSelection" class="p-error"
      >Selecciona un grupo comercial para asociar.</small
    >
  </div>
  <div
    *ngIf="
      selectedItem && selectedItem.comercialName && selectedItem.identification
    "
    class="grid col-12 pt-5"
  >
    <div class="col-6">
      <div class="label-title-info-group">
        <small>N° de RUC</small>
      </div>
      <div class="label-subtitle-info-group">
        <span
          ><strong>{{ selectedItem.identification }}</strong></span
        >
      </div>
    </div>
    <div class="col-6">
      <div class="label-title-info-group">
        <small>Nombre Comercial</small>
      </div>
      <div class="label-subtitle-info-group">
        <span
          ><strong>{{ selectedItem.comercialName }}</strong></span
        >
      </div>
    </div>
  </div>
</app-card>
