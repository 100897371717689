import { BehaviorSubject } from 'rxjs';
import { CustomBackofficeControlledErrorInterface } from '../types';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorStateService {
  private readonly errorSource =
    new BehaviorSubject<CustomBackofficeControlledErrorInterface | null>(null);
  error$ = this.errorSource.asObservable();

  constructor() {
    this.errorSource.next(null);
  }

  setError(error: CustomBackofficeControlledErrorInterface) {
    this.errorSource.next(error);
  }

  cleanError() {
    this.errorSource.next(null);
  }
}
