import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { assetUrl } from '@deuna/bp-sh-helpers-ng';
import { clientRoute } from 'src/app/constants/routes';

@Component({
  selector: 'app-step-new-account',
  templateUrl: './step-new-account.page.html',
  styleUrls: ['./step-new-account.page.scss'],
})
export class StepNewAccountPage implements OnInit {
  relativeRoute = clientRoute;
  stepInitialForm!: FormGroup;
  hasError: boolean = false;
  showConfirmationModal:boolean = false;
  confirmAccountModalText:string = "¿Quieres brindarle acceso a [correo] a [plataforma]?";
  imgConfirm:string = assetUrl('png/id-confirmation.png');
  
  constructor(
    private router: Router,
    private readonly fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }
  
  initForm(): void {
    this.stepInitialForm = this.fb.group({
      platform: ['Portal Deuna Negocios'],
      permissions: ['Acceso básico'],
      email: [null, [Validators.required, Validators.email]],
    });

    this.stepInitialForm.get('platform')?.disable();
    this.stepInitialForm.get('permissions')?.disable();
  }

  async onSubmit(): Promise<void> {
    this.stepInitialForm.markAllAsTouched();
    
    if (this.stepInitialForm.valid) {
      const mail = this.stepInitialForm.get('email')?.value;
      const platform = this.stepInitialForm.get('platform')?.value;
      this.confirmAccountModalText = this.confirmAccountModalText.replace('[correo]', mail);
      this.confirmAccountModalText = this.confirmAccountModalText.replace('[plataforma]', platform);
      this.displayConfirmationModal();
    }
  }

  displayConfirmationModal() {
    this.showConfirmationModal = true;
  }

  hideConfirmationModal() {
    this.showConfirmationModal = false;
  }

  goBack() {
    const backRoute = this.router.url.split('/').slice(0, -1).join('/');
    this.router.navigateByUrl(backRoute);
  }

  confirmCreateAccount() {
    console.log('complete create account');
  }
}
