import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfigurationFlowCreationService } from '../../../../../services/config/merchant/configuration-flow-creation.service';
import { CataloguesService } from '../../../../../services/http/catalogues.service';
import {
  ICatalogue,
  ICatalogueCanton,
} from '../../../../../shared/interfaces/calatogue.interface';
import { optionsTypeNotificationGeneral } from '../../../../../constants/catalogos/mocks-catalogo';
import { clientRoute } from 'src/app/constants/routes';
import { IFormGroup } from 'src/app/shared/interfaces/form-group.interface';
import { ClientType } from 'src/app/constants/merchant-constants';

@Component({
  selector: 'app-step-2',
  templateUrl: './step-2.page.html',
  styleUrls: ['./step-2.page.scss'],
})
export class Step2Page implements OnInit {
  relativeRoute = clientRoute;
  displayModal: boolean = false;
  isEditable: boolean = true;
  regions: Array<ICatalogue> = [];
  provinces: Array<ICatalogue> = [];
  cantons: Array<ICatalogueCanton> = [];
  cantonsSelect: Array<ICatalogueCanton> = [];
  phoneCodes: Array<ICatalogue> = [];
  notifications: Array<ICatalogue> = [];
  sourceChannels: Array<ICatalogue> = [];
  formConfig!: IFormGroup;
  CN004!: FormGroup;
  CN005!: FormGroup;
  CN006!: FormGroup;
  CN015!: FormGroup;
  CN019!: FormGroup;
  type: string = '';

  clientType = ClientType;

  constructor(
    private router: Router,
    private cataloguesService: CataloguesService,
    private configFlow: ConfigurationFlowCreationService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.initData();
  }

  initForm() {
    const forms = this.configFlow.getForm();
    this.CN004 = forms.CN004;
    this.CN005 = forms.CN005;
    this.CN006 = forms.CN006;
    this.CN015 = forms.CN015;
    this.CN019 = forms.CN019;

    this.type = forms.search.get('clientType')?.value;
    this.CN006.enable();
    if (!forms.CN000.valid && !forms.CN001.valid && !forms.CN002.valid) {
      this.router.navigate([`${clientRoute}/create/search`]);
    }
    this.cantonsSelect = [];
  }

  async initData() {
    this.CN005.get('webhookVersion')?.setValue('V2');
    this.cataloguesService.getRegions().subscribe((res) => {
      this.regions = res;
    });

    this.cataloguesService.getProvinces().subscribe((res) => {
      this.provinces = res;
    });

    this.cataloguesService.getCantons().subscribe((res) => {
      this.cantons = res;
      this.getCantons();
    });

    this.cataloguesService.getCountriesCode().subscribe((res) => {
      this.phoneCodes = res;
    });

    this.cataloguesService.getSourceChannels().subscribe((res) => {
      this.sourceChannels = res;
    });

    this.formConfig = this.configFlow.getForm();
    this.notifications = optionsTypeNotificationGeneral;
  }

  validateJuridicMerchantFormGroup() {
    return this.CN004.valid && this.CN005.valid && this.CN006.valid && this.CN019.valid;
  }

  validateNaturalMerchantFormGroup() {
    return (
      this.CN004.valid &&
      this.CN005.valid &&
      this.CN006.valid &&
      this.CN015.valid &&
      this.CN019.valid
    );
  }

  onSubmit(): void {
    this.CN004.markAllAsTouched();
    this.CN005.markAllAsTouched();
    this.CN006.markAsDirty();
    this.CN006.markAllAsTouched();
    this.CN006.updateValueAndValidity();
    this.CN015.markAllAsTouched();
    this.CN019.markAllAsTouched();

    if (this.type === ClientType.NATURAL) {
      if (!this.validateNaturalMerchantFormGroup()) return;
    } else {
      if (!this.validateJuridicMerchantFormGroup()) return;
    }
    this.router.navigate([`${clientRoute}/create/step-3`]);
  }

  getCantons() {
    this.cantonsSelect = [];
    if (this.CN004.get('province')) {
      this.cantonsSelect = this.cantons.filter(
        (x) => +x.province === +this.CN004.get('province')?.value?.code
      );
    }
  }

  openModal() {
    this.displayModal = true;
  }

  hideModal() {
    this.displayModal = false;
  }
}
