import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { assetUrl } from '@deuna/bp-sh-helpers-ng';
import { clientRoute } from 'src/app/constants/routes';
import { AccountItem, IQueryAccounts, Meta } from 'src/app/shared/interfaces/query-settings';

@Component({
  selector: 'app-list-accounts',
  templateUrl: './list-accounts.page.html',
  styleUrls: [],
  providers: [],
})
export class ListAccounts implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute:ActivatedRoute,
  ) { }

  relativeRoute = clientRoute;
  showConfirmationModal:boolean = false;
  deleteAccountItem:AccountItem|null = null;
  
  imgConfirmDelete:string = assetUrl('png/delete.png');
  imgEmpty: string = assetUrl('png/mas.png');
  imgError: string = assetUrl('png/report-inactive.png');

  dataPagination: Meta = {
    itemCount: 1,
    totalItems: 1,
    itemsPerPage: 10,
    totalPages: 1,
    currentPage: 1,
  };
  paramsQuery: IQueryAccounts = {
    page: 1,
    limit: 5
  };
  tableStatus: 'LOADING' | 'COMPLETE' | 'EMPTY' | 'ERROR' = 'LOADING';
  currentPage: number = 1;
  limitPage: number = 5;
  accountsList: Array<AccountItem> = [
    {
      id: 'mock-account-id',
      mail: 'example@correo.com',
      platform: 'Portal Deuna Negocios',
      permissions: 'Acceso basico',
    }
  ];

  ngOnInit(): void {
    setTimeout(() => {
      this.tableStatus = 'ERROR';
    }, 1500);
  }

  columns = [
    'Correo',
    'Plataforma',
    'Permisos',
    '',
  ];

  goToPage(page: number) {
    this.paramsQuery.page = page;
  }

  changeToItems(page: number) {
    this.paramsQuery.page = 1;
    this.paramsQuery.limit = page;
  }
  
  deleteAccount(account:AccountItem) {
    console.log(account);
    this.deleteAccountItem = account;
    this.displayConfirmationModal();
  }

  addNewAccount() {
    this.router.navigate(['step-initial'], { relativeTo: this.activatedRoute });
  }

  reload() {
    this.tableStatus = 'LOADING';

    setTimeout(() => {
      this.tableStatus = 'COMPLETE';
    }, 1500);
  }

  confirmDeleteAccount() {
    console.log('complete deleting account');
  }

  displayConfirmationModal() {
    this.showConfirmationModal = true;
  }

  hideConfirmationModal() {
    this.showConfirmationModal = false;
  }

  get confirmDeleteAccountModalTitle():string {
    return `¿Deseas revocar el acceso de ${this.deleteAccountItem?.mail} a ${this.deleteAccountItem?.platform}?`;
  }

  get confirmDeleteAccountModalText():string {
    return `Al confirmar esta acción, este correo ya no podrá acceder a ${this.deleteAccountItem?.platform}.`;
  }
}
