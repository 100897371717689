import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IFormGroup } from 'src/app/shared/interfaces/form-group.interface';
import { ConfigurationDataService } from '../../../../../services/config/merchant/configuration-data.service';
import { ConfigName } from 'src/app/shared/interfaces/configuration-merchant.enum';
import { FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';

const {
  BUSSINESS_INFO,
  LEGAL_REPRESENTATIVE,
  SEGMENT,
  LOCATION,
  CONTACT,
  NOTIFICATION_SETTING,
  SOURCE_SUPPORT,
} = ConfigName;
@Component({
  selector: 'app-tab-perfil',
  templateUrl: './tab-perfil.component.html',
  styleUrls: ['./tab-perfil.component.scss'],
})
export class TabPerfilComponent implements OnInit {
  @Output() closeSide = new EventEmitter();
  @Input() search!: FormGroup;

  isEditable: boolean = true;
  isFlowUpdate: boolean = true;
  loadData: boolean = true;
  subActivatedRoute!: Subscription;
  merchantId: string = '';
  formConfigData!: IFormGroup;
  CN000!: FormGroup;
  CN001!: FormGroup;
  CN002!: FormGroup;
  CN003!: FormGroup;
  CN004!: FormGroup;
  CN005!: FormGroup;
  CN006!: FormGroup;
  CN019!: FormGroup;

  configs: Array<string> = [
    BUSSINESS_INFO,
    LEGAL_REPRESENTATIVE,
    SEGMENT,
    LOCATION,
    CONTACT,
    NOTIFICATION_SETTING,
    SOURCE_SUPPORT,
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private configDataService: ConfigurationDataService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  showToast(error?: string) {
    if (!error)
      return this.messageService.add({
        key: 'br',
        severity: 'deuna',
        summary: 'Guardado exitosamente',
        closable: false,
      });
    return this.messageService.add({
      key: 'global',
      severity: 'error',
      summary: error,
      closable: false,
      icon: '',
    });
  }

  async initData() {
    this.merchantId = this.getMerchantIdFromParams() || '';
    await this.configDataService.setFormDataMerchant(
      this.merchantId,
      this.configs
    );

    this.formConfigData = this.configDataService.getForm();
    this.CN000 = this.formConfigData.CN000;
    this.CN001 = this.formConfigData.CN001;
    this.CN002 = this.formConfigData.CN002;
    this.CN003 = this.formConfigData.CN003;
    this.CN004 = this.formConfigData.CN004;
    this.CN005 = this.formConfigData.CN005;
    this.CN019 = this.formConfigData.CN019;
    this.search = this.formConfigData.search;
    this.CN000.disable();
    this.CN001.disable();
    this.CN002.disable();
    this.CN003.disable();
    this.CN004.disable();
    this.CN005.disable();
    this.CN019.disable();
    this.loadData = false;
  }

  getMerchantIdFromParams() {
    return this.activatedRoute.snapshot.paramMap.get('merchantId');
  }

  onSubmit() {}
}
