<deuna-loading [titulo]="'Cargando información'" *ngIf="loading" [subtitulo]="'Por favor, espera un momento'">
</deuna-loading>
<form (ngSubmit)="onSubmit()" class="grid my-5" autocomplete="off" *ngIf="!loading">
  <div class="col-8 col-offset-2">
    <div class="mb-5">
      <div class="text-style-back" routerLink="{{relativeRoute+'/commercial-group/create/search'}}">
        Regresar
      </div>
    </div>
    <app-load-step [stepLoad]="20"></app-load-step>
  </div>
  <ng-container>
    <div class="col-8 col-offset-2">
      <app-card-general-commercial-group [isEditable]="isEditable" [formCN000]="formCN000" [search]="search"></app-card-general-commercial-group>
    </div>
    <div class="col-8 col-offset-2 pt-4">
      <app-card-commercial [isEditable]="true" [formDataCN000]="formCN000"></app-card-commercial>
    </div>
  </ng-container>

  <div class="col-6 col-offset-4 pt-4">
    <div class="grid">
      <div class="col-6">
        <deuna-button label="Cancelar" deUnaClass="p-button-outlined" (eventClick)="openModal()">
        </deuna-button>
      </div>
      <div class="col-6">
        <deuna-button label="Crear Empresa" type="submit"></deuna-button>
      </div>
    </div>
  </div>
</form>
<app-exit-modal [displayModal]="displayModal" (onHide)="hideModal()"></app-exit-modal>