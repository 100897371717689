import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client';
import { assetUrl } from '@deuna/bo-sh-helpers-ng';
import { MenuItem } from 'primeng/api';
import { clientRoute } from 'src/app/constants/routes';
import { GraphqlMerchantService } from 'src/app/services/graphql/graphql-merchant.service';
import { ResponseCommercialGroups } from 'src/app/shared/interfaces/query-merchants';

@Component({
  selector: 'app-info',
  templateUrl: './info-group.page.html',
  styleUrls: [],
})
export class InfoGroupPage implements OnInit {
  relativeRoute = clientRoute;
  avatar: string = assetUrl('svg/merchant-group-logo.svg');
  nodeId: string = '';

  activeItem: MenuItem = {};

  items: Array<MenuItem> = [];
  businessName!: string;
  groupNodeId!: string;
  loading = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private servicesMerchantsGql: GraphqlMerchantService
  ) {}

  ngOnInit(): void {
    this.items = this.getTabs();
    this.getInitialData();
    this.activatedRoute.queryParams?.subscribe((params) => {
      const tab = params['tab'];
      if (!tab) {
        return this.redirectTab({ item: this.items[2] });
      }
      if (tab !== this.activeItem.id) {
        return this.redirectTab({
          item: this.getTabs().find((i) => i.id === tab),
        });
      }
    });
    this.getGroupData();
  }

  getInitialData() {
    this.activatedRoute.paramMap?.subscribe((params) => {
      const groupNodeId = params.get('nodeId') || '';
      this.groupNodeId = groupNodeId;
    });
  }

  private redirectTab = ({ item }) => {
    this.router.navigate(
      [`${clientRoute}/commercial-group/info/`, this.groupNodeId],
      {
        queryParams: { tab: item.id },
      }
    );
    this.activeItem = item;
  };

  getGroupData() {
    this.loading = true;
    this.servicesMerchantsGql
      .getGroupsPage({ page: 1, limit: 30 , status: 'A'}, 'businessName')
      ?.subscribe({
        next: (res: ApolloQueryResult<ResponseCommercialGroups>) => {
          if (res.data.findAllCommercialGroups) {
            const { items } = res.data.findAllCommercialGroups;

            this.businessName =
              items.find((item) => item.nodeId === this.groupNodeId)?.configData
                .businessName || '';
          }
          this.loading = false;
        },
      });
  }

  private getTabs = () => {
    return [
      {
        label: 'Perfil',
        id: 'perfil',
        command: (item) => this.redirectTab(item),
      },
      {
        label: 'Información finaciera',
        id: 'financialInformation',
        command: (item) => this.redirectTab(item),
      },
      {
        label: 'Negocios asociados',
        id: 'merchants',
        command: (item) => this.redirectTab(item),
      },
    ];
  };
}
