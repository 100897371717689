import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { assetUrl } from '@deuna/bp-sh-helpers-ng';
import { clientRoute } from 'src/app/constants/routes';
import {
  ConfigurationFlowReportDataService,
} from 'src/app/services/config/report/configuration-flow-data-report.service';

@Component({
  selector: 'app-card-recipient',
  templateUrl: './card-recipient.component.html',
  styleUrls: ['./card-recipient.component.scss'],
})
export class CardRecipientComponent {
  @Input() isEditable: boolean = true;
  @Input() form!: FormGroup;
  @Input() isFlowUpdate: boolean = true;
  @Input() merchantId!: string;
  @Input() maximumAllowedEmails!: number;
  displayModal: boolean = false;
  loading: boolean = false;
  trash: string = assetUrl('svg/trash.svg');
  constructor(
    private readonly fb: FormBuilder,
    private router: Router,
    private configurationFlowReportDataService: ConfigurationFlowReportDataService
  ) {}

  get records(): FormArray {
    return this.form.get('mails') as FormArray;
  }

  async onSubmit() {
    this.form.markAllAsTouched();
    if (!this.form.valid) return;
    this.loading = true;
    const updated =
      await this.configurationFlowReportDataService.updateMailsReport();
    if (updated) {
      this.router.navigate([`${clientRoute}/info`, this.merchantId], {
        queryParams: { tab: 'reportsProgram' },
      });
    }
    this.loading = false;
  }

  onAcceptCancel() {
    this.router.navigate([`${clientRoute}/info`, this.merchantId], {
      queryParams: { tab: 'reportsProgram' },
    });
    this.configurationFlowReportDataService.resetFormData();
  }

  addNewRow() {
    if (this.records.length === this.maximumAllowedEmails) return;
    const newRow = this.fb.group(this.configurationFlowReportDataService.setEmailValidation);
    this.records.push(newRow);
  }

  onHideModal() {
    this.displayModal = false;
  }

  openModalCancel() {
    this.displayModal = true;
  }
  withErroRecord(i: number, error: string): boolean {
    return this.records.controls[i].get('mail')!.hasError(error);
  }
  removeRowForm(index: number) {
    this.records.removeAt(index);
  }

  isTouchedRecord(i: number): boolean {
    return this.records.controls[i].get('mail')!.touched;
  }

  isDirtyRecord(i: number): boolean {
    return this.records.controls[i].get('mail')!.dirty;
  }

  isValidRecord(i: number) {
    return this.records.controls[i].get('mail')!.invalid;
  }

  getRecordErrors(i: number) {
    return Object.keys(this.records.controls[i].get('mail')?.errors || {})[0];
  }
}
