import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CataloguesService } from 'src/app/services/http/catalogues.service';
import { ICatalogue } from 'src/app/shared/interfaces/calatogue.interface';
import { ConfigurationDataService } from 'src/app/services/config/merchant/configuration-data.service';
import _ from 'lodash';
import {
  BannerTypes,
  DeUnaInputActionStates,
} from '@deuna/bp-sh-components-ng';

@Component({
  selector: 'app-card-commission-accounts-natural',
  templateUrl: './card-commission-accounts-natural.component.html',
})
export class CardCommissionAccountsNaturalComponent implements OnInit {
  @Input() isEditable: boolean = false;
  @Input() isFlowUpdate: boolean = false;
  loading: boolean = false;
  enableEdit: boolean = false;
  @Input() formCN012!: FormGroup;
  formCN012TMP!: FormGroup;
  accountsTypes: Array<ICatalogue> = [];
  accountInputState = DeUnaInputActionStates.INITIAL;
  bannerType = BannerTypes.BASIC_INFO;

  sameAccountOption: boolean = false;
  option: Array<ICatalogue> = [
    {
      code: '01',
      name: 'Usar la misma cuenta de recaudo para cargo de comisiones',
    },
  ];
  @Input() formCN006!: FormGroup;

  onChange() {
    this.sameAccountOption = !this.sameAccountOption;
  }

  constructor(
    private configDataService: ConfigurationDataService,
    private cataloguesService: CataloguesService
  ) {}

  ngOnInit(): void {
    this.getDataCatalogs();
  }

  getDataCatalogs() {
    this.formCN012TMP = _.cloneDeep(this.formCN012);
    this.cataloguesService.getAccountTypes().subscribe((response) => {
      this.accountsTypes = response.filter((item) => item.code !== 'Otro');
    });
  }

  handleValidate() {
    this.accountInputState = DeUnaInputActionStates.DISABLED;
  }

  handleEvent($event: boolean) {
    if ($event) {
      this.enableEdit = true;
      const accountType = this.accountsTypes.find(
        (item) => item.name === this.formCN012.controls['accountType']?.value
      );
      this.formCN012.enable();
      this.formCN012.get('accountType')?.setValue(accountType?.code);
    }
  }
  onCancelEdit() {
    this.enableEdit = false;
    this.formCN012.patchValue(this.formCN012TMP.value);
  }

  async submit() {
    this.loading = true;
    this.formCN012.markAllAsTouched();
    if (!this.formCN012.valid) {
      this.loading = false;
      return;
    }
    await Promise.resolve(
      this.configDataService.updateConfiguration('CN003')
    ).then(() => {
      this.formCN012TMP = _.cloneDeep(this.formCN012);
      this.enableEdit = false;
      this.loading = false;
    });
  }
}
