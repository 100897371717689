<deuna-loading [titulo]="'Creando negocio'" *ngIf="state" [subtitulo]="'Por favor, espera un momento'"></deuna-loading>
<form *ngIf="!errorRequestPage" [formGroup]="formStep3" (ngSubmit)="onSubmit()" class="grid my-5">
    <div class="col-8 col-offset-2 ">
        <div class="mb-5">
            <span class="text-style-back" routerLink="{{relativeRoute+'/create/step-2'}}">Regresar</span>
        </div>
        <app-load-step [stepLoad]="60"></app-load-step>
    </div>
    <div class="col-8 col-offset-2">
        <app-card header="Sucursales" subHeader="Indica si el negocio tiene más de una sucursal, esto nos ayudará a configurar el formulario de información complementaria.">
            <deuna-radio-button label="¿El comercio tiene más de una sucursal?" styleClassItem="pt-2" [values]="optionStep3" control="optionBranch" [form]="formStep3" (click)="reset()">
            </deuna-radio-button>
        </app-card>
    </div>
    <div class="col-8 col-offset-2" *ngIf="formStep3.controls['optionBranch'].value=='1'">
        <app-card header="Envío de formulario de sucursales a negocio" subHeader="Indica si deseas enviarle al negocio un formulario predefinido en formato Excel para que agregue información de sus sucursales. ">
            <deuna-radio-button label="¿Enviar formulario Excel de sucursales al negocio?" styleClassItem="pt-2" [values]="optionsExcelStep3" control="optionExcel" [form]="formStep3" (click)="resetSendForm()">
            </deuna-radio-button>
            <br>
            <div *ngIf="formStep3.controls['optionExcel'].value=='SI'">
                <deuna-input [masked]="false" styleClass="mt-4" [form]="formStep3" label="Nombre del autorizador/a o contacto negocio" name="nameMerchatContact" control="nameMerchatContact" [maxLength]="100"></deuna-input>
                <deuna-input [masked]="false" styleClass="mt-4" [form]="formStep3" label="Correo del autorizador/a o contacto negocio" name="mailMerchantContact" control="mailMerchantContact" [maxLength]="100"></deuna-input>
            </div>
        </app-card>
    </div>
    <div class="col-6 col-offset-4 pt-4">
        <div class="grid">
            <div class="col-6">
                <deuna-button label="Cancelar" deUnaClass="p-button-outlined" (eventClick)="openModal()">
                </deuna-button>
            </div>
            <div class="col-6">
                <deuna-button [disabled]="state" label="Crear empresa" type="submit"></deuna-button>
            </div>
        </div>
    </div>
</form>
<app-exit-modal [displayModal]="displayModal" (onHide)="hideModal()"></app-exit-modal>

<app-empty-list-return *ngIf="errorRequestPage" title="Ocurrió un problema al afiliar este negocio" subtitle="Haz click en “Volver al formulario” para regresar a la pantalla anterior y no perder los datos ingresados." [buttonCancel]="true" [buttonAccept]="true" textButtonAccept="Volver al formulario" textButtonCancel="Volver al inicio" (onSubmitAccept)="onSubmitAccept()" (onSubmitCancel)="onSubmitCancel()"></app-empty-list-return>