<app-card
  header="Tributación"
  [isFlowUpdate]="isFlowUpdate"
  [isEditable]="isEditable"
  (customEvent)="handleEvent($event)"
>
  <deuna-radio-button
    label="Régimen tributario"
    styleClassItem="pt-2"
    [values]="taxRegimeOptions"
    control="taxRegime"
    styleClass="pt-2"
    [form]="formCN016"
  >
  </deuna-radio-button>
</app-card>
