<div class="grid mt-2 mx-5">
  <div class="col-12 py-5">
    <div class="titles">Negocios afiliados</div>
    <div class="grid pt-5">
      <div class="col-3">
        <div *ngIf="!enableMassiveMerchantCreation">
          <deuna-button [label]="'Nuevo negocio'"
            routerLink="{{relativeRoute+ (enviroment.enableCommercialGroup ? '/step-initial' : '/create/search')}}"></deuna-button>
        </div>
        <div *ngIf="enableMassiveMerchantCreation">
          <deuna-button label="Crear negocio(s)" (eventClick)="subMenuButton.toggle($event)" [icon]="true">
            <div class="icon"><i class=" material-icons material-icons-outlined" aria-hidden="true">
                add_circle_outline</i>
            </div>
          </deuna-button>
          <deuna-menu [menuButton]="menuButton" (registerSubmenuButton)="registerSubmenu($event)">
          </deuna-menu>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="grid mx-5 pb-10 tab-container">
  <div class="col-12">
    <deuna-tab-menu [items]="items" [activeItem]="activeItem"></deuna-tab-menu>
  </div>
</div>
<ng-template [ngIf]="activeItem.id === 'merchants' ">
  <app-list-merchant></app-list-merchant></ng-template>
<ng-template [ngIf]="activeItem.id === 'groups' ">
  <app-list-groups></app-list-groups></ng-template>