import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-update-button-footer',
  templateUrl: './update-button-footer.component.html',
})
export class UpdateButtonFooterComponent {
  @Input() loading: boolean = false;
  @Output() eventSubmit: EventEmitter<void> = new EventEmitter<void>();
  @Output() eventCancel: EventEmitter<void> = new EventEmitter<void>();

  submit() {
    this.eventSubmit.emit();
  }

  onCancelEdit() {
    this.eventCancel.emit();
  }
}
