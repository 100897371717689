<deuna-loading [titulo]="'Cargando información'" *ngIf="loading" [subtitulo]="'Por favor, espera un momento'">
</deuna-loading>
<div class="grid" style="height: 95vh !important;" *ngIf="!loading">
  <div class="col grid">
    <div class="md:col-6 md:col-offset-3 p-5 md:p-0">
      <div class="text-style-back mt-8 mb-5" routerLink="{{relativeRoute+'/step-initial'}}">
        Regresar
      </div>
      <strong class="titles">Afilia un nuevo negocio</strong>
      <form [formGroup]="searchClientForm" (ngSubmit)="onSubmit()" autocomplete="off">
        <div class="mt-5">
          <label for="type" class="">Tipo de cliente</label>
          <deuna-radio-button styleClassContent="flex flex-wrap mt-2" styleClassItem="m-1" [background]="true" [values]="clientTypeOptions" control="clientType" [form]="searchClientForm">
          </deuna-radio-button>
        </div>
        <div class="rif-input-container max-width">
          <deuna-input [masked]="false" [label]="inputLabel" [placeholder]="'Ej: 7653859762001'" name="identification" control="identification" [maxLength]="200" [form]="searchClientForm" [type]="'number'"></deuna-input>
        </div>
        <div class="mt-4 max-width">
          <deuna-button [label]="'Iniciar creación'" [type]="'submit'"></deuna-button>
        </div>
      </form>
    </div>
  </div>
  <div class="m-0 p-0 hidden md:col md:flex background-container align-content-center justify-content-end flex-wrap form-wrapper">
    <div class="flex justify-content-center">
      <img [src]="searchClientImg" alt="Client Search" class="w-6" />
    </div>
  </div>
</div>