<p-toast position="bottom-right" key="br" [baseZIndex]="10000">
  <ng-template let-message pTemplate="message">
    <div class="flex" style="flex: 1">
      <div class="text-center">
        <i
          class="material-icons material-icons-outlined"
          style="vertical-align: middle; padding-right: 4px"
          aria-hidden="true"
          >done</i
        >
        <span style="vertical-align: middle">{{ message.summary }}</span>
      </div>
    </div>
  </ng-template>
</p-toast>

<div class="col-8 col-offset-2">
  <div class='col-12 mt-5' *ngIf="isUnifiedReport">
    <app-card-frequency [isEditable]="isEditable" [form]="formReportData"></app-card-frequency>
  </div>
  
  <div class='col-12'>
    <app-card-recipient [isEditable]="isEditableEmails" [form]="formReportData.frequency" [merchantId]="merchantId" [maximumAllowedEmails]="maximumAllowedEmails">
    </app-card-recipient>
  </div>
</div>