<div class="col-12 text-center" *ngIf="loadData">
    <p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
    <h2 class="mt-2">
        Cargando....
    </h2>
</div>

<ng-container *ngIf="this.formConfigData && !loadData">
    <div class="col-8 col-offset-2 ">
        <app-card-collection-accounts [isEditable]="isEditable" [isFlowUpdate]="isFlowUpdate" [formCN003]="CN003" (toastEvent)="showToast()"></app-card-collection-accounts>
    </div>
    <div class="col-8 col-offset-2 ">
        <app-card-commission-accounts 
        [isEditable]="isEditable" 
        [isFlowUpdate]="isFlowUpdate"
        [isCN012Created]="isCN012Created"
        [formCN012]="CN012" 
        (toastEvent)="showToast()"
        ></app-card-commission-accounts>
    </div>
    <div class="col-8 col-offset-2 ">
        <app-card-operating-accounts [isEditable]="false"></app-card-operating-accounts>
    </div>
    <div class="col-8 col-offset-2 ">
        <app-card-billing-information 
        [isEditable]="isEditable" 
        [isFlowUpdate]="isFlowUpdate" 
        [isCN013Created]="isCN013Created"
        [formCN013]="CN013"
        (toastEvent)="showToast()"></app-card-billing-information>
    </div>

</ng-container>

<p-toast position="bottom-right" key="br" [baseZIndex]="10000">
    <ng-template let-message pTemplate="message">
        <div class="flex" style="flex: 1">
            <div class="text-center">
                <i class="material-icons material-icons-outlined" style="vertical-align: middle; padding-right: 4px" aria-hidden="true">done</i>
                <span style="vertical-align: middle">{{ message.summary }}</span>
            </div>
        </div>
    </ng-template>
</p-toast>