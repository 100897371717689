import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApolloQueryResult } from '@apollo/client';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { clientRoute } from 'src/app/constants/routes';
import { GraphqlMerchantService } from 'src/app/services/graphql/graphql-merchant.service';
import {
  ICommercialGroupItem,
  ISearchGroupResult,
} from 'src/app/shared/interfaces/query-merchants';

@Component({
  selector: 'app-search-group',
  templateUrl: './search-group-card.component.html',
  styleUrls: ['./search-group-card.component.scss'],
})
export class SearchGroupComponent {
  @Input() formGroup!: FormGroup;
  @Input() isValidSelection!: boolean;
  @Output() isValidSelectionChange = new EventEmitter<boolean>();

  showCleanIcon: boolean = false;
  showSearchIcon: boolean = true;
  commercialGroupSuggestions: ICommercialGroupItem[] = [];
  selectedItem: ICommercialGroupItem | null = null;

  optionsFeedback: 'NOT_FOUND' | 'ERROR' | 'NO_INFO' | 'LOADING' = 'NO_INFO';
  relativeRoute = clientRoute;

  constructor(private merchantService: GraphqlMerchantService) {}

  search(event: AutoCompleteCompleteEvent) {
    const search = event.query;
    this.showCleanIcon = true;
    this.showSearchIcon = false;
    if (search.length < 3) {
      this.commercialGroupSuggestions = [];
      this.optionsFeedback = 'NO_INFO';
      return;
    }
    this.optionsFeedback = 'LOADING';

    this.merchantService.searchGroup(search).subscribe({
      next: (res: ApolloQueryResult<ISearchGroupResult>) => {
        const foundComercialGroups = res.data.searchCommercialGroup.items;
        this.commercialGroupSuggestions = foundComercialGroups;
        this.optionsFeedback = !foundComercialGroups.length
          ? 'NOT_FOUND'
          : 'NO_INFO';
      },
      error: () => {
        this.commercialGroupSuggestions = [];
        this.optionsFeedback = 'ERROR';
      },
      complete: () => {},
    });
  }

  onComercialGroupSelect(event: ICommercialGroupItem) {
    this.selectedItem = event;
    this.isValidSelection = true;
    if (this.selectedItem) {
      this.showCleanIcon = true;
      this.showSearchIcon = false;
    }
    this.isValidSelectionChange.emit(this.isValidSelection);
  }

  cleanSelectedItem() {
    this.optionsFeedback = 'NO_INFO';
    this.selectedItem = null;
    this.showSearchIcon = true;
    this.showCleanIcon = false;
    this.isValidSelection = true;

    this.isValidSelectionChange.emit(this.isValidSelection);
    this.formGroup.reset();
  }
}
