import { Component, EventEmitter, Input, Output } from '@angular/core';
import { assetUrl } from '@deuna/bp-sh-helpers-ng';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
  @Input() img: string = assetUrl('png/create_error.png');
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() button: boolean = false;
  @Input() buttonBackText: string = '';
  @Input() buttonRetrytext: string = '';

  @Output() onActionBackButton = new EventEmitter();
  @Output() onActionRetryButton = new EventEmitter();

  actionBackButton() {
    this.onActionBackButton.emit(true);
  }

  actionRetryButton() {
    this.onActionRetryButton.emit(true);
  }
}
