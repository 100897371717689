<deuna-loading [titulo]="'Cargando información'" *ngIf="loading" [subtitulo]="'Por favor, espera un momento'">
</deuna-loading>
<div class="grid h-screen">
  <ng-container *ngIf="!hasError else notClientBp">
    <div class="col grid">
      <div class="md:col-6 md:col-offset-3 p-5 md:p-0">
        <div class="text-style-back mt-8 mb-5" routerLink="{{backRoute}}">
          Regresar
        </div>
        <strong class="titles">Ingresa los datos de identificación</strong>
        <form [formGroup]="searchClientForm" (ngSubmit)="onSubmit()" autocomplete="off">
          <div class="mt-5">
            <label for="type" class="">Tipo de cliente</label>
            <deuna-radio-button styleClassContent="flex flex-wrap mt-2" styleClassItem="m-1" [background]="true" [values]="clientTypeOptions" control="clientType" [form]="searchClientForm" (eventOnChange)="changeOfType()">
            </deuna-radio-button>
          </div>
          <div class="rif-input-container max-width">
            <deuna-input [masked]="false" [label]="inputLabel" [placeholder]="'Ej: 7653859762001'" name="identification" control="identification" [maxLength]="200" [form]="searchClientForm" [type]="'number'"></deuna-input>
          </div>
          <div class="mt-4 max-width">
            <deuna-button [disabled]="searchClientForm.get('clientType')?.value === clientType.NATURAL && !environment.enableNaturalMerchantCreation" [label]="'Iniciar afiliación'" [type]="'submit'"></deuna-button>
          </div>
        </form>
      </div>
    </div>
    <div class="m-0 p-0 hidden md:col md:flex pink-container align-content-center justify-content-end flex-wrap form-wrapper">
      <div class="flex justify-content-end">
        <img [src]="searchClientImg" alt="Client Search" class="w-12" />
      </div>
    </div>
  </ng-container>
  <ng-template #notClientBp>
    <div class="notClientBp-page">
      <div class="content">
        <div class="no-cif">
          <img [src]="img_error" alt="Error bank" width="256px" height="256px" />
          <div class="title">
            Este negocio no está afiliado a Banco Pichincha
          </div>
          <div class="desc">
            Por favor, contacta a un ejecutivo del banco para afiliar a esta
            empresa o ingresa correctamente la identificación.
          </div>
          <div class="error-Cif">
            <deuna-button routerLink="{{relativeRoute+'/list'}}" label="Volver al inicio" deUnaClass="p-button-outlined"></deuna-button>
            <deuna-button id="test-clearFlagsBtn" (eventClick)="retry()" label="Reintentar"></deuna-button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>