import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { assetUrl } from '@deuna/bo-sh-helpers-ng';
import {
  BUTTON_NEXT,
  ERRORS_ASSOCIATE_GROUP_HEADER,
  ERRORS_ASSOCIATE_GROUP_SUBHEADER,
} from 'src/app/constants/commercial-group';
import { clientRoute, SEARCH_COMMGROUP_ROUTE } from 'src/app/constants/routes';

@Component({
  selector: 'app-step-end-commercial-group',
  templateUrl: './step-end.page.html',
})
export class StepEndPage implements OnInit {
  image: string = assetUrl('png/success-create-group.png');
  header: string = 'El grupo empresarial se creó correctamente';
  subHeader: string =
    'Crea un ‘negocio individual’ y asócialo a este grupo. Recuerda que este paso es obligatorio para que el grupo se habilite completamente.';
  routesBack: string[] = [`${clientRoute}/list`];
  buttonBack: string = 'Volver al inicio';
  routesNext: string[] = [`${clientRoute}/create/check-association`];
  buttonNext: string = `Ir a ‘Crear negocios’`;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.initScreen();
  }

  initScreen() {
    const status = this.activatedRoute.snapshot.params['status'];
    if (status !== 'success') {
      this.image = assetUrl('png/create-error.png');
      this.header = ERRORS_ASSOCIATE_GROUP_HEADER;
      this.subHeader = ERRORS_ASSOCIATE_GROUP_SUBHEADER;
      this.buttonNext = BUTTON_NEXT;
      this.routesNext = [SEARCH_COMMGROUP_ROUTE];
    }
  }
}
