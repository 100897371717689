<deuna-loading [titulo]="'Cargando información'" *ngIf="load" [subtitulo]="'Por favor, espera un momento'">
</deuna-loading>

<div class="content-app" *ngIf="!load">
  <div class="grid mt-2 mx-5">
    <div class="col-12 py-5">
      <span class="text-style-back mt-2" routerLink="{{relativeRoute+'/list'}}">Negocios afiliados</span>
      <span class="text-style-back mt-2" style="text-decoration: none; cursor: auto">
        /
      </span>
      <span class="text-style-back mt-2" routerLink="{{relativeRoute+'/info/'+ merchant?.id}}">
        {{merchant?.comercialName}}</span>
      <span class="text-style-back mt-2" style="text-decoration: none; cursor: auto">
        / {{branch?.name}}</span>
    </div>
    <div class="col-icono">
      <img [src]="avatar" alt="avatar" class="w-100" />
    </div>
    <div class="col pl-4">
      <div class="grid">
        <div class="col-12">
          <span class="titles">{{merchant?.comercialName}}</span>
          <span class="titles"> / {{branch?.name}}</span>
        </div>
        <div class="col-12 pt-3">
          <span class="font-id"><strong>ID: </strong>{{merchant?.identification}}</span>
          <deuna-chip backgroundColor="{{existsBranchs? '#ECF9EF':'#FDF4E6'}}" color="{{existsBranchs? '#236936':'#835200'}}" text="{{(existsBranchs? 100: 70) +'% completado'}}"></deuna-chip>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 ml-2 mb-4">
    <deuna-tab-menu [items]="items" [activeItem]="activeItem"></deuna-tab-menu>
  </div>
  <div class="col-8 col-offset-2">
    <app-card header="Información básica de la sucursal" subHeader="Dale un nombre que sea fácil de identificar.">
      <deuna-input [masked]="false" label="Nombre de la sucursal" placeholder="Ej: Comercio" name="branchName" control="branchName" [maxLength]="200" [form]="config007"></deuna-input>
      <div class="col-offset-8 pt-4">
        <div class="grid">
          <div class="col-6 col-offset-6">
            <deuna-button label="Actualizar" [icon]="false" (eventClick)="updateConfig007()"></deuna-button>
          </div>
        </div>
      </div>
    </app-card>
  </div>
  <div class="col-8 col-offset-2">
    <app-card header="Datos de notificación" subHeader="Estos datos nos ayudarán a notificar a la sucursal cuando un cliente realiza un pago.">
      <deuna-checklist [form]="config009Checks" (eventOnChange)="onChangeChecks()" [options]="notifications" label="¿Cómo quieres notificar a la Sucursal cuando reciba pagos?" name="notification1" control="notification1"></deuna-checklist>
      <deuna-input *ngIf="enabledSms" label="Número de teléfono" placeholder="Ej: 0999999999" name="phone" control="phone" [form]="config009" [maxLength]="200" appOnlyNumber></deuna-input>
      <deuna-input *ngIf="enabledEmail" [masked]="false" label="Correo" placeholder="Ej: email@dominio.com" name="email" control="email" [maxLength]="200" [form]="config009"></deuna-input>
      <div class="col-offset-8 pt-4">
        <div class="grid">
          <div class="col-6 col-offset-6">
            <deuna-button [label]="'Actualizar'" [icon]="false" (eventClick)="updateConfig()"></deuna-button>
          </div>
        </div>
      </div>
    </app-card>
  </div>

  <div class="col-8 col-offset-2">
    <app-card header="Agregar cajas a la sucursal" subHeader="Esta información nos ayudara a generar QR y enlaces personalizados para el negocio">
      <deuna-input [masked]="false" styleClass="mt-4" [form]="formBranchBox" label="Número de cajas a agregar" name="numberBox" control="numberBox" [maxLength]="100" appOnlyNumber>
      </deuna-input>
      <ng-container *ngIf="formBranchBox.get('numberBox')?.invalid">
        <small id='POS-length-error' *ngIf="formBranchBox.get('numberBox')?.errors && formBranchBox.get('numberBox')?.touched" class="p-error">
          El número de cajas debe ser menor a 100.
        </small>
      </ng-container>
      <div class="col-offset-8 pt-1">
        <div class="grid">
          <div class="col-6 col-offset-6">
            <deuna-button [label]="'Actualizar'" [icon]="false" (eventClick)="updateQuantityBoxesBranch()"></deuna-button>
          </div>
        </div>
      </div>
    </app-card>
  </div>

  <div class="col-8 col-offset-2">
    <app-card header="Gestión de QR/link" subHeader="Define el tipo de recursos que usa esta sucursal.">
      <deuna-radio-button label="¿QR/link opera con monto predefinido?" styleClassItem="pt-2" [values]="amountRequiredOptions" styleClass="pt-2" control="amountRequired" [form]="formCN017"></deuna-radio-button>
      <div class="col-offset-8 pt-1">
        <div class="grid">
          <div class="col-6 col-offset-6">
            <deuna-button [label]="'Guardar'" [icon]="false" (eventClick)="updateFormCN017()"></deuna-button>
          </div>
        </div>
      </div>
    </app-card>
  </div>
</div>

<deuna-modal [displayModal]="hasUpdateBoxError" [buttonFooter]="true" [image]="image" alt="image" (onAccept)="goBackBranchList()" (onHide)="goBackBranchList()" headerModalText="{{updateBoxError.reason}}" textModalText="{{updateBoxError.details}}" labelAcceptButton="Entendido" labelCancelButton="No, continuar editando">
</deuna-modal>