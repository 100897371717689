<app-empty-list
  *ngIf="!existsBranchs"
  [img]="emptyReportInactive"
  title="Lo sentimos, aún no puedes programar reportes"
  subtitle="Primero debes ingresar las sucursales de este comercio."
></app-empty-list>
<app-empty-list
  *ngIf="existsBranchs && reportList.length == 0 && !loadList && paramsQuery.search === ''"
  [img]="emptyReportActive"
  title="Comienza a programar reportes para este negocio"
  (clickChip)="redirectCreated()"
  subtitle="Para crear uno haz click en “Programar reporte”. Recuerda que el documento llegará al correo que ingreses."
  [button]="true"
  buttonText="+ Programar reporte"
></app-empty-list>
<p-toast position="bottom-right" key="br" [baseZIndex]="10000">
  <ng-template let-message pTemplate="message">
    <div class="flex" style="flex: 1">
      <div class="text-center">
        <i
          class="material-icons material-icons-outlined"
          style="vertical-align: middle; padding-right: 4px"
          aria-hidden="true"
          >done</i
        >
        <span style="vertical-align: middle">{{ message.summary }}</span>
      </div>
    </div>
  </ng-template>
</p-toast>
<div *ngIf="existsBranchs && (reportList.length > 0 || (reportList.length === 0 &&  paramsQuery.search !== '') || loadList)">
  <div class="grid mx-5">
    <div class="col-5">
      <deuna-input
        [masked]="false"
        styles="background:#F1F2F5"
        [form]="formSearch"
        placeholder="Buscar un No de ID o palabra clave"
        [maxLength]="200"
        name="textSearch"
        control="textSearch"
        (keyUpData)="goToSearchKeys($event)"
      >
      </deuna-input>
    </div>
    <div class="col-7">
      <div class="grid">
        <div
          class="col-offset-3 col-9 flex align-items-center justify-content-end"
        >
          <div class="ml-4">
            <deuna-button
              label="+ Programar reporte"
              (eventClick)="redirectCreated()"
            >
            </deuna-button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 text-center" *ngIf="loadList">
      <p-progressBar
        mode="indeterminate"
        [style]="{ height: '6px' }"
      ></p-progressBar>
      <h2 class="mt-2">Cargando....</h2>
    </div>
    <div class="col-12" *ngIf="!loadList">
      <ng-template> Filtro aplicados </ng-template>
      <deuna-table [arrayData]="[1]" [columnas]="headers">
        <ng-container *ngFor="let item of reportList; let i = index">
          <tr (click)="redirectEdit(item.id, item.level)">
            <deuna-table-cell>
              <p>{{ item["type"] }}_{{item["id"]}}</p>
            </deuna-table-cell>
            <deuna-table-cell>{{ item["level"] }}</deuna-table-cell>
            <deuna-table-cell>{{ item["branches"] }}</deuna-table-cell>
            <deuna-table-cell>{{ item["daysFrequency"] }}</deuna-table-cell>
            <deuna-table-cell>{{ item["cutOffTime"] }}</deuna-table-cell>
            <deuna-table-cell>{{ item["createdBy"] }}</deuna-table-cell>
            <deuna-table-cell>
              {{ item.level === 'Unificado' ? (item["updateDate"] | date : "dd/MM/yyyy") + ' por ' + item["updatedBy"] : item["updateDate"]}}
            </deuna-table-cell>
            <deuna-table-cell
              (click)="redirectDownloadable($event, item.id, item.level)"
            >
              <span
                class="text-style-back"
              >
                Ver reportes
              </span>
            </deuna-table-cell>
            <deuna-table-cell>
              <deuna-button
                *ngIf="item.level === 'Unificado'"
                deUnaClass="p-button-rounded p-button-text with-auto p-button-help"
                (eventClick)="deletedReport($event, item.id)"
              >
                <div class="icon">
                  <i
                    aria-hidden="true"
                    class="material-icons material-icons-lined"
                    >delete</i
                  >
                </div>
              </deuna-button>
            </deuna-table-cell>
          </tr>
        </ng-container>
      </deuna-table>
      <deuna-pagination
        (paginaEmitter)="goToPageTable($event)"
        (itemsEmmiter)="changeToItemsTable($event)"
        [currentPage]="dataPagination.currentPage"
        [limitPage]="dataPagination.itemsPerPage"
        [totalItems]="dataPagination.totalItems"
        [totalPages]="dataPagination.totalPages"
      >
      </deuna-pagination>
    </div>
  </div>
</div>
