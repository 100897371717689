import { Component, EventEmitter, Input, Output } from '@angular/core';
import { assetUrl } from '@deuna/bp-sh-helpers-ng';

@Component({
  selector: 'app-empty-list-return',
  templateUrl: './empty-list-return.component.html',
  styleUrls: ['./empty-list-return.component.scss'],
})
export class EmptyListReturnComponent {
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() img: string = assetUrl('png/create_error.png');
  @Output() onSubmitAccept = new EventEmitter();
  @Output() onSubmitCancel = new EventEmitter();
  @Input() buttonAccept: boolean = false;
  @Input() buttonCancel: boolean = false;
  @Input() textButtonAccept: string = '';
  @Input() textButtonCancel: string = '';

  clickAccept() {
    this.onSubmitAccept.emit();
  }

  clickCancel() {
    this.onSubmitCancel.emit();
  }
}
