import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-image-textvisible',
  templateUrl: './image-textvisible.component.html',
  styleUrls: ['./image-textvisible.component.scss'],
})
export class ImageTextvisibleComponent {
  @Input() image: string = '';
  @Input() text: string = '';
  @Input() avatar: string = '';
}
