<div class="grid">
    <div class="col p-0">
        <ng-content select=".content-app"></ng-content>
    </div>
    <div *ngIf="showSide" class="col-fixed side-lateral p-0 px-0">
        <div class="grid m-3">
            <div class="col-10">
                <span class="title-side">
                    {{title}}
                </span>
            </div>
            <div class="col-2 text-right pt-3 pr-3">
                <span (click)="clickClose()" style="cursor: pointer;">
                    X
                </span>
            </div>
        </div>
        <ng-content class="grid m-3" select=".content-side"></ng-content>
    </div>
</div>
