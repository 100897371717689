<div>
  <div class="grid mx-5">
    <div class="col-5 mt-3" *ngIf="showSearchField()">
      <deuna-input
        [masked]="false"
        styles="background:#F1F2F5"
        [form]="formSearch"
        placeholder="Buscar por nombre de archivo"
        [maxLength]="200"
        name="textSearch"
        control="textSearch"
      >
      </deuna-input>
    </div>

    <div class="col-12 text-center" *ngIf="loading">
      <p-progressBar
        mode="indeterminate"
        [style]="{ height: '6px' }"
      ></p-progressBar>
      <h2 class="mt-2">Cargando....</h2>
    </div>

    <div
      *ngIf="!loading && !reportFileList?.length"
      class="container-center col-12"
      style="display: flex; justify-content: center"
    >
      <div style="text-align: center">
        <img
          loading="lazy"
          [src]="emptyReportInactive"
          alt="image"
          style="width: 40%"
        />
        <div>
          <h1 class="fontLoadHeader mb-4" style="margin: auto">
            No hay archivos descargables para este reporte programado
          </h1>
          <h2 class="fontLoadSubHeader">
            Recuerda que este espacio solo muestra los archivos generados en los últimos 30 días.
          </h2>
        </div>
      </div>
    </div>

    <div class="col-12" *ngIf="!loading && !!reportFileList?.length">
      <deuna-table [arrayData]="[1]" [columnas]="headers">
        <ng-container *ngFor="let item of reportFileList; let i = index">
          <tr>
            <deuna-table-cell>{{ item["fileName"] }}</deuna-table-cell>
            <deuna-table-cell>{{ item["createDate"] | date:"dd/LL/YYYY" }}</deuna-table-cell>
            <deuna-table-cell>{{ item["createDate"] | date:"HH:mm" }}</deuna-table-cell>
            <deuna-table-cell>
              <deuna-button
                deUnaClass="p-button-rounded p-button-text with-auto p-button-help"
                (eventClick)="downloadButton(item.id)"
              >
                <div class="icon">
                  <i
                    aria-hidden="true"
                    class="material-icons material-icons-lined"
                    >download</i
                  >
                </div>
              </deuna-button>
            </deuna-table-cell>
          </tr>
        </ng-container>
      </deuna-table>
      <deuna-pagination
        (paginaEmitter)="onPageChange($event)"
        (itemsEmmiter)="onLimitChange($event)"
        [currentPage]="dataPagination.currentPage"
        [limitPage]="dataPagination.itemsPerPage"
        [totalItems]="dataPagination.totalItems"
        [totalPages]="dataPagination.totalPages"
      >
      </deuna-pagination>
    </div>
  </div>
</div>