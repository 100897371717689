<div class="main">
    <section class="form">
        <form (ngSubmit)="onSubmit()" autocomplete="off">
            <div class="mb-5 text-style-back" routerLink="{{relativeRoute+'/step-initial'}}">
                Regresar
            </div>
            <div class="title">
                <strong>Queremos conocer más sobre este nuevo negocio</strong>
            </div>
            <deuna-radio-button label="¿El negocio pertenece a un grupo?" styleClassItem="pt-2" [values]="checkAssociationOptions" styleClass="pt-2" control="hasGroup" [form]="formCheckAssociation"></deuna-radio-button>

            <div class="col-12">
                <deuna-button label="Siguiente" type="submit"></deuna-button>
            </div>

        </form>
    </section>
    <section class="brand">
        <img [src]="merchantIconImg" alt="Merchant Icon" />
    </section>
</div>