<deuna-loading
  [titulo]="'Cargando información'"
  *ngIf="loadPage"
  [subtitulo]="'Por favor, espera un momento'"
></deuna-loading>
<deuna-modal
  [displayModal]="showDeleteModal"
  (onAccept)="deleteReport()"
  (onHide)="hideDeleteModal()"
  headerModalText="¿Quieres eliminar esta sucursal del reporte?"
  textModalText="Al seleccionar “Sí”, se eliminará la sucursal que forma parte de este reporte programado."
  labelAcceptButton="Sí"
  labelCancelButton="No"
></deuna-modal>
<p-toast position="bottom-right" key="br" [baseZIndex]="10000">
  <ng-template let-message pTemplate="message">
    <div class="flex" style="flex: 1">
      <div class="text-center">
        <i
          class="material-icons material-icons-outlined"
          style="vertical-align: middle; padding-right: 4px"
          aria-hidden="true"
          >done</i
        >
        <span style="vertical-align: middle">{{ message.summary }}</span>
      </div>
    </div>
  </ng-template>
</p-toast>
<div *ngIf="!loadPage" class="content-app">
  <div class="grid mt-2 mx-5">
    <div id="breadcrumb" class="col-12 py-5" style="font-size: 15px">
      <span class="text-style-back mt-2" [routerLink]="[relativeRoute, 'list']">
        Negocios afiliados
      </span>
      <span
        class="text-style-back mt-2 mx-1"
        style="text-decoration: none; cursor: auto"
        >/</span
      >
      <span
        class="text-style-back mt-2"
        [routerLink]="[relativeRoute, 'info', merchantState.id]"
        [queryParams]="{ tab: 'reportsProgram' }"
      >
        {{this.merchantState.comercialName}}
      </span>
      <span
        class="text-style-back mt-2 mx-1"
        style="text-decoration: none; cursor: auto"
        >/</span
      >
      <span
        class="text-style-back mt-2"
        style="text-decoration: none; cursor: auto; color: #4f596f"
        >{{reportType}}-{{reportId}}</span
      >
    </div>
    <div class="col pl-4">
      <div class="grid">
        <div class="col-12">
          <span class="titles">{{reportType}}-{{reportId}}</span>
        </div>
        <div class="col-12" style="color: #4f596f">
          <span>Reporte de ventas | Por Sucursal</span>
        </div>

        <div class="col-12 mb-4">
          <deuna-tab-menu [items]="items" [activeItem]="activeItem"></deuna-tab-menu>
        </div>

        <div class="col-12" *ngIf="activeItem.id === 'configuration'">
          <div class="col-5">
            <deuna-input
              [masked]="false"
              styles="background:#F1F2F5"
              [form]="formSearch"
              placeholder="Buscar por palabra clave"
              [maxLength]="200"
              name="textSearch"
              control="textSearch"
            >
            </deuna-input>
          </div>
          <div class="col-12 text-center" *ngIf="loadList">
            <p-progressBar
              mode="indeterminate"
              [style]="{ height: '6px' }"
            ></p-progressBar>
            <h2 class="mt-2">Cargando....</h2>
          </div>
          <div class="col-12" *ngIf="!loadList">
            <ng-template> Filtro aplicados </ng-template>
            <deuna-table [arrayData]="[1]" [columnas]="headers">
              <ng-container *ngFor="let item of reportList; let i = index">
                <tr (click)="redirectEdit($event, item.id)">
                  <deuna-table-cell>{{ item["branchName"] }}</deuna-table-cell>
                  <deuna-table-cell>{{ item["branchId"] }}</deuna-table-cell>
                  <deuna-table-cell
                    >Cada {{ item["daysFrequency"] }} horas</deuna-table-cell
                  >
                  <deuna-table-cell>{{ item["mails"][0] }}</deuna-table-cell>
                  <deuna-table-cell>{{ item["mails"][1] ?? '-' }}</deuna-table-cell>
                  <deuna-table-cell>
                    <deuna-button
                      deUnaClass="p-button-rounded p-button-text with-auto p-button-help"
                      (eventClick)="redirectEdit($event, item.id)"
                    >
                      <div class="icon">
                        <i
                          aria-hidden="true"
                          class="material-icons material-icons-lined"
                          >edit</i
                        >
                      </div>
                    </deuna-button>
                    <deuna-button
                      (eventClick)="handleShowDeleteModal($event, item)"
                      deUnaClass="p-button-rounded p-button-text with-auto p-button-help"
                    >
                      <div class="icon">
                        <i
                          aria-hidden="true"
                          class="material-icons material-icons-lined"
                          >delete</i
                        >
                      </div>
                    </deuna-button>
                  </deuna-table-cell>
                </tr>
              </ng-container>
            </deuna-table>
            <deuna-pagination
              (paginaEmitter)="goToPageTable($event)"
              (itemsEmmiter)="changeToItemsTable($event)"
              [currentPage]="dataPagination.currentPage"
              [limitPage]="dataPagination.itemsPerPage"
              [totalItems]="dataPagination.totalItems"
              [totalPages]="dataPagination.totalPages"
            >
            </deuna-pagination>
          </div>
        </div>

        <div class="col-12" *ngIf="activeItem.id == 'downloadable'">
          <app-reports-list-table [reportsList]="reportIdList"></app-reports-list-table>
        </div>
      </div>
    </div>
  </div>
</div>
