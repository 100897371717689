import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { clientRoute } from 'src/app/constants/routes';
import { IFormGroupReport } from 'src/app/shared/interfaces/form-group.interface';
import { ConfigurationFlowReportDataService } from 'src/app/services/config/report/configuration-flow-data-report.service';

@Component({
  selector: 'app-tab-update-report',
  templateUrl: './tab-update-report.component.html',
  styleUrls: ['./tab-update-report.component.scss'],
})
export class TabUpdateReportComponent implements OnInit {
  @Input() existsBranchs: boolean = false;
  @Input() reportId!: string;
  @Output() closeSide = new EventEmitter();

  relativeRoute = clientRoute;
  merchantId!: string;
  formReportData!: IFormGroupReport;
  loadData: boolean = true;
  isEditable: boolean = false;
  isEditableEmails: boolean = true;
  reportName: string = 'Reporte de ventas';
  levelReport: string = '';
  typeReport: string = '';
  isUnifiedReport!: boolean;
  maximumAllowedEmails!: number;
  maxAllowedEmailsUnified = 10;
  maxAllowedEmailsByBranch = 2;

  constructor(
    private configurationFlowReportDataService: ConfigurationFlowReportDataService
  ) {}

  ngOnInit(): void {
    this.close();
    this.init();
  }

  async init() {
    await this.configurationFlowReportDataService.setFormDataReport(
      this.reportId
    );
    this.formReportData = this.configurationFlowReportDataService.getForm();
    this.merchantId = this.formReportData.initial.controls['merchantId']?.value;
    const levelValue = this.formReportData.initial.controls['level']?.value;
    this.levelReport = levelValue === 'BRANCH' ? 'Por sucursal' : 'Unificado';
    this.isUnifiedReport = this.isUnified();
    this.maximumAllowedEmails = this.isUnified() ? this.maxAllowedEmailsUnified : this.maxAllowedEmailsByBranch;
    this.loadData = false;
  }

  private isUnified() {
    return this.levelReport === 'Unificado';
  }

  close() {
    this.closeSide.emit();
  }
}