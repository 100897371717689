import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { pathUrl } from '@deuna/bp-sh-helpers-ng';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ICatalogue,
  ICatalogueCanton,
  ICatalogueSourceChannel,
  ICatalogueSubCategory,
} from '../../shared/interfaces/calatogue.interface';

@Injectable()
export class CataloguesService {
  private url: string = pathUrl();

  mapFunctionCatalogue = (res, concat = false, key = '') => {
    return res.map((item) => {
      const response = {
        code: item.code,
        name: concat
          ? `${item.name} +${item.code}`
          : this.toTitleCase(item.name),
      };
      if (key !== '') {
        response[key] = item[key];
      }
      return response;
    });
  };

  constructor(private http: HttpClient) {}

  toTitleCase(str: string): string {
    return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
      return match.toUpperCase();
    });
  }

  getProvinces(): Observable<Array<ICatalogue>> {
    return this.http
      .get<Array<ICatalogue>>(`${this.url}assets/json/provinces.json`)
      .pipe(
        map((res) => {
          return this.mapFunctionCatalogue(res);
        })
      );
  }

  getCantons(): Observable<Array<ICatalogueCanton>> {
    return this.http
      .get<Array<ICatalogueCanton>>(`${this.url}assets/json/cantons.json`)
      .pipe(
        map((res) => {
          return this.mapFunctionCatalogue(res, false, 'province');
        })
      );
  }

  getCategories(): Observable<Array<ICatalogue>> {
    return this.http.get<Array<ICatalogue>>(
      `${this.url}assets/json/categories.json`
    );
  }

  getTaxationRegime(): Observable<Array<ICatalogue>> {
    return this.http.get<Array<ICatalogue>>(
      `${this.url}assets/json/taxationRegime.json`
    );
  }

  getSegments(): Observable<Array<ICatalogue>> {
    return this.http.get<Array<ICatalogue>>(
      `${this.url}assets/json/segments.json`
    );
  }

  getRegions(): Observable<Array<ICatalogue>> {
    return this.http
      .get<Array<ICatalogue>>(`${this.url}assets/json/regions.json`)
      .pipe(
        map((res) => {
          return this.mapFunctionCatalogue(res);
        })
      );
  }

  getCountriesCode(): Observable<Array<ICatalogue>> {
    return this.http
      .get<Array<ICatalogue>>(`${this.url}assets/json/countries.json`)
      .pipe(
        map((res) => {
          return this.mapFunctionCatalogue(res, true);
        })
      );
  }

  getAccountTypes(): Observable<Array<ICatalogue>> {
    return this.http.get<Array<ICatalogue>>(
      `${this.url}assets/json/accountType.json`
    );
  }

  getSubcategory(): Observable<Array<ICatalogueSubCategory>> {
    return this.http
      .get<Array<ICatalogueSubCategory>>(
        `${this.url}assets/json/subcategories.json`
      )
      .pipe(
        map((res) => {
          return this.mapFunctionCatalogue(res, false, 'category');
        })
      );
  }

  getBusinessType(): Observable<Array<ICatalogue>> {
    return this.http
      .get<Array<ICatalogue>>(`${this.url}assets/json/businessType.json`)
      .pipe(
        map((res) => {
          return this.mapFunctionCatalogue(res);
        })
      );
  }

  getSourceChannels(): Observable<Array<ICatalogueSourceChannel>> {
    return this.http
      .get<Array<ICatalogueSourceChannel>>(
        `${this.url}assets/json/sourceChannels.json`
      );
  }
}
