import { Component, EventEmitter, OnInit, Output, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ConfigurationDataService } from 'src/app/services/config/merchant/configuration-data.service';
import { ConfigName } from 'src/app/shared/interfaces/configuration-merchant.enum';
import { IFormGroup } from 'src/app/shared/interfaces/form-group.interface';
const { BANK_ACCOUNT_COLLECTIONS, BANK_COMMISSION, BILLING_INFORMATION } =
  ConfigName;

@Component({
  selector: 'app-tab-financial-information',
  templateUrl: './tab-financial-information.component.html',
  styleUrls: ['./tab-financial-information.component.scss'],
})
export class TabFinancialInformationComponent implements OnInit, OnDestroy {
  @Output() closeSide = new EventEmitter();
  isEditable: boolean = true;
  isFlowUpdate: boolean = true;
  formConfigData!: IFormGroup;
  loadData: boolean = true;
  merchantId: string = '';
  CN003!: FormGroup;
  CN012!: FormGroup;
  CN013!: FormGroup;
  isCN012Created: boolean = false;
  isCN013Created: boolean = false;
  configs: Array<string> = [
    BANK_ACCOUNT_COLLECTIONS,
    BANK_COMMISSION,
    BILLING_INFORMATION,
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private configDataService: ConfigurationDataService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  ngOnDestroy(): void {
    this.isCN012Created = false;
    this.isCN013Created = false;
    this.CN003.reset();
    this.CN012.reset();
    this.CN013.reset();
  }
  
  showToast() {
    this.messageService.add({
      key: 'br',
      severity: 'deuna',
      summary: 'Guardado exitosamente',
      closable: false,
    });
  }

  async initData() {
    this.merchantId = this.getMerchantIdFromParams() || '';
    await this.configDataService.setFormDataMerchant(
      this.merchantId,
      this.configs
    );

    this.formConfigData = this.configDataService.getForm();

    this.CN003 = this.formConfigData.CN003;
    this.CN012 = this.formConfigData.CN012;
    this.CN013 = this.formConfigData.CN013;
    this.loadData = false;
    this.isCN012Created = Object.values(this.CN012.value).every(value => value !== null); 
    this.isCN013Created = Object.values(this.CN013.value).every(value => value !== null); 
  }
  getMerchantIdFromParams() {
    return this.activatedRoute.snapshot.paramMap.get('merchantId');
  }
}
