import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-card-general-commercial-group',
  templateUrl: './card-general-commercial-group.component.html',
})
export class CardGeneralCommercialGroupComponent {
  @Input() isEditable: boolean = false;
  @Input() isFlowUpdate: boolean = true;
  @Input() search!: FormGroup;
  @Input() formCN000!: FormGroup;
  loading: boolean = false;
}
