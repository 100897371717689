import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';

import { GraphqlMerchantService } from '../../../../services/graphql/graphql-merchant.service';
import { clientRoute } from 'src/app/constants/routes';
import { MerchantItem } from 'src/app/shared/interfaces/query-merchants';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.page.html',
  styleUrls: ['./settings.page.scss'],
})
export class SettingsPage implements OnInit {
  relativeRoute = clientRoute;
  load: boolean = true;
  items: Array<MenuItem> = [];
  activeItem: MenuItem = {};
  merchantId: string = '';
  merchant: Partial<MerchantItem> = {
    id: '',
    identification: '',
    comercialName: '',
    businessName: '',
  };

  constructor(
    private graphqlMerchantService: GraphqlMerchantService,
    public activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getInitial();
  }

  async getInitial() {
    this.merchantId = this.activatedRoute.snapshot.params['merchantId'];

    if (this.merchantId) {
      try {
        const getMerchantByIdRequest =
          this.graphqlMerchantService.getMerchantById(
            this.merchantId,
            `
              id
              identification
              comercialName
              businessName
            `
          );
        this.merchant = (
          await lastValueFrom(getMerchantByIdRequest)
        ).data.getClient;
        this.load = false;
      } catch (error) {
        this.load = false;
        this.router.navigate([`${clientRoute}/list`]);
      }
    }

    this.items = this.getTabs(this.merchantId);

    this.activatedRoute.queryParams.subscribe((params) => {
      const tab = params['tab'];
      let band = true;
      if (tab) {
        const index = this.items.findIndex((x) => x.id === tab);
        if (index >= 0) {
          band = false;
          this.activeItem = this.items[index];
        }
      }
      if (band) {
        this.router.navigate([`${clientRoute}/settings`, this.merchantId], {
          queryParams: { tab: this.items[2].id },
        });
        this.activeItem = this.items[2];
      }
    });
  }

  private redirectTab = (item, merchantId) => {
    this.router.navigate([`${clientRoute}/settings`, merchantId], {
      queryParams: { tab: item.item.id },
    });
    this.activeItem = item.item;
  };

  private getTabs = (merchantId) => {
    return [
      {
        label: 'Ajuste métodos de pago',
        command: (item) => this.redirectTab(item, merchantId),
        id: 'paymentMethods',
      },
      {
        label: 'Integraciones',
        id: 'integrations',
        command: (item) => this.redirectTab(item, merchantId),
      },
      {
        label: 'Gestión de cuentas',
        command: (item) => this.redirectTab(item, merchantId),
        id: 'accounts',
      },
    ];
  };

  reloadList() {
    window.location.reload();
  }

  addNewAccount() {
    this.router.navigate(['step-initial'], { relativeTo: this.activatedRoute });
  }
}