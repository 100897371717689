<div class="line-container">
  <deuna-input-action
    [form]="form"
    name="accountNumber"
    control="accountNumber"
    (onValidate)="handleValidate()"
    [state]="accountInputState"
    [disabled]="isButtonClicked"
    (input)="resetInputState()"
  ></deuna-input-action>
  <div class="account-owner-container">
    <div class="account-owner-title">{{ accountOwnerHeaderTitle }}</div>
    <div class="account-owner" [ngClass]="{'red-font': accountHolderName && responseMessage}">{{ accountHolderName }}
    </div>
    <div [ngClass]="{'error-message': isAccountNotSet() || isAccountNotValidated()}">
      <small
        *ngIf="isAccountNotSet() || isAccountNotValidated()"
        class="red-font-message"
      >
        -
        <br>
        Debes de validar esta cuenta
      </small>
      <div class="account-owner"
           [ngClass]="{'red-font-message': accountHolderName && responseMessage  && isButtonClicked}">
        {{ responseMessage }}
      </div>
    </div>
  </div>
</div>
