<container-element [ngSwitch]="tableStatus">
  <div class="col-12 text-center" *ngSwitchCase="'LOADING'">
    <p-progressBar
      mode="indeterminate"
      [style]="{'height': '6px'}"
    ></p-progressBar>
    <h2 class="mt-2">Cargando...</h2>
  </div>
  <ng-container *ngSwitchCase="'COMPLETE'"
    ><div class="grid mx-5">
      <div class="col-12">
        <div class="grid">
          <div class="col-5">
            <deuna-input
              [masked]="false"
              [masked]="false"
              styles="background:#F1F2F5"
              [form]="formFilterGroup"
              placeholder="Buscar un No de ID o palabra clave"
              name="textSearchGroup"
              control="textSearchGroup"
              [maxLength]="200"
            >
            </deuna-input>
          </div>  
        </div>
        <deuna-table [arrayData]="[1]" [columnas]="columns">
          <ng-container *ngFor="let merchant of merchantsList">
            <tr>
              <deuna-table-cell routerLink="{{relativeRoute+'/commercial-group/info/'+merchant.nodeId}}" style="cursor: pointer">
                <div class="grid my-autoSipo">
                  <div class="my-auto p-2 box-icon">
                    <img [src]="defaultAvatar" alt="image" style="width: 3rem; height: 3rem" />
                  </div>
                  <div class="col my-auto" style="text-decoration: none; color: black">
                    {{merchant.configData.comercialName}}
                  </div>
                </div>
              </deuna-table-cell>
              <deuna-table-cell>
                {{merchant.configData.identification}}
              </deuna-table-cell>
              <deuna-table-cell>
                <ng-container
                *ngIf="merchant.status =='A'; else Incomplete"
              >
                <deuna-chip
                  backgroundColor="#ECF9EF"
                  color="#236936"
                  [text]="merchant.status === 'A' ? 'Activo' : 'Inactivo'" 
                >
                </deuna-chip>
              </ng-container>
              <ng-template #Incomplete>
                <deuna-chip
                  backgroundColor="#FDF4E6"
                  color="#835200"
                  [text]="merchant.status"
                >
                </deuna-chip>
              </ng-template>
              </deuna-table-cell>
              <deuna-table-cell></deuna-table-cell>
              <deuna-table-cell></deuna-table-cell>
            </tr>
          </ng-container>
        </deuna-table>
        <deuna-pagination
          (paginaEmitter)="goToPage($event)"
          (itemsEmmiter)="changeToItems($event)"
          [currentPage]="dataPagination.currentPage"
          [limitPage]="dataPagination.itemCount"
          [totalItems]="dataPagination.totalItems"
          [totalPages]="dataPagination.totalPages"
        >
        </deuna-pagination>
      </div>
    </div>
  </ng-container>
  <div
    *ngSwitchCase="'EMPTY'"
    class="container-center col-12"
    style="display: flex; justify-content: center"
  >
    <div style="text-align: center">
      <img
        loading="lazy"
        [src]="imgNotFoundMerchants"
        alt="image"
        style="width: 40%"
      />
      <div>
        <h2 class="fontLoadHeader" style="margin: auto">
          Ups, parece que no hay grupos comerciales por gestionar
        </h2>
        <h2 class="fontLoadSubHeader">
          Sé la primera persona en crear uno, selecciona “Nuevo negocio”
        </h2>
      </div>
    </div>
  </div>
</container-element>
