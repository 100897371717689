import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { assetUrl } from '@deuna/bp-sh-helpers-ng';
import { Subscription } from 'rxjs';
import { clientRoute } from 'src/app/constants/routes';
import {
  options,
  optionsTypeNotification,
} from '../../../../../../constants/catalogos/mocks-catalogo';
import { ConfigurationFlowBranchService } from '../../../../../../services/config/branch/configuration-flow-branch.service';
import { CataloguesService } from '../../../../../../services/http/catalogues.service';
import {
  ICatalogue,
  ICatalogueCanton,
} from '../../../../../../shared/interfaces/calatogue.interface';

interface IError {
  reason: string;
  details: string;
}

@Component({
  selector: 'app-step-branch',
  templateUrl: './step-branch.page.html',
  styleUrls: ['./step-branch.page.scss'],
})
export class StepBranchPage implements OnInit, OnDestroy {
  relativeRoute = clientRoute;
  formStepBranch!: FormGroup;
  idMerchantCreated: string = '';
  load: boolean = false;
  optionsStepBranch: Array<ICatalogue> = [];
  provinces: Array<ICatalogue> = [];
  cantons: Array<ICatalogueCanton> = [];
  cantonsSelect: Array<ICatalogueCanton> = [];
  phonePayCodes: Array<ICatalogue> = [];
  typeNotificationStepBranch: Array<ICatalogue> = [];
  subActivatedRoute!: Subscription;
  displayModal: boolean = false;
  hasCreateSucursalError: boolean = false;
  createSucursalError: IError = {
    reason: '',
    details: '',
  };

  image = assetUrl('png/exit_logo.png');

  constructor(
    private router: Router,
    private cataloguesService: CataloguesService,
    private configFlowBranch: ConfigurationFlowBranchService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.initDataCatalogo();
  }

  ngOnDestroy(): void {
    this.subActivatedRoute?.unsubscribe();
  }

  initForm() {
    this.subActivatedRoute = this.activatedRoute.params.subscribe((params) => {
      this.idMerchantCreated = params['merchantId'];
    });
    const formBranch = this.configFlowBranch.getForm();
    this.formStepBranch = formBranch.createBranch;
    this.cantonsSelect = [];
  }

  initDataCatalogo() {
    this.optionsStepBranch = options;
    this.typeNotificationStepBranch = optionsTypeNotification;

    this.cataloguesService.getProvinces().subscribe((res) => {
      this.provinces = res;
    });

    this.cataloguesService.getCantons().subscribe((res) => {
      this.cantons = res;
      this.getCantons();
    });

    this.cataloguesService.getCountriesCode().subscribe((res) => {
      this.phonePayCodes = res;
    });
  }

  async onSubmit(): Promise<void> {
    this.formStepBranch.markAllAsTouched();
    try {
      if (!this.formStepBranch.valid) return;
      this.load = true;
      await this.configFlowBranch.createBranch(this.idMerchantCreated);
      this.router.navigate([`${clientRoute}/info`, this.idMerchantCreated]);
    } catch (error: any) {
      this.load = false;
      this.hasCreateSucursalError = true;
      this.createSucursalError.reason = error?.reason || 'DEFAULT ERROR';
      this.createSucursalError.details = error?.details || 'DEFAULT DETAIL';
    }
  }

  goBackSucursalList() {
    this.hasCreateSucursalError = false;
  }

  changeOfNotificationPay() {
    if (this.formStepBranch.get('email')?.value) {
      this.formStepBranch.controls['mailNotificationPay'].setValidators([
        Validators.required,
        Validators.email,
      ]);
      this.formStepBranch.get('sms')?.clearValidators();
    } else {
      this.formStepBranch.controls['mailNotificationPay'].clearValidators();
      this.formStepBranch.get('mailNotificationPay')?.setValue('');
    }

    if (this.formStepBranch.get('sms')?.value) {
      this.formStepBranch.controls['numberPhoneSMSPay'].setValidators(
        Validators.required
      );
      this.formStepBranch.get('sms')?.clearValidators();
    } else {
      this.formStepBranch.controls['numberPhoneSMSPay'].clearValidators();
      this.formStepBranch.get('numberPhoneSMSPay')?.setValue('');
    }

    if (
      !this.formStepBranch.get('email')?.value &&
      !this.formStepBranch.get('sms')?.value
    ) {
      this.formStepBranch.get('sms')?.setErrors(Validators.required);
    }

    this.formStepBranch.get('numberPhoneSMSPay')?.updateValueAndValidity();
    this.formStepBranch.get('mailNotificationPay')?.updateValueAndValidity();
  }

  onChangeSelect() {
    this.formStepBranch.get('canton')?.setValue('');
    this.getCantons();
  }

  getCantons() {
    this.cantonsSelect = [];
    if (this.formStepBranch.get('province')) {
      this.cantonsSelect = this.cantons.filter(
        (x) => +x.province === +this.formStepBranch.get('province')?.value?.code
      );
    }
  }

  resetTypeNotification() {
    if (Number(this.formStepBranch.get('optionNotification')?.value) === 1) {
      this.formStepBranch.get('sms')?.setValidators([Validators.required]);
    } else {
      this.formStepBranch.get('sms')?.clearValidators();
      this.formStepBranch.controls['mailNotificationPay'].clearValidators();
      this.formStepBranch.controls['numberPhoneSMSPay'].clearValidators();
      this.formStepBranch.get('sms')?.setValue('');
    }
    this.formStepBranch.get('sms')?.setValue('');
    this.formStepBranch.get('email')?.setValue('');
    this.formStepBranch.get('numberPhoneSMSPay')?.setValue('');
    this.formStepBranch.get('mailNotificationPay')?.setValue('');
  }

  openModal() {
    this.displayModal = true;
  }

  hideModal() {
    this.displayModal = false;
  }
}
