<div id="form-container" class="grid h-screen">
  <ng-container>
    <div class="col-8 col-offset-2">
      <div class="text-style-back mt-8 mb-4 mx-2 no-underline" (click)="goBack()">
        Regresar
      </div>
      <div class="col-12">
        <app-load-step [stepLoad]="50" heigth="12px"></app-load-step>
      </div>
      <div class="col-12">
        <strong class="titles">Agregar nuevo usuario</strong>
      </div>
      <div class="col-12 mt-2">
        <span>Ingresa el correo empresarial al cual deseas dar acceso.</span>
      </div>
      <form [formGroup]="stepInitialForm" (ngSubmit)="onSubmit()" autocomplete="off">
        <div class="col-12">
          <p-card styleClass="shadow-4 mt-2 p-5 mb-2">
            <div class="grid">
              <div class="col-6">
                <deuna-input
                  [masked]="false"
                  [form]="stepInitialForm"
                  label="Plataforma"
                  name="platform"
                  control="platform"
                  [disabled]="false"
                  type="text"
                ></deuna-input>
              </div>
              <div class="col-6">
                <deuna-input
                  [masked]="false"
                  [form]="stepInitialForm"
                  label="Permisos"
                  name="permissions"
                  control="permissions"
                  [disabled]="false"
                  type="text"
                ></deuna-input>
              </div>
              <div class="col-6">
                <deuna-input
                  [masked]="false"
                  styleClass="mt-4"
                  [form]="stepInitialForm"
                  label="Correo"
                  [maxLength]="50"
                  placeholder="Ej: usuario@ecorreo.com"
                  name="email"
                  control="email"
                ></deuna-input>
              </div>
            </div>
          </p-card>
        </div>
        <div class="col-12 flex gap-4" style="justify-content: end;">
          <deuna-button deUnaClass="flex flex-auto pr-5 bg-white" label="Cancelar" deUnaStyle="border: 2px solid #4c1d80; color: #4c1d80;" (click)="goBack()"></deuna-button>
          <deuna-button deUnaClass="flex flex-auto pr-5" label="Crear" type="submit"></deuna-button>
        </div>
      </form>
    </div>
  </ng-container>
  <deuna-modal
  [displayModal]="showConfirmationModal"
  (onAccept)="confirmCreateAccount()"
    (onHide)="hideConfirmationModal()"
    [image]="imgConfirm"
    [headerModalText]="confirmAccountModalText"
    textModalText="Al seleccionar sí, le llegará una invitación al usuario con acceso a la herramienta."
    labelAcceptButton="Sí"
    labelCancelButton="No"
  ></deuna-modal>
</div>