<div class="grid text-center pt-5">
    <div class="col-8 col-offset-2">
        <img [src]="image" alt="logo-page" class="w-4" style="margin-top: 2%;">
    </div>
    <div class="col-8 col-offset-2 pt-1">
        <div class="fontHeader">
            {{headerText}}
        </div>
        <div class="fontSubHeader" style="padding-top: 3%;">
            {{subHeaderText}}
        </div>
    </div>
    <div class="col-6 col-offset-3 pt-4">
        <div class="grid">
            <div class="col-6">
                <deuna-button [label]="buttonBack" deUnaClass="p-button-outlined" (eventClick)="continueBack()">
                </deuna-button>
            </div>
            <div class="col-6">
                <deuna-button [label]="buttonNext" (eventClick)="continueNext()"></deuna-button>
            </div>
        </div>
    </div>
</div>