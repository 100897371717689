import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { assetUrl } from '@deuna/bo-sh-helpers-ng';
import { ConfigurationFlowCreationService } from '../../../../../../services/config/commercial-group/configuration-flow-creation.service';
import { clientRoute } from 'src/app/constants/routes';
import { ClientType } from 'src/app/constants/merchant-constants';
import { IFormGroupCommercialGroup } from 'src/app/shared/interfaces/form-group.interface';

@Component({
  selector: 'app-step-1-commercial-group',
  templateUrl: './step-1.page.html',
  styleUrls: ['./step-1.page.scss'],
})
export class Step1Page implements OnInit {
  formGroupCommercialGroup!: IFormGroupCommercialGroup;
  search!: FormGroup;
  formCN000!: FormGroup;
  isEditable: boolean = false;
  avatar: string = assetUrl('png/avatar_merchant.png');
  nombreComercialImg = assetUrl('png/iphone 1.png');
  loading: boolean = false;
  relativeRoute = clientRoute;
  displayModal: boolean = false;
  clientTypes = ClientType;

  constructor(
    private router: Router,
    private configFlow: ConfigurationFlowCreationService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.initForm();
  }

  async initForm() {
    this.formGroupCommercialGroup = this.configFlow.getForm();
    this.search = this.formGroupCommercialGroup.search;
    this.formCN000 = this.formGroupCommercialGroup.CN000;
    this.formCN000.enable();
    const identification = this.search.controls['identification'].value;
    const identificationType = this.search.controls['identificationType'].value;
    const clientType = this.search.controls['clientType'].value;
    const cif = this.search.controls['cifCode'].value;
    if (!identification || identification === '') {
      this.router.navigate([`${clientRoute}/commercial-group/create/search`]);
    }
    this.formCN000.controls['identification'].setValue(identification);
    this.formCN000.controls['identificationType'].setValue(identificationType);
    this.formCN000.controls['clientType']?.setValue(clientType);
    this.formCN000.controls['clientAccountId']?.setValue(cif);
    this.search.disable();
    this.formCN000.disable();
    this.formCN000.controls['tradename'].enable();
    this.loading = false;
  }

  async onSubmit(): Promise<void> {
    this.formCN000.markAllAsTouched();
    if (this.formCN000.valid) {
      this.loading = true;
      const status = await this.configFlow.createCommercialGroup();
      this.router.navigate([
        `${clientRoute}/commercial-group/create/step-end/${status}`,
      ]);
    }
  }
  openModal() {
    this.displayModal = true;
  }

  hideModal() {
    this.displayModal = false;
  }
}
