<form (ngSubmit)="onSubmit()" class="grid my-5" autocomplete="off">
  <div class="col-8 col-offset-2">
    <div class="mb-5">
      <span class="text-style-back" routerLink="{{relativeRoute+'/create/step-1'}}">Regresar</span>
    </div>
    <app-load-step [stepLoad]="40"></app-load-step>
  </div>

  <ng-container *ngIf="type !== clientType.NATURAL; else naturalInformation">
    <div class="col-8 col-offset-2 pt-4">
      <app-card-address [isEditable]="isEditable" [formCN004]="CN004" [formCN005]="CN005"></app-card-address>
    </div>

    <div class="col-8 col-offset-2">
      <app-card-notification [isEditable]="true" [isFlowUpdate]="false" (toastEvent)="onSubmit()" [isCreationFlow]="true"></app-card-notification>
    </div>

    <div class="col-8 col-offset-2 pt-4">
      <app-card-source-support [isFlowUpdate]="false" [isEditable]="isEditable" [formCN019]="CN019"></app-card-source-support>
    </div>

    <div class="col-6 col-offset-4 pt-4">
      <div class="grid">
        <div class="col-6">
          <deuna-button label="Cancelar" deUnaClass="p-button-outlined" (eventClick)="openModal()">
          </deuna-button>
        </div>
        <div class="col-6">
          <deuna-button label="Siguiente" type="submit"></deuna-button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #naturalInformation>
    <div class="col-8 col-offset-2 pt-4">
      <app-card-address [isEditable]="isEditable" [formCN004]="CN004" [formCN005]="CN005"></app-card-address>
    </div>
    <div class="col-8 col-offset-2">
      <app-card-notification [isEditable]="true" [isFlowUpdate]="false" (toastEvent)="onSubmit()" [isCreationFlow]="true"></app-card-notification>
    </div>
    <div class="col-8 col-offset-2 pt-4">
      <app-card-data-usage [isCreationFlow]="true" [formCN015]="CN015"></app-card-data-usage>
    </div>
    <div class="col-8 col-offset-2 pt-4">
      <app-card-source-support [isFlowUpdate]="false" [isEditable]="isEditable" [formCN019]="CN019"></app-card-source-support>
    </div>
    <div class="col-6 col-offset-4 pt-4">
      <div class="grid">
        <div class="col-6">
          <deuna-button label="Cancelar" deUnaClass="p-button-outlined" (eventClick)="openModal()">
          </deuna-button>
        </div>
        <div class="col-6">
          <deuna-button label="Siguiente" type="submit"></deuna-button>
        </div>
      </div>
    </div>
  </ng-template>
</form>
<app-exit-modal [displayModal]="displayModal" (onHide)="hideModal()"></app-exit-modal>