export enum ConfigName {
  BUSSINESS_INFO = 'CN000',
  LEGAL_REPRESENTATIVE = 'CN001',
  SEGMENT = 'CN002',
  BANK_ACCOUNT_COLLECTIONS = 'CN003',
  LOCATION = 'CN004',
  CONTACT = 'CN005',
  NOTIFICATION_SETTING = 'CN006',
  BANK_COMMISSION = 'CN012',
  BILLING_INFORMATION = 'CN013',
  SOURCE_SUPPORT = 'CN019',
}
