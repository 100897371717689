import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client';
import { assetUrl } from '@deuna/bo-sh-helpers-ng';
import { GraphqlMerchantService } from 'src/app/services/graphql/graphql-merchant.service';
import {
  IQueryGroup,
  MerchantItem,
  ResponseMerchantsInCommercialGroup,
} from 'src/app/shared/interfaces/query-merchants';

enum TableStates {
  LOADING = 'LOADING',
  COMPLETE = 'COMPLETE',
  EMPTY = 'EMPTY',
}

@Component({
  selector: 'app-tab-related-merchants',
  templateUrl: './tab-list-related-merchants.component.html',
  styleUrls: [],
})
export class TabRelatedMerchantsComponent implements OnInit {
  columns = ['Nombre comercial', 'No de RUC', 'Razón social'];
  merchantsList: Array<MerchantItem> = [];
  avatar: string = assetUrl('svg/store-mall-directory.svg');
  paramsQuery!: IQueryGroup;

  resultfields = 'businessName comercialName identification';

  tableStatus: TableStates = TableStates.LOADING;

  constructor(
    private readonly merchantService: GraphqlMerchantService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.paramsQuery = { limit: 30, page: 1, status:'A'};

    this.getInitialData();
  }

  getInitialData() {
    this.activatedRoute.paramMap?.subscribe((params) => {
      const groupNodeId = params.get('nodeId') || '';
      this.paramsQuery.nodeId = Number(groupNodeId);
    });
    this.getMerchants();
  }

  getMerchants() {
    this.merchantsList = [];
    return new Promise<boolean>((_resolve, _reject) => {
      this.merchantService
        .getMerchantsByGroupNodeId(this.paramsQuery, this.resultfields)
        ?.subscribe({
          next: (
            res: ApolloQueryResult<ResponseMerchantsInCommercialGroup>
          ) => {
            if (res.data.getAllMerchantsByCommercialGroups) {
              this.merchantsList =
                res.data.getAllMerchantsByCommercialGroups.items;
            }
            this.tableStatus = TableStates.COMPLETE;
          },
        });
    });
  }
}
