import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { CataloguesService } from 'src/app/services/http/catalogues.service';
import {
  ICatalogue,
  ICatalogueSubCategory,
} from 'src/app/shared/interfaces/calatogue.interface';
import _ from 'lodash';
import { ConfigurationDataService } from 'src/app/services/config/merchant/configuration-data.service';

@Component({
  selector: 'app-card-natural',
  templateUrl: './card-natural.component.html',
})
export class CardNaturalComponent implements OnInit {
  businessType: Array<ICatalogue> = [];
  businessTypeRead: Array<ICatalogue> = [];
  subCategorySelected: Array<ICatalogueSubCategory> = [];
  segments: Array<ICatalogue> = [];
  accounts: Array<ICatalogue> = [];
  loading: boolean = false;

  @Input() search!: FormGroup;
  @Output() toastEvent: EventEmitter<void> = new EventEmitter<void>();
  formCN000TMP!: FormGroup;
  formCN002TMP!: FormGroup;
  categories: Array<ICatalogue> = [];
  subcategories: Array<ICatalogueSubCategory> = [];
  enableEdit: boolean = false;
  @Input() formCN000!: FormGroup;
  @Input() formCN002!: FormGroup;
  @Input() isEditable: boolean = false;
  @Input() isFlowUpdate: boolean = false;
  constructor(
    private configDataService: ConfigurationDataService,
    private cataloguesService: CataloguesService
  ) {}

  initData() {
    this.getDataCatalogs();
    this.search.disable();
    this.formCN002.controls['businessType'].setValue('PRI');
  }

  ngOnInit(): void {
    this.subCategorySelected = [];
    this.initData();
  }

  async getDataCatalogs() {
    const accountTypes$ = this.cataloguesService.getAccountTypes();
    const businessType$ = this.cataloguesService.getBusinessType();
    const subcategory$ = this.cataloguesService.getSubcategory();
    const categories$ = this.cataloguesService.getCategories();
    const segments$ = this.cataloguesService.getSegments();
    this.formCN002TMP = _.cloneDeep(this.formCN002);

    forkJoin([
      categories$,
      segments$,
      accountTypes$,
      businessType$,
      subcategory$,
    ]).subscribe(
      ([categories, segments, accountTypes, businessType, subcategory]) => {
        this.categories = categories;
        this.segments = segments.filter(
          (item) => item.code === 'PY' || item.code === 'MICRO'
        );
        this.subcategories = subcategory;
        this.businessType = businessType.filter((item) => item.code === 'PRI');
        this.accounts = accountTypes;
        this.getSubCategories();
      }
    );
  }

  getSubCategories() {
    this.subCategorySelected = [];
    if (this.formCN002.controls['category']) {
      this.subCategorySelected = this.subcategories.filter(
        (x) => x.category === this.formCN002.get('category')?.value?.code
      );
    }
  }

  onCancelEdit() {
    this.enableEdit = false;
    this.formCN002.patchValue(this.formCN002TMP.getRawValue());
  }

  handleEvent(data: boolean) {
    if (data) {
      this.formCN002.enable();
      this.enableEdit = true;

      const segment = this.segments.find(
        (item) => item.name === this.formCN002.controls['segment']?.value
      );
      const business = this.businessType.find(
        (item) => item.name === this.formCN002.controls['businessType']?.value
      );

      this.formCN002
        .get('category')
        ?.setValue(this.formCN002TMP.controls['category'].value);
      this.formCN002
        .get('subcategory')
        ?.setValue(this.formCN002TMP.controls['subcategory'].value);
      this.formCN002.get('businessType')?.setValue(business?.code);
      this.formCN002.get('segment')?.setValue(segment?.code);
    }
  }

  submit() {
    this.loading = true;
    this.formCN002.markAllAsTouched();

    if (!this.formCN002.valid) {
      this.loading = false;
      return;
    }
    return Promise.allSettled([
      this.configDataService.updateConfiguration('CN002'),
      this.configDataService.updateCN001Configuration(),
    ])
      .then(() => {
        this.formCN002TMP = _.cloneDeep(this.formCN002);

        this.enableEdit = false;
        this.loading = false;
      })
      .catch((err) => console.error({ err }));
  }

  onChangeCategorySelect() {
    this.formCN002.get('subcategory')?.setValue('');
    this.getSubCategories();
  }

  emitToast() {
    this.toastEvent.emit();
  }
}
