import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { Apollo, ApolloBase, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { ValidateAccount, ValidateAccountResponse } from '../../shared/interfaces/config-client.interfaces';

@Injectable()
export class GraphqlClientService {
  public apollo: ApolloBase;

  constructor(private readonly apolloProvider: Apollo) {
    this.apollo = this.apolloProvider.use('newClientName');
  }

  validateAccount(input: ValidateAccount): Observable<ApolloQueryResult<ValidateAccountResponse>> {
    return this.apollo.query({
      query: gql`
        query verifyAccount($input: AccountValidation!) {
          verifyAccount(accountValidation: $input) {
            canContinue
            message
            accountHolderName
          }
        }`,
      variables: {
        input,
      },
    });
  }
}
