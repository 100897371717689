<app-card
  header="Datos de facturación"
  [isFlowUpdate]="isFlowUpdate"
  [isEditable]="isEditable"
  (customEvent)="handleEvent($event)"
>
  <deuna-input
    [maxLength]="200"
    [masked]="false"
    [form]="formCN013"
    label="Correo para recepción de facturas"
    placeholder="Ej: micorreo@mail.com"
    name="email"
    styleClass="pt-2"
    control="email"
  >
  </deuna-input>
  <ng-container *ngIf="formCN013.get('email')?.invalid">
    <small
      *ngIf="formCN013.get('email')?.errors && formCN013.get('email')?.touched"
      class="p-error"
    >
      Correo no válido.
    </small>
  </ng-container>

  <div
    *ngIf="(isFlowUpdate && isEditable && !isFlowUpdate) || enableEdit"
    class="col-offset-6 pt-4"
  >
    <app-update-button-footer
      [loading]="loading"
      (eventSubmit)="submit()"
      (eventCancel)="onCancelEdit()"
    ></app-update-button-footer>
  </div>
</app-card>
