import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export const customFormValid: ValidatorFn = (
  abstractControl: AbstractControl
): ValidationErrors | null => {
  let fg = abstractControl as FormGroup;
  let isValid = false;
  isValid = Object.keys(fg.controls).some((field) => {
    const ctrl = fg.get(field);
    return ctrl?.value === true;
  });

  const isTouched = Object.values(fg.controls).some((form) => {
    // @ts-ignore
    return form?._pendingDirty || form?.touched;
  });

  if (isValid || !isTouched) return null;

  return { invalidField: true };
};
