<p class="description-title"> {{ description }} </p>
<div>
    <div class="text-center card-file file-{{ status }} upload-form-base-{{status}}"
        [ngClass]="{'file-upload__card-error': required}">
        <span [ngSwitch]="status">
            <span *ngSwitchCase="'new'">
                <svg width="45" height="30" viewBox="0 0 45 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M35.975 11.4054C34.7283 5.08036 29.1733 0.332031 22.5 0.332031C17.2017 0.332031 12.6 3.3387 10.3083 7.7387C4.79 8.32536 0.5 13.0004 0.5 18.6654C0.5 24.7337 5.43167 29.6654 11.5 29.6654H35.3333C40.3933 29.6654 44.5 25.5587 44.5 20.4987C44.5 15.6587 40.7417 11.7354 35.975 11.4054ZM35.3333 25.9987H11.5C7.44833 25.9987 4.16667 22.717 4.16667 18.6654C4.16667 14.907 6.97167 11.772 10.6933 11.387L12.655 11.1854L13.5717 9.4437C15.3133 6.0887 18.7233 3.9987 22.5 3.9987C27.3033 3.9987 31.4467 7.4087 32.3817 12.1204L32.9317 14.8704L35.7367 15.072C38.5967 15.2554 40.8333 17.657 40.8333 20.4987C40.8333 23.5237 38.3583 25.9987 35.3333 25.9987ZM15.1667 16.832H19.8417V22.332H25.1583V16.832H29.8333L22.5 9.4987L15.1667 16.832Z"
                        fill="#4C1D80" />
                </svg>
            </span>
            <span *ngSwitchCase="'success'" style="font-size: 60px">
                <svg width="37" height="38" viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18.5013 0.667969C8.3813 0.667969 0.167969 8.8813 0.167969 19.0013C0.167969 29.1213 8.3813 37.3346 18.5013 37.3346C28.6213 37.3346 36.8346 29.1213 36.8346 19.0013C36.8346 8.8813 28.6213 0.667969 18.5013 0.667969ZM14.8346 28.168L5.66797 19.0013L8.25297 16.4163L14.8346 22.9796L28.7496 9.06464L31.3346 11.668L14.8346 28.168Z"
                        fill="#3AAE59" />
                </svg>
            </span>

        </span>
        <div [ngSwitch]="status">
            <div *ngSwitchCase="'new'" (dragover)="onDragOver($event)" (drop)="onDrop($event)" pDroppable>
                <div class="grid">
                    <div class="col-12">
                        <span>
                            <span class="text-new-title">Arrastra el
                                archivo o
                            </span><strong><a (click)="fileInput.click()">selecciónalo aquí</a></strong>
                        </span>
                    </div>
                    <div class="col-12">
                        <span class="text-new-subtitle">{{ allowedExtensions[0].toUpperCase()}} (máximo {{maxSizeMB}}
                            MB)</span>
                    </div>
                </div>
            </div>
            <span *ngSwitchCase="'upload'">
                <div class="grid">
                    <div class="col-8 file-upload-wrapper" style="justify-content: left;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                            <path
                                d="M14.67 5.83887L19.5 10.6689V19.8389H5.5V5.83887H14.67ZM14.67 3.83887H5.5C4.4 3.83887 3.5 4.73887 3.5 5.83887V19.8389C3.5 20.9389 4.4 21.8389 5.5 21.8389H19.5C20.6 21.8389 21.5 20.9389 21.5 19.8389V10.6689C21.5 10.1389 21.29 9.62887 20.91 9.25887L16.08 4.42887C15.71 4.04887 15.2 3.83887 14.67 3.83887ZM7.5 15.8389H17.5V17.8389H7.5V15.8389ZM7.5 11.8389H17.5V13.8389H7.5V11.8389ZM7.5 7.83887H14.5V9.83887H7.5V7.83887Z"
                                fill="#202020" />
                        </svg>
                        <p style="font-weight: 600;font-size: 14px;line-height: 20px;color:#202020;">
                            {{ fileName }}
                        </p>
                    </div>
                    <div class="col-4 file-upload-wrapper" style="justify-content: right;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"
                            (click)="resetFile($event)">
                            <path
                                d="M12.5 2.83887C6.97 2.83887 2.5 7.30887 2.5 12.8389C2.5 18.3689 6.97 22.8389 12.5 22.8389C18.03 22.8389 22.5 18.3689 22.5 12.8389C22.5 7.30887 18.03 2.83887 12.5 2.83887ZM17.5 16.4289L16.09 17.8389L12.5 14.2489L8.91 17.8389L7.5 16.4289L11.09 12.8389L7.5 9.24887L8.91 7.83887L12.5 11.4289L16.09 7.83887L17.5 9.24887L13.91 12.8389L17.5 16.4289Z"
                                fill="#6F7D9D" />
                        </svg>
                    </div>
                </div>
            </span>

            <div *ngSwitchCase="'process'">
                <div class="grid">
                    <div class="col-8 file-upload-wrapper" style="justify-content: left;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                            <path
                                d="M14.67 5.83887L19.5 10.6689V19.8389H5.5V5.83887H14.67ZM14.67 3.83887H5.5C4.4 3.83887 3.5 4.73887 3.5 5.83887V19.8389C3.5 20.9389 4.4 21.8389 5.5 21.8389H19.5C20.6 21.8389 21.5 20.9389 21.5 19.8389V10.6689C21.5 10.1389 21.29 9.62887 20.91 9.25887L16.08 4.42887C15.71 4.04887 15.2 3.83887 14.67 3.83887ZM7.5 15.8389H17.5V17.8389H7.5V15.8389ZM7.5 11.8389H17.5V13.8389H7.5V11.8389ZM7.5 7.83887H14.5V9.83887H7.5V7.83887Z"
                                fill="#202020" />
                        </svg>
                        <p style="font-weight: 600;font-size: 14px;line-height: 20px;color:#202020;">
                            {{ fileName }}
                        </p>
                    </div>
                </div>

                <div class="progressBar-container">
                    <p-progressBar mode="indeterminate" styleClass="p-progressbar-value"
                        [showValue]="false"></p-progressBar>
                </div>
            </div>

            <span *ngSwitchCase="'validate'">
                <div class="grid">
                    <div class="col-8 file-upload-wrapper" style="justify-content: left;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                            <path
                                d="M14.67 5.83887L19.5 10.6689V19.8389H5.5V5.83887H14.67ZM14.67 3.83887H5.5C4.4 3.83887 3.5 4.73887 3.5 5.83887V19.8389C3.5 20.9389 4.4 21.8389 5.5 21.8389H19.5C20.6 21.8389 21.5 20.9389 21.5 19.8389V10.6689C21.5 10.1389 21.29 9.62887 20.91 9.25887L16.08 4.42887C15.71 4.04887 15.2 3.83887 14.67 3.83887ZM7.5 15.8389H17.5V17.8389H7.5V15.8389ZM7.5 11.8389H17.5V13.8389H7.5V11.8389ZM7.5 7.83887H14.5V9.83887H7.5V7.83887Z"
                                fill="#202020" />
                        </svg>
                        <p style="font-weight: 600;font-size: 14px;line-height: 20px;color:#202020;">
                            {{ fileName }}
                        </p>

                        <p
                            style="font-weight: 400;font-size: 12px;line-height: 20px;color:#202020; font-style: normal; color:#6F7D9D;">
                            {{ fileSizeMB }}
                        </p>
                    </div>
                    <div class="col-4 file-upload-wrapper" style="justify-content: right;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"
                            (click)="resetFile($event)">
                            >
                            <path
                                d="M6.5 19.8389C6.5 20.9389 7.4 21.8389 8.5 21.8389H16.5C17.6 21.8389 18.5 20.9389 18.5 19.8389V7.83887H6.5V19.8389ZM8.5 9.83887H16.5V19.8389H8.5V9.83887ZM16 4.83887L15 3.83887H10L9 4.83887H5.5V6.83887H19.5V4.83887H16Z"
                                fill="#202020" />
                        </svg>
                    </div>
                </div>
            </span>

            <div *ngSwitchCase="'success'" class="upload-form-success">
                <p>
                    "{{ fileName }}" se cargó correctamente
                </p>
                <deuna-button [label]="'Eliminar'" type="button" (eventClick)="fileInput.click()"
                    [deUnaClass]="'p-button-outlined'"></deuna-button>
            </div>


            <input #fileInput type="file" style="display: none" (change)="readFile()" (click)="resetFile($event)"
                [accept]="accept" />
        </div>
    </div>
</div>
<div *ngIf="showDownloadTemplate">
    <span class="file-upload__required-error">{{required ? "Dato requerido": ""}}</span>
    <p class="template-text">
        {{ staticTemplateMessage }}
        <a href="{{ staticUrlDownload }}" target="_blank" rel="noopener">{{staticDownloadMessage}}</a>
    </p>
</div>