import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { assetUrl } from '@deuna/bp-sh-helpers-ng';
import { ConfigurationDataService } from 'src/app/services/config/merchant/configuration-data.service';
import _ from 'lodash';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-card-commercial',
  templateUrl: './card-commercial.component.html',
})
export class CardCommercialComponent implements OnInit {
  @Input() formDataCN000!: FormGroup;
  @Input() isEditable: boolean = false;
  @Input() isFlowUpdate: boolean = false;
  @Output() toastEvent: EventEmitter<void> = new EventEmitter<void>();
  formDataCN000TMP!: FormGroup;
  loading: boolean = false;
  enableEdit: boolean = false;
  nombreComercialImg = assetUrl('png/iphone 1.png');
  avatar: string = assetUrl('png/avatar_merchant.png');

  constructor(
    private configDataService: ConfigurationDataService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  initData() {
    this.formDataCN000TMP = _.cloneDeep(this.formDataCN000);
    if (this.isEditable && this.isFlowUpdate) {
      this.setStateReable();
    }
  }

  handleEvent(data: boolean) {
    if (data) {
      this.enableEdit = true;
      this.formDataCN000.controls['tradename'].enable();
    }
  }

  onCancelEdit() {
    this.enableEdit = false;
    this.formDataCN000.patchValue(this.formDataCN000TMP.getRawValue());
    this.setStateReable();
  }

  submit() {
    this.loading = true;
    this.formDataCN000.markAllAsTouched();
    if (!this.formDataCN000.valid) {
      this.loading = false;
      return;
    }
    const merchantId = this.getMerchantIdFromParams() || '';
    return Promise.allSettled([
      this.configDataService.updateCN000Configuration(),
      this.configDataService.updateClientComercialNameInfo(merchantId),
    ])
      .then(() => {
        this.formDataCN000TMP = _.cloneDeep(this.formDataCN000);
        this.emitToast();
        this.setStateReable();
        this.loading = false;
        this.enableEdit = false;
      })
      .catch((err) => console.error({ err }));
  }

  setStateReable() {
    this.formDataCN000.controls['tradename'].disable();
  }

  emitToast() {
    this.toastEvent.emit();
  }

  getMerchantIdFromParams() {
    return this.activatedRoute.snapshot.paramMap.get('merchantId');
  }
}
