import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CataloguesService } from 'src/app/services/http/catalogues.service';
import { ICatalogue } from 'src/app/shared/interfaces/calatogue.interface';
import { ConfigurationDataService } from 'src/app/services/config/merchant/configuration-data.service';
import _ from 'lodash';
import {
  BannerTypes,
  DeUnaInputActionStates,
} from '@deuna/bo-sh-components-ng';

@Component({
  selector: 'app-card-collection-accounts-natural',
  templateUrl: './card-collection-accounts-natural.component.html',
})
export class CardCollectionAccountsNaturalComponent implements OnInit {
  @Input() isEditable: boolean = false;
  @Input() isFlowUpdate: boolean = false;
  loading: boolean = false;
  enableEdit: boolean = false;
  @Input() formCN003!: FormGroup;
  formCN003TMP!: FormGroup;
  accountsTypes: Array<ICatalogue> = [];
  accountInputState = DeUnaInputActionStates.INITIAL;
  bannerType = BannerTypes.BASIC_INFO;

  constructor(
    private configDataService: ConfigurationDataService,
    private cataloguesService: CataloguesService
  ) {}

  ngOnInit(): void {
    this.getDataCatalogs();
  }

  handleValidate() {
    this.accountInputState = DeUnaInputActionStates.DISABLED;
  }

  getDataCatalogs() {
    this.cataloguesService.getAccountTypes().subscribe((response) => {
      this.accountsTypes = response.filter((item) => item.code !== 'Otro');
    });
    this.formCN003TMP = _.cloneDeep(this.formCN003);
  }

  handleEvent($event: boolean) {
    if ($event) {
      this.formCN003.enable();
      this.enableEdit = true;

      const accountType = this.accountsTypes.find(
        (item) => item.name === this.formCN003.controls['accountType']?.value
      );
      this.formCN003.get('accountType')?.setValue(accountType?.code);
    }
  }

  async submit() {
    this.loading = true;
    this.formCN003.markAllAsTouched();
    if (!this.formCN003.valid) {
      this.loading = false;
      return;
    }

    await Promise.resolve(
      this.configDataService.updateConfiguration('CN003')
    ).then(() => {
      this.formCN003TMP = _.cloneDeep(this.formCN003);
      this.loading = false;
      this.enableEdit = false;
    });
  }
}
