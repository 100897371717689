import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';

import { assetUrl } from '@deuna/bp-sh-helpers-ng';
import {
  MerchantItem,
  ResponseMerchant,
} from '../../../../../shared/interfaces/query-merchants';
import { GraphqlMerchantService } from '../../../../../services/graphql/graphql-merchant.service';
import { Branch } from '../../../../../shared/interfaces/query-branchs';
import { clientRoute } from 'src/app/constants/routes';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IFormGroup } from 'src/app/shared/interfaces/form-group.interface';
import {
  INodeConfiguration,
  IResponseBranchConfiguration,
  NodeConfiguration,
} from 'src/app/shared/interfaces/query-configuration';
import {
  IConfigDataCN009,
  INodeQuantity,
} from 'src/app/shared/interfaces/config-merchant.interfaces';
import { MutationResult } from 'apollo-angular';
import { ICatalogue } from 'src/app/shared/interfaces/calatogue.interface';
import { ConfigurationFlowCreationService } from 'src/app/services/config/merchant/configuration-flow-creation.service';
import { ConfigurationFlowBranchService } from 'src/app/services/config/branch/configuration-flow-branch.service';
import { REGEX_WITHOUT_SPECIAL_CHARACTERS } from 'src/app/constants/merchant-constants';

interface IError {
  reason: string;
  details: string;
}

@Component({
  selector: 'app-info',
  templateUrl: './info-branch.page.html',
  styleUrls: ['./info-branch.page.scss'],
})
export class InfoBranchPage implements OnInit {
  relativeRoute = clientRoute;
  avatar: string = assetUrl('png/avatar_merchant.png');
  merchant: MerchantItem | null | undefined = null;
  branch: Branch | null | undefined = null;
  load: boolean = true;

  amountRequiredOptions: Array<ICatalogue> = [
    { code: 'SI', name: 'Si' },
    { code: 'NO', name: 'No' },
  ];
  isFormCN017Created = false;

  display: boolean = false;
  subActivatedRoute!: Subscription;
  subGraphqlMerchantService!: Subscription;
  items: Array<MenuItem> = [];
  activeItem: MenuItem = {};
  existsBranchs = false;
  hasUpdateBoxError: boolean = false;
  updateBoxError: IError = {
    reason: '',
    details: '',
  };
  image = assetUrl('png/exit_logo.png');

  resultfields: string = `id,
  identification
  comercialName
  businessName
  isComplete
  status
  cifAccount`;

  typeNodeToDeactivate: string = '';
  idMerchantToActivate: string = '';
  idNodeToDeactivate: number = 0;
  formConfigData!: IFormGroup;
  formCN017!: FormGroup;
  search!: FormGroup;
  config007!: FormGroup;
  config009!: FormGroup;
  config009Checks!: FormGroup;
  formBranchBox!: FormGroup;
  idConfig007!: string;
  idConfig009!: string;
  configdata009!: IConfigDataCN009;
  formBuilder = new FormBuilder();
  notifications: Array<ICatalogue> = [
    { code: 'phone', name: 'SMS' },
    { code: 'email', name: 'Correo' },
  ];
  enabledSms = false;
  enabledEmail = false;
  constructor(
    private graphqlMerchantService: GraphqlMerchantService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private configFlow: ConfigurationFlowCreationService,
    private readonly configFlowBranch: ConfigurationFlowBranchService
  ) {}

  ngOnInit(): void {
    this.getInitial();
  }

  initFormCN017(configs: INodeConfiguration[]): void {
    const configCN017 = configs.find(
      (configuracion) => configuracion.configName === 'CN017'
    );

    if (!configCN017) {
      this.formCN017.setValue({ amountRequired: 'NO', id: null });
      return;
    }
    this.formCN017.setValue({
      // @ts-ignore
      amountRequired: configCN017.configData.amountRequired ? 'SI' : 'NO',
      id: configCN017.id,
    });

    this.isFormCN017Created = true;
  }

  async updateFormCN017() {
    this.formCN017.markAllAsTouched();
    if (!this.formCN017.valid || !this.branch?.id) return;
    if (!this.isFormCN017Created) {
      const createdConfigId = await this.configFlowBranch.createBranchConfig(
        'CN017',
        this.branch.id.toString(),
        {
          amountRequired: this.formCN017.get('amountRequired')?.value === 'SI',
        }
      );
      this.formCN017.get('id')?.setValue(createdConfigId);
      this.isFormCN017Created = true;
      this.reloadCurrentRoute();
    } else {
      const nodeConfigInput: NodeConfiguration = {
        nodeId: this.branch?.id,
        configId: this.formCN017.get('id')?.value,
        configName: 'CN017',
        configData: JSON.stringify({
          amountRequired: this.formCN017.get('amountRequired')?.value === 'SI',
        }),
      };
      this.graphqlMerchantService
        .updateConfiguration(nodeConfigInput)
        .subscribe({
          next: (_result: MutationResult<NodeConfiguration>) => {
            this.reloadCurrentRoute();
          },
        });
    }
  }
  async getInitial() {
    this.subActivatedRoute = this.activatedRoute.paramMap.subscribe(
      (params) => {
        this.idMerchantToActivate = params.get('merchantId')!;
      }
    );
    this.config007 = this.formBuilder.group({
      branchName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern(REGEX_WITHOUT_SPECIAL_CHARACTERS),
        ],
      ],
    });

    this.config009 = this.formBuilder.group({
      phone: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      email: ['', [Validators.required, Validators.email]],
    });

    this.formCN017 = this.formBuilder.group({
      amountRequired: ['NO', [Validators.required]],
      id: [null, null],
    });

    this.config009Checks = this.formBuilder.group({
      phone: [false, []],
      email: [false, []],
    });

    this.formBranchBox = this.formBuilder.group({
      numberBox: [
        null,
        [Validators.required, Validators.min(1), Validators.max(99)],
      ],
    });

    this.formConfigData = this.configFlow.getForm();
    this.search = this.formConfigData?.search;
    await new Promise<string>((resolve) => {
      this.subActivatedRoute = this.activatedRoute.paramMap.subscribe(
        (params) => {
          const idMerchant = params.get('merchantId');
          const idBranch = params.get('branchId');
          if (idBranch) {
            this.subGraphqlMerchantService = this.graphqlMerchantService
              .getAllBranchConfigByNodeId(idBranch)
              .subscribe({
                next: (
                  res: ApolloQueryResult<IResponseBranchConfiguration>
                ) => {
                  const branchConfigs: INodeConfiguration[] = JSON.parse(
                    res.data.getConfigByNode
                  );
                  this.initFormCN017(branchConfigs);
                  const configName = 'CN009';
                  const infoConfigName = 'CN007';
                  const configFound = branchConfigs.find(
                    (configuracion) => configuracion.configName === configName
                  );
                  const infoConfigFound = branchConfigs.find(
                    (configuracion) =>
                      configuracion.configName === infoConfigName
                  );
                  this.configdata009 = {
                    administratorName:
                      configFound?.configData['administratorName'],
                    notificationBranchPay:
                      configFound?.configData['notificationBranchPay'],
                    optionNotificationBranchPay:
                      configFound?.configData['optionNotificationBranchPay'],
                  };
                  this.idConfig007 = infoConfigFound?.id!;
                  this.config007
                    .get('branchName')
                    ?.setValue(infoConfigFound?.configData['branchName']);
                  console.log(infoConfigFound?.configData['branchName']);
                  this.branch = {
                    id: +idBranch,
                    type: 'B',
                    name: infoConfigFound?.configData['branchName'],
                    POSList: [],
                    cellphone:
                      configFound?.configData['optionNotificationBranchPay'][
                        'cellPhone'
                      ],
                    email:
                      configFound?.configData['optionNotificationBranchPay'][
                        'email'
                      ],
                  };
                  this.idConfig009 = configFound!.id;
                  this.config009
                    .get('phone')
                    ?.setValue(
                      configFound?.configData['optionNotificationBranchPay'][
                        'cellPhone'
                      ]
                    );

                  this.config009Checks
                    .get('phone')
                    ?.setValue(
                      configFound?.configData['optionNotificationBranchPay'][
                        'notificationSMS'
                      ]
                    );

                  this.config009
                    .get('email')
                    ?.setValue(
                      configFound?.configData['optionNotificationBranchPay'][
                        'email'
                      ]
                    );
                  this.config009Checks
                    .get('email')
                    ?.setValue(
                      configFound?.configData['optionNotificationBranchPay'][
                        'notificationEmail'
                      ]
                    );
                  this.enableInputs();
                },
                error: () => {
                  this.load = false;
                  this.router.navigate([`${clientRoute}/list`]);
                },
              });
          }
          if (idMerchant) {
            this.subGraphqlMerchantService = this.graphqlMerchantService
              .getMerchantById(idMerchant, this.resultfields)
              .subscribe({
                next: (res: ApolloQueryResult<ResponseMerchant>) => {
                  this.merchant = res.data.getClient;
                  this.existsBranchs = this.merchant.isComplete;
                  this.load = false;
                  resolve(idMerchant);
                },
                error: () => {
                  this.load = false;
                  this.router.navigate([`${clientRoute}/list`]);
                },
              });
          }
        }
      );
    });

    this.items = [
      {
        label: 'Info Surcursal',
        id: 'infoBranch',
      },
    ];
    this.activeItem = this.items[0];
    this.search.controls['identification'].setValue(
      this.merchant?.identification
    );
    this.search.controls['cifCode'].setValue(this.merchant?.cifAccount);
  }

  ngOnDestroy() {
    this.subActivatedRoute.unsubscribe();
    this.subGraphqlMerchantService.unsubscribe();
  }

  reloadList() {
    window.location.reload();
  }

  async updateConfig007() {
    if (this.config007.valid) {
      this.updateServices(this.idConfig007, 'CN007', this.config007.value);
    }
  }

  async updateConfig() {
    this.configdata009.optionNotificationBranchPay.cellPhone =
      this.config009.get('phone')?.value;
    this.configdata009.optionNotificationBranchPay.email =
      this.config009.get('email')?.value;

    this.configdata009.optionNotificationBranchPay.notificationSMS =
      this.config009Checks.get('phone')?.value;
    this.configdata009.optionNotificationBranchPay.notificationEmail =
      this.config009Checks.get('email')?.value;
    if (
      this.config009Checks.get('phone')?.value ||
      this.config009Checks.get('email')?.value
    ) {
      this.configdata009.notificationBranchPay = true;
    } else {
      this.configdata009.notificationBranchPay = false;
    }

    this.updateServices(this.idConfig009, 'CN009', this.configdata009);
  }

  async updateServices(configId: string, configName: string, configData: any) {
    this.load = true;
    const nodeconfigInput: NodeConfiguration = {
      nodeId: this.branch?.id,
      configId,
      configName,
      configData: JSON.stringify(configData),
    };

    this.graphqlMerchantService
      .updateConfiguration(nodeconfigInput)
      ?.subscribe({
        next: (_result: MutationResult<NodeConfiguration>) => {
          this.reloadCurrentRoute();
        },
        error: (err) => {
          this.load = false;
          console.log('error update config:', err);
        },
      });
  }

  reloadCurrentRoute() {
    const currentRoute = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]);
    });
  }

  onChangeChecks() {
    this.enableInputs();
  }

  enableInputs() {
    this.enabledSms = this.config009Checks.get('phone')?.value;
    this.enabledEmail = this.config009Checks.get('email')?.value;
  }

  async updateQuantityBoxesBranch() {
    this.formBranchBox.markAllAsTouched();
    if (!this.formBranchBox.valid) return;
    this.load = true;
    const branchBoxes: INodeQuantity = {
      id: this.branch?.id as number,
      quantity: +this.formBranchBox.get('numberBox')?.value,
      nodeType: 'C',
      createAlias: true,
    };
    try {
      const result = await this.configFlowBranch.createPOSBranch(branchBoxes);
      if (result) {
        this.router.navigate([
          `${clientRoute}/info`,
          this.idMerchantToActivate,
        ]);
      }
      this.load = false;
    } catch (error: any) {
      this.load = false;
      this.updateBoxError.reason = error?.reason || 'DEFAULT ERROR';
      this.updateBoxError.details = error?.details || 'DEFAULT DETAIL';
      this.hasUpdateBoxError = true;
    }
  }

  goBackBranchList() {
    this.hasUpdateBoxError = false;
  }
}
