import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from './error.component';
import { DeUnaButtonModule } from '@deuna/bo-sh-components-ng';

@NgModule({
  declarations: [ErrorComponent],
  imports: [CommonModule, DeUnaButtonModule],
  exports: [ErrorComponent],
})
export class ErrorComponentModule {}
