import { BehaviorSubject } from "rxjs";


export class MerchantStateService {
    private readonly merchantSource = new BehaviorSubject<any>(null)
    merchant$ = this.merchantSource.asObservable();

    constructor() {}

    setMerchant(item: any) {
        this.merchantSource.next(item);
    }
}