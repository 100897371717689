<app-card header="Información general" [isFlowUpdate]="isFlowUpdate" [isEditable]="isEditable">
  <div class="grid">
    <div class="col-6">
      <deuna-input [masked]="false" styleClass="mt-4" [form]="formCN000" label="Tipo de cliente" placeholder="Tipo de cliente" name="clientType" control="clientType"></deuna-input>
    </div>
    <div class="col-6">
      <deuna-input [masked]="false" styleClass="mt-4" [form]="formCN000" label="N° de RUC" placeholder="N° de RUC" name="identification" control="identification"></deuna-input>
    </div>
    <div class="col-6">
      <deuna-input [masked]="false" styleClass="mt-4" [form]="search" label="CIF" placeholder="CIF" name="cifCode" control="cifCode"></deuna-input>
    </div>
    <div class="col-6">
      <deuna-input [masked]="false" styleClass="mt-4" [form]="formCN000" label="Razón social" placeholder="Razón social" name="businessName" control="businessName"></deuna-input>
    </div>
  </div>
</app-card>