import {
  Component,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { assetUrl } from '@deuna/bp-sh-helpers-ng';
import { clientRoute } from 'src/app/constants/routes';
import { ConfigurationFlowPymeCreation } from 'src/app/services/config/pymes/configuration-flow-pyme-creation.service';
import { IPymesList, Meta, PymeItem } from 'src/app/shared/interfaces/query-pymes';

@Component({
  selector: 'app-list-pymes',
  templateUrl: './list-pymes.page.html',
  styleUrls: ['./list-pymes.page.scss'],
})
export class ListPymesPage implements OnInit {
  hasError: boolean = false;
  loading: boolean = false;
  load: boolean = true;
  flagGet: boolean = false;
  showSide: boolean = false;
  showUploadedFile: boolean = true;

  pointBrown: string = assetUrl('svg/dot-brown.svg');
  pointGreen: string = assetUrl('svg/dot-green.png');
  pointRed: string = assetUrl('svg/dot-red.svg');
  emptyReportInactive: string = assetUrl('png/report-inactive.png');
  folderDown: string = assetUrl('svg/folder-down.svg');
  cross: string = assetUrl('png/cross.png');
  download: string = assetUrl('svg/download.svg');

  excelFile: string = assetUrl(
    'xlsx/Template_creacion_masiva_pymes.xlsx'
  );

  formFilterPymes!: FormGroup;

  frameSide = {
    title: '',
    subTitle: '',
    orderSubtitle: '',
    view: 'related'
  }

  frameSideFile = {
    title: 'Documentos relacionados',
    subTitle: 'N° de orden',
    orderSubtitle: '12345',
    view: 'related',
  };

  fileStatusFrameSide = {
    title: 'Orden por carga masiva',
    subTitle: '',
    orderSubtitle: '',
    view: 'related'
  };

  columns = [
    'N° de orden',
    'Tipo de orden',
    'Fecha de ingreso',
    'Estado de subida',
    'Estado de creación',
    'Coordinador comercial',
    '',
  ];

  pymeList: Array<PymeItem> = [
    {
      id: 'an Id',
      orderNumber: '01234',
      oderType: 'string',
      date: 'string',
      uploadStatus: 'APPROVED',
      creationStatus: 'APPROVED',
      comercialCoordinator: 'string',
      files: [],
    },
    {
      id: 'an Id',
      orderNumber: '12345',
      oderType: 'string',
      date: 'string',
      uploadStatus: 'FAILED',
      creationStatus: 'FAILED',
      comercialCoordinator: 'string',
      files: [
        {
          id: 'an id',
          fileName: 'pymes_123',
          fileUrl: 'an url',
        },
        {
          id: 'an id 2',
          fileName: 'pymes_321',
          fileUrl: 'an url',
        },
      ],
    },
    {
      id: 'an Id',
      orderNumber: '23456',
      oderType: 'string',
      date: 'string',
      uploadStatus: 'APPROVED',
      creationStatus: 'APPROVED',
      comercialCoordinator: 'string',
      files: [
        {
          id: 'an id',
          fileName: 'pymes_123',
          fileUrl: 'an url',
        },
      ],
    },
    {
      id: 'an Id',
      orderNumber: '34567',
      oderType: 'string',
      date: 'string',
      uploadStatus: 'FAILED',
      creationStatus: 'FAILED',
      comercialCoordinator: 'string',
      files: [],
    },
    {
      id: 'an Id',
      orderNumber: '45678',
      oderType: 'string',
      date: 'string',
      uploadStatus: 'APPROVED',
      creationStatus: 'APPROVED',
      comercialCoordinator: 'string',
      files: [],
    },
    {
      id: 'an Id',
      orderNumber: '56789',
      oderType: 'string',
      date: 'string',
      uploadStatus: 'FAILED',
      creationStatus: 'PROCESSING',
      comercialCoordinator: 'string',
      files: [],
    },
  ];

  selectedPyme?: PymeItem;

  currentPage: number = 1;
  limitPage: number = 7;
  dataPagination!: Meta;

  paramsQuery: IPymesList = {
    page: this.currentPage,
    limit: this.limitPage,
    orderNUmber: ''
  };

  constructor(
    private readonly fb: FormBuilder,
    private readonly flowPymeCreation: ConfigurationFlowPymeCreation
  ) {}

  textLoading = '';
  backRoute = clientRoute;
  forwardRoute = clientRoute;

  uploadedFileName = 'prueba.xlsx';

  ngOnInit(): void {
    this.loading = false;
    this.backRoute = `${this.backRoute}/list?tab=merchants`;
    this.forwardRoute = `${this.forwardRoute}/pymes/create/step-1`;
    this.dataPagination = {
      currentPage: 1,
      itemCount: 1,
      itemsPerPage: 7,
      totalItems: 1,
      totalPages: 1,
    };

    this.getPymes();

    this.formFilterPymes = this.fb.group({
      textSearchMerchant: [''],
    });

    this.flowPymeCreation.sideAppState$.subscribe((open)=>{
      this.showSide = open;
      this.showUploadedFile = open;
      this.frameSide = open ? this.fileStatusFrameSide : this.frameSideFile
    })
  }

  closeError() {
    this.hasError = false;
  }

  retryFetch() {
    this.hasError = false;
  }

  cleanInput() {
    this.formFilterPymes = this.fb.group({
      textSearchMerchant: [''],
    });
  }

  goToPageTable(page: number) {
    this.paramsQuery.page = page;
    this.getPymes();
  }

  changeToItemsTable(page: number) {
    this.paramsQuery.page = 1;
    this.paramsQuery.limit = page;
    this.getPymes();
  }

  downloadFile(fileName: string) {
    //should download a file
  }

  setSelectedPyme(selectedPyme: PymeItem) {
    this.selectedPyme = selectedPyme;
    this.showSide = true;
    this.showUploadedFile = false;
    this.frameSide.orderSubtitle = selectedPyme.orderNumber;
  }

  getPymes(){
    setTimeout(() => {
      this.downloadFile('value');
      this.load = false;
      this.flagGet = true;
    }, 3000);
  }

  closeSide(){
    this.showSide = false;
    this.flowPymeCreation.closeSideApp();
    this.showUploadedFile = false;
  }
}
