<div class="grid">
  <div class="col p-0" style="overflow-x: auto;">
    <ng-content select=".content-app"></ng-content>
  </div>
  <div *ngIf="showSide" class="col-fixed side-lateral p-0 px-0">
    <div class="grid m-3">
      <div class="col-10">
                <div class="title-side">
                    {{ title }}
                </div>
        <div *ngIf="!orderSubtitle" class="subtitle-side">
                    {{ subTitle }}
        </div>
        <div *ngIf="orderSubtitle" class="order-subtitle-side">
                    {{ subTitle }}
        </div>
        <div *ngIf="orderSubtitle" class="order-subtitle">
                  {{ orderSubtitle }}
        </div>
      </div>
      <div class="col-2 text-right pt-3 pr-3">
                <div (click)="clickClose()" style="cursor: pointer;">
                    X
                </div>
      </div>
      <div *ngIf="orderSubtitle" class="col-12 hr-divider" ></div>
    </div>
    <ng-content class="grid m-3" select=".content-side"></ng-content>
  </div>
</div>
