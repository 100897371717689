import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConfigurationDataService } from 'src/app/services/config/merchant/configuration-data.service';
import { CataloguesService } from 'src/app/services/http/catalogues.service';
import { ICatalogue } from 'src/app/shared/interfaces/calatogue.interface';
import _ from 'lodash';

@Component({
  selector: 'app-card-commission-accounts',
  templateUrl: './card-commission-accounts.component.html',
})
export class CardCommissionAccountsComponent implements OnInit {
  @Input() isEditable: boolean = false;
  @Input() isFlowUpdate: boolean = false;
  loading: boolean = false;
  enableEdit: boolean = false;
  @Input() formCN012!: FormGroup;
  formCN012TMP!: FormGroup;
  @Input() isCN012Created: boolean = false;
  @Output() toastEvent: EventEmitter<void> = new EventEmitter<void>();
  accountsTypes: Array<ICatalogue> = [];

  constructor(
    private configDataService: ConfigurationDataService,
    private cataloguesService: CataloguesService,
  ) {}

  ngOnInit(): void {
    this.getDataCatalogs();
  }

  getDataCatalogs() {
    this.formCN012TMP = _.cloneDeep(this.formCN012);
    this.cataloguesService.getAccountTypes().subscribe((res) => {
      this.accountsTypes = res;
    });
    this.setStateReadable();
  }

  handleEvent($event: boolean) {
    if ($event) {
      this.enableEdit = true;
      this.formCN012.enable();
      const accountType = this.accountsTypes.find(
        (item) => item.name === this.formCN012.controls['accountType']?.value
      );
      this.formCN012.get('accountType')?.setValue(accountType?.code);
    }
  }

  onCancelEdit() {
    this.enableEdit = false;
    this.formCN012.patchValue(this.formCN012TMP.value)
    this.setStateReadable();
  }

  async submit() {
    this.loading = true;
    this.formCN012.markAllAsTouched();
    if (!this.formCN012.valid) {
      this.loading = false;
      return;
    }
    if(this.isCN012Created){
      await Promise.resolve(this.configDataService.updateConfiguration('CN012'))
        .then(() => {
          this.emitToast();
        })
    }else{
      await Promise.resolve(this.configDataService.createConfigurationNode('CN012'))
        .then(() => { 
          this.emitToast();
        });
    }
    this.loading = false;
    this.enableEdit = false;
    this.formCN012TMP = _.cloneDeep(this.formCN012);
    this.setStateReadable();
  }

  setStateReadable() {
    this.formCN012.disable();
  }

  emitToast() {
    this.toastEvent.emit();
  }
}
