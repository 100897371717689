import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { CataloguesService } from 'src/app/services/http/catalogues.service';
import {
  ICatalogueSourceChannel,
} from 'src/app/shared/interfaces/calatogue.interface';
import { ConfigurationDataService } from 'src/app/services/config/merchant/configuration-data.service';
import _ from 'lodash';

@Component({
  selector: 'app-card-source-support',
  templateUrl: './card-source-support.component.html',
})
export class CardSourceSupportComponent implements OnInit {
  @Input() isEditable: boolean = false;
  @Input() isFlowUpdate: boolean = false;
  @Input() formCN019!: FormGroup;
  @Output() toastEvent: EventEmitter<void> = new EventEmitter<void>();
  
  formDataCN019TMP!: FormGroup;
  loading: boolean = false;
  enableEdit: boolean = false;
  hasProvider: boolean = false;
  sourceChannels: Array<ICatalogueSourceChannel> = [];

  constructor(
    private configDataService: ConfigurationDataService,
    private cataloguesService: CataloguesService
  ) {}

  ngOnInit(): void {
    this.getDataCatalogs();
    this.initFormCN019();
  }

  initFormCN019() {
    this.formCN019.enable();

    this.formDataCN019TMP = _.cloneDeep(this.formCN019);
  }

  async getDataCatalogs() {
    const sourceChannels$ = this.cataloguesService.getSourceChannels();
    
    forkJoin([sourceChannels$]).subscribe(
      ([sourceChannels]) => {
        this.sourceChannels = sourceChannels;

        const sourceChannelControl = this.formCN019.get('sourceChannel');
        if(sourceChannelControl && this.sourceChannels?.length) {
          this.setHasProvider(sourceChannelControl.value);
          
          sourceChannelControl.valueChanges.subscribe((value: string) => {
            this.setHasProvider(value);
          });
        }

        if (this.isEditable && this.isFlowUpdate) {
          this.setStateReadable();
        }
      }
    );
  }

  setHasProvider(value: string) {
    const sourceChannel = this.sourceChannels?.find(
      (item) => item.code === value
    );

    if(!sourceChannel) return;

    this.hasProvider = sourceChannel.hasProvider;
  }

  setProviderReadableValue() {
    const sourceChannelControl = this.formCN019.get('sourceChannel');

    if(sourceChannelControl && this.sourceChannels?.length) {
      const sourceChannel = this.sourceChannels.find(
        (item) => item.code === sourceChannelControl.value
      );

      this.formCN019.get('sourceChannel')?.setValue(sourceChannel?.name);
    }
  }

  handleEvent(data: boolean) {
    if (data) {
      this.enableEdit = true;
      const sourceChannel = this.sourceChannels.find(
        (item) => item.code === this.formDataCN019TMP.controls['sourceChannel']?.value
      );
      this.formCN019.get('sourceChannel')?.setValue(sourceChannel?.code);
      this.hasProvider = !!sourceChannel?.hasProvider;
      this.formCN019.enable();
    }
  }

  onCancelEdit() {
    this.enableEdit = false;
    this.formCN019.patchValue(this.formDataCN019TMP.getRawValue());
    this.setStateReadable();
  }

  submit() {
    this.loading = true;
    this.formCN019.markAllAsTouched();
    if (!this.formCN019.valid) {
      this.loading = false;
      return;
    }
    return Promise.allSettled([
      this.configDataService.updateOrCreateConfiguration('CN019'),
    ])
      .then(() => {
        this.loading = false;
        this.enableEdit = false;
        this.setStateReadable();
        this.emitToast();
      })
      .catch((err) => {
        console.error('An error occurred:', err);
      });
  }

  setStateReadable() {
    this.setProviderReadableValue();
    this.formCN019.disable();
  }

  emitToast() {
    this.toastEvent.emit();
  }
}
