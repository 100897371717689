import { Injectable } from '@angular/core';
import {
  IHierarchy,
  IDefaultConfig,
  IDataConfig,
  IClient,
  IMerchant,
  IPerson,
  ILegalRepresentatives,
  IConfigDataCN001,
  IConfigDataCN002,
  IConfigDataCN003,
  IConfigDataCN004,
  IConfigDataCN006,
  IConfigDataCN010,
  IConfigDataCN012,
  IConfigDataCN013,
} from '../../../shared/interfaces/config-merchant.interfaces';
import { AbstractControl, Validators } from '@angular/forms';

import {
  ICN000,
  ICN005,
  ICN015,
  ICN016,
  ICN019,
  IFormGroup,
} from '../../../shared/interfaces/form-group.interface';
import { GraphqlMerchantService } from '../../graphql/graphql-merchant.service';
import { verificarCedula, verificarRuc } from '@deuna/bo-sh-helpers-ng';
import {
  ResponseCodeCif,
  ResponseCreateMerchantAD,
} from '../../../shared/interfaces/query-merchants';
import { MutationResult } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client';
import { Subscription } from 'rxjs';

import { ConfigurationFormService } from './configuration-form.service';
import {
  CREATE_ERROR_CODE,
  ClientType,
  DEFAULT_BANK,
  DEFAULT_COUNTRY,
  IdentificationType,
  MCPK,
} from 'src/app/constants/merchant-constants';
import { ciFromRuc, dateISO8601 } from 'src/app/shared/utils/helpers';
import { CryptoService } from '@deuna/bo-sh-security-ng';

export const identificationCIValidator = (control: AbstractControl) => {
  const ciWithRuc = control.value;
  const ci = ciWithRuc ? ciFromRuc(ciWithRuc) : '';
  const valid = verificarCedula(ci);
  return !valid ? { identificationValid: true } : null;
};
export const identificationRUCValidator = (control: AbstractControl) => {
  const valid = verificarRuc(control.value);
  return !valid ? { identificationValid: true } : null;
};

@Injectable()
export class ConfigurationFlowCreationService {
  subGraphqlMerchantService!: Subscription;
  formGroup!: IFormGroup;
  validIdentification = [
    Validators.required,
    Validators.minLength(13),
    Validators.maxLength(13),
    Validators.pattern('^[0-9]*$'),
  ];
  dataHierarchy: IHierarchy = {
    clientId: '',
    nodeType: 'S',
  };
  configMerchants: IDataConfig = { data: [] };

  constructor(
    private graphqlMerchantService: GraphqlMerchantService,
    private configurationFormService: ConfigurationFormService,
    private cryptoService: CryptoService
  ) {}

  getForm() {
    if (this.formGroup) {
      return this.formGroup;
    }
    this.formGroup = this.configurationFormService.getForm();
    return this.formGroup;
  }

  resetForm() {
    this.formGroup.CN000.reset();
    this.formGroup.CN001.reset();
    this.formGroup.CN002.reset();
    this.formGroup.CN003.reset();
    this.formGroup.CN004.reset();
    this.formGroup.CN005.reset();
    this.formGroup.CN006.reset();
    this.formGroup.CN010.reset();
    this.formGroup.CN013.reset();
    this.formGroup.CN012.reset();
    this.formGroup.CN019.reset();
  }

  cleanConfigMerchants() {
    this.configMerchants = { data: [] };
  }

  private getClientFormData(clientType: ClientType): IClient {
    const identification = this.formGroup.search.get('identification')
      ?.value as string;

    if (clientType === ClientType.NATURAL) {
      return {
        identification: ciFromRuc(identification),
        identificationType: IdentificationType.CI,
      };
    } else {
      return {
        identification,
        identificationType: IdentificationType.RUC,
      };
    }
  }

  async getBpClientData(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const clientType = this.formGroup.search.get('clientType')
        ?.value as ClientType;
      const clientData = this.getClientFormData(clientType);

      const resultfields: string = ` cif name`;
      this.graphqlMerchantService
        .getBpClientData(clientData, resultfields)
        .subscribe({
          next: (res: ApolloQueryResult<ResponseCodeCif>) => {
            if (!res.data.getTransactionalContact) {
              resolve(false);
            }
            this.formGroup.search.patchValue({
              cifCode: res.data.getTransactionalContact.cif,
            });
            if (clientType === ClientType.NATURAL) {
              this.formGroup.CN000.patchValue({
                businessName: res.data.getTransactionalContact.name,
              });
            }

            resolve(true);
          },
          error: (_err) => {
            resolve(false);
          },
        });
    });
  }

  async createMerchantHierarchyConfig(): Promise<string | boolean> {
    try {
      const idMerchant: string = await this.createMerchant();
      if (idMerchant === CREATE_ERROR_CODE) {
        return true;
      }
      this.resetForm();
      return idMerchant;
    } catch (error) {
      return true;
    }
  }

  createMerchant() {
    return new Promise<string>(async (resolve, reject) => {
      this.cleanConfigMerchants();
      const dataMerchant: IMerchant = this.createDataMerchant();
      const dataConfig = this.createConfigurationNodes('0');

      this.graphqlMerchantService
        .createMerchant({
          clientParams: dataMerchant,
          configurationParams: dataConfig,
        })
        ?.subscribe({
          next: (res: MutationResult<ResponseCreateMerchantAD>) => {
            if (res.data) {
              this.dataHierarchy.clientId = res.data.createMerchant.clientId;
              resolve(res.data.createMerchant.clientId);
            }
            reject(CREATE_ERROR_CODE);
          },
          error: (err) => {
            console.log(err);
            resolve(CREATE_ERROR_CODE);
          },
        });
    });
  }

  createHierarchy(merchantId: string): Promise<string> {
    this.dataHierarchy.clientId = merchantId;
    return new Promise<string>((resolve, reject) => {
      const merchantCreate: IHierarchy = {
        clientId: merchantId,
        nodeType: 'M',
      };
      this.graphqlMerchantService.createHierarchy(merchantCreate).subscribe({
        next: (result: any) => {
          resolve(result.data.createNodeHierachy.id);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  createConfigurationNodes(nodeMerchant: string) {
    this.configMerchants.data.push(
      this.createDataCN000(nodeMerchant.toString())
    );
    this.configMerchants.data.push(
      this.createDataCN001(nodeMerchant.toString())
    );
    this.configMerchants.data.push(
      this.createDataCN002(nodeMerchant.toString())
    );
    this.configMerchants.data.push(
      this.createDataCN003(nodeMerchant.toString())
    );
    this.configMerchants.data.push(
      this.createDataCN004(nodeMerchant.toString())
    );
    this.configMerchants.data.push(
      this.createDataCN005(nodeMerchant.toString())
    );
    this.configMerchants.data.push(
      this.createDataCN006(nodeMerchant.toString())
    );
    this.configMerchants.data.push(
      this.createDataCN010(nodeMerchant.toString())
    );

    if (this.formGroup.search.get('clientType')?.value === ClientType.NATURAL) {
      this.configMerchants.data.push(
        this.createDataCN013(nodeMerchant.toString())
      );
      this.configMerchants.data.push(
        this.createDataCN015(nodeMerchant.toString())
      );
      this.configMerchants.data.push(
        this.createDataCN016(nodeMerchant.toString())
      );
      this.configMerchants.data.push(
        this.createDataCN012(nodeMerchant.toString())
      );
    }
    
    this.configMerchants.data.push(
      this.createDataCN019(nodeMerchant.toString())
    );

    return this.configMerchants;
  }

  createDataMerchant() {
    const commercialGroup =
      this.formGroup.associationGroup.get('commercialGroupId')?.value;

    return {
      identification: this.cryptoService.encryptData(
        MCPK,
        this.formGroup.search.get('identification')?.value
      ),
      identificationType:
        this.formGroup.search.get('identificationType')?.value,
      clientType: this.formGroup.search.get('clientType')?.value,
      businessName: this.cryptoService.encryptData(
        MCPK,
        this.formGroup.CN000.get('businessName')?.value
      ),
      comercialName: this.formGroup.CN000.get('tradename')?.value,
      countryCode: this.formGroup.CN005.get('phoneCode')?.value.code,
      cellPhone: this.formGroup.CN005.get('phoneNumber')?.value,
      cifAccount: this.formGroup.search.get('cifCode')?.value,
      comercialGroupNodeId: commercialGroup
        ? parseInt(commercialGroup.nodeId, 10)
        : undefined,
    };
  }

  createDataCN000(nodeId: string) {
    const config: ICN000 = {
      identification: this.cryptoService.encryptData(
        MCPK,
        this.formGroup.search.get('identification')?.value
      ),
      identificationType:
        this.formGroup.search.get('identificationType')?.value,
      businessName: this.cryptoService.encryptData(
        MCPK,
        this.formGroup.CN000.get('businessName')?.value
      ),
      comercialName: this.formGroup.CN000.get('tradename')?.value,
      clientType: this.formGroup.CN000.get('clientType')?.value,
    };
    return {
      configName: 'CN000',
      nodeId,
      configData: JSON.stringify(config),
    };
  }

  createDataCN001(nodeId: string) {
    const person: IPerson = {
      name: this.formGroup.CN001.get('legalRepresentative')?.value,
    };
    const persons: Array<IPerson> = [];
    persons.push(person);
    const legalRepresentatives: ILegalRepresentatives = {
      person: persons,
    };
    const config: IConfigDataCN001 = {
      legalRepresentatives: legalRepresentatives,
    };
    return {
      configName: 'CN001',
      nodeId,
      configData: JSON.stringify(config),
    };
  }

  createDataCN002(nodeId: string) {
    const config: IConfigDataCN002 = {
      businessType: this.formGroup.CN002.get('businessType')?.value,
      segment: this.formGroup.CN002.get('segment')?.value,
      category: this.formGroup.CN002.get('category')?.value,
      subcategory: this.formGroup.CN002.get('subcategory')?.value,
    };
    return {
      configName: 'CN002',
      nodeId,
      configData: JSON.stringify(config),
    };
  }

  createDataCN003(nodeId: string) {
    const config: IConfigDataCN003 = {
      accountNumber: this.cryptoService.encryptData(
        MCPK,
        this.formGroup.CN003.get('accountNumber')?.value
      ),
      identificationNumber: this.formGroup.CN003.get('identificationNumber')
        ?.value,
      bankName: DEFAULT_BANK,
      accountType: this.formGroup.CN003.get('accountType')?.value,
    };
    return {
      configName: 'CN003',
      nodeId,
      configData: JSON.stringify(config),
    };
  }

  createDataCN004(nodeId: string) {
    const config: IConfigDataCN004 = {
      country: DEFAULT_COUNTRY,
      region: this.formGroup.CN004.get('region')?.value,
      province: this.formGroup.CN004.get('province')?.value,
      canton: this.formGroup.CN004.get('canton')?.value,
      mainStreet: this.formGroup.CN004.get('streetPrincipal')?.value,
      secondaryStreet: this.formGroup.CN004.get('streetSecondary')?.value,
      reference: this.formGroup.CN004.get('houseNumber')?.value,
    };

    return {
      configName: 'CN004',
      nodeId,
      configData: JSON.stringify(config),
    };
  }

  createDataCN005(nodeId: string): IDefaultConfig {
    const config: ICN005 = {
      region: this.formGroup.CN004.get('region')?.value,
      countryCode: this.formGroup.CN005.get('phoneCode')?.value.code,
      cellPhone: this.formGroup.CN005.get('phoneNumber')?.value,
      email: this.formGroup.CN005.get('emailMerchant')?.value,
      webhookUrl: this.formGroup.CN005.get('webhookUrl')?.value,
      webhookVersion: this.formGroup.CN005.get('webhookVersion')?.value,
      webhookHeaders: this.formGroup.CN005.get('webhookHeaders')?.value,
      webhookEmail: this.formGroup.CN005.get('webhookEmail')?.value,
    };

    return {
      configName: 'CN005',
      nodeId,
      configData: JSON.stringify(config),
    };
  }

  createDataCN006(nodeId: string): IDefaultConfig {
    const config: IConfigDataCN006 = {
      email: this.formGroup.CN006.get('email')?.value,
      sms: this.formGroup.CN006.get('sms')?.value,
      webhook: this.formGroup.CN006.get('webhook')?.value,
    };
    return {
      configName: 'CN006',
      nodeId,
      configData: JSON.stringify(config),
    };
  }

  createDataCN010(nodeId: string) {
    const config: IConfigDataCN010 = {
      optionBranch: this.formGroup.CN010.get('optionBranch')?.value,
      optionExcel: this.formGroup.CN010.get('optionExcel')?.value,
      nameMerchatContact: this.formGroup.CN010.get('nameMerchatContact')?.value,
      mailMerchantContact: this.formGroup.CN010.get('mailMerchantContact')
        ?.value,
    };
    return {
      configName: 'CN010',
      nodeId,
      configData: JSON.stringify(config),
    };
  }

  createDataCN012(nodeId: string) {
    const config: IConfigDataCN012 = {
      accountNumber: this.formGroup.CN012.get('accountNumber')?.value,
      bankName: DEFAULT_BANK,
      accountType: this.formGroup.CN012.get('accountType')?.value,
    };
    return {
      configName: 'CN012',
      nodeId,
      configData: JSON.stringify(config),
    };
  }

  createDataCN013(nodeId: string) {
    const config: IConfigDataCN013 = {
      email: this.formGroup.CN013.get('email')?.value,
    };
    return {
      configName: 'CN013',
      nodeId,
      configData: JSON.stringify(config),
    };
  }

  createDataCN015(nodeId: string) {
    const date = dateISO8601(
      new Date(this.formGroup.CN015.get('permissionGrantingDate')?.value)
    );

    const config: ICN015 = {
      permissionGrantingDate: date,
    };
    return {
      configName: 'CN015',
      nodeId,
      configData: JSON.stringify(config),
    };
  }

  createDataCN016(nodeId: string) {
    const config: ICN016 = {
      taxRegime: this.formGroup.CN016.get('taxRegime')?.value,
    };
    return {
      configName: 'CN016',
      nodeId,
      configData: JSON.stringify(config),
    };
  }

  createDataCN019(nodeId: string) {
    const config: ICN019 = {
      sourceChannel: this.formGroup.CN019.get('sourceChannel')?.value,
      sourceProvider: this.formGroup.CN019.get('sourceProvider')?.value,
      zendeskEmail: this.formGroup.CN019.get('zendeskEmail')?.value,
    };
    return {
      configName: 'CN019',
      nodeId,
      configData: JSON.stringify(config),
    };
  }
}
