<div
  class="card-check grid"
  [ngClass]="{ 'card-check-active': active, 'card-check-disabled': disabled }"
>
  <div class="col-fixed col-icon">
    <div class="card-check-icon">
      <img [src]="icon" alt="icon_card" />
    </div>
  </div>
  <div class="col">
    <p class="card-check-title pb-1">{{ titleCard }}</p>
    <p class="card-check-subtitle">{{ subtitleCard }}</p>
  </div>
</div>
