<deuna-loading [titulo]="'Cargando información'" *ngIf="loading" [subtitulo]="'Por favor, espera un momento'">
</deuna-loading>
<div id="form-container" class="grid h-screen" *ngIf="!loading">
  <ng-container>
    <div class="col grid">
      <div class="md:col-8 md:col-offset-2">
        <div class="text-style-back mt-8 mb-5 mx-2" routerLink="{{relativeRoute+'/list'}}">
          Regresar
        </div>
        <strong class="titles mx-2">¡Comencemos! Completa algunos datos para iniciar la afiliación</strong>
        <form [formGroup]="stepInitialForm" (ngSubmit)="onSubmit()" autocomplete="off">
          <div class="mt-5">
            <deuna-radio-button label="Nivel de gestión" [background]="true" styleClassItem="mt-4" [values]="createTypeOptions" styleType="card" control="type" [form]="stepInitialForm">
            </deuna-radio-button>
          </div>
          <div class="mt-4 mx-2">
            <deuna-button label="Siguiente" [type]="'submit'"></deuna-button>
          </div>
        </form>
      </div>
    </div>
    <div class="m-0 p-0 hidden md:col md:flex pink-container align-content-center justify-content-end flex-wrap form-wrapper">
      <div class="flex justify-content-end">
        <img [src]="searchClientImg" alt="client" class="w-12" />
      </div>
    </div>
  </ng-container>
</div>