<app-exit-modal-report [displayModal]="displayModal" (onAccept)="onAccept()" (onHide)="onHide()"></app-exit-modal-report>
<form *ngIf="!loading" [formGroup]="formStepInitial" (ngSubmit)="onSubmit()" class="grid my-5">
  <div class="col-8 col-offset-2">
    <div class="mb-5">
      <div class="text-style-back" [routerLink]="[relativeRoute, 'info', merchantId]" [queryParams]="{ tab: 'reportsProgram' }">
        Regresar
      </div>
    </div>
    <app-load-step [stepLoad]="50"></app-load-step>
  </div>
  <div class="col-8 col-offset-2">
    <app-card header="Nuevo reporte programado" subHeader="Selecciona el reporte que deseas programar">
      <label for="name" class="block pb-3">Tipo de reporte</label>
      <div class="grid">
        <div class="col-6">
          <app-card-check [icon]="money" [active]="formStepInitial.controls['type'].value === optionSales" (click)="selectCard(optionSales)" titleCard="Reporte de ventas" subtitleCard="Informe que reune todas las ventas del negocio a través de Deuna."></app-card-check>
        </div>
        <div class="col-6">
          <app-card-check [disabled]="true" [icon]="reload" titleCard="Reporte de conciliación" subtitleCard="Informe que reune los depósitos que hace Deuna al negocio por las ventas acumuladas durante el día."></app-card-check>
        </div>
      </div>
      <small *ngIf="(formStepInitial.get('type')?.invalid && (formStepInitial.get('type')?.dirty || formStepInitial.get('type')?.touched))" class="pt-2 p-error block">
        Elige una opción para continuar
      </small>
      <div class="mt-3" *ngIf="formStepInitial.controls['type'].value">
        <deuna-radio-button label="Tipo de cuenta" styleClassItem="pt-2" [values]="levelSend" control="level" [form]="formStepInitial">
        </deuna-radio-button>
      </div>
    </app-card>
  </div>
  <div class="col-4 col-offset-6 pt-4">
    <div class="grid">
      <div class="col-6">
        <deuna-button label="Cancelar" deUnaClass="p-button-outlined" (eventClick)="openModal()">
        </deuna-button>
      </div>
      <div class="col-6">
        <deuna-button label="Siguiente" type="submit"></deuna-button>
      </div>
    </div>
  </div>
</form>