import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CataloguesService } from 'src/app/services/http/catalogues.service';
import { ICatalogue } from 'src/app/shared/interfaces/calatogue.interface';
import { ConfigurationDataService } from 'src/app/services/config/merchant/configuration-data.service';
import _ from 'lodash';

@Component({
  selector: 'app-card-collection-accounts',
  templateUrl: './card-collection-accounts.component.html',
})
export class CardCollectionAccountsComponent implements OnInit {
  @Input() isEditable: boolean = false;
  @Input() isFlowUpdate: boolean = false;
  loading: boolean = false;
  enableEdit: boolean = false;
  @Input() formCN003!: FormGroup;
  formCN003TMP!: FormGroup;
  accountsTypes: Array<ICatalogue> = [];
  @Output() toastEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private configDataService: ConfigurationDataService,
    private cataloguesService: CataloguesService
  ) {}

  ngOnInit(): void {
    this.getDataCatalogs();
  }

  getDataCatalogs() {
    this.formCN003TMP = _.cloneDeep(this.formCN003);
    this.cataloguesService.getAccountTypes().subscribe((res) => {
      this.accountsTypes = res;
    });
    if  (this.isFlowUpdate) {
      this.setStateReable();
    }
  }

  setStateReable() {
    this.formCN003.disable();
  }

  handleEvent($event: boolean) {
    if ($event) {
      this.enableEdit = true;
      this.formCN003.enable();
      const accountType = this.accountsTypes.find(
        (item) => item.name === this.formCN003.controls['accountType']?.value
      );
      this.formCN003.get('accountType')?.setValue(accountType?.code);
    }
  }
  onCancelEdit() {
    this.enableEdit = false;
    this.formCN003.patchValue(this.formCN003TMP.value)
    this.setStateReable();
  }

  async submit() {
    this.loading = true;
    this.formCN003.markAllAsTouched();
    if (!this.formCN003.valid) {
      this.loading = false;
      return;
    }
    await Promise.resolve(this.configDataService.updateConfiguration('CN003'))
      .then(() => {
        this.formCN003TMP = _.cloneDeep(this.formCN003);
        this.emitToast();
        this.setStateReable();
        this.loading = false;
        this.enableEdit = false;
      })
  }

  emitToast() {
    this.toastEvent.emit();
  }
}
