import { Component, EventEmitter, Input, Output } from '@angular/core';
import { assetUrl } from '@deuna/bp-sh-helpers-ng';

@Component({
  selector: 'app-empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.scss'],
})
export class EmptyListComponent {
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() button: boolean = false;
  @Input() buttonText: string = '';
  @Input() img: string = assetUrl('png/checklist.png');
  @Output() clickChip = new EventEmitter<string>();

  click(e: string) {
    this.clickChip.emit(e);
  }
}
