import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ConfigurationFlowPymeCreation {
  private readonly sideSubject = new BehaviorSubject<boolean>(false);

  sideAppState$ = this.sideSubject.asObservable();

  openSideApp() {
    this.sideSubject.next(true);
  }

  closeSideApp() {
    this.sideSubject.next(false);
  }
}
