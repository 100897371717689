import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { assetUrl } from '@deuna/bo-sh-helpers-ng';
import { clientRoute } from 'src/app/constants/routes';
import { ConfigurationFlowCreationService } from 'src/app/services/config/merchant/configuration-flow-creation.service';
import { ICatalogue } from 'src/app/shared/interfaces/calatogue.interface';
import { IFormGroup } from 'src/app/shared/interfaces/form-group.interface';

@Component({
  selector: 'app-check-association',
  templateUrl: 'check-association.page.html',
  styleUrls: ['./check-association.page.scss'],
})
export class CheckAssociationPage implements OnInit {
  relativeRoute = clientRoute;
  merchantIconImg = assetUrl('png/merchantIcon.png');
  formConfigData!: IFormGroup;
  formCheckAssociation!: FormGroup;
  checkAssociationOptions: Array<ICatalogue> = [
    { code: 'yes', name: 'Sí' },
    { code: 'no', name: 'No' },
  ];

  constructor(
    private configFlow: ConfigurationFlowCreationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  async initForm() {
    this.formConfigData = this.configFlow.getForm();
    this.formCheckAssociation = this.formConfigData.checkAssociation;
    this.formCheckAssociation.enable();
  }

  onSubmit() {
    this.formCheckAssociation.markAllAsTouched();

    if(!this.formCheckAssociation.valid) {
      return;
    }

    this.router.navigate([`${clientRoute}/create/search`]);
  }
}
