<deuna-modal [displayModal]="showModalDeactivateNodes" (onAccept)="deactivadeNode()" (onHide)="hideModal()" [headerModalText]="headerModalText" [textModalText]="textModalText" labelAcceptButton="Sí, eliminar" labelCancelButton="No, mantener Sucursal"></deuna-modal>
<deuna-loading [titulo]="'Cargando información'" *ngIf="load" [subtitulo]="'Por favor, espera un momento'">
</deuna-loading>
<deuna-side-right *ngIf="!load" [title]="frameSide.title" [showSide]="showSide" (closeSide)="showSide = false">
    <div class="content-app">
        <div class="grid mt-2 mx-5">
            <div class="col-12 py-5">
                <span class="text-style-back mt-2" routerLink="{{relativeRoute+'/list'}}">Negocios afiliados</span>
                <span class="text-style-back mt-2" style="text-decoration: none; cursor: auto">
                    / {{this.merchant.comercialName}}</span>
            </div>
            <div class="col-icono">
                <img [src]="avatar" alt="avatar" class="w-100" />
            </div>
            <div class="col pl-4">
                <div class="grid">
                    <div class="col-12">
                        <span class="titles">{{this.merchant.comercialName}}</span>
                    </div>
                    <div class="col-12 pt-3">
                        <span class="font-id"><strong>ID: </strong>{{this.merchant.identification}}</span>
                        <deuna-chip backgroundColor="{{existsBranchs? '#ECF9EF':'#FDF4E6'}}" color="{{existsBranchs? '#236936':'#835200'}}" text="{{(existsBranchs? 100: 70) +'% completado'}}"></deuna-chip>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 ml-5 mb-4">
            <deuna-tab-menu [items]="items" [activeItem]="activeItem"></deuna-tab-menu>
        </div>
        <app-tab-branchs (showModalDeactivateNodes)="showModal($event)" (openSide)="openSide($event)" *ngIf="activeItem.id == 'branchs'" [merchant]="merchant"></app-tab-branchs>
        <app-tab-financial-information *ngIf="activeItem.id == 'financialInformation'" (closeSide)="closeSide()"></app-tab-financial-information>
        <app-tab-perfil *ngIf="activeItem.id == 'perfil'" (closeSide)="closeSide()" [search]="search"></app-tab-perfil>
        <app-tab-program-reports *ngIf="activeItem.id == 'reportsProgram'" [existsBranchs]="existsBranchs" (closeSide)="closeSide()"></app-tab-program-reports>
        <app-tab-groups *ngIf="activeItem.id == 'groups'" [name]="merchant.comercialName" (closeSide)="closeSide()"></app-tab-groups>
        <reports-list-table *ngIf="activeItem.id == 'downloadable'" [ruc]="merchant.identification || ''" (closeSide)="closeSide()"></reports-list-table>
    </div>

    <div class="content-side grid m-5" [ngSwitch]="frameSide.view">
        <app-file-update-side class="col-12" *ngSwitchCase="'upload'" (reloadList)="reloadList()" extension="xlsx" [merchant]="merchant"></app-file-update-side>
        <app-view-qr-side class="col-12" *ngSwitchCase="'viewQr'" [merchant]="merchant" [branch]="branch" style="padding-bottom: 50px;"></app-view-qr-side>
    </div>
</deuna-side-right>