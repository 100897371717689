import { Injectable } from '@angular/core';
import {
  IClient,
  IConfigCN000,
} from '../../../shared/interfaces/config-merchant.interfaces';

import { IFormGroupCommercialGroup } from '../../../shared/interfaces/form-group.interface';
import { GraphqlMerchantService } from '../../graphql/graphql-merchant.service';
import { ResponseCodeCif } from '../../../shared/interfaces/query-merchants';
import { ApolloQueryResult } from '@apollo/client';
import { Subscription } from 'rxjs';

import { ConfigurationFormService } from './configuration-form.service';
import {
  ClientType,
  IdentificationType,
} from 'src/app/constants/merchant-constants';
import { ciFromRuc } from 'src/app/shared/utils/helpers';
import { GraphqlCommercialGroupService } from '../../graphql/graphql-commercial-group.service';

@Injectable()
export class ConfigurationFlowCreationService {
  subGraphqlMerchantService!: Subscription;
  formGroup!: IFormGroupCommercialGroup;

  constructor(
    private graphqlMerchantService: GraphqlMerchantService,
    private graphqlCommercialGroupService: GraphqlCommercialGroupService,
    private configurationFormService: ConfigurationFormService
  ) {}

  getForm() {
    if (this.formGroup) {
      return this.formGroup;
    }
    this.formGroup = this.configurationFormService.getForm();
    return this.formGroup;
  }

  resetForm() {
    this.formGroup.search.reset();
    this.formGroup.CN000.reset();
  }

  private getClientFormData(
    clientType: ClientType,
    identification: string
  ): IClient {
    if (clientType === ClientType.NATURAL) {
      return {
        identification: ciFromRuc(identification),
        identificationType: IdentificationType.CI,
      };
    }
    return {
      identification,
      identificationType: IdentificationType.RUC,
    };
  }

  async getBpClient(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const clientType = this.formGroup.search.get('clientType')
        ?.value as ClientType;
      const clientData = this.getClientFormData(
        clientType,
        this.formGroup.search.get('identification')?.value as string
      );
      const resultfields: string = ` cif name`;
      this.graphqlMerchantService
        .getBpClientData(clientData, resultfields)
        .subscribe({
          next: (res: ApolloQueryResult<ResponseCodeCif>) => {
            if (!res.data.getTransactionalContact) {
              resolve(false);
            }
            this.formGroup.search.patchValue({
              cifCode: res.data.getTransactionalContact.cif,
            });
            this.formGroup.CN000.patchValue({
              businessName: res.data.getTransactionalContact.name,
            });
            resolve(true);
          },
          error: (_err) => {
            resolve(false);
          },
        });
    });
  }

  async createCommercialGroup(): Promise<'success' | 'error'> {
    this.formGroup.CN000.enable();
    const tmp = this.formGroup.CN000.value;
    delete tmp.clientAccountId;
    delete tmp.clientType;
    const dataCN000: IConfigCN000 = tmp;
    console.log('createCommercialGroup', dataCN000);
    return new Promise<'success' | 'error'>((resolve) => {
      this.graphqlCommercialGroupService
        .createCommercialGroup(dataCN000)
        .subscribe({
          next: () => {
            resolve('success');
          },
          error: () => {
            resolve('error');
          },
        });
    });
  }
}
