import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { assetUrl } from '@deuna/bp-sh-helpers-ng';
import { ConfigurationFlowCreationService } from '../../../../services/config/merchant/configuration-flow-creation.service';
import { ConfigurationFlowBranchService } from '../../../../services/config/branch/configuration-flow-branch.service';
import { clientRoute } from 'src/app/constants/routes';

@Component({
  selector: 'app-exit-modal',
  templateUrl: './exit-modal.component.html',
  styleUrls: ['./exit-modal.component.scss'],
})
export class ExitModalComponent {
  @Input() displayModal: boolean = false;
  @Input() pathReturn: string = `${clientRoute}/list`;
  @Output() onHide = new EventEmitter();
  image = assetUrl('png/exit_logo.png');

  constructor(
    private router: Router,
    private configFlow: ConfigurationFlowCreationService,
    private configFlowBranch: ConfigurationFlowBranchService
  ) {}

  hideModal() {
    this.displayModal = false;
    this.onHide.emit();
  }

  removeStorage() {
    this.configFlow.resetForm();
    this.configFlowBranch.resetForm();
    this.router.navigate([this.pathReturn]);
  }
}
