import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { assetUrl } from '@deuna/bp-sh-helpers-ng';

const ICON_MAP = {
  emptyReportInactive: 'png/report-inactive.png',
  emptyBranchReportInactive: 'png/exit_logo.png',
};
@Component({
  selector: 'app-exit-modal-report',
  templateUrl: './exit-modal-report.component.html',
})
export class ExitModalReportComponent implements OnInit {
  @Input() exitModalTitle: string = '¿Quieres abandonar el proceso?';
  @Input() exitModalDescription: string =
    'Al seleccionas “Cancelar”, se eliminará toda la información registrada del negocio hasta el momento';
  @Input() exitAcceptActionMessage: string = 'Sí, cancelar';
  @Input() exitCancelActionMessage: string = 'No, continuar editando';
  @Input() exitModalIcon: string = 'emptyReportInactive';
  @Input() displayModal: boolean = false;
  @Output() onAccept = new EventEmitter();
  @Output() onHide = new EventEmitter();
  emptyReportInactive: string = '';

  ngOnInit(): void {
    this.emptyReportInactive = assetUrl(ICON_MAP[this.exitModalIcon]);
  }

  accept() {
    this.onAccept.emit();
  }

  hide() {
    this.onHide.emit();
  }
}
