import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ErrorStateService } from './state/global-error.state.service';
import { ActionInterface, CustomBackofficeControlledErrorInterface, DisplayModeActionFn, DisplayModeTypes } from './types';
import { GlobalErrorActionService } from './actions/global-error.actions.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-global-error',
  templateUrl: './global-error.component.html',
})
export class GlobalErrorComponent implements OnInit {
  globalError!: CustomBackofficeControlledErrorInterface | null;
  currentDisplayMode: DisplayModeTypes | null = null;

  mapOfDisplayModeWithLoadActions: Record<DisplayModeTypes, DisplayModeActionFn | undefined> = {
    ACTION: this.executeAction.bind(this),
    TOAST: this.executeToast.bind(this),
    FULL_SCREEN: undefined,
    SCREEN: undefined,
  }

  constructor(
    private readonly errorStateService: ErrorStateService,
    private readonly errorActionService: GlobalErrorActionService,
    private readonly messageService: MessageService,
  ) {}

  @Output() hasError: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.errorStateService.error$.subscribe((error) => {
      this.globalError = error;
      this.currentDisplayMode = this.globalError?.displayMode || null;
      this.hasError.emit(Boolean(error) && this.currentDisplayMode === 'SCREEN');
      this.executeDisplayMode();
    });
  }

  executeDisplayMode() {
    const displayMode = this.globalError?.displayMode || undefined;
    const action = this.globalError?.actions?.[0] || undefined;

    if (!displayMode) return;

    const actionFn = this.mapOfDisplayModeWithLoadActions[displayMode];

    actionFn?.(this.globalError?.title!, action);
  }

  executeAction(_message: string, action: ActionInterface | undefined) {
    this.errorActionService.executeAction(action?.actionType!, action?.value!);
  }

  executeToast(message: string, action: ActionInterface | undefined,) {
    this.messageService.add({
      key: 'global-error',
      severity: 'error',
      summary: message,
      closable: false,
    });
    if (action) {
      this.errorActionService.executeAction(action.actionType, action?.value);
    }
  }
}
