import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { assetUrl } from '@deuna/bo-sh-helpers-ng';
import { MutationResult } from 'apollo-angular';
import { MessageService } from 'primeng/api';
import { clientRoute } from 'src/app/constants/routes';
import { GraphqlReportService } from 'src/app/services/graphql/graphql-report.service';
import {
  ResponseCreateReportBranch,
  ResponseFileValidation,
} from 'src/app/shared/interfaces/generation-config-report.interface';

@Component({
  selector: 'app-report-sales-branch',
  templateUrl: './report-sales-branch.page.html',
  styleUrls: ['./report-sales-branch.page.scss'],
})
export class ReportSalesBranchPage implements OnInit {
  relativeRoute = clientRoute;
  merchantId: string = '';
  loading: boolean = false;
  displayModal: boolean = false;
  uploadFileStatus: string = 'new';
  uploadFileProgress: number = 0;
  uploadErrorTitle: string = '';
  uploadErrorMessage: string = '';
  uploadErrorDetails: string[] = [];
  isErrorCreatingReport: boolean = false;
  createReportErrorImage = assetUrl('png/report-inactive.png');
  programmedReportTemplate = assetUrl(
    'xlsx/Plantilla_creación_reporte_por_sucursales.xlsx'
  );

  constructor(
    private readonly router: Router,
    private readonly graphqlReportService: GraphqlReportService,
    private readonly messageService: MessageService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.merchantId = this.getMerchantIdFromParams();
  }

  async onSubmit() {
    this.loading = true;
    this.createBranchReport(this.merchantId);
  }

  onAccept() {
    this.router.navigate([`${clientRoute}/info`, this.merchantId], {
      queryParams: { tab: 'reportsProgram' },
    });
  }

  onHide() {
    this.displayModal = false;
  }

  openModal() {
    this.displayModal = true;
  }

  onFileUploaded(event) {
    this.uploadFileStatus = 'upload';
    this.uploadFileProgress = 90;
    if (event) {
      const file = event;
      this.uploadFile(file, this.merchantId);
    }
  }

  private uploadFile(file: File, merchantId: string) {
    this.graphqlReportService
      .uploadFile(file, 'scheduledReport', merchantId)
      .subscribe({
        next: (res: MutationResult<ResponseFileValidation>) => {
          const data = res.data;
          if (data) {
            const valid = data.fileValidation.success;
            if (valid) {
              this.uploadFileProgress = 100;
              this.uploadFileStatus = 'success';
            } else {
              const { title, message, details } =
                data.fileValidation.errorMessage!;
              console.log(title, message, details);
              this.uploadFileStatus = 'error';
              this.uploadErrorTitle = title!;
              this.uploadErrorMessage = message!;
              this.uploadErrorDetails = details ?? [];
            }
          }
        },
        error: (error) => {
          this.uploadFileStatus = 'error';
          this.uploadErrorTitle = 'Ocurrio un problema al cargar el archivo';
          this.uploadErrorMessage = 'Por favor, vuelva a cargarlo';
        },
      });
  }

  private createBranchReport(merchantId) {
    this.graphqlReportService.createBranchReport(merchantId).subscribe({
      next: (res: MutationResult<ResponseCreateReportBranch>) => {
        const data = res.data;
        if (data) {
          this.showReportCreatedNotification();
          this.router.navigate([`${clientRoute}/info`, this.merchantId], {
            queryParams: { tab: 'reportsProgram' },
          });
        }
      },
      error: () => {
        this.isErrorCreatingReport = true;
        this.loading = false;
      },
    });
  }

  showReportCreatedNotification() {
    this.messageService.add({
      key: 'global',
      severity: 'deuna',
      summary: 'Programaste un nuevo reporte',
      closable: false,
    });
  }

  onSubmitErrorAccept() {
    this.router.navigate([`${clientRoute}/info`, this.merchantId], {
      queryParams: { tab: 'reportsProgram' },
    });
  }

  onSubmitErrorCancel() {
    this.router.navigate([
      `${clientRoute}/report/create/report-initial`,
      this.merchantId,
    ]);
  }

  getMerchantIdFromParams() {
    return this.activatedRoute.snapshot.paramMap.get('merchantId') ?? '';
  }
}
