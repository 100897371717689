import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-load-step',
  templateUrl: './load-step.component.html',
  styleUrls: ['./load-step.component.scss'],
})
export class LoadStepComponent {
  @Input() stepLoad: number = 0;
  @Input() heigth: string = '12px';
}
