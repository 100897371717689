<app-card header="Información visible al cliente" [isFlowUpdate]="isFlowUpdate" [isEditable]="isEditable" (customEvent)="handleEvent($event)">
  <div class="grid">
    <div class="col-6 pr-3">
      <deuna-input [masked]="false" [maxLength]="100" [form]="formDataCN000" label="Nombre comercial" placeholder="Ej: Dulcafe" name="tradename" control="tradename">
      </deuna-input>
      <div *ngIf="(isFlowUpdate && isEditable && !isFlowUpdate) || enableEdit" class="pt-4">
        <app-update-button-footer [loading]="loading" (eventSubmit)="submit()" (eventCancel)="onCancelEdit()"></app-update-button-footer>
      </div>
    </div>
    <div class="col-6">
      <app-image-textvisible [image]="nombreComercialImg" [avatar]="avatar" [text]="formDataCN000.controls['tradename'].value">
      </app-image-textvisible>
    </div>
  </div>
</app-card>