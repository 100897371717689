<div class="grid mx-5">
  <div class="col-5">
    <deuna-input [masked]="false" styles="background:#F1F2F5" [form]="formSearch" placeholder="Buscar un No de ID o palabra clave" [maxLength]="200" name="textSearch" control="textSearch" (keyUpData)="goToSearchKeys($event)">
    </deuna-input>
  </div>
  <div class="col-7">
    <div class="grid">
      <div class="col-offset-3 col-9 flex align-items-center justify-content-end">
        <div>
          <deuna-button label="Descargar" deUnaClass="p-button-outlined" [disabled]="branchsList.length === 0" (eventClick)="subMenuButtonDownload.toggle($event)">
            <div class="icon">
              <i class="material-icons material-icons-outlined" aria-hidden="true">{{ "cloud_download" }}</i>
            </div>
          </deuna-button>
          <deuna-menu [menuButton]="menuButtonDownload" (registerSubmenuButton)="registerSubmenuDownload($event)">
          </deuna-menu>
        </div>
        <div class="ml-4">
          <deuna-button id='add-branches-button' label="Agregar sucursales" (eventClick)="subMenuButtonBranch.toggle($event)">
          </deuna-button>
          <deuna-menu [menuButton]="menuButtonBranch" (registerSubmenuButton)="registerSubmenuBranch($event)">
          </deuna-menu>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 text-center" *ngIf="loadListBranchs">
    <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
    <h2 class="mt-2">Cargando....</h2>
  </div>

  <div class="col-12" *ngIf="!loadListBranchs">
    <ng-container *ngIf="branchsList.length > 0; else notBranch">
      <deuna-table [arrayData]="[1]" [columnas]="headers">
        <ng-container *ngFor="let branch of branchsList; let i = index">
          <tr>
            <deuna-table-cell class="px-0 text-center">
              <strong class="button-pag" (click)="rowsExpands = rowsExpands === i ? -1 : i">{{ rowsExpands === i ? "-" : "+" }}</strong>&nbsp;
              <p-checkbox name="group1" value="branch.id" label=" " inputId="ny" (onChange)="selectBranch($event, branch)">
              </p-checkbox>
            </deuna-table-cell>
            <deuna-table-cell>
              <deuna-chip backgroundColor="#ECF9EF" color="#236936" [text]="branch.type === 'S' ? 'Sucursal' : branch.type">
              </deuna-chip>
            </deuna-table-cell>
            <deuna-table-cell [routerLink]="[relativeRoute + '/branch/info/', merchantId, branch.id]" style="cursor: pointer">{{ branch.name }}
            </deuna-table-cell>
            <deuna-table-cell>{{ branch.id }}</deuna-table-cell>
            <deuna-table-cell>{{ branch.email }}</deuna-table-cell>
            <deuna-table-cell>{{ branch.cellphone }}</deuna-table-cell>
            <deuna-table-cell>
              <span class="text-style-back" (click)="clickOpen(frameQr, branch, null)">
                Ver QRs</span>
            </deuna-table-cell>
            <deuna-table-cell [routerLink]="[relativeRoute + '/branch/info', merchantId, branch.id]" style="cursor: pointer">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 15.2486V18.9986H3.75L14.81 7.93859L11.06 4.18859L0 15.2486ZM17.71 5.03859C18.1 4.64859 18.1 4.01859 17.71 3.62859L15.37 1.28859C14.98 0.898594 14.35 0.898594 13.96 1.28859L12.13 3.11859L15.88 6.86859L17.71 5.03859Z" fill="#4C1D80" />
              </svg>
            </deuna-table-cell>
            <deuna-table-cell>
              <a href="#" (click)="clickTrash(branch)">
                <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z" fill="#4C1D80" />
                </svg>
              </a>
            </deuna-table-cell>
          </tr>
          <tr *ngIf="rowsExpands === i">
            <td colspan="9" style="padding-top: 0px">
              <deuna-table [arrayData]="[]" [columnas]="[]">
                <ng-container *ngFor="let subBranch of branch.POSList">
          <tr>
            <deuna-table-cell>
              <p-checkbox name="group1" value="subBranch.id" label=" " inputId="ny" (onChange)="selectBranch($event, subBranch)">
              </p-checkbox>
            </deuna-table-cell>
            <deuna-table-cell>
              <deuna-chip [backgroundColor]="'#F1F2F5'" [color]="'#3D4556'" [text]="subBranch.nodeType === 'C' ? 'Caja' : ''">
              </deuna-chip>
            </deuna-table-cell>
            <deuna-table-cell>{{ subBranch.nodeType === "C" ? "Caja" : "" }} -
              {{ subBranch.id }}
            </deuna-table-cell>
            <deuna-table-cell>{{ subBranch.id }}</deuna-table-cell>
            <deuna-table-cell>-</deuna-table-cell>

            <deuna-table-cell>-</deuna-table-cell>
            <deuna-table-cell>
              <span class="text-style-back" (click)="clickOpen(frameQr, branch, subBranch)">Ver QR</span>
            </deuna-table-cell>
            <deuna-table-cell>-</deuna-table-cell>
            <deuna-table-cell>
              <a href="#" (click)="clickTrash(subBranch)">
                <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z" fill="#4C1D80" />
                </svg>
              </a>
            </deuna-table-cell>
          </tr>
        </ng-container>
      </deuna-table>
      </td>
      </tr>
    </ng-container>
    </deuna-table>
    <deuna-pagination (paginaEmitter)="goToPageBranch($event)" (itemsEmmiter)="changeToItemsBranch($event)" [currentPage]="dataPagination.currentPage" [limitPage]="dataPagination.itemCount" [totalItems]="dataPagination.totalItems" [totalPages]="dataPagination.totalPages">
    </deuna-pagination>
    </ng-container>
  </div>
</div>
<ng-template #notBranch>
  <app-empty-list title="Ups, parece que no hay sucursales por gestionar" subtitle="Para crear una nueva sucursal haz click en “Agregar sucursal”"></app-empty-list>
</ng-template>
<p-toast key="info" [style]="{ width: 'auto' }" position="bottom-right" [baseZIndex]="10000">
  <ng-template let-message pTemplate="message">
    <div class="flex" style="
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      ">
      <div class="icon">
        <i class="material-icons material-icons-outlined" aria-hidden="true">{{
          message.icon
          }}</i>
      </div>
      <em style="vertical-align: middle; margin-left: 8px">{{
        message.summary
        }}</em>
    </div>
  </ng-template>
</p-toast>
<p-toast key="faild" [style]="{ width: 'auto', height: '100px' }" position="bottom-right" [baseZIndex]="10000">
  <ng-template let-message pTemplate="message">
    <div style="margin-top: 5%">
      <em>{{ message.summary }}</em>
    </div>
  </ng-template>
</p-toast>