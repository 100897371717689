import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ICatalogue } from '../../../../../shared/interfaces/calatogue.interface';
import {
  optionsDays,
  optionsFrequency,
} from '../../../../../constants/catalogos/mocks-catalogo';

import { createDays, createHours } from '../../../../../shared/utils/helpers';
import { IFormGroupReport } from '../../../../../shared/interfaces/form-group.interface';
@Component({
  selector: 'app-card-frequency',
  templateUrl: './card-frequency.component.html',
})
export class CardFrequencyComponent implements OnInit {
  @Input() isEditable: boolean = false;
  @Input() form!: IFormGroupReport;
  formFrecuency!: FormGroup;
  labelDay: string = '';
  optionsFrequency: Array<ICatalogue> = optionsFrequency;

  optionsHour: Array<ICatalogue> = [];
  tmpOptionsDays: Array<ICatalogue> = [];
  optionsDaysMonth: Array<ICatalogue> = [];

  ngOnInit(): void {
    this.optionsHour = createHours();
    this.optionsDaysMonth = createDays(30);
    this.setStateEnable();
  }

  setStateEnable() {
    this.formFrecuency = this.form.frequency;
    this.formFrecuency.get('cutOffTime')?.disable();
    this.mapOptions();
    this.formFrecuency.get('daysFrequency')?.disable();
    this.formFrecuency.get('cutOffDay')?.disable();
  }

  mapOptions(): void {
    const frequency = this.formFrecuency.get('daysFrequency')?.value;
    const optionFrecuency = this.optionsFrequency.find(
      (item) => item.code === frequency
    );
    this.formFrecuency.get('daysFrequency')?.setValue(optionFrecuency?.name);

    const optionsMap: Record<string, any[]> = {
      '1': [],
      '7': optionsDays,
      '30': this.optionsDaysMonth,
    };

    const optionsToSearch = optionsMap[frequency] || [];

    const optionDay = optionsToSearch.find(
      (item) => item.code === this.formFrecuency.get('cutOffDay')?.value
    );
    this.formFrecuency.get('cutOffDay')?.setValue(optionDay?.name || '-');

    const optionHour = this.optionsHour.find(
      (item) =>
        item.code === this.formFrecuency.controls['cutOffTime']?.value[0]
    );
    this.formFrecuency.get('cutOffTime')?.setValue(optionHour?.name);
  }
}
