import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DeUnaInputActionStates } from '@deuna/bo-sh-components-ng';
import { InputStates } from '../../../../constants/merchant-constants';
import { ConfigurationFormService } from '../../../../services/config/merchant/configuration-form.service';
import { GraphqlClientService } from '../../../../services/graphql/graphql-client.service';
import { VerifyAccount } from '../../../../shared/interfaces/config-client.interfaces';

@Component({
  selector: 'account-validator',
  templateUrl: './account-validator.component.html',
  styleUrls: ['./account-validator.component.scss'],
})
export class AccountValidatorComponent {
  @Input() form!: FormGroup;
  accountInputState = DeUnaInputActionStates.INITIAL;
  accountHolderName: string = '';
  responseMessage: string | undefined = '';
  accountOwnerHeaderTitle!: string;
  isButtonClicked: boolean = false;
  canContinue = false;
  validationState: InputStates = InputStates.IDLE;
  inputHasData = false;

  constructor(
    private readonly graphqlClientService: GraphqlClientService,
    private readonly configurationFormService: ConfigurationFormService,
  ) {
  }

  async handleValidate() {
    this.validationState = InputStates.VALIDATING;
    if (this.inputHasData) this.accountInputState = DeUnaInputActionStates.LOADING;
    this.clearAccountData();
    this.isButtonClicked = true;
    this.form.get('accountNumber')?.updateValueAndValidity();
    const accountNumber = (this.form.get('accountNumber')?.value).toString();
    const cif = this.configurationFormService.getForm().search.get('cifCode')?.value;
    const res = await this.validateAccount(accountNumber, cif);
    this.canContinue = res?.canContinue;
    this.accountOwnerHeaderTitle = 'Titular de la cuenta';
    this.accountHolderName = res?.accountHolderName ?? '-';
    this.responseMessage = res?.message;
    this.form.setErrors(this.canContinue ? null : { 'invalid': true });
    this.validationState = InputStates.COMPLETED;
    this.accountInputState = DeUnaInputActionStates.INITIAL;
  }

  clearAccountData() {
    this.accountOwnerHeaderTitle = '';
    this.accountHolderName = '';
    this.responseMessage = '';
  }

  async validateAccount(accountNumber: string, cif: string) {
    return new Promise<VerifyAccount>((resolve, reject) => {
      this.graphqlClientService.validateAccount({ accountNumber, cif })
        .subscribe({
          next: (res) => {
            const data = res.data.verifyAccount;
            resolve(data);
          },
          error: (error) => {
            reject(error);
          },
        });
    });
  }

  resetInputState() {
    this.inputHasData = true;
    this.validationState = InputStates.INSERTING;
    this.clearAccountData();
    this.form.setErrors({ 'invalid': true });
  }

  isAccountNotSet() {
    return this.form.get('accountNumber')?.invalid && this.form.get('accountNumber')?.errors?.['required'] && this.form.get('accountNumber')?.touched;
  }

  isAccountNotValidated() {
    return this.validationState === InputStates.INSERTING;
  }
}
