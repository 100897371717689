<div class="container-center text-center">
    <img loading="lazy" [src]="img" alt="image" style="width: 350px;">
    <div>
        <h2 class="fontLoadHeader" style="margin:auto">{{title}}</h2>
        <h2 class="fontLoadSubHeader">{{subtitle}}</h2>
    </div>
    <div *ngIf="button">
        <br>
        <deuna-chip backgroundColor="#202020" color="#FFFFFF" [text]="buttonText" [chipClick]="true" (closeChip)="click($event)">
        </deuna-chip>
    </div>
</div>