import { Component, OnInit } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { clientRoute } from 'src/app/constants/routes';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { assetUrl } from '@deuna/bp-sh-helpers-ng';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-list',
  templateUrl: './list.page.html',
  styleUrls: ['./list.page.scss'],
  providers: [TitleCasePipe],
})
export class ListPage implements OnInit {
  relativeRoute = clientRoute;
  load: boolean = true;
  items: Array<MenuItem> = [];
  activeItem: MenuItem = {};
  imgNotFoundMerchants: string = assetUrl('png/checklist.png');
  enviroment = environment;
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.items = this.getTabs();

    this.activatedRoute.queryParams.subscribe((params) => {
      const tab = params['tab'];
      if (!tab) {
        return this.redirectTab({ item: this.items[0] });
      }
      if (tab !== this.activeItem.id) {
        return this.redirectTab({
          item: this.items.find((i) => i.id === tab),
        });
      }
    });
  }

  private redirectTab = ({ item }) => {
    this.router.navigate([`${clientRoute}/merchant/client/list`], {
      queryParams: { tab: item.id },
    });
    this.activeItem = item;
  };

  private getTabs = () => {
    return [
      {
        label: 'Negocios',
        id: 'merchants',
        command: (item) => this.redirectTab(item),
      },
      {
        label: 'Grupos',
        id: 'groups',
        command: (item) => this.redirectTab(item),
      },
    ];
  };
}
