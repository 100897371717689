import { Injectable } from '@angular/core';
import {
  ICN000,
  ICN001,
  ICN002,
  ICN003,
  ICN004,
  ICN005,
  ICN006,
  ICN012,
  ICN013,
  ICN016,
  ICN019,
  IFormGroup,
} from 'src/app/shared/interfaces/form-group.interface';
import { GraphqlMerchantService } from '../../graphql/graphql-merchant.service';
import { ApolloQueryResult } from '@apollo/client';
import {
  INodeConfiguration,
  IResponseConfiguration,
  NodeConfiguration,
} from 'src/app/shared/interfaces/query-configuration';
import { ConfigurationFormService } from './configuration-form.service';
import { MutationResult } from 'apollo-angular';
import {
  CreateAllConfiguration,
  IConfigDataCN012,
  IConfigDataCN013,
  IDataConfig,
} from 'src/app/shared/interfaces/config-merchant.interfaces';
import {
  InputUpdateClient,
  ResponseMessage,
} from 'src/app/shared/interfaces/query-merchants';
import { DEFAULT_BANK } from 'src/app/constants/merchant-constants';

@Injectable()
export class ConfigurationDataService {
  formGroup!: IFormGroup;
  configurationsData!: INodeConfiguration[];
  configMerchants: IDataConfig = { data: [] };

  constructor(
    private graphqlMerchantService: GraphqlMerchantService,
    private configurationFormService: ConfigurationFormService
  ) {}

  getForm(): IFormGroup {
    if (this.formGroup) {
      return this.formGroup;
    }
    this.formGroup = this.configurationFormService.getForm();
    return this.formGroup;
  }

  getConfigurationsData(configurationName): INodeConfiguration {
    return this.configurationsData.find(
      (config) => config.configName === configurationName
    ) as INodeConfiguration;
  }

  async setFormDataMerchant(merchantId: string, configs: string[]) {
    this.getForm();
    await this.getMerchantDataConfigurations(merchantId);

    for (const config of configs) {
      let data = await this.getConfigDataByName(config);

      if(!data && config === 'CN019') {
        data = {
          zendeskEmail: '',
          sourceChannel: null,
          sourceProvider: null,
        };
      }

      const nameFunt = `getFormData${config}`;
      if (data) this[nameFunt](data);
    }
  }

  async getMerchantDataConfigurations(merchantId: string) {
    return new Promise<void>((resolve) => {
      this.graphqlMerchantService
        .getAllConfigMerchantById(merchantId)
        .subscribe({
          next: async (res: ApolloQueryResult<IResponseConfiguration>) => {
            const { configurations } = res.data.getAllConfigMerchantById;
            this.configurationsData = [...configurations];
            resolve();
          },
        });
    });
  }

  getConfigDataByName(configurationName: string) {
    const configName = this.configurationsData.find(
      (config) => config.configName === configurationName
    ) as INodeConfiguration;
    return configName ? JSON.parse(configName?.configData) : null;
  }

  getFormDataCN000(configData: ICN000): void {
    this.setControl('businessName', 'CN000', configData);
    this.formGroup.CN000.controls['tradename'].setValue(
      configData?.comercialName
    );
    this.setControl('identification', 'CN000', configData);
    this.setControl('identificationType', 'CN000', configData);
  }

  getFormDataCN001(configData: ICN001): void {
    this.formGroup.CN001.controls['legalRepresentative'].setValue(
      configData.legalRepresentatives.person[0].name
    );
  }

  getFormDataCN002(configData: ICN002): void {
    this.formGroup.CN002.controls['category'].setValue(configData.category);
    this.formGroup.CN002.controls['subcategory'].setValue(
      configData.subcategory
    );
    this.setControl('businessType', 'CN002', configData);
    this.setControl('segment', 'CN002', configData);
  }

  getFormDataCN003(configData: ICN003): void {
    this.formGroup.CN003.controls['bankName'].setValue(configData.bankName);
    this.formGroup.CN003.controls['accountType'].setValue(
      configData.accountType
    );
    this.formGroup.CN003.controls['accountNumber'].setValue(
      configData.accountNumber
    );
    this.formGroup.CN003.controls['identificationNumber'].setValue(
      configData.identificationNumber
    );
  }

  getFormDataCN004(configData: ICN004): void {
    this.setControl('region', 'CN004', configData);
    this.setControl('province', 'CN004', configData);
    this.setControl('canton', 'CN004', configData);
    this.setControl('country', 'CN004', configData);
    this.formGroup.CN004.controls['streetPrincipal'].setValue(
      configData?.mainStreet
    );
    this.formGroup.CN004.controls['streetSecondary'].setValue(
      configData?.secondaryStreet
    );
    this.formGroup.CN004.controls['houseNumber'].setValue(
      configData?.reference
    );
  }

  getFormDataCN005(configData: ICN005): void {
    this.setControl('region', 'CN005', configData);
    this.formGroup.CN005.controls['phoneCode'].setValue(
      configData?.countryCode
    );
    this.formGroup.CN005.controls['phoneNumber'].setValue(
      configData?.cellPhone
    );
    this.formGroup.CN005.controls['emailMerchant'].setValue(configData?.email);
    this.formGroup.CN005.controls['webhookUrl']?.setValue(
      configData?.webhookUrl
    );
    this.formGroup.CN005.controls['webhookEmail']?.setValue(
      configData?.webhookEmail
    );
    this.configurationFormService.setFormHeadersCN005(
      configData?.webhookHeaders
    );
  }

  getFormDataCN006(configData: ICN006): void {
    this.formGroup.CN006.controls['sms'].setValue(configData?.sms);
    this.formGroup.CN006.controls['email'].setValue(configData?.email);
    this.formGroup.CN006.controls['webhook'].setValue(configData?.webhook);
  }

  getFormDataCN012(configData: ICN012): void {
    this.formGroup.CN012?.controls['bankName'].setValue(configData?.bankName);
    this.formGroup.CN012?.controls['accountType'].setValue(
      configData?.accountType
    );
    this.formGroup.CN012?.controls['accountNumber'].setValue(
      configData?.accountNumber
    );
  }

  getFormDataCN013(configData: ICN013): void {
    this.formGroup.CN013?.controls['email'].setValue(configData?.email);
  }

  getFormDataCN016(configData: ICN016): void {
    this.formGroup.CN016?.controls['taxRegime'].setValue(configData?.taxRegime);
  }

  getFormDataCN019(configData: ICN019): void {
    this.setControl('sourceChannel', 'CN019', configData);
    this.formGroup.CN019?.controls['zendeskEmail'].setValue(configData?.zendeskEmail ?? '');
    this.formGroup.CN019?.controls['sourceProvider'].setValue(configData?.sourceProvider);
  }

  setControl(_name: string, _nameFormGroup: string, _data: object): void {
    this.formGroup[_nameFormGroup].controls[_name].setValue(_data[_name]);
  }

  buildUpdateRequestConfig(
    formValues:
      | ICN000
      | ICN001
      | ICN002
      | ICN003
      | ICN004
      | ICN005
      | ICN006
      | ICN012
      | ICN013
      | ICN016
      | ICN019,
    configName: string
  ): NodeConfiguration {
    const currentValues: INodeConfiguration =
      this.getConfigurationsData(configName);
    const { id, nodeId } = currentValues;
    const configData = JSON.stringify(formValues);

    return {
      configId: id,
      configName,
      nodeId,
      configData,
    };
  }

  updateCN005Configuration(): Promise<MutationResult<NodeConfiguration>> {
    const CN005FormValues = this.formGroup.CN005.getRawValue();
    const {
      emailMerchant,
      region,
      phoneNumber,
      phoneCode,
      webhookUrl,
      webhookHeaders,
      webhookEmail,
    } = CN005FormValues;
    const phoneCodeTMP = phoneCode?.name ? phoneCode.name : phoneCode;
    const phoneCodeSplit: string[] = phoneCodeTMP
      ? phoneCodeTMP.split('+')
      : ['593'];

    const countryCode =
      phoneCodeSplit.length > 1 ? phoneCodeSplit[1] : phoneCodeSplit[0];
    const updatedConfigData: ICN005 = {
      email: emailMerchant,
      region: region,
      cellPhone: phoneNumber,
      countryCode,
      webhookUrl,
      webhookVersion: 'V2',
      webhookHeaders,
      webhookEmail: webhookEmail,
    };
    const config = this.buildUpdateRequestConfig(updatedConfigData, 'CN005');
    return this.updateConfigGraphql(config);
  }

  updateCN000Configuration(): Promise<MutationResult<NodeConfiguration>> {
    const CN000FormValues = this.formGroup.CN000.getRawValue();
    const { identification, type, businessName, tradename } = CN000FormValues;

    const updatedConfigData: ICN000 = {
      identification,
      businessName,
      identificationType: type,
      comercialName: tradename,
    };
    const config = this.buildUpdateRequestConfig(updatedConfigData, 'CN000');
    return this.updateConfigGraphql(config);
  }

  updateClientComercialNameInfo(
    merchantId: string
  ): Promise<MutationResult<ResponseMessage>> {
    const CN000FormValues = this.formGroup.CN000.getRawValue();
    const { tradename } = CN000FormValues;

    const inputUpdateClient: InputUpdateClient = {
      clientId: merchantId,
      comercialName: tradename,
    };
    return this.updateClientInfoGraphql(inputUpdateClient);
  }

  updateCN001Configuration(): Promise<MutationResult<NodeConfiguration>> {
    const CN001FormValues = this.formGroup.CN001.getRawValue();
    const { legalRepresentative } = CN001FormValues;

    const updatedConfigData: ICN001 = {
      legalRepresentatives: {
        person: [{ name: legalRepresentative }],
      },
    };
    const config = this.buildUpdateRequestConfig(updatedConfigData, 'CN001');
    return this.updateConfigGraphql(config);
  }

  updateCN004Configuration(): Promise<MutationResult<NodeConfiguration>> {
    const {
      region,
      province,
      canton,
      streetPrincipal,
      streetSecondary,
      houseNumber,
      country,
    } = this.formGroup.CN004.getRawValue();
    const updatedConfigData: ICN004 = {
      region,
      province,
      canton,
      mainStreet: streetPrincipal,
      secondaryStreet: streetSecondary,
      reference: houseNumber,
      country,
    };
    const config = this.buildUpdateRequestConfig(updatedConfigData, 'CN004');
    return this.updateConfigGraphql(config);
  }

  updateConfiguration(
    configId: 'CN002' | 'CN003' | 'CN006' | 'CN012' | 'CN013' | 'CN019'
  ): Promise<MutationResult<NodeConfiguration>> {
    const updatedConfigData = this.formGroup[configId].getRawValue() as
      | ICN002
      | ICN003
      | ICN006
      | ICN012
      | ICN013
      | ICN019;

    const config = this.buildUpdateRequestConfig(updatedConfigData, configId);
    return this.updateConfigGraphql(config);
  }

  async updateOrCreateConfiguration(
    configId: 'CN019'
  ): Promise<MutationResult<NodeConfiguration | CreateAllConfiguration>> {
    //Validate configuration doesn't exist by checking id in currentValues
    //then attempt to create it
    const configData = this.getConfigurationsData(configId);
    if(!configData?.id) { 
      return (await this.createConfigurationNode('CN019')) as MutationResult<CreateAllConfiguration>;
    }

    //Fallback to updating if id was found in current values
    return this.updateConfiguration(configId);
  }

  updateConfigGraphql(
    config: NodeConfiguration
  ): Promise<MutationResult<NodeConfiguration>> {
    return new Promise<MutationResult<NodeConfiguration>>((resolve, reject) => {
      this.graphqlMerchantService.updateConfiguration(config).subscribe({
        next: (result: MutationResult<NodeConfiguration>) => {
          resolve(result);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  updateClientInfoGraphql(
    config: InputUpdateClient
  ): Promise<MutationResult<ResponseMessage>> {
    return new Promise<MutationResult<ResponseMessage>>((resolve, reject) => {
      this.graphqlMerchantService.updateClient(config).subscribe({
        next: (result: MutationResult<ResponseMessage>) => {
          resolve(result);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  createDataCN013(nodeId: string) {
    const config: IConfigDataCN013 = {
      email: this.formGroup.CN013.get('email')?.value,
    };
    return {
      configName: 'CN013',
      nodeId,
      configData: JSON.stringify(config),
    };
  }

  createDataCN012(nodeId: string) {
    const config: IConfigDataCN012 = {
      accountNumber: this.formGroup.CN012.get('accountNumber')?.value,
      bankName: DEFAULT_BANK,
      accountType: this.formGroup.CN012.get('accountType')?.value,
    };
    return {
      configName: 'CN012',
      nodeId,
      configData: JSON.stringify(config),
    };
  }

  createDataCN019(nodeId: string) {
    const config:ICN019 = {
      zendeskEmail: this.formGroup.CN019.get('zendeskEmail')?.value,
      sourceChannel: this.formGroup.CN019.get('sourceChannel')?.value,
      sourceProvider: this.formGroup.CN019.get('sourceProvider')?.value,
    };
    return {
      configName: 'CN019',
      nodeId,
      configData: JSON.stringify(config),
    };
  }

  createConfigurationNode(configName: string) {
    const currentValues: INodeConfiguration =
      this.getConfigurationsData('CN003');
    const { nodeId } = currentValues;
    return new Promise<Object>((resolve, reject) => {
      this.configMerchants.data.push(
        this[`createData${configName}`](nodeId?.toString())
      );
      this.graphqlMerchantService
        .createConfiguration(this.configMerchants)
        .subscribe({
          next: (result: Object) => {
            resolve(result);
          },
          error: (err) => {
            reject(err);
          },
        });
    });
  }
}
