import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-card-data-usage',
  templateUrl: './card-data-usage.component.html',
})
export class CardDataUsageComponent implements OnInit {
  loading: boolean = false;

  @Input() search!: FormGroup;
  enableEdit: boolean = false;
  @Input() formCN015!: FormGroup;
  @Input() isCreationFlow: boolean = false;

  @Input() isEditable: boolean = false;
  @Input() isFlowUpdate: boolean = false;

  ngOnInit(): void {
    if (this.isCreationFlow) {
      this.formCN015.controls['permissionGrantingDate'].enable();
    }
  }

  handleEvent(data: boolean) {
    if (data) {
      this.enableEdit = true;
      this.formCN015.controls['permissionGrantingDate'].enable();
    }
  }

  submit() {
    this.loading = true;
    this.formCN015.markAllAsTouched();
  }
}
