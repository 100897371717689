<app-card
  header="Cuentas para cargo de comisiones"
  [isFlowUpdate]="isFlowUpdate"
  [isEditable]="isEditable"
  (customEvent)="handleEvent($event)"
>
  <!-- //NEEDS DEFINITION -->
  <!-- <div class="mb-5">
    <deuna-checklist
      [options]="option"
      label=""
      (eventOnChange)="onChange()"
    ></deuna-checklist>
  </div> -->

  <div class="mb-5">
    <span class="block">Entidad bancaria</span><br />
    <span class="bold-input">Banco Pichincha</span>
  </div>

  <deuna-radio-button
    label="Tipo de cuenta"
    styleClassItem="pt-2"
    [values]="accountsTypes"
    control="accountType"
    styleClass="pt-2"
    [form]="formCN012"
  >
  </deuna-radio-button>

  <account-validator [form]="formCN012"></account-validator>

  <div
    *ngIf="(isFlowUpdate && isEditable && !isFlowUpdate) || enableEdit"
    class="col-offset-6 pt-4"
  >
    <app-update-button-footer
      [loading]="loading"
      (eventSubmit)="submit()"
      (eventCancel)="onCancelEdit()"
    ></app-update-button-footer>
  </div>
</app-card>
