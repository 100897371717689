<deuna-loading [titulo]="'Creando sucursal'" *ngIf="load" [subtitulo]="'Por favor, espera un momento'"></deuna-loading>
<form [formGroup]="formStepBranch" *ngIf="!load" (ngSubmit)="onSubmit()" class="grid my-5">
  <div class="col-8 col-offset-2">
    <div class="mb-5">
      <span class="text-style-back" [routerLink]="[relativeRoute+'/info', idMerchantCreated]">Regresar</span>
    </div>
  </div>
  <div class="col-8 col-offset-2">
    <app-card header="Información básica de la sucursal" [subHeader]="'Dale un nombre que sea fácil de identificar'">
      <deuna-input [masked]="false" styleClass="mt-4" [form]="formStepBranch" label="Nombre de la sucursal" [maxLength]="50" name="nameMerchant" control="nameMerchant">
      </deuna-input>
    </app-card>
  </div>
  <div class="col-8 col-offset-2">
    <app-card header="Ubicación" subHeader="Ingresa la ubicación y dirección de la sucursal.">
      <div class="grid">
        <div class="col-6">
          <deuna-select [options]="provinces" (onChangeSelect)="onChangeSelect()" optionLabel="name" [form]="formStepBranch" label="Provincia" name="province" control="province" placeholder="Selecciona una provincia"></deuna-select>
        </div>
        <div class="col-6">
          <deuna-select [options]="cantonsSelect" [disabled]="cantonsSelect.length === 0" optionLabel="name" [form]="formStepBranch" label="Cantón" name="canton" control="canton" placeholder="Selecciona un cantón"></deuna-select>
        </div>
      </div>

      <deuna-input [masked]="false" styleClass="mt-4" [form]="formStepBranch" label="Calle, avenida, vía o carretera principal" name="mainAvenue" [maxLength]="100" control="mainAvenue"></deuna-input>
      <deuna-input [masked]="false" styleClass="mt-4" [form]="formStepBranch" label="Calle secundaria(Opcional)" [maxLength]="100" name="secondaryStreet" control="secondaryStreet">
      </deuna-input>
      <deuna-input [masked]="false" styleClass="mt-4" [form]="formStepBranch" label="Nombre de edificio, número de local o dpto (Opcional)" [maxLength]="100" name="buildName" control="buildName">
      </deuna-input>
      <div class="grid">
        <div class="col-6">
          <deuna-input [masked]="false" styleClass="mt-4" [form]="formStepBranch" label="Latitud (Opcional)" [maxLength]="100" placeholder="Ej: -0.17711428195706749" name="latitud" control="latitud"></deuna-input>
        </div>
        <div class="col-6">
          <deuna-input [masked]="false" styleClass="mt-4" [form]="formStepBranch" label="Longitud (Opcional)" [maxLength]="100" placeholder="Ej: -78.17711428195706749" name="longitud" control="longitud"></deuna-input>
        </div>
      </div>
    </app-card>
  </div>
  <div class="col-8 col-offset-2">
    <app-card header="Cajas" subHeader="Esta información nos ayudara a generar QR y enlaces personalizados para el negocio">
      <deuna-input [masked]="false" styleClass="mt-4" [form]="formStepBranch" label="Número de cajas que tiene esta sucursal" [maxLength]="3" name="numberBox" control="numberBox" appOnlyNumber>
      </deuna-input>
      <ng-container *ngIf="formStepBranch.get('numberBox')?.invalid && !formStepBranch.get('numberBox')?.errors?.['required']">
        <small id="POS-length-error" *ngIf="formStepBranch.get('numberBox')?.errors && formStepBranch.get('numberBox')?.touched" class="p-error">
          El número de cajas debe ser menor a 100.
        </small>
      </ng-container>
    </app-card>
  </div>
  <div class="col-8 col-offset-2">
    <app-card header="Datos de notificacion" subHeader="Estos datos nos ayudará a la sucursal cuando un cliente realiza un pago">
      <deuna-input [masked]="false" label="Nombre del administrador/a de la sucursal (Opcional)" [maxLength]="200" styleClass="mt-4" [form]="formStepBranch" name="administratorName" control="administratorName">
      </deuna-input>
      <deuna-radio-button label="Deseas notificar a la sucursal cuando un cliente reciba un pago?" styleClassItem="pt-2" [values]="optionsStepBranch" control="optionNotification" [form]="formStepBranch" (click)="resetTypeNotification()">
      </deuna-radio-button>
      <br />
      <div *ngIf="formStepBranch.controls['optionNotification'].value=='1'">
        <deuna-checklist [options]="typeNotificationStepBranch" [form]="formStepBranch" label="¿Cómo quieres notificar a la sucursal cuando reciba pagos?" name="lblnotificacionPay" (eventOnChange)="changeOfNotificationPay()"></deuna-checklist>
        <br />
        <div class="grid" *ngIf="this.formStepBranch.get('sms')?.value">
          <div class="col-5">
            <deuna-select [options]="phonePayCodes" [disabled]="true" optionLabel="name" [form]="formStepBranch" label="País y código" name="codePhonePay" control="codePhonePay">
            </deuna-select>
          </div>
          <div class="col-7">
            <deuna-input [masked]="false" styleClass="mt-4" [form]="formStepBranch" label="Número de celular de notificación SMS" [maxLength]="10" placeholder="Ej: 993894433" name="numberPhoneSMSPay" control="numberPhoneSMSPay"></deuna-input>
          </div>
        </div>

        <div *ngIf="this.formStepBranch.get('email')?.value">
          <deuna-input [masked]="false" styleClass="mt-4" [form]="formStepBranch" label="Correo de notificación" [maxLength]="40" name="mailNotificationPay" control="mailNotificationPay"></deuna-input>
        </div>
      </div>
    </app-card>
  </div>
  <div class="col-4 col-offset-5 pt-4">
    <div class="grid">
      <div class="col-6">
        <deuna-button label="Cancelar" deUnaClass="p-button-outlined" (eventClick)="openModal()">
        </deuna-button>
      </div>
      <div class="col-6">
        <deuna-button label="Guardar y crear QR" type="submit"></deuna-button>
      </div>
    </div>
  </div>
</form>
<deuna-modal [displayModal]="hasCreateSucursalError" [buttonFooter]="true" [image]="image" alt="image" (onAccept)="goBackSucursalList()" (onHide)="hideModal()" headerModalText="{{createSucursalError.reason}}" textModalText="{{createSucursalError.details}}" labelAcceptButton="Entendido" labelCancelButton="No, continuar editando">
</deuna-modal>

<app-exit-modal [displayModal]="displayModal" (onHide)="hideModal()" pathReturn="{{relativeRoute}}/list-branchs/{{idMerchantCreated}}"></app-exit-modal>