import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { assetUrl } from '@deuna/bp-sh-helpers-ng';
import {
  CodeType,
  createTypeOptions,
} from 'src/app/constants/catalogos/mocks-catalogo';
import { clientRoute } from 'src/app/constants/routes';
import { ICatalogueCard } from 'src/app/shared/interfaces/calatogue.interface';
import { IObjectFunction } from 'src/app/shared/interfaces/code-type.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-step-initial',
  templateUrl: './step-initial.page.html',
  styleUrls: ['./step-initial.page.scss'],
})
export class StepInitialPage implements OnInit {
  relativeRoute = clientRoute;
  searchClientImg = assetUrl('png/search-ruc.png');
  img_error: string = assetUrl('png/image-bank-error.png');
  loading: boolean = false;
  stepInitialForm!: FormGroup;
  createTypeOptions: Array<ICatalogueCard> = [];
  environment = environment;
  hasError: boolean = false;
  redirect: IObjectFunction = {
    [CodeType.COMMERCE_GROUP]: () =>
      this.router.navigate([`${clientRoute}/commercial-group/create/search`]),
    [CodeType.MERCHANT]: () =>
      this.router.navigate([`${clientRoute}/create/check-association`]),
  };

  constructor(private router: Router, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initData();
    this.initForm();
  }

  initForm(): void {
    this.stepInitialForm = this.fb.group({
      type: [null, Validators.required],
    });
  }

  async onSubmit(): Promise<void> {
    this.stepInitialForm.markAllAsTouched();
    if (this.stepInitialForm.valid) {
      this.loading = true;
      const type = this.stepInitialForm.get('type')?.value;
      this.redirect[type]();
    }
  }

  initData() {
    this.createTypeOptions = createTypeOptions;
  }
}
