<div class="grid text-center pt-5">
  <div class="col-8 col-offset-2">
    <img [src]="img" alt="image" class="w-4" style="margin-top: 2%;">
  </div>
  <div class="col-8 col-offset-2 pt-1">
    <div class="fontHeader">{{title}}</div>
    <div class="fontSubHeader" style="padding-top: 3%;">{{subtitle}}</div>
  </div>
  <div class="col-6 col-offset-4 pt-4">
    <div class="grid">
      <div class="col-4" *ngIf="buttonCancel">
        <deuna-button [label]="textButtonCancel" (eventClick)="clickCancel()" deUnaClass="p-button-outlined">
        </deuna-button>
      </div>
      <div class="col-4" *ngIf="buttonAccept">
        <deuna-button [label]="textButtonAccept" type="submit" (eventClick)="clickAccept()"></deuna-button>
      </div>
    </div>
  </div>
</div>