<app-card
  header="Información general"
  [isFlowUpdate]="isFlowUpdate"
  [isEditable]="isEditable"
  (customEvent)="handleEvent($event)"
>
  <div class="grid">
    <div class="col-6">
      <deuna-input
        [masked]="false"
        styleClass="mt-4"
        [form]="formCN000"
        placeholder="Ej: Dulcafe"
        label="Tipo de cliente"
        name="clientType"
        control="clientType"
      ></deuna-input>
      <deuna-input
        [masked]="false"
        styleClass="mt-4"
        [form]="search"
        label="CIF"
        placeholder="Ej: Dulcafe"
        name="cifCode"
        control="cifCode"
      ></deuna-input>
    </div>
    <div class="col-6">
      <deuna-input
        [masked]="false"
        styleClass="mt-4"
        [form]="formCN000"
        label="Número de RUC"
        placeholder="Ej: 7653859762001"
        name="identification"
        control="identification"
      ></deuna-input>
      <deuna-input
        [masked]="false"
        styleClass="mt-4"
        [form]="formCN000"
        label="Nombres y apellidos"
        placeholder="Ej: Pablo Andres Perez Jaramillo"
        name="businessName"
        control="businessName"
      ></deuna-input>
    </div>
  </div>

  <deuna-radio-button
    label="Tipo de Empresa"
    styleClassItem="pt-2"
    [values]="businessType"
    styleClass="pt-2"
    control="businessType"
    [form]="formCN002"
  ></deuna-radio-button
  ><br />

  <deuna-radio-button
    label="Segmento"
    styleClassItem="pt-2"
    [values]="segments"
    styleClass="pt-2"
    control="segment"
    [form]="formCN002"
  ></deuna-radio-button>

  <deuna-select
    [options]="categories"
    (onChangeSelect)="onChangeCategorySelect()"
    optionLabel="name"
    [form]="formCN002"
    label="Categoría"
    name="category"
    control="category"
    placeholder="Selecciona una categoría"
  ></deuna-select>
  <deuna-select
    [options]="subCategorySelected"
    [disabled]="subCategorySelected.length === 0"
    optionLabel="name"
    [form]="formCN002"
    label="Subcategoria"
    name="subcategory"
    control="subcategory"
    placeholder="Selecciona una subcategoria"
  ></deuna-select>

  <div
    *ngIf="(isFlowUpdate && isEditable && !isFlowUpdate) || enableEdit"
    class="col-offset-6 pt-4"
  >
    <app-update-button-footer
      [loading]="loading"
      (eventSubmit)="submit()"
      (eventCancel)="onCancelEdit()"
    ></app-update-button-footer>
  </div>
</app-card>
