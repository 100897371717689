import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo, ApolloBase, gql, MutationResult } from 'apollo-angular';
import { Observable } from 'rxjs';
import {
  ICreateReport,
  IResponseCreateReport,
  ResponseCreateReportBranch,
  ResponseFileValidation,
} from 'src/app/shared/interfaces/generation-config-report.interface';
import {
  IDeleteReport,
  IQueryBranchReport,
  IQueryFile,
  IQueryFilesReport,
  IQueryReport,
  IReport,
  IResponseBranchReports,
  IResponseFile,
  IResponseFilesReports,
  IResponseReports,
  IUpdateRequestReport,
  IUpdateResponseReport,
} from 'src/app/shared/interfaces/query-reports';

@Injectable()
export class GraphqlReportService {
  public apollo: ApolloBase;

  constructor(private apolloProvider: Apollo) {
    this.apollo = this.apolloProvider.use('newClientName');
  }

  getReports(
    input: IQueryReport,
    outPutResult: string
  ): Observable<ApolloQueryResult<IResponseReports>> {
    return this.apollo.query({
      query: gql`
      query listReport($input: ListReportInput!){
        listReport(listReport:$input){
            items {
              ${outPutResult}
            }
            meta{
              totalItems
              itemCount
              itemsPerPage
              totalPages
              currentPage
            }
        }
    }
    `,
      variables: {
        input,
      },
    });
  }

  listFilesReports(
    input: IQueryFilesReport,
    outPutResult: string
  ): Observable<ApolloQueryResult<IResponseFilesReports>> {
    return this.apollo.query({
      query: gql`query listFilesReports($input: ReportsFilteringInput!) { 
          listFilesReports(filterInput: $input){
              items{
                  ${outPutResult}
              }
              meta{
                  totalItems
                  itemCount
                  itemsPerPage
                  totalPages
                  currentPage
              }
          }
      } 
    `,
      variables: {
        input,
      },
    });
  }

  getFromFileManagerById(
    input: IQueryFile,
    _outPutResult: string
  ): Observable<ApolloQueryResult<IResponseFile>> {
    return this.apollo.query({
      query: gql`
        query getFromFileManagerById($input: GetFromFileManagerInput!) {
          getFromFileManagerById(getFromFileManagerInput: $input) {
            signedUrl
          }
        }
      `,
      variables: {
        input,
      },
    });
  }

  createConfigReport(
    input: ICreateReport
  ): Observable<MutationResult<IResponseCreateReport>> {
    return this.apollo.mutate({
      mutation: gql`
        mutation createReport($input: CreateReportInput!) {
          createReport(createReport: $input) {
            id
            type
            daysFrequency
            level
            cutOffDay
            cutOffTime
            mails
          }
        }
      `,
      variables: {
        input,
      },
    });
  }
  getReportById(
    reportId: string,
    outPutResult: string
  ): Observable<ApolloQueryResult<IReport>> {
    return this.apollo.query({
      query: gql`
        query getReport($reportId: String!) {
          getReport(reportId: $reportId) {
            ${outPutResult}
          }
        }
      `,
      variables: {
        reportId,
      },
    });
  }

  updateReport(
    input: IUpdateRequestReport
  ): Observable<MutationResult<IUpdateResponseReport>> {
    return this.apollo.mutate({
      mutation: gql`
        mutation updateReport($input: UpdateReportInput!) {
          updateReport(updateReport: $input) {
            message
            status
          }
        }
      `,
      variables: {
        input,
      },
    });
  }

  deleteReport(
    input: IDeleteReport
  ): Observable<MutationResult<IUpdateResponseReport>> {
    return this.apollo.mutate({
      mutation: gql`
        mutation deleteReport($input: DeleteReport!) {
          deleteReport(deleteReport: $input) {
            message
            status
          }
        }
      `,
      variables: {
        input,
      },
    });
  }

  uploadFile(
    file: File,
    strategy: string,
    merchantId
  ): Observable<MutationResult<ResponseFileValidation>> {
    const extraData = {
      merchantId,
    };
    return this.apollo.mutate({
      mutation: gql`
        mutation fileValidation($file: Upload!,  $extraData: FileValidationExtraData) {
          fileValidation(
            file: $file
            strategy: "${strategy}"
            extraData: $extraData
          ) {
            errorMessage {
              title
              message
              details
            }
            success
          }
        }
      `,
      variables: {
        file,
        extraData,
      },
      context: {
        useMultipart: true,
      },
    });
  }

  createBranchReport(
    merchantId: string
  ): Observable<MutationResult<ResponseCreateReportBranch>> {
    const inputBulkReport = {
      merchantId: merchantId,
      type: 'SALES',
      level: 'BRANCH',
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation bulkReport($input: InputBulkReport!) {
          bulkReport(bulkReport: $input) {
            identifiers {
              id
            }
          }
        }
      `,
      variables: {
        input: inputBulkReport,
      },
      context: {
        useMultipart: true,
      },
    });
  }

  getBranchReportsReports(
    input: IQueryBranchReport,
    outPutResult: string
  ): Observable<ApolloQueryResult<IResponseBranchReports>> {
    return this.apollo.query({
      query: gql`
      query listBranchReports($input: ListBranchReportsInput!){
        listBranchReports(listReport:$input){
            items {
              ${outPutResult}
            }
            meta{
                totalItems
                itemCount
                itemsPerPage
                totalPages
                currentPage
            }
        }
    }
    `,
      variables: {
        input,
      },
    });
  }
}
