import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-successfull-creation',
  templateUrl: './successfull-creation.component.html',
  styleUrls: ['./successfull-creation.component.scss'],
})
export class SuccessfullCreationComponent {
  @Input() image: string = '';
  @Input() subHeaderText: string = '';
  @Input() headerText: string = '';
  @Input() routesBack: string[] = [];
  @Input() buttonBack: string = 'Volver al inicio';
  @Input() routesNext: string[] = [];
  @Input() buttonNext: string = 'Agregar sucursales';
  @Output() onHide = new EventEmitter();

  constructor(private router: Router) {}

  continueBack() {
    this.router.navigate(this.routesBack);
  }

  continueNext() {
    this.router.navigate(this.routesNext);
  }
}
