<div class="col-12" style="font-size: 14px; text-align: justify;">
    Carga el formulario que te compartió la empresa. Asegúrate que el formato y la información sea correcta.
</div>
<div class="col-12">
    <div class="p-4 text-center card-file file-{{status}}">
        <span [ngSwitch]="status">
            <span *ngSwitchCase="'new'" style="font-size: 60px;">
                <svg width="45" height="30" viewBox="0 0 45 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M35.975 11.4054C34.7283 5.08036 29.1733 0.332031 22.5 0.332031C17.2017 0.332031 12.6 3.3387 10.3083 7.7387C4.79 8.32536 0.5 13.0004 0.5 18.6654C0.5 24.7337 5.43167 29.6654 11.5 29.6654H35.3333C40.3933 29.6654 44.5 25.5587 44.5 20.4987C44.5 15.6587 40.7417 11.7354 35.975 11.4054ZM35.3333 25.9987H11.5C7.44833 25.9987 4.16667 22.717 4.16667 18.6654C4.16667 14.907 6.97167 11.772 10.6933 11.387L12.655 11.1854L13.5717 9.4437C15.3133 6.0887 18.7233 3.9987 22.5 3.9987C27.3033 3.9987 31.4467 7.4087 32.3817 12.1204L32.9317 14.8704L35.7367 15.072C38.5967 15.2554 40.8333 17.657 40.8333 20.4987C40.8333 23.5237 38.3583 25.9987 35.3333 25.9987ZM15.1667 16.832H19.8417V22.332H25.1583V16.832H29.8333L22.5 9.4987L15.1667 16.832Z"
                        fill="#4C1D80" />
                </svg>
            </span>
            <span *ngSwitchCase="'error'" style="font-size: 60px;">
                <svg width="37" height="38" viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18.5013 0.667969C8.3813 0.667969 0.167969 8.8813 0.167969 19.0013C0.167969 29.1213 8.3813 37.3346 18.5013 37.3346C28.6213 37.3346 36.8346 29.1213 36.8346 19.0013C36.8346 8.8813 28.6213 0.667969 18.5013 0.667969ZM20.3346 28.168H16.668V24.5013H20.3346V28.168ZM20.3346 20.8346H16.668V9.83463H20.3346V20.8346Z"
                        fill="#A92825" />
                </svg>
            </span>
            <span *ngSwitchCase="'success'" style="font-size: 60px;">
                <svg width="37" height="38" viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18.5013 0.667969C8.3813 0.667969 0.167969 8.8813 0.167969 19.0013C0.167969 29.1213 8.3813 37.3346 18.5013 37.3346C28.6213 37.3346 36.8346 29.1213 36.8346 19.0013C36.8346 8.8813 28.6213 0.667969 18.5013 0.667969ZM14.8346 28.168L5.66797 19.0013L8.25297 16.4163L14.8346 22.9796L28.7496 9.06464L31.3346 11.668L14.8346 28.168Z"
                        fill="#3AAE59" />
                </svg>
            </span>
            <span *ngSwitchCase="'upload'" class="file-name grid">
                <div class="col-11 text-left ellipsis">
                    {{fileName}}
                </div>
                <div class="col-1 text-rigth" style="cursor:pointer;">
                    X
                </div>
            </span>
        </span>
        <span [ngSwitch]="status">
            <p *ngSwitchCase="'new'">
                Formato permitido: .xlsx / <br>
                Nº sucursales: Máximo 500 /<br>
                Peso máximo: 10MB
            </p>
            <div class="mt-2" *ngSwitchCase="'upload'">
                <app-load-step [stepLoad]="stepLoad" heigth="5px"></app-load-step>`
            </div>
            <p class="mt-3" *ngIf="title !== '' || message !== ''">
                <strong *ngIf="title !== ''">{{title}}</strong>
                <span *ngIf="message !== ''"><br><br>{{message}}</span>
            </p>
        </span>
        <div class="pt-3 mx-5" *ngIf="status !== 'upload'">
            <deuna-button [label]="titleButton" deUnaClass="p-button-outlined"
                (eventClick)="fileInput.click()"></deuna-button>
        </div>
        <input #fileInput type="file" style="display:none;" (change)="readFile()" [accept]="accept" />
    </div>
</div>
<div class="col-12 mt-4">
    <strong>¿Quieres obtener el formulario de sucursales?</strong>&nbsp;<a href="{{excelFile}}">Descarga la
        plantilla</a>
</div>