import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApolloQueryResult } from '@apollo/client/core';
import { MutationResult } from 'apollo-angular';
import {
  REGEX_COORDINATE,
  REGEX_ONLY_NUMBER,
  REGEX_WITHOUT_SPECIAL_CHARACTERS,
} from 'src/app/constants/merchant-constants';
import { defaultCountry } from '../../../constants/catalogos/mocks-catalogo';
import {
  CreateAllConfiguration,
  IConfigDataCN007,
  IConfigDataCN008,
  IConfigDataCN009,
  IConfigDataCN017,
  IDataConfig,
  IDefaultConfig,
  IHierarchy,
  INodeQuantity,
  IOptionNotificationBranchPay,
} from '../../../shared/interfaces/config-merchant.interfaces';
import { IFormGroupBranch } from '../../../shared/interfaces/form-group.interface';
import { ResponseCreateBranch, ResponseCreatePos } from '../../../shared/interfaces/query-branchs';
import { ParamsNodeByClientId, ResponseNodeByClientId } from '../../../shared/interfaces/query-merchants';
import { GraphqlMerchantService } from '../../graphql/graphql-merchant.service';

@Injectable()
export class ConfigurationFlowBranchService {
  configMerchants!: IDataConfig;

  constructor(
    private readonly fb: FormBuilder,
    private graphqlMerchantService: GraphqlMerchantService
  ) {
    this.getForm();
  }

  formGroup!: IFormGroupBranch;

  getForm() {
    if (this.formGroup) {
      return this.formGroup;
    }

    this.formGroup = {
      createBranch: this.fb.group({
        nameMerchant: [
          null,
          [
            Validators.required,
            Validators.pattern(REGEX_WITHOUT_SPECIAL_CHARACTERS),
          ],
        ],
        province: [null, [Validators.required]],
        canton: [null, [Validators.required]],
        mainAvenue: [
          null,
          [
            Validators.required,
            Validators.pattern(REGEX_WITHOUT_SPECIAL_CHARACTERS),
          ],
        ],
        secondaryStreet: [
          null,
          Validators.pattern(REGEX_WITHOUT_SPECIAL_CHARACTERS),
        ],
        buildName: [null, [Validators.pattern(REGEX_WITHOUT_SPECIAL_CHARACTERS)]],
        longitud: [null, [Validators.pattern(REGEX_COORDINATE)]],
        latitud: [null, [Validators.pattern(REGEX_COORDINATE)]],
        numberBox: [
          null,
          [Validators.required, Validators.pattern(REGEX_ONLY_NUMBER), Validators.max(99)],
        ],
        administratorName: [
          null,
          Validators.pattern(REGEX_WITHOUT_SPECIAL_CHARACTERS),
        ],
        optionNotification: [null, [Validators.required]],
        email: [false],
        sms: [false],
        codePhonePay: [defaultCountry, [Validators.required]],
        numberPhoneSMSPay: [null],
        mailNotificationPay: [null, [Validators.email]],
      }),
    };
    return this.formGroup;
  }

  resetForm() {
    this.formGroup.createBranch.reset();
    this.formGroup.createBranch.get('codePhonePay')?.setValue(defaultCountry);
  }

  createNodeBranch(dataNodeHierarchy: IHierarchy): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      this.graphqlMerchantService.createHierarchy(dataNodeHierarchy).subscribe({
        next: (res: MutationResult<ResponseCreateBranch>) => {
          if (res.data) {
            if (res.data.createNodeHierachy.temporalError) {
              reject(res.data.createNodeHierachy.temporalError)
            }
            const nodeIdbranch = res.data.createNodeHierachy.id;
            resolve(nodeIdbranch);
          }
          reject('');
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  getNodeMerchant(clientId: string): Promise<number> {
    const search: ParamsNodeByClientId = {
      clientId,
      nodeType: 'M',
    };
    return new Promise<number>((resolve, reject) => {
      this.graphqlMerchantService.getNodeMerchant(search).subscribe({
        next: (res: ApolloQueryResult<ResponseNodeByClientId>) => {
          resolve(res.data.getNode.id);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  createPOSBranch(data: INodeQuantity): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.graphqlMerchantService.createPOSBranch(data).subscribe({
        next: (res: MutationResult<ResponseCreatePos>) => {
          if (res.data) {
            if (res.data.createChildrenNodeByQuantity.temporalError) {
              reject(res.data.createChildrenNodeByQuantity.temporalError)
            }
            resolve(res.data.createChildrenNodeByQuantity.status);
          }
          reject('');
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  async createBranch(idMerchant: string): Promise<boolean> {
    const parent = await this.getNodeMerchant(idMerchant);
    const branchCreate: IHierarchy = {
      clientId: idMerchant,
      nodeType: 'S',
      parent: parent.toString(),
    };
    const idNodeBranch = await this.createNodeBranch(branchCreate);
    await this.createPOSBranch(this.createDataQCN007(idNodeBranch.toString()));
    const configurationBranchs: IDataConfig = { data: [] };
    configurationBranchs.data.push(
      this.createDataCN007(idNodeBranch.toString())
    );
    configurationBranchs.data.push(
      this.createDataCN008(idNodeBranch.toString())
    );
    configurationBranchs.data.push(
      this.createDataCN009(idNodeBranch.toString())
    );
    return new Promise<boolean>((resolve, reject) => {
      this.graphqlMerchantService
        .createConfiguration(configurationBranchs)
        .subscribe({
          next: (res: MutationResult<CreateAllConfiguration>) => {
            if (res.data) {
              this.resetForm();
              resolve(true);
            }
            resolve(false);
          },
          error: (err) => {
            reject(err);
          },
        });
    });
  }

  //data for create branch
  createDataCN007(nodeId: string) {
    const configData007: IConfigDataCN007 = {
      branchName: this.formGroup.createBranch.get('nameMerchant')?.value,
      numberBox: this.formGroup.createBranch.get('numberBox')?.value,
    };
    return {
      configName: 'CN007',
      nodeId,
      configData: JSON.stringify(configData007),
    };
  }

  createDataQCN007(nodeId: string): INodeQuantity {
    return {
      id: +nodeId,
      quantity: +this.formGroup.createBranch.get('numberBox')?.value,
      nodeType: 'C',
      createAlias: true,
    };
  }

  //data for Ubication step branch
  createDataCN008(nodeId: string) {
    const configData8: IConfigDataCN008 = {
      province: this.formGroup.createBranch.get('province')?.value,
      canton: this.formGroup.createBranch.get('canton')?.value,
      mainStreet: this.formGroup.createBranch.get('mainAvenue')?.value,
      secondaryStreet:
        this.formGroup.createBranch.get('secondaryStreet')?.value,
      reference: this.formGroup.createBranch.get('buildName')?.value,
      latitud: this.formGroup.createBranch.get('latitud')?.value,
      longitud: this.formGroup.createBranch.get('longitud')?.value,
    };
    return {
      configName: 'CN008',
      nodeId,
      configData: JSON.stringify(configData8),
    };
  }

  // data for notification step-branch
  createDataCN009(nodeId: string) {
    const optionNotificationPay: IOptionNotificationBranchPay = {
      notificationSMS: this.formGroup.createBranch.get('sms')?.value,
      notificationEmail: this.formGroup.createBranch.get('email')?.value,
      countryCode: this.formGroup.createBranch.get('codePhonePay')?.value,
      cellPhone: this.formGroup.createBranch.get('numberPhoneSMSPay')?.value,
      email: this.formGroup.createBranch.get('mailNotificationPay')?.value,
    };

    const configData9: IConfigDataCN009 = {
      administratorName:
        this.formGroup.createBranch.get('administratorName')?.value,
      notificationBranchPay:
        this.formGroup.createBranch.get('optionNotification')?.value,
      optionNotificationBranchPay: optionNotificationPay,
    };

    return {
      configName: 'CN009',
      nodeId,
      configData: JSON.stringify(configData9),
    };
  }

  async createBranchConfig(
    configName: 'CN007' | 'CN008' | 'CN009' | 'CN017',
    idNodeBranch: string,
    configData:
      | IConfigDataCN007
      | IConfigDataCN008
      | IConfigDataCN009
      | IConfigDataCN017
  ): Promise<string> {
    const inputData: IDefaultConfig = {
      configData: JSON.stringify(configData),
      configName,
      nodeId: idNodeBranch,
    };

    return new Promise<string>((resolve, reject) => {
      this.graphqlMerchantService
        .createUniqueConfiguration(inputData)
        .subscribe({
          next: (res: any) => {
            if (res.data) {
              resolve(res.data.createConfiguration.id);
            }
            resolve('');
          },
          error: (err) => {
            reject(err);
          },
        });
    });
  }
}
