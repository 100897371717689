<app-card
  header="Cuentas de recaudo"
  [isFlowUpdate]="isFlowUpdate"
  [isEditable]="isEditable"
  (customEvent)="handleEvent($event)"
>
  <div class="mb-5">
    <deuna-info-banner
      title="Información importante"
      description="Asegúrate que la cuenta bancaria esté asociada a la cédula de identidad del cliente, no a su RUC."
      [type]="bannerType"
    >
    </deuna-info-banner>
  </div>
  <div class="mb-5">
    <span class="block">Entidad bancaria</span><br />
    <span class="bold-input">Banco Pichincha</span>
  </div>

  <deuna-radio-button
    label="Tipo de cuenta"
    styleClassItem="pt-2"
    [values]="accountsTypes"
    control="accountType"
    styleClass="pt-2"
    [form]="formCN003"
    (eventOnChange)="setValidationError()"
  >
  </deuna-radio-button>

  <account-validator [form]="formCN003"></account-validator>
</app-card>
