<deuna-modal
  [displayModal]="showConfirmationModal"
  (onAccept)="confirmDeleteAccount()"
  (onHide)="hideConfirmationModal()"
  [image]="imgConfirmDelete"
  [headerModalText]="confirmDeleteAccountModalTitle"
  [textModalText]="confirmDeleteAccountModalText"
  [buttonFooter]="true"
  labelAcceptButton="Deshabilitar"
></deuna-modal>
<div [ngSwitch]="tableStatus">
  <div class="col-12 text-center" *ngSwitchCase="'LOADING'">
    <p-progressBar
      mode="indeterminate"
      [style]="{'height': '6px'}"
    ></p-progressBar>
    <h2 class="mt-2">Cargando....</h2>
  </div>
  <ng-container *ngSwitchCase="'COMPLETE'">
    <deuna-table [arrayData]="[1]" [columnas]="columns">
      <ng-container *ngFor="let account of accountsList">
        <tr>
          <deuna-table-cell>
            {{ account.mail }}
          </deuna-table-cell>
          <deuna-table-cell>
            {{ account.platform }}
          </deuna-table-cell>
          <deuna-table-cell>
            {{ account.permissions }}
          </deuna-table-cell>
          <deuna-table-cell>
            <div class="flex-1" style="justify-items: end;">
              <a href="#" (click)="deleteAccount(account)" class="flex flex-auto px-2 no-underline">
                <div class="icon">
                  <i class="material-icons material-icons-solid flex text-xl" aria-hidden="true">{{ "delete" }}</i>
                </div>
              </a>
            </div>
          </deuna-table-cell>
        </tr>
      </ng-container>
    </deuna-table>
    <deuna-pagination
      (paginaEmitter)="goToPage($event)"
      (itemsEmmiter)="changeToItems($event)"
      [currentPage]="dataPagination.currentPage"
      [limitPage]="dataPagination.itemCount"
      [totalItems]="dataPagination.totalItems"
      [totalPages]="dataPagination.totalPages"
    >
    </deuna-pagination>
  </ng-container>
  <div
    *ngSwitchCase="'EMPTY'"
    class="container-center col-12"
    style="display: flex; justify-content: center"
  >
    <div class="text-center pt-4">
      <img
        loading="lazy"
        [src]="imgEmpty"
        alt="image"
        style="width: 25%"
        class="my-6"
      />
      <div class="flex flex-column">
        <h2 class="fontLoadHeader font-bold">
          Aún no has agregado ningun usuario
        </h2>
        <span class="pt-3 pb-2">
          Agrega un correo para que los comercios
        </span>
        <span>
          puedan acceder a las plataformas de Deuna.
        </span>

        <deuna-button
          [label]="'Agregar nuevo usuario'"
          deUnaClass="text-sm w-auto pr-5 mt-5"
          deUnaStyle="background:black;"
          (click)="addNewAccount()"
        >
        </deuna-button>
      </div>
    </div>
  </div>

  <div
    *ngSwitchCase="'ERROR'"
    class="container-center col-12"
    style="display: flex; justify-content: center"
  >
    <div class="text-center pt-4">
      <img
        loading="lazy"
        [src]="imgError"
        alt="image"
        style="width: 25%"
        class="my-6"
      />
      <div class="flex flex-column">
        <h2 class="fontLoadHeader font-bold">
          Hubo un error al cargar los usuarios configurados
        </h2>
        <span class="pt-3 pb-2">
          Por favor, prueba otra vez.
        </span>

        <deuna-button
          [label]="'Intentar de nuevo'"
          deUnaClass="text-sm w-auto pr-5 mt-5"
          deUnaStyle="background:black;"
          (click)="reload()"
        >
        </deuna-button>
      </div>
    </div>
  </div>
</div>
