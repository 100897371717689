<div class="col-12 text-center head">
  <strong>Código QR</strong>
</div>
<div class="col-12 px-0 pt-5">Configura el código QR y descárgalo.</div>

<div class="pt-2">
  <deuna-input
    [masked]="false"
    [masked]="false"
    [form]="formGroup"
    label="Nombre"
    placeholder="Ej: Principal"
    name="branch"
    control="branch"
  >
  </deuna-input>
</div>

<deuna-radio-button
  label="Fondo"
  styleClassItem="pt-2"
  [values]="optionstypesQr"
  styleClassConten="pt-5"
  (eventOnChange)="changeImagen()"
  control="typeQr"
  [form]="formGroup"
></deuna-radio-button
><br />

<deuna-radio-button
  *ngIf="formGroup.controls['typeQr'].value === 'QRSP'"
  label="Tamaño del QR"
  styleClassItem="pt-2"
  [values]="optionsSizesQr"
  styleClassConten="pt-5"
  control="sizeQr"
  [form]="formGroup"
  (eventOnChange)="changeSize()"
></deuna-radio-button
><br />
<div
  class="col-12 px-0"
  *ngIf="
    (formGroup.controls['typeQr'].value === 'QRSP' &&
      formGroup.controls['sizeQr'].value != '') ||
    formGroup.controls['typeQr'].value === 'QRP'
  "
>
  <div class="pb-2">Previsualización</div>
  <img
    [src]="imagenQr"
    alt="Qr"
    width="100%"
    height="475px"
    class="p-4"
    style="background: #f1f2f5"
  />
</div>
<deuna-button
  [label]="downloadBand ? 'Descargando ...' : 'Descargar'"
  deUnaClass="p-button-outlined"
  (eventClick)="generationPdf()"
  [disabled]="downloadBand"
>
</deuna-button>
