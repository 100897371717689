<app-card
  header="Cuentas de recaudo"
  [isFlowUpdate]="isFlowUpdate"
  [isEditable]="isEditable"
  (customEvent)="handleEvent($event)"
>
  <div class="mb-2">
    <ng-container *ngIf="isEditable; else editBankName">
      <span class="block">Entidad bancaria</span><br />
      <span class="bold-input">Banco Pichincha</span>
    </ng-container>

    <ng-template #editBankName>
      <deuna-input
        [masked]="false"
        [form]="formCN003"
        label="Entidad bancaria"
        placeholder="Ej: 83721937192"
        name="bankName"
        styleClass="pt-2"
        control="bankName"
      >
      </deuna-input>
    </ng-template>
  </div>

  <deuna-input
    [masked]="false"
    *ngIf="!enableEdit && isFlowUpdate"
    [type]="'password'"
    [form]="formCN003"
    label="Número de cuenta"
    [disabled]="true"
    placeholder="Ej: 83721937192"
    name="accountNumber"
    styleClass="pt-2"
    control="accountNumber"
    [masked]="true"
    appOnlyNumber
  >
  </deuna-input>

  <deuna-input
    *ngIf="!(!enableEdit && isFlowUpdate)"
    [type]="'text'"
    [form]="formCN003"
    label="Número de cuenta"
    placeholder="Ej: 83721937192"
    name="accountNumber"
    styleClass="pt-2"
    [disabled]="true"
    control="accountNumber"
    [maxLength]="200"
    [masked]="!enableEdit && isFlowUpdate"
    appOnlyNumber
  >
  </deuna-input>

  <deuna-input
    *ngIf="!enableEdit && isFlowUpdate"
    [type]="'password'"
    [form]="formCN003"
    label="Número de cédula"
    placeholder="Ej: 83721937192"
    name="identificationNumber"
    styleClass="pt-2"
    control="identificationNumber"
    [masked]="!enableEdit && isFlowUpdate"
    appOnlyNumber
    [maxLength]="13"
  >
  </deuna-input>

  <deuna-input
    [masked]="false"
    *ngIf="!(!enableEdit && isFlowUpdate)"
    [type]="'text'"
    [form]="formCN003"
    label="Número de cédula"
    placeholder="Ej: 83721937192"
    name="identificationNumber"
    styleClass="pt-2"
    control="identificationNumber"
    [masked]="!enableEdit && isFlowUpdate"
    [type]="!enableEdit && isFlowUpdate ? 'password' : 'text'"
    appOnlyNumber
    [maxLength]="13"
  >
  </deuna-input>

  <ng-container
    *ngIf="(isEditable && !isFlowUpdate) || enableEdit; else editAccountType"
  >
    <deuna-radio-button
      label="Tipo de cuenta"
      styleClassItem="pt-2"
      [values]="accountsTypes"
      control="accountType"
      styleClass="pt-2"
      [form]="formCN003"
    >
    </deuna-radio-button>
  </ng-container>
  <ng-template #editAccountType>
    <deuna-input
      [masked]="false"
      styleClass="mt-4"
      [form]="formCN003"
      label="Tipo de cuenta"
      name="businessType"
      control="accountType"
    ></deuna-input>
  </ng-template>

  <div
    *ngIf="(isFlowUpdate && isEditable && !isFlowUpdate) || enableEdit"
    class="col-offset-6 pt-4"
  >
    <app-update-button-footer
      [loading]="loading"
      (eventSubmit)="submit()"
      (eventCancel)="onCancelEdit()"
    ></app-update-button-footer>
  </div>
</app-card>
