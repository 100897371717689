// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* You can add global styles to this file, and also import other style files */
* :not(i, .pi) {
  font-family: "Montserrat", sans-serif !important;
  font-style: normal;
  font-size: 14;
}

.container {
  position: relative;
}

.line {
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
  border-style: solid;
  border-width: 0.5px;
  border-color: #bdc3d2;
}

.bold-input {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.text-style-back {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #4c1d80;
  cursor: pointer;
}

.titles {
  font-weight: 700;
  font-size: 48px;
  line-height: 52px;
}

strong {
  font-weight: 700;
}

.title-side {
  font-weight: 600;
  font-size: 25px;
  line-height: 36px;
}`, "",{"version":3,"sources":["webpack://./src/styles.scss"],"names":[],"mappings":"AAAA,8EAAA;AACA;EACE,gDAAA;EACA,kBAAA;EACA,aAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AACA;EACE,cAAA;EACA,qBAAA;EACA,yBAAA;EACA,uBAAA;EACA,yBAAA;EACA,uBAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;EACA,qBAAA;AAEF;;AACA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAEF;;AACA;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,+BAAA;EACA,cAAA;EACA,eAAA;AAEF;;AACA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AAEF;;AACA;EACE,gBAAA;AAEF;;AACA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AAEF","sourcesContent":["/* You can add global styles to this file, and also import other style files */\n* :not(i ,.pi) {\n  font-family: \"Montserrat\", sans-serif !important;\n  font-style: normal;\n  font-size: 14;\n}\n\n.container {\n  position: relative;\n}\n.line {\n  display: block;\n  unicode-bidi: isolate;\n  margin-block-start: 0.5em;\n  margin-block-end: 0.5em;\n  margin-inline-start: auto;\n  margin-inline-end: auto;\n  overflow: hidden;\n  border-style: solid;\n  border-width: 0.5px;\n  border-color: #bdc3d2;\n}\n\n.bold-input {\n  font-weight: 700;\n  font-size: 16px;\n  line-height: 24px;\n  color: #000000;\n}\n\n.text-style-back {\n  font-style: normal;\n  font-weight: 700;\n  font-size: 16px;\n  line-height: 20px;\n  text-decoration-line: underline;\n  color: #4c1d80;\n  cursor: pointer;\n}\n\n.titles {\n  font-weight: 700;\n  font-size: 48px;\n  line-height: 52px;\n}\n\nstrong {\n  font-weight: 700;\n}\n\n.title-side {\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
