import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  options,
  optionsExcel,
} from '../../../../../constants/catalogos/mocks-catalogo';
import { ICatalogue } from '../../../../../shared/interfaces/calatogue.interface';
import { clientRoute } from '../../../../../constants/routes';
import { ConfigurationFlowCreationService } from '../../../../../services/config/merchant/configuration-flow-creation.service';

@Component({
  selector: 'app-step-3',
  templateUrl: './step-3.page.html',
})
export class Step3Page implements OnInit {
  relativeRoute = clientRoute;
  formStep3!: FormGroup;

  displayModal: boolean = false;
  optionStep3: Array<ICatalogue> = [];
  optionsExcelStep3: Array<ICatalogue> = [];

  state: boolean = false;
  errorRequestPage: boolean = false;

  constructor(
    private router: Router,
    private configFlow: ConfigurationFlowCreationService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.initDataCatalogo();
  }

  initForm() {
    const forms = this.configFlow.getForm();
    this.formStep3 = forms.CN010;
  }

  initDataCatalogo() {
    this.optionStep3 = options;
    this.optionsExcelStep3 = optionsExcel;
  }

  markOptionalFieldsAsDirty() {
    if (!this.formStep3.get('mailMerchantContact')?.value) {
      this.formStep3.controls['mailMerchantContact'].setValue('.');
      this.formStep3.controls['mailMerchantContact'].setValue('');
    }
    if (!this.formStep3.get('nameMerchatContact')?.value) {
      this.formStep3.get('nameMerchatContact')?.setValue('.');
      this.formStep3.get('nameMerchatContact')?.setValue('');
    }
  }

  async onSubmit() {
    this.formStep3.markAllAsTouched();
    this.formStep3.markAsDirty();
    this.markOptionalFieldsAsDirty();

    if (!this.formStep3.valid) return;
    this.state = !this.state;
    try {
      const optionBranch = this.formStep3.controls['optionBranch'].value;
      const option = this.formStep3.controls['optionExcel'].value;
      const idMerchant: string | boolean =
        await this.configFlow.createMerchantHierarchyConfig();
      if (idMerchant === true) {
        this.errorRequestPage = true;
        this.state = false;
        return;
      } else {
        if (optionBranch === '1') {
          this.router.navigate([
            `${clientRoute}/create/step-4`,
            idMerchant,
            option,
          ]);
        } else {
          this.configFlow.resetForm();
          this.router.navigate([
            `${clientRoute}/branch/create/step-branch`,
            idMerchant,
          ]);
        }
      }
    } catch (err) {}
  }

  openModal() {
    this.displayModal = true;
  }

  hideModal() {
    this.displayModal = false;
  }

  reset() {
    if (this.formStep3.controls['optionBranch'].value === '1' ) {
      this.formStep3.controls['optionExcel']?.setValidators([
        Validators.required,
      ]);
    } else {
      this.formStep3.controls['optionExcel']?.clearValidators();
      this.formStep3.controls['nameMerchatContact']?.clearValidators();
      this.formStep3.controls['mailMerchantContact']?.clearValidators();
      this.formStep3.controls['nameMerchatContact']?.reset();
      this.formStep3.controls['mailMerchantContact']?.reset();
    }
    this.formStep3.controls['optionExcel'].reset();
  }

  resetSendForm() {
    if (this.formStep3.controls['optionExcel'].value === 'NO') {
      this.formStep3.controls['nameMerchatContact']?.clearValidators();
      this.formStep3.controls['mailMerchantContact']?.clearValidators();
    } else {
      this.formStep3.controls['nameMerchatContact']?.setValidators([
        Validators.required,
      ]);
      this.formStep3.controls['mailMerchantContact']?.setValidators([
        Validators.required,
      ]);
    }
    this.formStep3.controls['nameMerchatContact'].reset();
    this.formStep3.controls['mailMerchantContact'].reset();
  }

  onSubmitCancel() {
    this.configFlow.resetForm();
    this.router.navigate([`${clientRoute}/info`]);
  }
  onSubmitAccept() {
    this.errorRequestPage = false;
  }
}
