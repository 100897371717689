import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyNumber]',
})
export class OnlyNumberDirective {
  navigationKeys: Array<string> = ['Backspace'];

  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    if (this.navigationKeys.indexOf(e.key) > -1) {
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (e.key === ' ' || isNaN(Number(e.key))) {
      e.preventDefault();
    }
  }
}
