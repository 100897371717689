import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultUsersLayout } from './layaouts/default/default.layout';
import { UpdatePage } from './pages/report/update/update.page';
import { Step1Page } from './pages/create/step-1/step-1.page';
import { Step1Page as Step1CommercialPage } from './pages/commercial-group/create/step-1/step-1.page';
import { ListPage } from './pages/list/list.page';
import { SearchPage } from './pages/create/search/search.page';
import { SearchPage as SearchCommercialPage } from './pages/commercial-group/create/search/search.page';
import { CheckAssociationPage } from './pages/create/check-association/check-association.page';
import { Step2Page } from './pages/create/step-2/step-2.page';
import { Step3Page } from './pages/create/step-3/step-3.page';
import { StepBranchPage } from './pages/branch/create/step-branch/step-branch.page';
import { LoadStepComponent } from './components/load-step/load-step.component';
import { CardComponent } from './components/card/card.component';
import { FilterListPipe } from '../../pipes/filter-list.pipe';
import { Step4Page } from './pages/create/step-4/step-4.page';
import { ExitModalComponent } from './components/exit-modal/exit-modal.component';
import { SuccessfullCreationComponent } from './components/successfull-creation/successfull-creation.component';
import { InfoPage } from './pages/info/info.page';
import { EmptyListComponent } from './components/empty-list/empty-list.component';

import { TabPerfilComponent } from './components/tabs-info-merchant/tab-perfil/tab-perfil.component';
import { SideRightComponent } from './components/side-right/side-right.component';
import { FileUpdateSideComponent } from './components/file-update-side/file-update-side.component';
import { ViewQrSideComponent } from './components/view-qr-side/view-qr-side.component';
import { ImageTextvisibleComponent } from './components/image-textvisible/image-textvisible.component';
import { TabProgramReportsComponent } from './components/tabs-info-merchant/tab-program-reports/tab-program-reports.component';
import { TabUpdateReportComponent } from './components/tabs-report-settings/tab-update-report/tab-update-report.component';
import { StepInitialPage as InitialReport } from './pages/report/create/step-initial/step-initial.page';
import { ReportSalesUnifiedPage } from './pages/report/create/report-sales-unified/report-sales-unified.page';
import { ReportSalesBranchPage } from './pages/report/create/report-sales-branch/report-sales-branch.page';
import { ExitModalReportComponent } from './components/exit-modal-report/exit-modal-report.component';
import { CardCheckComponent } from './components/card-check/card-check.component';
import { spaPath } from 'src/app/constants/routes';
import { EmptyListReturnComponent } from './components/empty-list-return/empty-list-return.component';
import { InfoBranchPage } from './pages/branch/info/info-branch.page';
import { TabFinancialInformationComponent } from './components/tabs-info-merchant/tab-financial-information/tab-financial-information.component';
import { CardFrequencyComponent } from './components/form-report/card-frequency/card-frequency.component';
import { CardRecipientComponent } from './components/form-report/card-recipient/card-recipient.component';
import { UpdateButtonFooterComponent } from './components/form-merchant/update-button-footer/update-button-footer.component';
import { ListBranchReportsPage } from './pages/report/list-branch-reports/list-branch-reports.page';
import { AccountValidatorComponent } from './components/account-validator/account-validator.component';
import { StepInitialPage } from './pages/step-initial/step-initial.page';
import { TabRelatedMerchantsComponent } from './components/tabs-info-commercial-group/tab-list-related-merchants/tab-list-related-merchants.component';
import { ListMerchant } from './components/tabs-list/list-merchants/list-merchant.page';
import { ListGroups } from './components/tabs-list/list-groups/list-groups.page';
import { TabBranchsComponent } from './components/tabs-info-merchant/tab-branchs/tab-branchs.component';
import { InfoGroupPage } from './pages/commercial-group/info/info-group.page';
import { CardGeneralCommercialGroupComponent } from './components/form-merchant/card-general-commercial-group/card-general-commercial-group.component';
import { StepEndPage } from './pages/commercial-group/create/step-end/step-end.page';
import { TabGroupsComponent } from './components/tab-list-branchs/tab-groups/tab-groups.component';
import { AssociateCommercialGroupPage } from './pages/associate-commercial-group/associate-commercial-group.page';
import { AssociateCommercialAnExistingMerchantGroupPage } from './pages/associate-commercial-group-an-existing-merchant/associate-commercial-group-an-existing-merchant.page';
import { ReportsListTable } from './components/reports-list-table/reports-list-table.component';

const routes: Routes = [
  {
    path: spaPath,
    component: DefaultUsersLayout,
    children: [
      {
        path: 'list',
        component: ListPage,
      },
      {
        path: 'step-initial',
        component: StepInitialPage,
      },
      {
        path: 'create/search',
        component: SearchPage,
      },
      {
        path: 'create/check-association',
        component: CheckAssociationPage,
      },
      {
        path: 'create/step-1',
        component: Step1Page,
      },
      {
        path: 'create/step-2',
        component: Step2Page,
      },
      {
        path: 'create/step-3',
        component: Step3Page,
      },
      {
        path: 'create/step-4/:merchantId/:option',
        component: Step4Page,
      },
      {
        path: 'branch/create/step-branch/:merchantId',
        component: StepBranchPage,
      },
      {
        path: 'commercial-group/create/search',
        component: SearchCommercialPage,
      },
      {
        path: 'commercial-group/create/step-1',
        component: Step1CommercialPage,
      },
      {
        path: 'commercial-group/create/step-end/:status',
        component: StepEndPage,
      },
      {
        path: 'info/:merchantId',
        component: InfoPage,
      },
      {
        path: 'commercial-group/info/:nodeId',
        component: InfoGroupPage,
      },
      {
        path: 'branch/info/:merchantId/:branchId',
        component: InfoBranchPage,
      },
      {
        path: 'report/list-branch-reports/:merchantId/reports/:reportId',
        component: ListBranchReportsPage,
      },
      {
        path: 'report/create/step-initial/:merchantId',
        component: InitialReport,
      },
      {
        path: 'report/create/report-sales-unified/:merchantId',
        component: ReportSalesUnifiedPage,
      },
      {
        path: 'report/create/report-sales-branch/:merchantId',
        component: ReportSalesBranchPage,
      },
      {
        path: 'report/update/:reportId',
        component: UpdatePage,
      },
      {
        path: 'commercial-group/associate',
        component: AssociateCommercialGroupPage,
      },
      {
        path: 'commercial-group/associate/:merchantId',
        component: AssociateCommercialAnExistingMerchantGroupPage,
      },
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      { path: '**', redirectTo: 'list', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClientsRoutingModule {
  static readonly LAYOUTS = [DefaultUsersLayout];
  static readonly PAGES = [
    ListPage,
    SearchPage,
    CheckAssociationPage,
    Step1Page,
    Step2Page,
    Step3Page,
    Step4Page,
    StepBranchPage,
    SearchCommercialPage,
    Step1CommercialPage,
    InfoPage,
    InfoGroupPage,
    InitialReport,
    ReportSalesUnifiedPage,
    ReportSalesBranchPage,
    InfoBranchPage,
    UpdatePage,
    ListBranchReportsPage,
    StepInitialPage,
    StepEndPage,
  ];
  static readonly COMPONENTS = [
    UpdateButtonFooterComponent,
    LoadStepComponent,
    CardComponent,
    FilterListPipe,
    SuccessfullCreationComponent,
    ExitModalComponent,
    EmptyListComponent,
    TabBranchsComponent,
    ReportsListTable,
    TabRelatedMerchantsComponent,
    TabFinancialInformationComponent,
    TabPerfilComponent,
    TabGroupsComponent,
    SideRightComponent,
    FileUpdateSideComponent,
    ImageTextvisibleComponent,
    ViewQrSideComponent,
    TabProgramReportsComponent,
    TabUpdateReportComponent,
    ExitModalReportComponent,
    CardCheckComponent,
    AccountValidatorComponent,
    EmptyListReturnComponent,
    CardFrequencyComponent,
    CardRecipientComponent,
    ListMerchant,
    ListGroups,
    CardGeneralCommercialGroupComponent,
  ];
}
