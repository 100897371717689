<deuna-loading
  [titulo]="'Cargando información'"
  *ngIf="loading"
  [subtitulo]="'Por favor, espera un momento'"
>
</deuna-loading>
<div class="content-app">
  <div class="grid mt-2 mx-5">
    <div class="col-12 py-5">
      <span class="text-style-back mt-2" routerLink="{{relativeRoute+'/list'}}"
        >Negocios afiliados</span
      >
      <span
        class="text-style-back mt-2"
        style="text-decoration: none; cursor: auto"
      >
        / {{ businessName }}</span
      >
    </div>
    <div class="col-icono">
      <img [src]="avatar" alt="image" style="height: 100px" />
    </div>
    <div class="col pl-4">
      <div class="grid">
        <div class="col-12">
          <span class="titles">{{ businessName }}</span>
        </div>
        <div class="col-12 pt-3">
          <span class="font-id"><strong>ID: </strong>{{groupNodeId}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 ml-5 mb-4">
    <deuna-tab-menu [items]="items" [activeItem]="activeItem"></deuna-tab-menu>
  </div>
  <app-tab-related-merchants
    *ngIf="activeItem.id == 'merchants'"
  ></app-tab-related-merchants>
</div>
