import { ICatalogue } from '../interfaces/calatogue.interface';
import { CustomExceptionDetailsError, CustomExceptionError } from './types';

export const ciFromRuc = (ci: string): string => ci.slice(0, 10);

export function removeSpecialCharacters(input: string): string {
  const regex = /[^a-zA-Z0-9 ]/g;
  return input.replace(regex, '');
}

export function createHours(): Array<ICatalogue> {
  const data: Array<ICatalogue> = [];
  for (let i = 0; i <= 23; i++) {
    const code = i < 10 ? `0${i}:00` : `${i}:00`;
    const name = i < 10 ? `0${i}:00 h (GMT-5)` : `${i}:00 h (GMT-5)`;
    data.push({
      code,
      name,
    });
  }
  return [...data];
}

export function createDays(days: number): Array<ICatalogue> {
  const data: Array<ICatalogue> = [];
  for (let i = 1; i <= days; i++) {
    data.push({
      code: `${i}`,
      name: `${i} de cada mes`,
    });
  }
  return [...data];
}

export function formatDate(date: Date = new Date()): string {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear() % 100;
  const formattedDay = day < 10 ? `0${day}` : `${day}`;
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  const formattedYear = year < 10 ? `0${year}` : `${year}`;
  return `${formattedDay}${formattedMonth}${formattedYear}`;
}

export function downloadFile(
  base64: string,
  fileName: string,
  typeFile: 'pdf' | 'excel'
) {
  const mimeTypes = {
    pdf: 'application/pdf',
    excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };
  const byteCharacters = window.atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimeTypes[typeFile] });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(url);
}

export function dateISO8601(date: Date): string {
  const opciones = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC',
  };

  // @ts-ignore
  const formateador = new Intl.DateTimeFormat('us', opciones);
  return formateador.format(date);
}

const isCustomExceptionDetailsError = (
  errorDetails: any
): errorDetails is CustomExceptionDetailsError => {
  return Boolean(
    errorDetails.code &&
      typeof errorDetails.code === 'number' &&
      errorDetails.reason &&
      typeof errorDetails.reason === 'string' &&
      errorDetails.details &&
      typeof errorDetails.details === 'string'
  );
};

export const isCustomExceptionError = (
  error: any
): error is CustomExceptionError => {
  return Boolean(
    error &&
      error?.statuscode &&
      typeof error.statuscode === 'number' &&
      error?.error &&
      isCustomExceptionDetailsError(error.error) &&
      error?.code &&
      typeof error.code === 'string'
  );
};

export const toastErrorMapping = (errorMessage?: string) => {
  if (!errorMessage) return undefined;

  const items = {
    email: 'Correo principal asociado a Deuna!',
    webhookUrl: 'Url del Webhook',
    phoneCode: 'Número celular asociado a Deuna!',
  };

  if (errorMessage.includes('Invalid value')) {
    const aux = errorMessage.split(':')[1].split(',');
    return `Error al guardar cambios. Revisa ${aux.map(
      (i) => `‘${items[i.trim()]}’`
    )}`;
  }

  return errorMessage;
};
