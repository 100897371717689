import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MutationResult } from 'apollo-angular';
import { clientRoute } from 'src/app/constants/routes';
import { ConfigurationFlowCreationService } from 'src/app/services/config/merchant/configuration-flow-creation.service';
import { GraphqlMerchantService } from 'src/app/services/graphql/graphql-merchant.service';
import { IFormGroup } from 'src/app/shared/interfaces/form-group.interface';
import { IMutatioAssociateGroupToMerchantResult } from 'src/app/shared/interfaces/query-merchants';

import { assetUrl } from '@deuna/bo-sh-helpers-ng';
import { ModalType } from 'src/app/constants/merchant-constants';

@Component({
  selector: 'app-associate-commercial-group-an-existing-merchant',
  templateUrl: './associate-commercial-group-an-existing-merchant.page.html',
  styleUrls: [],
})
export class AssociateCommercialAnExistingMerchantGroupPage implements OnInit {
  constructor(
    private router: Router,
    private configFlowCreation: ConfigurationFlowCreationService,
    private merchantService: GraphqlMerchantService
  ) {}
  relativeRoute = clientRoute;
  associating: boolean = false;
  showConfirmModal: boolean = false;
  modalMessage: string = '';
  modalTextMessage: string = '';
  labelAcceptButton: string = 'Sí';
  labelCancelButton: string = 'No';
  confirmFlow: ModalType = ModalType.SUBMIT;
  isValidSelection: boolean = true;
  errorAssociating: boolean = false;
  @Input() name: string = '';
  @Input() merchantId: string = '';

  formGroup!: FormGroup;
  formConfigData!: IFormGroup;
  stepLoad: number = 0;
  modalImg: string | null = null;

  ngOnInit(): void {
    this.initForm();
  }

  async initForm() {
    this.formConfigData = this.configFlowCreation.getForm();
    this.formGroup = this.formConfigData.associationGroup;
    this.formGroup.enable();
  }

  onCancel() {
    this.showConfirmModal = false;
  }

  onCancelAssociation() {
    this.setModalText(ModalType.CANCEL);
    this.modalImg = assetUrl('png/exit_logo.png');
    this.showConfirmModal = true;
  }

  onSubmit() {
    this.isValidSelection = true;
    const selectedItem = this.formGroup.value.commercialGroupId;

    if (this.formGroup.valid && selectedItem && selectedItem.nodeId) {
      this.formGroup.markAllAsTouched();
      this.setModalText(ModalType.SUBMIT, selectedItem?.businessName);
      this.showConfirmModal = true;
    } else {
      this.isValidSelection = false;
    }
  }

  setModalText(type: ModalType, group: string = '') {
    this.confirmFlow = type;
    this.modalImg =
      type === ModalType.CANCEL ? assetUrl('png/exit_logo.png') : null;
    this.modalTextMessage =
      type === ModalType.CANCEL
        ? `Al seleccionar “Sí, cancelar”, se eliminará toda la información 
    registrada hasta el momento.`
        : `Al seleccionar “Sí, asociar”, se afiliará el grupo de forma permanente.`;
    this.modalMessage =
      type === ModalType.CANCEL
        ? `¿Quieres abandonar el proceso?`
        : `¿Quieres asociar ${this.name} al grupo ${group}?`;
    this.labelAcceptButton = type === ModalType.CANCEL ? 'Sí, cancelar' : 'Sí';
    this.labelCancelButton =
      type === ModalType.CANCEL ? 'No, continuar editando' : 'No';
  }

  acceptModalSubmit() {
    if (this.confirmFlow === ModalType.SUBMIT) this.associateGroup();
    if (this.confirmFlow === ModalType.CANCEL) this.cancelAssociation();
  }

  associateGroup() {
    this.associating = true;
    this.merchantService
      .associateMerchantToGroup({
        clientId: this.merchantId || '',
        idParentNode: parseInt(
          this.formGroup?.get('commercialGroupId')?.value?.nodeId,
          10
        ),
      })
      .subscribe({
        next: (res: MutationResult<IMutatioAssociateGroupToMerchantResult>) => {
          const data = res.data;
          if (data) {
            this.associating = false;
            this.router.navigate([`${clientRoute}/info`, this.merchantId], {
              queryParams: { tab: 'groups', isAssociated: true },
            });
          }
        },
        error: () => {
          this.associating = false;
          this.errorAssociating = true;
        },
      });
  }

  cancelAssociation() {
    this.router.navigate([`${clientRoute}/info`, this.merchantId], {
      queryParams: { tab: 'groups' },
    });
  }

  onActionRetryButton() {
    this.errorAssociating = false;
  }

  onActionBackButton() {
    this.router.navigate([`${clientRoute}/list`], {
      queryParams: { tab: 'merchants' },
    });
  }
}
