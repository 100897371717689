import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { IFormGroupCommercialGroup } from '../../../shared/interfaces/form-group.interface';
import { form000, formSearch } from 'src/app/shared/utils/forms-control';

@Injectable()
export class ConfigurationFormService {
  formGroup!: IFormGroupCommercialGroup;

  constructor(private readonly fb: FormBuilder) {}

  getForm() {
    if (this.formGroup) {
      return this.formGroup;
    }

    const form: IFormGroupCommercialGroup = {
      search: this.fb.group(formSearch),
      CN000: this.fb.group(form000),
    };
    this.formGroup = form;
    return this.formGroup;
  }
}
