<app-card header="Cuentas operativas">
    <div class="mb-2">
        <ng-container *ngIf="isEditable else editBankName ">
            <span class="block">Entidad</span><br>
            <span class="bold-input">Mambu</span><br>
        </ng-container>
          
        <ng-template #editBankName>
            <deuna-input [masked]="false" [form]="formMerchantData" label="Entidad" name="bankName" styleClass="pt-2"
            control="bankName">
         </deuna-input>
        </ng-template> 
       
    </div>

    <deuna-input [masked]="false" [form]="formMerchantData" label="Número de cuenta" placeholder="Ej: 09090909" name="accountNumber" styleClass="pt-2"
        control="accountNumber" [masked]="!isEditable" [type]="!isEditable?'password': 'text'">
    </deuna-input>


  
</app-card>