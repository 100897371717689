import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import {
  ICreateReport,
  IFrequency,
  IResponseCreateReport,
} from 'src/app/shared/interfaces/generation-config-report.interface';
import { MutationResult } from 'apollo-angular';
import { IFormGroupReport } from 'src/app/shared/interfaces/form-group.interface';
import { IDataConfig } from 'src/app/shared/interfaces/config-merchant.interfaces';
import { GraphqlReportService } from '../../graphql/graphql-report.service';

@Injectable()
export class ConfigurationFlowReportService {
  formGroup!: IFormGroupReport;
  configMerchants: IDataConfig = { data: [] };

  constructor(
    private readonly fb: FormBuilder,
    private gqlReportService: GraphqlReportService
  ) {
    this.getForm();
  }

  getForm() {
    if (this.formGroup) {
      return this.formGroup;
    }
    const form = {
      initial: this.fb.group({
        merchantId: [null, [Validators.required]],
        type: [null, [Validators.required]],
        level: [null, [Validators.required]],
      }),
      branch: this.fb.group({
        file: null,
      }),
      frequency: this.fb.group({
        daysFrequency: [null, [Validators.required]],
        cutOffTime: [null, [Validators.required]],
        cutOffDay: [],
        cutOffDaySecond: [],
        mails: this.fb.array([]),
      }),
    };
    this.formGroup = form;
    return this.formGroup;
  }

  resetForm() {
    this.formGroup.initial.reset();
    this.formGroup.frequency.reset();
    (this.formGroup.frequency.get('mails') as FormArray).clear();
  }

  async saveForm(): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      const frequency: IFrequency = await this.transformDataFrequency();
      const data: ICreateReport = {
        ...this.formGroup.initial.value,
        ...frequency,
      };
      this.gqlReportService.createConfigReport(data).subscribe({
        next: (result: MutationResult<IResponseCreateReport>) => {
          if (result.data?.createReport) {
            resolve(true);
          }
        },
        error: () => {
          resolve(false);
        },
      });
    });
  }

  async transformDataFrequency(): Promise<IFrequency> {
    return new Promise<IFrequency>((resolve) => {
      const form = this.formGroup.frequency.value;
      delete form.cutOffDaySecond;
      const cutOffTime = [form.cutOffTime.code];
      const cutOffDay = form.cutOffDay?.code;
      let mails: Array<string> = [];
      form.mails.forEach((element) => {
        const mail: string = element.mail;
        mails.push(mail);
      });
      resolve({
        ...form,
        cutOffTime,
        cutOffDay,
        mails,
      });
    });
  }
}
