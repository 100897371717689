import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { assetUrl } from '@deuna/bp-sh-helpers-ng';
import { ICatalogue } from '../../../../../shared/interfaces/calatogue.interface';
import { CataloguesService } from '../../../../../services/http/catalogues.service';
import { ConfigurationFlowCreationService } from '../../../../../services/config/merchant/configuration-flow-creation.service';
import { clientRoute } from 'src/app/constants/routes';
import { IFormGroup } from 'src/app/shared/interfaces/form-group.interface';
import { DEFAULT_BANK, ClientType } from 'src/app/constants/merchant-constants';

@Component({
  selector: 'app-step-1',
  templateUrl: './step-1.page.html',
  styleUrls: ['./step-1.page.scss'],
})
export class Step1Page implements OnInit {
  formCN000!: FormGroup;
  formCN001!: FormGroup;
  formCN002!: FormGroup;
  formCN003!: FormGroup;
  formCN012!: FormGroup;
  formCN013!: FormGroup;
  formCN016!: FormGroup;
  search!: FormGroup;
  isEditable: boolean = true;

  backRoute: string = clientRoute;
  isAssociateGroupFlow: boolean = false;
  
  avatar: string = assetUrl('png/avatar_merchant.png');
  nombreComercialImg = assetUrl('png/iphone 1.png');
  loading: boolean = false;
  displayModal: boolean = false;
  accounts: Array<ICatalogue> = [];
  identification: string = '';
  clientType: string = '';
  formStep1!: FormGroup;
  formConfigData!: IFormGroup;

  constructor(
    private router: Router,
    private cataloguesService: CataloguesService,
    private configFlow: ConfigurationFlowCreationService
  ) {}

  clientTypes = ClientType;

  ngOnInit(): void {
    this.loading = true;
    this.initData();
    this.initForm();
  }

  async initForm() {
    this.formConfigData = this.configFlow.getForm();
    this.formCN000 = this.formConfigData.CN000;
    this.formCN001 = this.formConfigData.CN001;
    this.formCN002 = this.formConfigData.CN002;
    this.formCN003 = this.formConfigData.CN003;
    this.formCN012 = this.formConfigData.CN012;
    this.formCN013 = this.formConfigData.CN013;
    this.formCN016 = this.formConfigData.CN016;
    this.search = this.formConfigData.search;
    
    this.formCN000.enable();
    this.formCN001.enable();
    this.formCN002.enable();
    this.formCN003.enable();
    this.formCN012.enable();

    this.clientType = this.search.controls['clientType'].value;
    if (this.clientType === ClientType.NATURAL) {
      this.formCN000.controls['clientType']?.disable();
      this.formCN000.controls['businessName']?.disable();
      this.formCN000.controls['identification']?.disable();
      this.formCN013.enable();
    }

    const cifCode = this.search.controls['cifCode'].value;
    const identification = this.search.controls['identification'].value;
    const identificationType = this.search.controls['identificationType'].value;

    this.identification = cifCode ? `${identification} / ${cifCode}` : '';

    this.formCN000.controls['identification'].setValue(identification);
    this.formCN000.controls['identificationType'].setValue(identificationType);
    this.formCN000.controls['clientType']?.setValue(this.clientType);
    this.formCN003.controls['bankName'].setValue(DEFAULT_BANK);
    this.formCN012.controls['bankName'].setValue(DEFAULT_BANK);
    this.formCN000.controls['clientAccountId']?.setValue(cifCode);
  }

  initData() {
    this.cataloguesService.getAccountTypes().subscribe((res) => {
      this.accounts = res;
    });
    this.isAssociateGroupFlow = this.configFlow.getForm().checkAssociation.get('hasGroup')?.value === 'yes';
    this.backRoute = this.isAssociateGroupFlow ? `${this.backRoute}/commercial-group/associate` : `${this.backRoute}/create/search`;
    this.loading = false;
  }

  validateJuridicMerchantFormGroup() {
    return (
      this.formCN000.valid &&
      this.formCN001.valid &&
      this.formCN002.valid &&
      this.formCN003.valid
    );
  }

  validateNaturalMerchantFormGroup() {
    return (
      this.formCN000.valid &&
      this.formCN002.valid &&
      this.formCN003.valid &&
      this.formCN013.valid &&
      this.formCN012.valid
    );
  }

  onSubmit(): void {
    this.formCN000.markAllAsTouched();
    this.formCN001.markAllAsTouched();
    this.formCN002.markAllAsTouched();
    this.formCN003.markAllAsTouched();
    this.formCN013.markAllAsTouched();
    this.formCN012.markAllAsTouched();
    if (this.clientType === ClientType.NATURAL) {
      if (!this.validateNaturalMerchantFormGroup()) return;
    } else {
      if (!this.validateJuridicMerchantFormGroup()) return;
    }
    this.router.navigate([`${clientRoute}/create/step-2`]);
  }
  openModal() {
    this.displayModal = true;
  }

  hideModal() {
    this.displayModal = false;
  }
}
