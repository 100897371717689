import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { jwtsecurityService } from '@deuna/bo-sh-security-ng';

@Directive({
  selector: '[appRoleBased]',
})
export class RoleBasedDirective {
  @Input('appRoleBased') requiredRoles: string[] = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnInit() {
    console.log(
      'jwtsecurityService',
      jwtsecurityService.validateIn(this.requiredRoles, true)
    );
    console.log('this.requiredRoles', this.requiredRoles);
    console.log('this.requiredRoles');
    if (jwtsecurityService.validateIn(this.requiredRoles, true)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
