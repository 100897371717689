<container-element [ngSwitch]="tableStatus">
  <div class="col-12 text-center" *ngSwitchCase="'LOADING'">
    <p-progressBar
      mode="indeterminate"
      [style]="{'height': '6px'}"
    ></p-progressBar>
    <h2 class="mt-2">Cargando....</h2>
  </div>
  <ng-container *ngSwitchCase="'COMPLETE'">
    <deuna-side-right
      title="Filtros"
      [showSide]="showSideBar"
      (closeSide)="showSideBar = false"
    >
      <span class="content-app">
        <div class="grid mx-5">
          <div class="col-12">
            <div class="grid">
              <div class="col-5">
                <deuna-input
                  [masked]="false"
                  [masked]="false"
                  styles="background:#F1F2F5"
                  [form]="formFilterMerchant"
                  placeholder="Buscar por nombre comercial, razón social o N° de RUC"
                  name="textSearchMerchant"
                  control="textSearchMerchant"
                  [maxLength]="200"
                >
                </deuna-input>
              </div>
              <div class="col-7" style="text-align-last: right">
                <div class="pt-3 mr-4">
                  <deuna-chip
                    backgroundColor="#404040"
                    color="#FFFFFF"
                    [text]="'Filtrar '"
                    [chipClick]="true"
                    [valueAction]="searchFilter"
                    (closeChip)="openSideBar()"
                  >
                  </deuna-chip>
                </div>
              </div>
            </div>
            <ng-container *ngIf="viewFilters">
              <div class="grid mb-4">
                <div class="col-12">
                  <strong>Filtros aplicados</strong>
                  <deuna-chip
                    *ngFor="let item of listFilters"
                    backgroundColor="#ECF9EF"
                    color="#236936"
                    [text]="item.name"
                    [action]="true"
                    [valueAction]="' X'"
                    (closeChip)="clearFilter($event)"
                  >
                  </deuna-chip>
                </div>
              </div>
            </ng-container>

            <deuna-table [arrayData]="[1]" [columnas]="columns">
              <ng-container *ngFor="let merchant of merchantsList">
                <tr>
                  <deuna-table-cell
                    [routerLink]="[relativeRoute+'/info', merchant.id]"
                    style="cursor: pointer"
                  >
                    <div class="grid my-autoSipo">
                      <div class="my-auto p-2 box-icon">
                        <img
                          [src]="avatar"
                          alt="image"
                          style="width: 3rem; height: 3rem"
                        />
                      </div>
                      <div
                        class="col my-auto"
                        style="text-decoration: none; color: black"
                      >
                        {{merchant.comercialName}}
                      </div>
                    </div>
                  </deuna-table-cell>
                  <deuna-table-cell>
                    {{merchant.businessName}}
                  </deuna-table-cell>
                  <deuna-table-cell>
                    {{merchant.identificationType}}
                  </deuna-table-cell>
                  <deuna-table-cell>
                    {{merchant.identification}}
                  </deuna-table-cell>
                  <deuna-table-cell>
                    {{merchant.commercialGroup ?? 'No aplica'}}
                  </deuna-table-cell>
                  <deuna-table-cell>
                    {{getRegion(merchant.region)}}
                  </deuna-table-cell>
                  <deuna-table-cell>
                    {{merchant.lastModified | date:'dd-M-yyyy'}} por
                    {{merchant.updateBy}}
                  </deuna-table-cell>
                </tr>
              </ng-container>
            </deuna-table>
            <deuna-pagination
              (paginaEmitter)="goToPage($event)"
              (itemsEmmiter)="changeToItems($event)"
              [currentPage]="dataPagination.currentPage"
              [limitPage]="dataPagination.itemCount"
              [totalItems]="dataPagination.totalItems"
              [totalPages]="dataPagination.totalPages"
            >
            </deuna-pagination>
          </div></div
      ></span>
      <span class="content-side">
        <div class="grid mx-3">
          <div class="col-12">
            <strong>Region</strong>
          </div>
          <div class="grid ml-2">
            <div class="col-12" style="margin: 0">
              <input
                class="colorCheck mt-2"
                type="checkbox"
                name="allRegion"
                [checked]="areAllSelectedRegion"
                (change)="allSelectionRegion()"
              />
              Todas
            </div>
            <div class="col">
              <div *ngFor="let region of regionList; let i = index;">
                <div
                  style="height: 40px"
                  [style.backgroundColor]="region.selected ? '#F1F2F5': '#FFFFFF' "
                >
                  <input
                    class="colorCheck mt-2"
                    type="checkbox"
                    [(ngModel)]="region.selected"
                    [checked]="region.selected"
                  />
                  {{region.name}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid mx-3">
          <div class="col-12">
            <strong>Estado</strong>
          </div>
          <div class="grid ml-2">
            <div class="col-12" style="margin: 0">
              <input
                class="colorCheck mt-2"
                type="checkbox"
                name="allStatus"
                [checked]="areAllSelectedStatus"
                (change)="allSelectionStatus()"
              />
              Todas
            </div>
            <div class="col-12">
              <div *ngFor="let status of statusList; let i = index;">
                <div
                  style="height: 40px"
                  [style.backgroundColor]="status.selected ? '#F1F2F5': '#FFFFFF' "
                >
                  <input
                    class="colorCheck mt-2"
                    type="checkbox"
                    [(ngModel)]="status.selected"
                    [checked]="status.selected"
                  />
                  {{status.name}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid mt-3">
          <div class="col-12">
            <deuna-button
              [label]="'Aplicar filtros'"
              deUnaClass="p-button-outlined"
              (eventClick)="applyFilters()"
            ></deuna-button>
          </div>
          <div class="col-12 px-5 text-link">
            <u (click)="deleteFilters()">Borrar todos los filtros</u>
          </div>
        </div>
      </span>
    </deuna-side-right></ng-container
  >
  <div
    *ngSwitchCase="'EMPTY'"
    class="container-center col-12"
    style="display: flex; justify-content: center"
  >
    <div style="text-align: center">
      <img
        loading="lazy"
        [src]="imgNotFoundMerchants"
        alt="image"
        style="width: 40%"
      />
      <div>
        <h2 class="fontLoadHeader" style="margin: auto">
          Ups, parece que no hay negocios por gestionar
        </h2>
        <h2 class="fontLoadSubHeader">
          Sé la primera persona en crear uno, selecciona “Nuevo negocio”
        </h2>
      </div>
    </div>
  </div>
</container-element>
