import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { clientRoute } from '../../../../../constants/routes';
import { IFormGroupReport } from '../../../../../shared/interfaces/form-group.interface';
import { ConfigurationFlowReportDataService } from '../../../../../services/config/report/configuration-flow-data-report.service';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-update',
  templateUrl: './update.page.html',
  styleUrls: ['./update.page.scss'],
})
export class UpdatePage implements OnInit {
  relativeRoute = clientRoute;
  subActivatedRoute!: Subscription;
  reportId!: string;
  merchantId!: string;
  formReportData!: IFormGroupReport;
  loadData: boolean = true;
  isEditable: boolean = false;
  isEditableEmails: boolean = true;
  reportName: string = 'Reporte de ventas';
  levelReport: string = '';
  typeReport: string = '';
  isUnifiedReport!: boolean;
  maximumAllowedEmails!: number;
  maxAllowedEmailsUnified = 10;
  maxAllowedEmailsByBranch = 2;
  items: Array<MenuItem> = [];
  activeItem: MenuItem = {};
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private configurationFlowReportDataService: ConfigurationFlowReportDataService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init() {
    this.reportId = this.getReportIdFromParams() as string;
    await this.configurationFlowReportDataService.setFormDataReport(
      this.reportId
    );
    this.formReportData = this.configurationFlowReportDataService.getForm();
    this.merchantId = this.formReportData.initial.controls['merchantId']?.value;
    
    const levelValue = this.formReportData.initial.controls['level']?.value;
    const typeValue = this.formReportData.initial.controls['type']?.value;
    this.levelReport = levelValue === 'BRANCH' ? 'Por sucursal' : 'Unificado';
    this.isUnifiedReport = this.isUnified();
    this.typeReport =
      typeValue === 'SALES' ? 'Reporte de ventas' : 'Reporte de conciliación';
    this.loadData = false;

    this.items = this.getTabs();

    this.activatedRoute.queryParams?.subscribe((params) => {
      const tab = params['tab'] ?? 'configuration';
      const index = this.items.findIndex((x) => x.id === tab);
      if (index >= 0) {
        this.activeItem = this.items[index];
      }
    });
  }
  
  getReportIdFromParams() {
    return this.activatedRoute.snapshot.paramMap.get('reportId');
  }

  private isUnified() {
    return this.levelReport === 'Unificado';
  }

  private redirectTab = (item) => {
    this.router.navigate([`${clientRoute}/report/update`, this.reportId], {
      queryParams: { tab: item.item.id },
    });

    this.activeItem = item.item;
  };

  private getTabs = () => {
    return [
      {
        label: 'Configuración del reporte',
        command: (item) => this.redirectTab(item),
        id: 'configuration',
      },
      {
        label: 'Descargables',
        command: (item) => this.redirectTab(item),
        id: 'downloadable',
      },
    ];
  };
}
