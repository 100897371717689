<div class="error-page">
    <div class="content">
      <div class="error-boby">
        <img [src]="img" alt="Error bank" width="256px" height="256px" />
        <h1 class="title">
          {{title}}
        </h1>
        <p class="desc">
          {{subtitle}}
        </p>
        <div class="button-container">
          <deuna-button [label]="buttonBackText" deUnaClass="p-button-outlined" (eventClick)="actionBackButton()"></deuna-button>
          <deuna-button [label]="buttonRetrytext"  (eventClick)="actionRetryButton()"></deuna-button>
        </div>
      </div>
    </div>
</div>