<deuna-loading [titulo]="textLoading" *ngIf="load" [subtitulo]="'Por favor, espera un momento'">
</deuna-loading>

<p-toast position="bottom-right" key="br" [baseZIndex]="9000">
  <ng-template let-message pTemplate="message">
    <div class="flex" style="flex: 1">
      <div class="text-center">
        <i class="material-icons material-icons-outlined" aria-hidden="true"
          style="vertical-align: middle; padding-right: 4px">done</i>
        <span style="vertical-align: middle">{{message.summary}}</span>
      </div>
    </div>
  </ng-template>
</p-toast>

<app-side-right [title]="frameSide.title" [subTitle]="frameSide.subTitle" [orderSubtitle]="frameSide.orderSubtitle"
  [showSide]="showSide" (closeSide)="closeSide()">
  <span class="content-app">
    <div class="grid mt-2 mx-5 px-4">
      <div class="col-12 pt-8">
        <div class="text-style-back" [routerLink]="backRoute">
          Regresar
        </div>
      </div>
      <div class="col-12 py-5">
        <div class="titles">Carga Masiva</div>
      </div>
    </div>
    <div class="grid mx-5 px-4">
      <div class="col" style="overflow-x: auto">
        <ng-container>
          <div class="grid justify-content-between">
            <div class="col-5">
              <div class=" icon-input-container">
                <deuna-input style="margin-top: 48px; position: relative" [masked]="false" styles="background:#F1F2F5"
                  [form]="formFilterPymes" placeholder="Ingresa el número de orden" [maxLength]="20"
                  name="textSearchMerchant" control="textSearchMerchant">
                </deuna-input>
                <i class="input-icon material-icons material-icons-outlined" aria-hidden="true">search</i>
              </div>
            </div>
            <div class="mt-0">
              <deuna-button [label]="'Carga masiva pymes'" [routerLink]="forwardRoute" [icon]="true">
                <div class="icon">
                  <i class="material-icons" aria-hidden="true"> cloud_upload</i>
                </div>
              </deuna-button>
            </div>
          </div>

          <ng-container *ngIf="hasError">
            <div class=" container-center">
              <div style="text-align: center;">
                <img loading="lazy" [src]="emptyReportInactive" alt="emptyReport" style="width:25%">
                <div>
                  <h2 class="fontLoadHeader">Hubo un error al cargar el historial de carga masiva
                  </h2>
                  <h2 class="fontLoadSubHeader">Por favor, prueba otra vez
                  </h2>
                  <div class="chip-button">
                    <deuna-chip backgroundColor="#000000" color="#FFFFFF" [text]="'Intentar de nuevo'"
                      [chipClick]="true" (retryFetch)="retryFetch()">
                    </deuna-chip>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="!hasError && flagGet">
            <deuna-table [arrayData]="[1]" [columnas]="columns">
              <ng-container *ngFor="let pyme of pymeList">
                <tr class="select-row">
                  <deuna-table-cell> {{pyme.orderNumber}} </deuna-table-cell>
                  <deuna-table-cell> {{pyme.oderType}} </deuna-table-cell>
                  <deuna-table-cell> {{pyme.date}} </deuna-table-cell>
                  <deuna-table-cell>
                    <ng-container [ngSwitch]="pyme.uploadStatus">
                      <div class="chip-container">
                        <deuna-chip *ngSwitchCase="'APPROVED'" [valueAction]="pointGreen" backgroundColor="#ECF9EF"
                          color="#2D8846" text="Aprobada">
                        </deuna-chip>
                      </div>
                      <div class="chip-container">
                        <deuna-chip *ngSwitchCase="'FAILED'" [valueAction]="pointRed" backgroundColor="#FFBABA"
                          color="#D8000C" text="Fallida">
                        </deuna-chip>
                      </div>
                    </ng-container>
                  </deuna-table-cell>
                  <deuna-table-cell>
                    <ng-container [ngSwitch]="pyme.creationStatus">
                      <div class="chip-container">
                        <deuna-chip *ngSwitchCase="'APPROVED'" [valueAction]="pointGreen" backgroundColor="#ECF9EF"
                          color="#2D8846" text="Aprobada"></deuna-chip>
                      </div>
                      <div class="chip-container">
                        <deuna-chip *ngSwitchCase="'PROCESSING'" [valueAction]="pointBrown" backgroundColor=" #FDF4E6 "
                          color=" #835200" text="Procesando"></deuna-chip>
                      </div>
                      <div class="chip-container">
                        <deuna-chip *ngSwitchCase="'FAILED'" [valueAction]="pointRed" backgroundColor="#FFBABA"
                          color="#D8000C" text="Fallida"></deuna-chip>
                      </div>
                    </ng-container>
                  </deuna-table-cell>
                  <deuna-table-cell>
                    {{pyme.comercialCoordinator}}
                  </deuna-table-cell>
                  <deuna-table-cell>
                    <deuna-button deUnaClass="p-button-icon-outlined" (eventClick)="setSelectedPyme(pyme)">
                      <div class="icon">
                        <img [src]="folderDown" alt="folderDown" />
                      </div>
                    </deuna-button>
                  </deuna-table-cell>
                </tr>
              </ng-container>
            </deuna-table>
            <deuna-pagination  class="m-8" (paginaEmitter)="goToPageTable($event)" (itemsEmmiter)="changeToItemsTable($event)"
              [currentPage]="dataPagination.currentPage" [limitPage]="dataPagination.itemCount"
              [totalItems]="dataPagination.totalItems" [totalPages]="dataPagination.totalPages">
            </deuna-pagination>

            <ng-container *ngIf="!formFilterPymes.get('textSearchMerchant')?.value && !flagGet">
              <div class=" container-center">
                <div style="text-align: center;">
                  <img loading="lazy" [src]="emptyReportInactive" alt="emptyReport" style="width:25%">
                  <div>
                    <h2 class="fontLoadHeader"> No tienes cargas masivas disponibles
                    </h2>
                    <h2 class="fontLoadSubHeader"> Aún no has subido ninguna carga masiva. Para comenzar, crea una nueva
                      carga y gestiona tus archivos fácilmente.
                    </h2>
                    <div class="chip-button">
                      <deuna-chip backgroundColor="#000000" [valueAction]="cross" color="#FFFFFF"
                        [text]="'Crear carga masiva'" [chipClick]="true" [routerLink]="forwardRoute">
                      </deuna-chip>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="!!formFilterPymes.get('textSearchMerchant')?.value && !flagGet">
              <div class=" container-center">
                <div style="text-align: center;">
                  <img loading="lazy" [src]="emptyReportInactive" alt="emptyReport" style="width:25%">
                  <div>
                    <h2 class="fontLoadHeader"> No hay resultados que coincidan
                    </h2>
                    <h2 class="fontLoadSubHeader"> Prueba con otra búsqueda.
                    </h2>
                    <div class="chip-button">
                      <deuna-chip backgroundColor="#000000" color="#FFFFFF" [text]="'Borrar'" [chipClick]="true"
                        (closeChip)="cleanInput()">
                      </deuna-chip>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

          </ng-container>
        </ng-container>
      </div>
    </div>
  </span>

  <span class="content-side grid m-3" *ngIf="showSide && !showUploadedFile">
    <div class="col-12">
      <ng-container *ngFor="let file of selectedPyme?.files">
        <div class="file-to-download" (click)="downloadFile(file.fileUrl)">
          <div style="display: flex; justify-content: space-between">
            <div style="flex: auto ; width: 90%; word-wrap: break-word; line-height: normal; font-weight: 500;">
              {{file.fileName}}</div>
            <div style="flex: auto ; width: 10%;">
              <img [src]="download" width="24" height="24" alt="download" />
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </span>

  <span class="content-side grid m-3" *ngIf="showSide && showUploadedFile">
    <div class="col-12">
      <div class="title-message">
        Carga un archivo con varios negocios. Asegúrate que la información y el formato sean correctos.
      </div>
      <div class="file-info">
        <div style="display: flex; justify-content: space-between">
          <div style="flex: auto ; width: 90%; word-wrap: break-word; line-height: normal; font-weight: 600;">
            {{uploadedFileName}}</div>
        </div>
        <div class="progress-bar-div">
          <p-progressBar mode="indeterminate" [style]="{ height: '4px'}"
          color="#2BC59A"></p-progressBar>
        </div>
      </div>
      <div class="download-file">
        <p>¿Quieres obtener el formato de creación masiva de pymes?</p>
        <a [href]="excelFile" target="_blank">
          <span>Descarga la plantilla</span>
        </a>
      </div>
    </div>
  </span>

</app-side-right>
