import { Component, OnInit } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import {
  CommercialGroupItem,
  IQueryGroup,
  Meta,
  ResponseCommercialGroups,
} from 'src/app/shared/interfaces/query-merchants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { assetUrl } from '@deuna/bo-sh-helpers-ng';
import { Subscription, debounceTime } from 'rxjs';
import { GraphqlMerchantService } from 'src/app/services/graphql/graphql-merchant.service';
import { ApolloQueryResult } from '@apollo/client';
import { clientRoute } from 'src/app/constants/routes';
import { environment } from 'src/environments/environment';
import { REGEX_WITHOUT_SPECIAL_CHARACTERS } from 'src/app/constants/merchant-constants';

enum TableStates {
  LOADING = 'LOADING',
  COMPLETE = 'COMPLETE',
  EMPTY = 'EMPTY',
}

@Component({
  selector: 'app-list-groups',
  templateUrl: './list-groups.page.html',
  styleUrls: [],
  providers: [TitleCasePipe],
})
export class ListGroups implements OnInit {
  relativeRoute = clientRoute;
  formFilterGroup!: FormGroup;
  columns = [
    'Nombre',
    'No de RUC',
    'Estado',
    'Coordinador/a',
    'Última modificación',
  ];
  tableStatus: TableStates = TableStates.LOADING;
  inputSub!: Subscription;
  defaultAvatar: string = assetUrl('svg/merchant-group-logo.svg');
  imgNotFoundMerchants: string = assetUrl('png/checklist.png');
  subGraphqlMerchantService!: Subscription;
  dataPagination!: Meta;
  resultfields: string = '';
  paramsQuery!: IQueryGroup;
  merchantsList: Array<CommercialGroupItem> = [];

  constructor(
    private readonly fb: FormBuilder,
    private servicesMerchantsGql: GraphqlMerchantService
  ) {}

  ngOnInit(): void {
    this.formFilterGroup = this.fb.group({
      textSearchGroup: [
        '',
        Validators.pattern(REGEX_WITHOUT_SPECIAL_CHARACTERS),
      ],
    });
    this.paramsQuery = { limit: 10, inputSearch: '', page: 1 , status: 'A'};
    this.createConfigGetInitial();
    this.getGroupsByPage(true);
  }

  ngAfterContentInit() {
    this.suscribeToInputChanges();
  }

  suscribeToInputChanges() {
    this.inputSub = this.formFilterGroup.controls[
      'textSearchGroup'
    ].valueChanges
      .pipe(debounceTime(1500))
      .subscribe((value) => {
        if (!this.formFilterGroup.valid) {
          return;
        }
        this.paramsQuery.page = 1;
        this.paramsQuery.inputSearch = value;
        this.getGroupsByPage(false);
      });
  }

  createConfigGetInitial() {
    this.resultfields = ` 
    businessName, comercialName, identification`;
  }
  changeToItems(page: number) {
    this.paramsQuery.page = 1;
    this.paramsQuery.limit = page;
    this.getGroupsByPage(false);
  }

  goToPage(page: number) {
    this.paramsQuery.page = page;
    this.getGroupsByPage(false);
  }

  getGroupsByPage(flag: boolean) {
    this.tableStatus = TableStates.LOADING;
    this.subGraphqlMerchantService = this.servicesMerchantsGql
      .getGroupsPage(this.paramsQuery, this.resultfields)
      .subscribe({
        next: (res: ApolloQueryResult<ResponseCommercialGroups>) => {
          if (res.data.findAllCommercialGroups) {
            const { items, meta } = res.data.findAllCommercialGroups;
            this.merchantsList = items;
            this.dataPagination = meta;
            if (flag && this.merchantsList.length > 0) {
              this.tableStatus = TableStates.COMPLETE;
            }
          }
          this.tableStatus = TableStates.COMPLETE;
        },
      });
  }
}
