<app-card header="Información general" [isFlowUpdate]="isFlowUpdate" [isEditable]="isEditable" (customEvent)="handleEvent($event)">
  <div class="grid">
    <div class="col-6">
      <deuna-input [masked]="false" styleClass="mt-4" [form]="search" [label]="type" placeholder="Ej: Dulcafe" name="identification" control="identification" [disabled]="true"></deuna-input>
    </div>
    <div class="col-6">
      <deuna-input [masked]="false" styleClass="mt-4" [form]="search" label="CIF" placeholder="Ej: Dulcafe" name="cifCode" control="cifCode"></deuna-input>
    </div>
  </div>
  <deuna-input [masked]="false" [maxLength]="200" styleClass="mt-4" [form]="formCN000" label="Razón Social" placeholder="Ej: Dulcafe" [maxLength]="100" name="businessName" control="businessName"></deuna-input>
  <deuna-input [masked]="false" styleClass="mt-4" [form]="formCN001" label="Representante Legal" [maxLength]="100" placeholder="Ej: Juan Sanchez" name="legalRepresentative" control="legalRepresentative"></deuna-input>

  <ng-container *ngIf="(isEditable && !isFlowUpdate) || enableEdit; else editBusinessType">
    <deuna-radio-button label="Tipo de Empresa" styleClassItem="pt-2" [values]="businessType" styleClass="pt-2" control="businessType" [form]="formCN002"></deuna-radio-button><br />
  </ng-container>

  <ng-template #editBusinessType>
    <deuna-input [masked]="false" styleClass="mt-4" [form]="formCN002" label="Tipo de Empresa" name="businessType" control="businessType"></deuna-input>
  </ng-template>

  <ng-container *ngIf="(isEditable && !isFlowUpdate) || enableEdit; else editData">
    <deuna-radio-button label="Segmento" styleClassItem="pt-2" [values]="segments" styleClass="pt-2" control="segment" [form]="formCN002"></deuna-radio-button>
    <deuna-select [options]="categories" (onChangeSelect)="onChangeCategorySelect()" optionLabel="name" [form]="formCN002" label="Categoría" name="category" control="category" placeholder="Selecciona una categoría"></deuna-select>
    <deuna-select [options]="subCategorySelected" [disabled]="subCategorySelected.length === 0" optionLabel="name" [form]="formCN002" label="SubCategoria" name="subcategory" control="subcategory" placeholder="Selecciona una subcategoria"></deuna-select>
  </ng-container>
  <ng-template #editData>
    <deuna-input [masked]="false" styleClass="mt-4" [form]="formCN002" label="Segmento" control="segment"></deuna-input>
    <deuna-input [masked]="false" styleClass="mt-4" [form]="formCN002" label="Categoría" control="category"></deuna-input>
    <deuna-input [masked]="false" styleClass="mt-4" [form]="formCN002" label="SubCategoria" control="subcategory"></deuna-input>
  </ng-template>
  <div *ngIf="(isFlowUpdate && isEditable && !isFlowUpdate) || enableEdit" class="col-offset-6 pt-4">
    <app-update-button-footer [loading]="loading" (eventSubmit)="submit()" (eventCancel)="onCancelEdit()"></app-update-button-footer>
  </div>
</app-card>