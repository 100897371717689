import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client';
import { Subscription } from 'rxjs';
import { GraphqlMerchantService } from 'src/app/services/graphql/graphql-merchant.service';
import {
  MerchantItem,
  ResponseMerchant,
} from 'src/app/shared/interfaces/query-merchants';

@Component({
  selector: 'app-card-operating-accounts',
  templateUrl: './card-operating-accounts.component.html',
})
export class CardOperatingAccountsComponent implements OnInit {
  @Input() isEditable: boolean = false;
  formMerchantData!: FormGroup;
  merchant!: MerchantItem;
  subGraphqlMerchantService!: Subscription;
  subActivatedRoute!: Subscription;
  resultfields: string = `id,
  identification
  clientAcountId
   `;

  constructor(
    private graphqlMerchantService: GraphqlMerchantService,
    private activatedRoute: ActivatedRoute,
    private readonly fb: FormBuilder
  ) {
    this.formMerchantData = this.fb.group({
      bankName: [null],
      accountNumber: [null],
    });
  }

  ngOnInit(): void {
    this.getInitial();
  }

  async getInitial() {
    await new Promise<void>((resolve) => {
      this.subActivatedRoute = this.activatedRoute.paramMap.subscribe(
        (params) => {
          const idMerchant = params.get('merchantId');
          if (idMerchant) {
            this.subGraphqlMerchantService = this.graphqlMerchantService
              .getMerchantById(idMerchant, this.resultfields)
              .subscribe({
                next: (res: ApolloQueryResult<ResponseMerchant>) => {
                  this.merchant = res.data.getClient;
                  this.formMerchantData.get('bankName')?.setValue('Mambu');
                  this.formMerchantData
                    .get('accountNumber')
                    ?.setValue(this.merchant?.clientAcountId);
                  if (!this.isEditable) {
                    this.formMerchantData.disable();
                  }
                  resolve();
                },
                error: () => {},
              });
          }
        }
      );
    });
  }
  ngOnDestroy() {
    this.subGraphqlMerchantService?.unsubscribe();
    this.subActivatedRoute?.unsubscribe();
  }
}
