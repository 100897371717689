<deuna-loading [titulo]="'Cargando información'" *ngIf="loading" [subtitulo]="'Por favor, espera un momento'">
</deuna-loading>
<form (ngSubmit)="onSubmit()" class="grid my-5" autocomplete="off">
  <div class="col-8 col-offset-2">
    <div class="mb-5">
      <div class="text-style-back" routerLink="{{backRoute}}">
        Regresar
      </div>
    </div>
    <app-load-step [stepLoad]="20"></app-load-step>
  </div>
  <ng-container *ngIf="clientType !== clientTypes.NATURAL; else naturalInformation">
    <div class="col-8 col-offset-2">
      <app-card-general [isEditable]="isEditable" [formCN000]="formCN000" [formCN001]="formCN001" [formCN002]="formCN002" [search]="search"></app-card-general>
    </div>
    <div class="col-8 col-offset-2 pt-4">
      <app-card-commercial [isEditable]="true" [formDataCN000]="formCN000"></app-card-commercial>
    </div>
    <div class="col-8 col-offset-2 pt-4">
      <app-card-collection-accounts-natural [isEditable]="false" [formCN003]="formCN003"></app-card-collection-accounts-natural>
    </div>
  </ng-container>

  <ng-template #naturalInformation>
    <div class="col-8 col-offset-2">
      <app-card-natural [isEditable]="false" [formCN000]="formCN000" [formCN002]="formCN002" [search]="search"></app-card-natural>
    </div>
    <div class="col-8 col-offset-2 pt-4">
      <app-card-commercial [isEditable]="false" [formDataCN000]="formCN000"></app-card-commercial>
    </div>
    <div class="col-8 col-offset-2 pt-4">
      <app-card-tax [formCN016]="formCN016"></app-card-tax>
    </div>
    <div class="col-8 col-offset-2 pt-4">
      <app-card-collection-accounts-natural [isEditable]="false" [formCN003]="formCN003"></app-card-collection-accounts-natural>
    </div>
    <div class="col-8 col-offset-2 pt-4">
      <app-card-commission-accounts-natural [isEditable]="false" [formCN012]="formCN012" [isFlowUpdate]="false"></app-card-commission-accounts-natural>
    </div>
    <div class="col-8 col-offset-2 pt-4">
      <app-card-billing-information [isCreationForm]="true" [isEditable]="true" [formCN013]="formCN013"></app-card-billing-information>
    </div>
  </ng-template>

  <div class="col-6 col-offset-4 pt-4">
    <div class="grid">
      <div class="col-6">
        <deuna-button label="Cancelar" deUnaClass="p-button-outlined" (eventClick)="openModal()">
        </deuna-button>
      </div>
      <div class="col-6">
        <deuna-button label="Siguiente" type="submit"></deuna-button>
      </div>
    </div>
  </div>
</form>
<app-exit-modal [displayModal]="displayModal" (onHide)="hideModal()"></app-exit-modal>