import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { CataloguesService } from 'src/app/services/http/catalogues.service';
import {
  ICatalogue,
  ICatalogueSubCategory,
} from 'src/app/shared/interfaces/calatogue.interface';
import _ from 'lodash';
import { ConfigurationDataService } from 'src/app/services/config/merchant/configuration-data.service';

@Component({
  selector: 'app-card-general',
  templateUrl: './card-general.component.html',
})
export class CardGeneralComponent implements OnInit {
  @Input() isEditable: boolean = false;
  @Input() isFlowUpdate: boolean = false;
  loading: boolean = false;
  enableEdit: boolean = false;
  @Input() formCN000!: FormGroup;
  @Input() formCN001!: FormGroup;
  @Input() formCN002!: FormGroup;
  @Input() search!: FormGroup;
  @Output() toastEvent: EventEmitter<void> = new EventEmitter<void>();
  formCN000TMP!: FormGroup;
  formCN001TMP!: FormGroup;
  formCN002TMP!: FormGroup;
  categories: Array<ICatalogue> = [];
  subcategories: Array<ICatalogueSubCategory> = [];
  businessType: Array<ICatalogue> = [];
  businessTypeRead: Array<ICatalogue> = [];
  subCategorySelected: Array<ICatalogueSubCategory> = [];
  segments: Array<ICatalogue> = [];
  accounts: Array<ICatalogue> = [];
  type: string = 'RUC';
  constructor(
    private configDataService: ConfigurationDataService,
    private cataloguesService: CataloguesService
  ) {}

  ngOnInit(): void {
    this.subCategorySelected = [];
    this.initData();
  }

  initData() {
    this.getDataCatalogs();
    this.search.disable();
  }

  async getDataCatalogs() {
    this.formCN001TMP = _.cloneDeep(this.formCN001);
    this.formCN002TMP = _.cloneDeep(this.formCN002);
    const categories$ = this.cataloguesService.getCategories();
    const segments$ = this.cataloguesService.getSegments();
    const accountTypes$ = this.cataloguesService.getAccountTypes();
    const businessType$ = this.cataloguesService.getBusinessType();
    const subcategory$ = this.cataloguesService.getSubcategory();

    forkJoin([
      categories$,
      segments$,
      accountTypes$,
      businessType$,
      subcategory$,
    ]).subscribe(
      ([categories, segments, accountTypes, businessType, subcategory]) => {
        this.categories = categories;
        this.segments = segments;
        this.accounts = accountTypes;
        this.businessType = businessType;
        this.subcategories = subcategory;
        this.getSubCategories();
        if (this.isEditable && this.isFlowUpdate) {
          this.setStateReable();
        }
      }
    );
  }

  getSubCategories() {
    this.subCategorySelected = [];
    if (this.formCN002.controls['category']) {
      this.subCategorySelected = this.subcategories.filter(
        (x) => x.category === this.formCN002.get('category')?.value?.code
      );
    }
  }

  onCancelEdit() {
    this.enableEdit = false;
    this.formCN001.patchValue(this.formCN001TMP.getRawValue());
    this.formCN002.patchValue(this.formCN002TMP.getRawValue());
    this.setStateReable();
  }

  handleEvent(data: boolean) {
    if (data) {
      this.enableEdit = true;
      this.formCN001.enable();
      this.formCN002.enable();
      const business = this.businessType.find(
        (item) => item.name === this.formCN002.controls['businessType']?.value
      );
      const segment = this.segments.find(
        (item) => item.name === this.formCN002.controls['segment']?.value
      );
      this.formCN002.get('businessType')?.setValue(business?.code);
      this.formCN002.get('segment')?.setValue(segment?.code);
      this.formCN002
        .get('category')
        ?.setValue(this.formCN002TMP.controls['category'].value);
      this.formCN002
        .get('subcategory')
        ?.setValue(this.formCN002TMP.controls['subcategory'].value);
    }
  }

  submit() {
    this.loading = true;
    this.formCN001.markAllAsTouched();
    this.formCN002.markAllAsTouched();
    if (!this.formCN001.valid || !this.formCN002.valid) {
      this.loading = false;
      return;
    }
    return Promise.allSettled([
      this.configDataService.updateCN001Configuration(),
      this.configDataService.updateConfiguration('CN002'),
    ])
      .then(() => {
        this.formCN001TMP = _.cloneDeep(this.formCN001);
        this.formCN002TMP = _.cloneDeep(this.formCN002);
        this.emitToast();
        this.setStateReable();
        this.loading = false;
        this.enableEdit = false;
      })
      .catch((err) => console.error({ err }));
  }

  onChangeCategorySelect() {
    this.formCN002.get('subcategory')?.setValue('');
    this.getSubCategories();
  }

  setStateReable() {
    const business = this.businessType.find(
      (item) => item.code === this.formCN002.controls['businessType']?.value
    );
    const segment = this.segments.find(
      (item) => item.code === this.formCN002.controls['segment']?.value
    );
    this.formCN002.get('businessType')?.setValue(business?.name);
    this.formCN002.get('segment')?.setValue(segment?.name);
    this.formCN002
      .get('category')
      ?.setValue(this.formCN002.controls['category'].value?.name);
    this.formCN002
      .get('subcategory')
      ?.setValue(this.formCN002.controls['subcategory'].value?.name);
    this.formCN000.disable();
    this.formCN001.disable();
    this.formCN002.disable();
  }

  emitToast() {
    this.toastEvent.emit();
  }
}
