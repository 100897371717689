<deuna-loading [titulo]="'Cargando información'" *ngIf="load" [subtitulo]="'Por favor, espera un momento'">
</deuna-loading>
<div class="content-app">
  <div class="grid mt-2 mx-5">
    <div class="col-12 py-5">
      <span class="text-style-back mt-2" routerLink="{{relativeRoute+'/list'}}">Negocios afiliados</span>
      <span class="text-style-back mt-2" style="text-decoration: none; cursor: auto"> / </span>
      <span class="text-style-back mt-2" routerLink="{{relativeRoute+'/info/'+this.merchant.id}}">
        {{this.merchant.comercialName}}</span>
      <span class="text-style-back mt-2" style="text-decoration: none; cursor: auto">
        / Configuración general</span>
    </div>
    <div class="col-12">
      <div class="grid justify-center">
        <span class="titles">Configuración general</span>
      </div>
    </div>
    <div class="col-12 mb-3">
      <deuna-tab-menu [items]="items" [activeItem]="activeItem"></deuna-tab-menu>
    </div>
    <div class="col-3 col-offset-9 mb-3">
      <deuna-button
        [label]="'Agregar nuevo usuario'"
        deUnaClass="py-3 mr-5"
        (click)="addNewAccount()"
      >
        <div class="icon">
          <i class="material-icons material-icons-outlined" aria-hidden="true">{{ "add" }}</i>
        </div>
      </deuna-button>
    </div>
  
    <app-list-accounts class="w-full" *ngIf="activeItem.id == 'accounts'"></app-list-accounts>
  </div>
</div>