import { ErrorStateService } from "../state/global-error.state.service";

export const handleCustomControlledError = (extensions: any, errorStateService: ErrorStateService) => {
    const { errors } = extensions;
    const error = errors?.[0];
    if (errors) {
      if (error?.customError) {
        errorStateService.setError(error.customError);
      }
    }
  }