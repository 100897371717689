<app-exit-modal-report [displayModal]="displayModal" (onAccept)="onAccept()" (onHide)="onHide()" [exitModalIcon]="'emptyBranchReportInactive'" [exitModalTitle]="'¿Quieres abandonar el proceso?'" [exitModalDescription]="'Al seleccionar “Sí”, se eliminará toda la información 
registrada del reporte programado hasta el momento.'" [exitAcceptActionMessage]="'Sí'" [exitCancelActionMessage]="'No, continuar editando'"></app-exit-modal-report>
<deuna-loading *ngIf="loading" [titulo]="'Creando reporte programado'" [subtitulo]="'Por favor, espera un momento'"></deuna-loading>
<app-empty-list-return *ngIf="isErrorCreatingReport" title="Ocurrió un problema al crear tu reporte programado" subtitle="Por favor, espera un momento para volver a intentar." [buttonCancel]="true" [buttonAccept]="true" textButtonAccept="Ir a reportes programados" [img]="createReportErrorImage" textButtonCancel="Volver al inicio" (onSubmitAccept)="onSubmitErrorAccept()" (onSubmitCancel)="onSubmitErrorCancel()"></app-empty-list-return>
<form *ngIf="!(isErrorCreatingReport || loading)" (ngSubmit)="onSubmit()" class="grid my-5">
    <div class="col-8 col-offset-2 ">
        <div class="mb-5">
            <div class="text-style-back" [routerLink]="[relativeRoute+'/report/create/report-initial', merchantId]">Regresar</div>
        </div>
        <app-load-step [stepLoad]="75"></app-load-step>
    </div>
    <div class="col-8 col-offset-2">
        <app-card header="Configuración del reporte por sucursal" subHeader="Asigna la frecuencia, horario y destinatarios del reporte por cada sucursal.">
            <deuna-upload-file [description]="'Archivo para configurar reportes por sucursal'" [titleButton]="'Cargar Excel'" [showDownloadTemplate]="true" [staticUrlDownload]="programmedReportTemplate" [staticTemplateMessage]="'¿Quieres obtener el formato de configuración de reporte por sucursales?'" [status]="uploadFileStatus" [stepLoad]="uploadFileProgress" [errorTitle]="uploadErrorTitle" [details]="uploadErrorDetails" [errorMessage]="uploadErrorMessage" (onFileUploaded)="onFileUploaded($event)"></deuna-upload-file>
        </app-card>
    </div>
    <div class="col-4 col-offset-6 pt-4">
        <div class="grid">
            <div class="col-6">
                <deuna-button label="Cancelar" deUnaClass="p-button-outlined" (eventClick)="openModal()">
                </deuna-button>
            </div>
            <div class="col-6">
                <deuna-button label="Crear reporte" type="submit" [disabled]="uploadFileStatus !== 'success'"></deuna-button>
            </div>
        </div>
    </div>
</form>