import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { assetUrl } from '@deuna/bo-sh-helpers-ng';
import { ICatalogue } from 'src/app/shared/interfaces/calatogue.interface';
import {
  OptionLevelSendType,
  optionsLevelSend,
} from 'src/app/constants/catalogos/mocks-catalogo';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { clientRoute } from 'src/app/constants/routes';
import { ConfigurationFlowReportService } from 'src/app/services/config/report/configuration-flow-report.service';
import { environment } from 'src/environments/environment';

const ROUTES_BY_REPORT_LEVEL: Record<OptionLevelSendType, string> = {
  UNIFIED: `${clientRoute}/report/create/report-sales-unified`,
  BRANCH: `${clientRoute}/report/create/report-sales-branch`,
};

@Component({
  selector: 'app-step-initial-reports',
  templateUrl: './step-initial.page.html',
})
export class StepInitialPage implements OnInit {
  relativeRoute = clientRoute;
  formStepInitial!: FormGroup;
  displayModal: boolean = false;
  loading: boolean = false;
  merchantId: string | null = null;
  subActivatedRoute!: Subscription;

  levelSend: Array<ICatalogue> = optionsLevelSend.filter(
    ({ code }) => environment.enableBranchReport || code !== 'BRANCH'
  );
  optionConciliation: string = 'CONSOLIDATE';
  optionSales: string = 'SALES';
  money: string = assetUrl('svg/money.svg');
  reload: string = assetUrl('svg/reload.svg');

  constructor(
    private configurationFlowReportService: ConfigurationFlowReportService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    const forms = this.configurationFlowReportService.getForm();
    this.formStepInitial = forms.initial;
    this.subActivatedRoute = this.activatedRoute.paramMap.subscribe(
      (params) => {
        this.merchantId = params.get('merchantId');
        if (this.merchantId) {
          this.formStepInitial.get('merchantId')?.setValue(this.merchantId);
        }
      }
    );
  }

  onSubmit() {
    this.formStepInitial.markAllAsTouched();
    if (!this.formStepInitial.valid) return;

    const reportLevel = environment.enableBranchReport
      ? (this.formStepInitial.get('level')?.value as OptionLevelSendType)
      : 'UNIFIED';

    this.router.navigate([
      ROUTES_BY_REPORT_LEVEL[reportLevel],
      this.merchantId,
    ]);
  }

  openModal() {
    this.displayModal = true;
  }

  onAccept() {
    this.router.navigate([`${clientRoute}/info`, this.merchantId], {
      queryParams: { tab: 'reportsProgram' },
    });
    this.configurationFlowReportService.resetForm();
  }

  onHide() {
    this.displayModal = false;
  }

  selectCard(option: string) {
    this.formStepInitial.get('type')?.setValue(option);
  }

  ngOnDestroy() {
    this.subActivatedRoute.unsubscribe();
  }
}
