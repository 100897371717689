import {
  ICatalogue,
  ICatalogueCard,
} from '../../shared/interfaces/calatogue.interface';
import { ClientType } from '../merchant-constants';

export enum CodeType {
  COMMERCE_GROUP = 'G',
  MERCHANT = 'M',
}
export const options: Array<ICatalogue> = [
  { code: '1', name: 'Si' },
  { code: '0', name: 'No' },
];

export const optionsExcel: Array<ICatalogue> = [
  { code: 'SI', name: 'Sí, enviar formulario' },
  { code: 'NO', name: 'No, prefiero completarlo yo' },
];

export const optionsTypeNotification: Array<ICatalogue> = [
  { code: 'email', name: 'Correo' },
  { code: 'sms', name: 'SMS' },
];

export const optionsTypeNotificationGeneral: Array<ICatalogue> = [
  { code: 'email', name: 'Correo asociado a Deuna!' },
  { code: 'sms', name: 'SMS al celular asociado a Deuna!' },
  { code: 'webhook', name: 'Por integración al sistema del negocio' },
];

export const defaultCountry: ICatalogue = {
  code: '593',
  name: 'ECUADOR +593',
};

export const clientTypeOptions: Array<ICatalogue> = [
  { code: ClientType.JURIDICAL, name: 'Jurídico' },
];

export const clientTypeNatural = { 
  code: ClientType.NATURAL, name: 'Natural' 
}

export const createTypeOptions: Array<ICatalogueCard> = [
  {
    code: CodeType.COMMERCE_GROUP,
    name: 'Grupo empresarial',
    description:
      'Empresa que gestiona 1 o más negocios. Puede heredar datos como cuentas, reportes, etc.',
    icon: 'group_work',
    disabled: false,
  },
  {
    code: CodeType.MERCHANT,
    name: 'Negocio individual',
    description:
      'Negocio independiente con sucursales y cajas. Puede o no pertenecer a un grupo.',
    icon: 'store',
    disabled: false,
  },
];

export const optionsTypesQr: Array<ICatalogue> = [
  { code: 'QRP', name: 'QR con plantilla Deuna!' },
  { code: 'QRSP', name: 'QR sin plantilla Deuna!' },
];

export const defaultTypeQr: ICatalogue = {
  code: 'QRP',
  name: 'QR con plantilla Deuna!',
};

export const optionsSizesQr: Array<ICatalogue> = [
  { code: '5X5', name: '5cm x 5cm' },
  { code: '10X10', name: '10cm x 10cm' },
];

export const defaultSizeQr: ICatalogue = {
  code: '5X5',
  name: '5cm x 5cm',
};

export const optionsLevelSend: Array<ICatalogue> = [
  {
    code: 'UNIFIED',
    name: 'Unificado: Todas las ventas del negocio con Deuna en un solo reporte',
  },
  {
    code: 'BRANCH',
    name: 'Por sucursal: Reportes individuales por cada punto de venta a diferentes destinatarios',
  },
];
export type OptionLevelSendType = 'UNIFIED' | 'BRANCH';

export const optionsTypesSend: Array<ICatalogue> = [
  {
    code: 'CONSOLIDATE',
    name: 'Consolidado',
  },
  {
    code: 'SALES',
    name: 'Ventas',
  },
];

export const optionsFrequency: Array<ICatalogue> = [
  {
    code: '1',
    name: 'Diaria',
  },
  {
    code: '7',
    name: 'Semanal',
  },
  {
    code: '15',
    name: 'Quincenal',
  },
  {
    code: '30',
    name: 'Mensual',
  },
];

export const optionsDays: Array<ICatalogue> = [
  {
    code: '1',
    name: 'Lunes',
  },
  {
    code: '2',
    name: 'Martes',
  },
  {
    code: '3',
    name: 'Miercoles',
  },
  {
    code: '4',
    name: 'Jueves',
  },
  {
    code: '5',
    name: 'Viernes',
  },
  {
    code: '6',
    name: 'Sabados',
  },
  {
    code: '7',
    name: 'Domingo',
  },
];
