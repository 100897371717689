<app-card
  header="Uso y tratamiento de datos"
  subHeader="Indica las autorizaciones y fechas otorgadas por el negocio para el uso y tratamiento sus datos."
  [isFlowUpdate]="isFlowUpdate"
  [isEditable]="isEditable"
  (customEvent)="handleEvent($event)"
  ><deuna-calendar
    [onlyPastDates]="true"
    [form]="formCN015"
    control="permissionGrantingDate"
    name="permissionGrantingDate"
    label="Fecha otorgamiento de permiso"
  ></deuna-calendar>
</app-card>
