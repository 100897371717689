import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import { assetUrl } from '@deuna/bo-sh-helpers-ng';
import { MenuItem, MessageService } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { Subscription, debounceTime } from 'rxjs';
import { clientRoute } from 'src/app/constants/routes';
import {
  downloadFile,
  formatDate,
  removeSpecialCharacters,
} from 'src/app/shared/utils/helpers';
import { GraphqlMerchantService } from '../../../../../services/graphql/graphql-merchant.service';
import { IFrameSide } from '../../../../../shared/interfaces/frame-side.interface';
import {
  Branch,
  IQueryBranch,
  Meta,
  POSList,
  ResponseBranch,
} from '../../../../../shared/interfaces/query-branchs';
import { MerchantItem as Merchant } from '../../../../../shared/interfaces/query-merchants';

@Component({
  selector: 'app-tab-branchs',
  templateUrl: './tab-branchs.component.html',
  styleUrls: ['./tab-branchs.component.scss'],
})
export class TabBranchsComponent implements OnInit {
  @Output() openSide = new EventEmitter();
  @Output() showModalDeactivateNodes = new EventEmitter<{
    type: string;
    id: number;
  }>();
  @Input() merchant!: Merchant;
  relativeRoute = clientRoute;
  inputSub: any;
  valueChanged: any;
  value: any;
  subActivatedRoute!: Subscription;

  imgNotFoundMerchants: string = assetUrl('png/checklist.png');
  formSearch!: FormGroup;
  menuButtonBranch: Array<MenuItem> = [];
  subMenuButtonBranch!: Menu;
  menuButtonDownload: Array<MenuItem> = [];
  subMenuButtonDownload!: Menu;
  rowsExpands: number = -1;
  subGraphqlMerchantService!: Subscription;
  paramsQuery!: IQueryBranch;
  currentPage: number = 1;
  limitPage: number = 7;
  loadListBranchs: boolean = true;
  merchantId: string = '';

  frameUpdate: IFrameSide = {
    title: 'Carga masiva de sucursales',
    view: 'upload',
  };

  frameQr: IFrameSide = {
    title: 'Recursos',
    view: 'viewQr',
  };

  branchsList!: Array<Branch>;
  dataPagination!: Meta;
  resultfields: string = `
  type
  name
  id
  email
  cellphone
  POSList{
      id
      nodeType
  }`;

  headers = [
    '',
    'Tipo',
    'Nombre',
    'ID',
    'Correo',
    'Celular',
    'Recursos',
    'Editar',
    'Eliminar',
  ];

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly serviceMerchantsGql: GraphqlMerchantService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.createConfigGetInitial();
  }

  ngAfterContentInit() {
    this.inputSub = this.formSearch.controls['textSearch'].valueChanges
      .pipe(debounceTime(1500))
      .subscribe((value: string) => {
        this.value = value;
        this.paramsQuery.page = 1;
        this.getBranch();
      });
  }

  createConfigGetInitial() {
    this.subActivatedRoute = this.activatedRoute.paramMap.subscribe(
      (params) => {
        const id = params.get('merchantId');
        if (id) {
          this.paramsQuery = {
            page: this.currentPage,
            limit: this.limitPage,
            clientId: id,
          };
          this.merchantId = id;
          this.getBranch();
        }
      }
    );
    this.menuButtonBranch = [
      {
        id: 'excel',
        label: 'Carga masiva por Excel',
        command: () => {
          this.openSide.emit(this.frameUpdate);
        },
      },
      {
        id: 'add',
        label: 'Carga una sucursal',
        routerLink: `${clientRoute}/branch/create/step-branch/${this.merchantId}`,
      },
    ];
    this.menuButtonDownload = [
      {
        id: 'qrs',
        label: 'Todos los QRs',
        icon: 'pi pi-qrcode',
        command: () => {
          this.clickOpen(this.frameQr);
        },
      },
      {
        id: 'deeplinks',
        label: 'Todos los deeplinks',
        icon: 'pi pi-link',
        command: () => {
          this.downloadDocument();
        },
      },
    ];
    this.formSearch = this.fb.group({
      textSearch: [''],
    });
  }

  registerSubmenuBranch(subMenuButton: any) {
    this.subMenuButtonBranch = subMenuButton;
  }

  registerSubmenuDownload(subMenuButton: any) {
    this.subMenuButtonDownload = subMenuButton;
  }

  selectBranch(event: any, brach: any) {
    if (event.checked.length > 0) {
      console.log(brach);
    }
  }

  goToSearchKeys(event: any) {
    this.valueChanged.next(event);
  }

  goToPageBranch(page: number) {
    this.paramsQuery.page = page;
    this.getBranch();
  }

  changeToItemsBranch(page: number) {
    this.paramsQuery.page = 1;
    this.paramsQuery.limit = page;
    this.getBranch();
  }

  getBranch() {
    this.branchsList = [];
    this.loadListBranchs = true;
    this.paramsQuery.status = 'A';
    this.subGraphqlMerchantService = this.serviceMerchantsGql
      .getBranchs(this.paramsQuery, this.resultfields)
      .subscribe({
        next: (res: ApolloQueryResult<ResponseBranch>) => {
          if (res.data.getAllBrachOffice.items) {
            const { items, meta } = res.data.getAllBrachOffice;
            this.branchsList = items;
            this.dataPagination = meta;
          }
          this.loadListBranchs = false;
        },
        error: () => {
          this.loadListBranchs = false;
          this.router.navigate([`${clientRoute}/list`]);
        },
      });
  }

  clickOpen(
    frame: IFrameSide,
    branch: Branch | null = null,
    pos: POSList | null = null
  ) {
    frame.extra = null;
    if (branch) {
      if (pos) {
        frame.extra = {
          ...branch,
          POSList: [pos],
        };
      } else {
        frame.extra = { ...branch };
      }
    }
    this.openSide.emit(frame);
  }

  clickTrash(branch) {
    this.showModalDeactivateNodes.emit({
      type: branch.__typename,
      id: +branch.id,
    });
  }

  ngOnDestroy() {
    this.subGraphqlMerchantService.unsubscribe();
    this.subActivatedRoute.unsubscribe();
  }

  showErrorToast() {
    this.messageService.clear('info');
    this.messageService.add({
      severity: 'error-deuna',
      summary: 'Descarga Fallida',
      key: 'faild',
      closable: true,
      icon: 'close',
    });
  }
  downloadDocument() {
    const nameFile = `Deuna_${removeSpecialCharacters(
      this.merchant.comercialName
    )}_deeplinks_${formatDate(new Date())}`;
    this.messageService.add({
      severity: 'deuna',
      summary: `Descargando "${nameFile}"`,
      closable: false,
      icon: 'refresh',
      key: 'info',
    });
    this.serviceMerchantsGql
      .generateDeeplinksBookFile({
        businessName: this.merchant.businessName,
        comercialName: this.merchant.comercialName,
        identification: this.merchant.identification!,
        merchantId: this.merchantId,
      })
      .subscribe({
        next: (data) => {
          if (data.error || data.errors) this.showErrorToast();
          const base64 = data.data.generateDeeplinksBookFile.base64File;
          downloadFile(base64, nameFile, 'excel');
          this.messageService.add({
            severity: 'deuna',
            summary: `Descarga exitosa "${nameFile}"`,
            closable: true,
            icon: 'check',
            key: 'info',
          });
        },
        error: () => {
          this.showErrorToast();
        },
      });
  }
}
