<form (ngSubmit)="onSubmit()">
  <app-card
    header="Configuración general de notificaciones"
    [subHeader]="
      isCreationFlow
        ? 'Define cómo deseas notificar al negocio cuando reciba un pago. La notificación llevará el nombre de la empresa y sucursal.'
        : ''
    "
    [isFlowUpdate]="isFlowUpdate"
    [isEditable]="isEditable"
    (customEvent)="handleEvent($event)"
  >
    <div *ngIf="!enableEdit">
      <div class="grid">
        <div class="col-6">
          <span class="title"
            >¿Cómo quieres notificar al negocio cuando reciba pagos?</span
          >
        </div>
      </div>

      <span class="bold-input">{{ notification }}</span>
      <span class="line"></span><br />
      <div *ngIf="webhook">
        <deuna-input
          [masked]="false"
          styleClass="mt-4"
          [form]="formCN005"
          label="Correo del negocio para envío de credenciales de API"
          [maxLength]="50"
          name="webhookEmail"
          control="webhookEmail"
        ></deuna-input>
        <span
          *ngIf="
            formCN005.get('webhookEmail')?.hasError('email') &&
            formCN005.get('webhookEmail')?.touched
          "
          class="p-error block"
        >
          Email incorrecto
        </span>
        <deuna-input
          [masked]="false"
          styleClass="mt-4"
          [form]="formCN005"
          label="Inserta la url del Webhook"
          name="webhookUrl"
          [maxLength]="200"
          control="webhookUrl"
        ></deuna-input>
        <br />
      </div>
    </div>
    <div *ngIf="enableEdit">
      <deuna-checklist
        [options]="notifications"
        [form]="formCN006"
        label="¿Cómo quieres notificar al negocio cuando reciba pagos?"
        name="notification1"
        control="notification1"
        (eventOnChange)="onChange()"
      ></deuna-checklist>
      <div *ngIf="webhook" [formGroup]="formCN005">
        <deuna-input
          [masked]="false"
          *ngIf="webhook"
          styleClass="mt-4"
          [form]="formCN005"
          label="Correo del negocio para envío de credenciales de API"
          [maxLength]="200"
          name="webhookEmail"
          control="webhookEmail"
        ></deuna-input>
        <span
          *ngIf="
            formCN005.get('webhookEmail')?.hasError('email') &&
            formCN005.get('webhookEmail')?.touched
          "
          class="p-error block"
        >
          Email incorrecto
        </span>
        <deuna-input
          [masked]="false"
          *ngIf="webhook"
          styleClass="mt-4"
          [form]="formCN005"
          label="Inserta la url del webhook"
          placeholder="Ej: http://webhook.com"
          [maxLength]="200"
          name="webhookUrl"
          control="webhookUrl"
        ></deuna-input>
      </div>
    </div>

    <div *ngIf="webhook" [formGroup]="formCN005">
      <label *ngIf="enableEdit">Credenciales (Opcional)</label>
      <div style="padding-top: 30px" formArrayName="webhookHeaders">
        <div *ngFor="let field of webhookHeadersFields.controls; index as i">
          <div class="grid field" formGroupName="{{ i }}">
            <div class="col-6">
              <span> Clave </span>
              <input
                [ngClass]="{
                  'input-edit': !enableEdit,
                  'ng-invalid': isValidField(i, 'value')
                }"
                id="key{{ i + 1 }}"
                formControlName="key"
                styleClass="p-inputtext"
                type="string"
                aria-describedby="input"
                autocomplete="off"
                pInputText
              />
              <small
                *ngIf="isValidField(i, 'key') && isTouched(i, 'key')"
                class="p-error block"
              >
                Dato
                <span *ngIf="hasError(i, 'required', 'key')">
                  obligatorio
                </span>
              </small>
            </div>
            <div></div>
            <div class="col-5">
              <span> Valor </span>
              <input
                [ngClass]="{
                  'input-edit': !enableEdit,
                  'ng-invalid': isValidField(i, 'value')
                }"
                id="value{{ i + 1 }}"
                formControlName="value"
                styleClass="p-inputtext"
                [type]="enableEdit ? 'string' : 'password'"
                aria-describedby="input"
                autocomplete="off"
                pInputText
              />
              <small
                *ngIf="isValidField(i, 'value') && isTouched(i, 'value')"
                class="p-error block"
              >
                Dato
                <span *ngIf="hasError(i, 'required', 'value')">
                  obligatorio
                </span>
              </small>
            </div>
            <i
              id="mask{{ i + 1 }}"
              style="align-self: center; padding-top: 15px; cursor: pointer"
              *ngIf="!enableEdit"
              (click)="toggleMasked(i + 1)"
              class="material-icons material-icons-outlined masked-eye"
              aria-hidden="true"
              >{{ "visibility" }}</i
            >
            <div
              class="col-1"
              style="align-self: center; padding-top: 15px"
              *ngIf="enableEdit"
            >
              <img
                *ngIf="enableEdit"
                [src]="trash"
                alt="trash"
                style="cursor: pointer"
                (click)="removeRow(i)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="grid" *ngIf="enableEdit">
        <div class="col-6">
          <span
            (click)="addRow()"
            class="text-style-back"
            *ngIf="webhookHeadersFields.controls.length < 10; else notAdd"
          >
            <div class="icon">
              <i
                aria-hidden="true"
                class="material-icons material-icons-outlined"
                >add_circle</i
              >
              Agregar credenciales
            </div>
          </span>
          <ng-template #notAdd>
            <span class="text-info">
              * Solo puedes agregar hasta a 10 credenciales
            </span>
          </ng-template>
        </div>
      </div>
    </div>

    <span *ngIf="enableEdit && !isCreationFlow" class="line"></span><br />
    <div
      *ngIf="
        (isFlowUpdate && isEditable && !isFlowUpdate) ||
        (enableEdit && !isCreationFlow)
      "
      class="col-offset-6 pt-4"
    >
      <app-update-button-footer
        [loading]="loading"
        (eventSubmit)="onSubmit()"
        (eventCancel)="onCancelEdit()"
      ></app-update-button-footer>
    </div>
  </app-card>
</form>
