<container-element [ngSwitch]="tableStatus">
  <div class="col-12 text-center" *ngSwitchCase="'LOADING'">
    <p-progressBar
      mode="indeterminate"
      [style]="{ height: '6px' }"
    ></p-progressBar>
    <h2 class="mt-2">Cargando...</h2>
  </div>
  <div className="grid mx-5" *ngSwitchCase="'COMPLETE'">
    <div class="col-12 mx-5">
      <deuna-table [arrayData]="[1]" [columnas]="columns">
        <ng-container *ngFor="let merchant of merchantsList">
          <tr>
            <deuna-table-cell style="cursor: pointer">
              <div class="grid my-autoSipo">
                <div class="my-auto p-2 box-icon">
                  <img
                    [src]="avatar"
                    alt="image"
                    style="width: 3rem; height: 3rem"
                  />
                </div>
                <div
                  class="col my-auto"
                  style="text-decoration: none; color: black"
                >
                  {{ merchant.comercialName }}
                </div>
              </div>
            </deuna-table-cell>
            <deuna-table-cell> {{ merchant.identification }} </deuna-table-cell>

            <deuna-table-cell> {{ merchant.businessName }} </deuna-table-cell>
          </tr>
        </ng-container>
      </deuna-table>
    </div>
  </div>
</container-element>
