import { MessageService } from 'primeng/api';
import { IFormGroupReport } from '../../../shared/interfaces/form-group.interface';
import { Injectable } from '@angular/core';
import { ConfigurationFlowReportFormService } from './configuration-flow-form-report.service';
import { GraphqlReportService } from '../../graphql/graphql-report.service';
import {
  IReport,
  IUpdateRequestReport,
  IUpdateResponseReport,
} from 'src/app/shared/interfaces/query-reports';
import { ApolloQueryResult } from '@apollo/client';
import { MutationResult } from 'apollo-angular';
import { FormArray, FormBuilder, Validators } from '@angular/forms';

@Injectable()
export class ConfigurationFlowReportDataService {
  public resultfields: string = `merchantId
    id
    name
    type
    daysFrequency
    level
    cutOffDay
    cutOffTime
    mails
    status`;

  public formGroupDataReport!: IFormGroupReport;

  public configurationReport!: IReport;

  public setEmailValidation = {
    mail: [
      '',
      [
        Validators.required,
        Validators.maxLength(50),
        Validators.email
      ],
    ],
  }

  constructor(
    private graphqlReportService: GraphqlReportService,
    private configurationFlowReportFormService: ConfigurationFlowReportFormService,
    private fb: FormBuilder,
    private readonly messageService: MessageService,
  ) {
    this.getForm();
  }

  getForm(): IFormGroupReport {
    if (this.formGroupDataReport) {
      return this.formGroupDataReport;
    }
    this.formGroupDataReport =
      this.configurationFlowReportFormService.getForm();
    return this.formGroupDataReport;
  }

  async setFormDataReport(reportId: string) {
    this.getForm();
    await this.getReportByIdService(reportId);
    this.setFormReport(this.configurationReport);
  }

  async getReportByIdService(reportId: string) {
    return new Promise<void>((resolve) => {
      this.graphqlReportService
        .getReportById(reportId, this.resultfields)
        .subscribe({
          next: (res: ApolloQueryResult<IReport>) => {
            this.configurationReport = res.data['getReport'];
            resolve();
          },
          error: (err) => {
            console.log(err);
          },
        });
    });
  }

  setFormReport(report: IReport) {
    this.formGroupDataReport.initial.controls['merchantId'].setValue(
      report.merchantId
    );
    this.formGroupDataReport.initial.controls['nameReport'].setValue(
      report.name
    );
    this.formGroupDataReport.initial.controls['type'].setValue(report.type);
    this.formGroupDataReport.initial.controls['level'].setValue(report.level);

    this.formGroupDataReport.frequency.controls['daysFrequency'].setValue(
      report.daysFrequency
    );
    this.formGroupDataReport.frequency.controls['cutOffTime'].setValue(
      report.cutOffTime
    );
    this.formGroupDataReport.frequency.controls['cutOffDay'].setValue(
      report.cutOffDay
    );
    const mailsArray = this.formGroupDataReport.frequency.get(
      'mails'
    ) as FormArray;
    while (mailsArray.length) {
      mailsArray.removeAt(0);
    }
    report.mails.forEach((mail) => {
      const row = this.fb.group(this.setEmailValidation);

      row.controls['mail'].setValue(mail);
      mailsArray.push(row);
    });
  }
  resetFormData() {
    this.formGroupDataReport.initial.reset();
    this.formGroupDataReport.frequency.reset();
    (this.formGroupDataReport.frequency.get('mails') as FormArray).clear();
  }

  async updateMailsReport(): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      const emailUpdatedReports = await this.extractEmailsFromForm();
      if (emailUpdatedReports === false) {
        this.showReportCreatedNotification('error', 'Correo duplicado', 'empty_icon');
        resolve(false);
        return;
      }
      const payload = this.buildUpdateRequest(emailUpdatedReports as Array<string>);
      this.graphqlReportService.updateReport(payload).subscribe({
        next: (result: MutationResult<IUpdateResponseReport>) => {
          if (result.data?.updateReport?.status) {
            this.showReportCreatedNotification('deuna', 'Guardado exitosamente');
            resolve(true);
          }
        },
        error: () => {
          this.showReportCreatedNotification('error', 'Error al guardar los cambios. Intenta de nuevo.', 'empty_icon');
          resolve(false);
        },
      });
    });
  }

  buildUpdateRequest(emails: string[]): IUpdateRequestReport {
    return {
      id: this.configurationReport.id,
      type: this.configurationReport.type,
      daysFrequency: this.configurationReport.daysFrequency,
      level: this.configurationReport.level,
      cutOffTime: this.configurationReport.cutOffTime,
      mails: emails,
    };
  }

  async extractEmailsFromForm(): Promise<string[] | false> {
    const form = this.formGroupDataReport;
    const mailsArray = form.frequency.controls['mails'] as FormArray;
    let emails: string[] = [];

    for (let control of mailsArray.controls) {
      const email = control.get('mail')!.value;
      if (emails.includes(email)) {
        return false;
      }
      emails.push(email);
    }

    return emails;
  }

  showReportCreatedNotification(severity: string, summary: string, icon: string = 'done') {
    this.messageService.add({
      key: 'global',
      severity,
      summary,
      closable: false,
      icon
    });
  }
}
