import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { MerchantRoles } from '@deuna/ng-bo-roles';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent  implements OnInit {
  @Input() header: string = '';
  @Input() subHeader: string = '';
  @Input() isFlowUpdate: boolean = false;
  @Input() isEditable: boolean = false;
  @Output() customEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  executorRoles = [MerchantRoles.MERCHANT_EXECUTOR];
  ngOnInit() {
    console.log("executorRoles", this.executorRoles)
    console.log("executorRoles", MerchantRoles.MERCHANT_EXECUTOR)
}

  emitEvent() {
    this.customEvent.emit(true);
  }
}
