<app-exit-modal-report [displayModal]="displayModal" (onAccept)="onAcceptCancel()" (onHide)="onHideModal()"></app-exit-modal-report>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <app-card header="Destinatarios" subHeader="Escribe el correo de las personas a las que enviarás el reporte programado">
        <ng-container *ngIf="isEditable else visualizeEmails">
            <div formArrayName="mails">
                <div class="grid" *ngFor="let field of records.controls; index as i">

                    <div class="field col-5" formGroupName="{{i}}">
                        <label for="mail{{i+1}}" class="block labelInput">Correo {{i+1}}</label>
                        <input id="mail{{i+1}}" value="{{field.value.mail}}" formControlName="mail" styleClass="p-inputtext" type="string" aria-describedby="input" pInputText [class]="
                        {
                            'ng-invalid': (isValidRecord(i) && isDirtyRecord(i) || isTouchedRecord(i)),
                            'ng-dirty':(isValidRecord(i) && isDirtyRecord(i) || isTouchedRecord(i))
                        }" />
                        <small *ngIf="(isValidRecord(i) && (isDirtyRecord(i) || isTouchedRecord(i)))" class="p-error block">

                          <container-element
                            [ngSwitch]="getRecordErrors(i)"
                          >
                          <span *ngSwitchCase="'required'">
                            Dato obligatorio
                          </span>

                            <span *ngSwitchCase="'maxlength'">
                              Revisa que el correo tenga menos de 50 caracteres
                          </span>
                            <span *ngSwitchCase="'email'">
                              Usa un formato válido como nombre@ejemplo.com
                          </span>
                          </container-element>
                        </small>
                    </div>
                    <div class="col-1" style="align-self: center; margin-top: 13px;" *ngIf="i != 0">
                        <deuna-button deUnaClass="p-button-rounded p-button-text with-auto p-button-help"
                        (eventClick)="removeRowForm(i)">
                            <div class="icon">
                                <i aria-hidden="true" class="material-icons material-icons-outlined ">delete</i>
                            </div>
                      </deuna-button>
                    </div>
                </div>

                <div class="grid">
                    <div class="col-6">
                        <span (click)="addNewRow()" class="text-style-back" *ngIf="records.controls.length < maximumAllowedEmails; else notAdd">
                            <div class="icon">
                                <i aria-hidden="true" class="material-icons material-icons-outlined ">add_circle</i> Agregar correo
                            </div>
                        </span>
                        <ng-template #notAdd>
                            <span class="text-info">
                                * Solo puedes enviar hasta a {{maximumAllowedEmails}} correos
                            </span>
                        </ng-template>
                    </div>
                </div>
                <div *ngIf="isFlowUpdate" class="col-offset-8 pt-4">
                    <div class="grid">
                        <div class="col-6">
                            <deuna-button label="Cancelar" deUnaClass="p-button-outlined" (eventClick)="openModalCancel()">
                            </deuna-button>
                        </div>

                        <div class="col-6">
                            <deuna-button [label]="loading? 'Guardando': 'Guardar'" type="submit" [loading]="loading"></deuna-button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #visualizeEmails>
            inplementar cuando sea visualizacion
        </ng-template>
        <span class="line"></span><br>
    </app-card>
</form>
