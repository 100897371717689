import { Injectable } from '@angular/core';
import { Apollo, ApolloBase, MutationResult, gql } from 'apollo-angular';
import { Observable, delay, of, switchMap, throwError, timer } from 'rxjs';
import { IConfigCN000 } from 'src/app/shared/interfaces/config-merchant.interfaces';
import { ResponseCreateCommercialGroup, ResponseValidateCommercialGroupRuc } from 'src/app/shared/interfaces/query-branchs';


const mockValidateCommercialGroupResponse = {
  data: {
    validateCommercialGroup: {
      response: true,
    },
  },
  loading: false,
};

const mockErrorResponse = {
  title: 'Ya existe un grupo afiliado con este RUC',
  message: 'Revisa si ingresaste correctamente el RUC e intenta otra vez.',
  details: '',
}

@Injectable()
export class GraphqlCommercialGroupService {
  public apollo: ApolloBase;

  constructor(private readonly apolloProvider: Apollo) {
    this.apollo = this.apolloProvider.use('newClientName');
  }

  createCommercialGroup(
    data: IConfigCN000
  ): Observable<MutationResult<ResponseCreateCommercialGroup>> {
    return this.apollo.mutate({
      mutation: gql`
        mutation createCommercialGroup($data: CreateCommercialGroupInput!) {
          createCommercialGroup(createGroupInput: $data) {
            id
            businessName
          }
        }
      `,
      variables: {
        data,
      },
    });
  }

  validateCommercialGroupRuc(
    ruc: string
  ): Observable<MutationResult<ResponseValidateCommercialGroupRuc>> {
    return this.apollo.query({
      query: gql`
        query validateCreationCommercialGroup(
          $input: ValidateCreationGroupInput!
        ) {
          validateCreationCommercialGroup(validateCreationGroupInput: $input) {
            response
          }
        }
      `,
      variables: {
        input: {
          ruc,
        },
      },
    });
  }
}


