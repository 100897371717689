<div class="col-12 text-center" *ngIf="loadData">
  <p-progressBar
    mode="indeterminate"
    [style]="{ height: '6px' }"
  ></p-progressBar>
  <h2 class="mt-2">Cargando....</h2>
</div>
<p-toast position="bottom-right" key="br" [baseZIndex]="10000">
  <ng-template let-message pTemplate="message">
    <div class="flex" style="flex: 1">
      <div class="text-center">
        <i
          class="material-icons material-icons-outlined"
          style="vertical-align: middle; padding-right: 4px"
          aria-hidden="true"
          >done</i
        >
        <span style="vertical-align: middle">{{ message.summary }}</span>
      </div>
    </div>
  </ng-template>
</p-toast>
<ng-container *ngIf="this.formConfigData && !loadData">
  <div class="col-8 col-offset-2">
    <app-card-general
      [isEditable]="isEditable"
      [isFlowUpdate]="isFlowUpdate"
      [formCN000]="CN000"
      [formCN001]="CN001"
      [formCN002]="CN002"
      [search]="search"
      (toastEvent)="showToast()"
    ></app-card-general>
  </div>
  <div class="col-8 col-offset-2">
    <app-card-commercial
      [isEditable]="isEditable"
      [isFlowUpdate]="isFlowUpdate"
      [formDataCN000]="CN000"
      (toastEvent)="showToast()"
    ></app-card-commercial>
  </div>
  <div class="col-8 col-offset-2">
    <app-card-address
      [isEditable]="isEditable"
      [isFlowUpdate]="isFlowUpdate"
      [formCN004]="CN004"
      [formCN005]="CN005"
      (toastEvent)="showToast($event)"
    ></app-card-address>
  </div>
  <div class="col-8 col-offset-2">
    <app-card-notification
      [isEditable]="isEditable"
      [isFlowUpdate]="isFlowUpdate"
      (toastEvent)="showToast($event)"
    ></app-card-notification>
  </div>
  <div class="col-8 col-offset-2">
    <app-card-source-support
      [isEditable]="isEditable"
      [isFlowUpdate]="isFlowUpdate"
      [formCN019]="CN019"
      (toastEvent)="showToast()"
    ></app-card-source-support>
  </div>
</ng-container>
