<app-card
  header="Dirección y contacto"
  [isFlowUpdate]="isFlowUpdate"
  [isEditable]="isEditable"
  (customEvent)="handleEvent($event)"
>
  <ng-container
    *ngIf="
      (isEditable && !isFlowUpdate) || enableEdit || enableEdit;
      else editRegion
    "
  >
    <deuna-radio-button
      label="Región"
      styleClassItem="pt-2"
      [values]="regions"
      control="region"
      [form]="formCN004"
    >
    </deuna-radio-button>
  </ng-container>
  <ng-template #editRegion>
    <deuna-input
      [masked]="false"
      styleClass="mt-4"
      [form]="formCN004"
      label="Región"
      name="region"
      control="region"
    ></deuna-input>
  </ng-template>
  <div class="grid mt-2">
    <ng-container
      *ngIf="(isEditable && !isFlowUpdate) || enableEdit; else visualizeRegion"
    >
      <div class="col-6">
        <deuna-select
          [options]="provinces"
          (onChangeSelect)="onChangeSelect()"
          optionLabel="name"
          [form]="formCN004"
          label="Provincia"
          name="province"
          control="province"
          placeholder="Selecciona una provincia"
        ></deuna-select>
      </div>
      <div class="col-6">
        <deuna-select
          [options]="cantonsSelect"
          [disabled]="cantonsSelect.length === 0"
          optionLabel="name"
          [form]="formCN004"
          label="Cantón"
          name="canton"
          control="canton"
          placeholder="Selecciona un cantón"
        ></deuna-select>
      </div>
    </ng-container>
    <ng-template #visualizeRegion>
      <div class="col-6">
        <deuna-input
          [masked]="false"
          styleClass="mt-4"
          [form]="formCN004"
          label="Provincia"
          name="province"
          control="province"
        ></deuna-input>
      </div>
      <div class="col-6">
        <deuna-input
          [masked]="false"
          styleClass="mt-4"
          [form]="formCN004"
          label="Cantón"
          name="canton"
          control="canton"
        ></deuna-input>
      </div>
    </ng-template>
  </div>
  <deuna-input
    [masked]="false"
    styleClass="mt-4"
    [form]="formCN004"
    label="Calle, avenida, vía o carretera principal"
    [maxLength]="100"
    placeholder="Ej: Av. Amazonas"
    name="streetPrincipal"
    control="streetPrincipal"
  ></deuna-input>
  <deuna-input
    [masked]="false"
    styleClass="mt-4"
    [form]="formCN004"
    [maxLength]="100"
    label="Calle secundaria (Opcional)"
    placeholder="Ej: Av. Gaspar de Villaroel"
    name="streetSecondary"
    control="streetSecondary"
  >
  </deuna-input>
  <deuna-input
    [masked]="false"
    styleClass="mt-4"
    [form]="formCN004"
    label="Nombre de edificio, número de local o dpto. (Opcional)"
    [maxLength]="100"
    placeholder="Ej: Edificio Gaia"
    name="houseNumber"
    control="houseNumber"
  ></deuna-input>
  <div class="grid">
    <div class="col-5">
      <ng-container
        *ngIf="(isEditable && !isFlowUpdate) || enableEdit; else visualizePhone"
      >
        <deuna-select
          [options]="phoneCodes"
          [disabled]="true"
          class="disabled-text"
          optionLabel="name"
          [form]="formCN005"
          label="País y código"
          name="phoneCode"
          control="phoneCode"
        ></deuna-select>
      </ng-container>
      <ng-template #visualizePhone>
        <deuna-input
          [masked]="false"
          styleClass="mt-4"
          [form]="formCN005TMP"
          [disabled]="true"
          label="País y código"
          name="phoneCode"
          control="phoneCode"
        ></deuna-input>
      </ng-template>
    </div>
    <div class="col-7">
      <deuna-input
        [masked]="false"
        styleClass="mt-4"
        [form]="formCN005"
        label="Número celular asociado a Deuna!"
        [maxLength]="10"
        placeholder="Ej: 738472482"
        name="phoneNumber"
        control="phoneNumber"
        appOnlyNumber
      >
      </deuna-input>
    </div>
  </div>
  <deuna-input
    [masked]="false"
    styleClass="mt-4"
    [form]="formCN005"
    label="Correo principal asociado a Deuna!"
    [maxLength]="50"
    placeholder="Ej: micorreo@mail.com"
    name="emailMerchant"
    control="emailMerchant"
  ></deuna-input>

  <div
    *ngIf="(isFlowUpdate && isEditable && !isFlowUpdate) || enableEdit"
    class="col-offset-6 pt-4"
  >
    <app-update-button-footer
      [loading]="loading"
      (eventSubmit)="submit()"
      (eventCancel)="onCancelEdit()"
    ></app-update-button-footer>
  </div>
</app-card>
