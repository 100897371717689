import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { assetUrl } from '@deuna/bp-sh-helpers-ng';
import { Subscription } from 'rxjs';
import { clientRoute } from 'src/app/constants/routes';

@Component({
  selector: 'app-step-4',
  templateUrl: './step-4.page.html',
})
export class Step4Page implements OnInit {
  header: string = '';
  subHeader: string = '';
  routesBack: string[] = [`${clientRoute}/list`];
  routesNext: string[] = [];
  routeBaseNext: string = `${clientRoute}/info/`;
  image: string = '';
  subActivatedRoute!: Subscription;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.initScreen();
  }

  initScreen() {
    this.subActivatedRoute = this.activatedRoute.paramMap.subscribe(
      (params) => {
        const id = params.get('merchantId');
        if (id) {
          this.routesNext = [this.routeBaseNext, id];
          const option = params.get('option');
          if (option === 'SI') {
            this.image = assetUrl('png/image-invite.png');
            this.header =
              'El formulario de sucursales se envió correctamente al correo';
            this.subHeader =
              'Comunícate con el negocio para que complete esta información lo antes posible. Cuando tengas los datos, carga el formulario Excel con las sucursales para obtener los códigos QR.';
          } else {
            this.image = assetUrl('png/nuevo_negocio.png');
            this.header = 'El negocio se creo correctamente';
            this.subHeader =
              'Por favor, agrega o carga las sucursales para generar los códigos QR.';
          }
        }
      }
    );
  }

  ngOnDestroy() {
    this.subActivatedRoute.unsubscribe();
  }
}
