import { Component, ChangeDetectorRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { assetUrl } from '@deuna/bo-sh-helpers-ng';
import { debounceTime, Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { IQueryFilesReport } from 'src/app/shared/interfaces/query-reports';
import { GraphqlReportService } from 'src/app/services/graphql/graphql-report.service';
import { CryptoService } from '@deuna/bo-sh-security-ng';
import { MCPK } from 'src/app/constants/merchant-constants';

@Component({
  selector: 'reports-list-table',
  templateUrl: './reports-list-table.component.html',
  styleUrls: ['./reports-list-table.component.scss'],
})
export class ReportsListTable implements OnInit {
  @Input() reportsList?: string[] = [];
  @Input() reportId?: string = '';
  @Input() ruc: string = '';

  @Output() closeSide = new EventEmitter();

  emptyReportActive: string = assetUrl('png/report-active.png');
  emptyReportInactive: string = assetUrl('png/report-inactive.png');
  trashIcon: string = assetUrl('svg/trash.svg');
  editIcon: string = assetUrl('svg/edit.svg');
  subActivatedRoute!: Subscription;
  formSearch!: FormGroup;
  headers = ['Nombre', 'Fecha de envío', 'Hora de envío', ''];
  loading: boolean = true;
  inputSub: any;

  dataPagination = {
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    itemsPerPage: 25,
  };

  reportFileList: Array<{
    fileName: string;
    createDate: string;
    createHour: string;
    urlDownload: string;
    id: string;
  }> = [];

  paramsQuery: IQueryFilesReport = {
    page: this.dataPagination.currentPage,
    limit: this.dataPagination.itemsPerPage,
    referenceId: [],
    identificationId: '',
    fileName: '',
  };

  resultFields: string = `
    id
    fileName
    createDate
  `;

  constructor(
    private readonly fb: FormBuilder,
    private cryptoService: CryptoService,
    private gqlReportService: GraphqlReportService,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.formSearch = this.fb.group({
      textSearch: [''],
    });

    if(!!this.ruc?.length) {
      this.paramsQuery.identificationId = this.cryptoService.encryptData(MCPK, this.ruc);
    } else if(!!this.reportId?.length) {
      this.paramsQuery.referenceId = this.encryptReportsList([this.reportId]);
    } else if(!!this.reportsList?.length) {
      this.paramsQuery.referenceId = this.encryptReportsList(this.reportsList)
    }

    this.getReportFiles();
  }

  encryptReportsList(reports:string[]) {
    return reports.map(report => this.cryptoService.encryptData(MCPK, report));
  }

  ngAfterContentInit() {
    this.inputSub = this.formSearch.controls['textSearch'].valueChanges
      .pipe(debounceTime(1500))
      .subscribe((value: string) => {
        this.paramsQuery.fileName = value;
        this.paramsQuery.page = 1;
        this.getReportFiles();
        this.cdr.markForCheck();
      });
  }

  close() {
    this.closeSide.emit();
  }
  
  getReportFiles() {
    this.loading = true;
    this.gqlReportService
      .listFilesReports(this.paramsQuery, this.resultFields)
      ?.subscribe({
        next: (res) => {
          const { items, meta } = res.data?.listFilesReports ?? {items: []};
          if (items?.length > 0) {
            this.dataPagination = meta;
          } else {
            this.dataPagination = {
              totalItems: 0,
              totalPages: 0,
              currentPage: 1,
              itemsPerPage: 25,
            };
          }

          this.reportFileList = items.map((item) => ({
            fileName: item.fileName,
            createDate: item.createDate.split('T')[0],
            createHour: item.createDate.split('T')[1].split('.')[0],
            urlDownload: '',
            id: item.id,
          }));
          this.loading = false;
        },
        error: (err) => {
          this.loading = false;
        },
      });
  }

  downloadButton(fileId: string) {
    this.messageService.add({
      severity: 'deuna',
      summary: `Descargando...`,
      closable: false,
      icon: 'refresh',
      key: 'info',
    });

    this.gqlReportService
      .getFromFileManagerById({ id: fileId }, this.resultFields)
      .subscribe({
        next: (res) => {
          const { signedUrl } = res.data.getFromFileManagerById;

          open(signedUrl);
          this.messageService.clear('info');
          this.messageService.add({
            severity: 'deuna',
            summary: `Descarga exitosa`,
            closable: false,
            key: 'success',
          });
        },
        error: (err) => {
          this.messageService.clear('info');
          this.messageService.add({
            severity: 'error-deuna',
            summary: 'Descarga Fallida',
            key: 'failed',
            closable: true,
            icon: 'close',
          });
        },
      });
  }

  onPageChange(page: number) {
    this.paramsQuery.page = page;
    this.getReportFiles();
  }

  onLimitChange(limit: number) {
    this.paramsQuery.page = 1;
    this.paramsQuery.limit = limit;
    this.getReportFiles();
  }

  showSearchField(): boolean {
    return this.reportFileList?.length > 0 || (!this.reportFileList?.length && !!this.paramsQuery.fileName?.length);
  }  
}
