import { Component, Input, OnInit } from "@angular/core";
import { assetUrl } from "@deuna/bo-sh-helpers-ng";
import { GlobalErrorActionService } from "../../actions/global-error.actions.service";
import { CustomBackofficeControlledErrorInterface, ActionInterface, ActionStyleTypes } from "../../types";

const MAP_ACTION_STYLE: Record<ActionStyleTypes, string> = {
    FILLED: '',
    OUTLINE: 'p-button-outlined',
    TEXT: '',
}

const DEFAULT_ERROR_IMAGE = 'png/create_error.png'

@Component({
    selector: 'app-error-modal',
    templateUrl: './error-modal.component.html',
    styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent implements OnInit {
    @Input() error: CustomBackofficeControlledErrorInterface | null = null;

    title!: string;
    description!: string;
    img: string = assetUrl(DEFAULT_ERROR_IMAGE);
    actions: ActionInterface[] = [];

    constructor (private errorActionService: GlobalErrorActionService){}

    ngOnInit(): void {
        this.title = this.error?.title || '';
        this.description = this.error?.subtitle || '';
        this.actions = this.error?.actions || [];
        this.img = this.getIcon();
    }

    private getIcon() {
        const imgPath = this.error?.iconUrl || DEFAULT_ERROR_IMAGE;
        return assetUrl(imgPath);
    }

    getActionStyle(action: ActionInterface) {
        if (!action.type) {
            return MAP_ACTION_STYLE['FILLED']
        }
        return MAP_ACTION_STYLE[action.type];
    }

    onHandleAction(action: ActionInterface) {
        this.errorActionService.executeAction(action.actionType, action.value);
    }
}