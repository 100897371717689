<deuna-loading [titulo]="'Cargando información'" *ngIf="loading" [subtitulo]="'Por favor, espera un momento'">
</deuna-loading>

<form *ngIf="!loading && !isSuccess" class="grid my-5">
  <div class="col-8 col-offset-2">
    <div class="mb-5">
      <div class="text-style-back" [routerLink]="backRoute">
        Regresar
      </div>
    </div>
  </div>
  <div class="col-8 col-offset-2">
    <app-card header="Carga el archivo de creación de Pymes"
      subHeader="Verifica que el archivo no tenga errores y este en el formato correcto">
      <app-upload-file-sync [description]="messageStage" [titleButton]="'Cargar Excel'" [showDownloadTemplate]="true"
        [staticUrlDownload]="excelFile" [staticTemplateMessage]="'¿Quieres obtener los formatos?'" [status]="status"
        [errorTitle]="uploadErrorTitle" [details]="uploadErrorDetails" [errorMessage]="uploadErrorMessage"
        (onFileUploaded)="onFileUploaded($event)" (onFileReset)="onFileReset()"
        staticDownloadMessage="Descarga aquí"></app-upload-file-sync>
    </app-card>
  </div>

  <div class="col-8 col-offset-6 pt-4">
    <div class="grid">
      <div class="col-3">
        <deuna-button [disabled]="status === 'process'" label="Cancelar" deUnaClass="p-button-outlined"
          [routerLink]="backRoute">
        </deuna-button>
      </div>
      <div class="col-3" *ngIf="!isUpload">
        <deuna-button id="test-btnValidate" label="Validar formato" type="submit"
          [disabled]="status === 'new' || status === 'process'" (eventClick)="createMassive()"></deuna-button>
      </div>
    </div>
  </div>
</form>
