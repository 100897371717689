<deuna-loading [titulo]="'Cargando información'" *ngIf="loadData" [subtitulo]="'Por favor, espera un momento'">
</deuna-loading>
<ng-container *ngIf="this.formReportData && !loadData">
    <div class="grid m-5">
        <div class='col-12 mt-2'>
            <div class="mb-10">
                <div class="text-style-back" [routerLink]="[relativeRoute+'/info', this.merchantId]" [queryParams]="{ tab: 'reportsProgram' }">Regresar</div>
            </div>
        </div>


        <div class="col-12 mt-5">
            <div class="grid">
                <div class="col-12 ">
                    <span class="headerReport"> <strong>{{reportName}}</strong></span>
                </div>
                <div class="col-12 ">
                    <span class="sub-headerReport">{{typeReport}} | {{levelReport}}</span>

                </div>
            </div>
            <span class="line" *ngIf="levelReport !== 'Unificado'"></span><br>
        </div>

        <div *ngIf="levelReport === 'Unificado'" class="col-12 mb-4">
            <deuna-tab-menu [items]="items" [activeItem]="activeItem"></deuna-tab-menu>
        </div>
        <div class="col-12 mb-4">
            <app-tab-update-report
                *ngIf="activeItem.id == 'configuration'"
                [reportId]="reportId"
            ></app-tab-update-report>
            <reports-list-table
                *ngIf="activeItem.id == 'downloadable'"
                [reportId]="reportId"
            ></reports-list-table>
        </div>
    </div>
</ng-container>