import { OnInit, Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { assetUrl } from '@deuna/bo-sh-helpers-ng';
import { clientRoute } from 'src/app/constants/routes';
import { ActivatedRoute, Router } from '@angular/router';
import { GraphqlMerchantService } from 'src/app/services/graphql/graphql-merchant.service';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tab-groups',
  templateUrl: './tab-groups.component.html',
  styleUrls: ['./tab-groups.component.scss'],
})
export class TabGroupsComponent implements OnInit {
  formGroup!: FormGroup;
  @Input() name: string | undefined;

  loadData: boolean = true;
  isAssociatedToGroup: boolean = false;
  emptyGroup: string = assetUrl('png/no-associated-group.png');
  redirectIcon = assetUrl('svg/redirect.svg');
  isEditable: boolean = false;
  isFlowUpdate: boolean = false;
  relativeRoute = clientRoute;
  hasError: boolean = false;
  isShowToast: boolean = false;
  showAssociate: boolean = true;
  titleAssociate: string = ""
  groupNodeId: string | null = null;

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly graphqlMerchantService: GraphqlMerchantService,
    private readonly messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  async initData(): Promise<void> {
    const merchantId = this.getMerchantId();
    this.showAssociate = environment.enableCommercialGroup;
    this.titleAssociate =  this.showAssociate ? "Presiona ‘Asociar’ para asignarle un grupo" : ""
    this.loadGroup(merchantId);
    this.activatedRoute.queryParams.subscribe((params) => {
      this.isShowToast = params['isAssociated'];
      if (this.isShowToast) {
        this.viewToast();
      }
    });
  }

  viewToast() {
    this.messageService.add({
      key: 'global',
      severity: 'deuna',
      summary: 'Negocio asociado a grupo',
      closable: false,
    });
  }

  private async loadGroup(merchantId: string): Promise<void> {
    this.graphqlMerchantService
      .getMerchantGroup({ clientId: merchantId })
      .subscribe({
        next: (res) => {
          const group = res.data?.getCommercialGroupByClientId;
          if (group) {
            const { comercialName, identification, businessName } =
              group.configData;
            this.formGroup = this.fb.group({
              comercialName: [comercialName],
              identification: [identification],
              businessName: [businessName],
            });
            this.isAssociatedToGroup = true;
            this.formGroup.disable();

            this.groupNodeId = group.nodeId;
          }
          this.hasError = false;
          this.loadData = false;
        },
        error: () => {
          this.hasError = true;
          this.loadData = false;
        },
      });
  }

  private getMerchantId(): string {
    return this.activatedRoute.snapshot.params['merchantId'];
  }

  async associateGroup() {
    const merchantId = this.getMerchantId();
    this.router.navigate(
      [`${this.relativeRoute}/commercial-group/associate/`, merchantId],
      { queryParams: { name: this.name }, replaceUrl: true }
    );
  }

  goToGroup(): void {
    this.router.navigate(
      [`${this.relativeRoute}/commercial-group/info/${this.groupNodeId}`],
      {
        replaceUrl: true,
      }
    );
  }
}