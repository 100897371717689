import { assetUrl } from '@deuna/bp-sh-helpers-ng';
import { MapValuesBy } from '../shared/utils/types';

export const DEFAULT_COUNTRY = 'Ecuador';
export const DEFAULT_BANK = 'Banco Pichincha';
export const CREATE_ERROR_CODE = 'ERR00';
export const REGEX_WITHOUT_SPECIAL_CHARACTERS =
  /^[0-9a-zA-ZñÑáéíóúÁÉÍÓÚ&.,\-:"'{}\[\] ]*$/;
export const REGEX_COORDINATE = /^-?\d{1,3}\.\d{2,16}$/;
export const REGEX_ONLY_NUMBER = /^\d+$/;

export const IdentificationType = {
  CI: 'CI',
  RUC: 'RUC',
} as const;
export type IdentificationType = MapValuesBy<typeof IdentificationType>;

export const ClientType = {
  NATURAL: 'NATURAL',
  JURIDICAL: 'JURIDICAL',
} as const;
export type ClientType = MapValuesBy<typeof ClientType>;

export const InputStates = {
  IDLE: 'IDLE',
  VALIDATING: 'VALIDATING',
  INSERTING: 'INSERTING',
  COMPLETED: 'COMPLETED',
} as const;
export type InputStates = MapValuesBy<typeof InputStates>;
export const MCPK = 'MCPK';

export enum ModalType {
  SUBMIT = 'SUBMIT',
  CANCEL = 'CANCEL',
}

export const modalText = {
  [ModalType.SUBMIT]: {
    modalImg :null,
    modalMessage: '',
    modalTextMessage: '',
    labelAcceptButton: 'Sí',
    labelCancelButton: 'No',
  },
  [ModalType.CANCEL]: {
    modalImg: assetUrl('png/exit_logo.png'),
    modalMessage: '',
    modalTextMessage: '',
    labelAcceptButton: 'Sí',
    labelCancelButton: 'No',
  },
};
