import { IFormGroupReport } from 'src/app/shared/interfaces/form-group.interface';

import { FormBuilder, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
@Injectable()
export class ConfigurationFlowReportFormService {
  constructor(private readonly fb: FormBuilder) {}
  formGroup!: IFormGroupReport;

  getForm() {
    if (this.formGroup) {
      return this.formGroup;
    }

    const form: IFormGroupReport = {
      initial: this.getFormInitial(),
      branch: this.getFormBranch(),
      frequency: this.getFormFrequency(),
    };

    this.formGroup = form;
    return this.formGroup;
  }

  private getFormInitial() {
    return this.fb.group({
      nameReport: [],
      merchantId: [null, [Validators.required]],
      type: [null, [Validators.required]],
      level: [null, [Validators.required]],
    });
  }

  private getFormBranch() {
    return this.fb.group({
      file: null,
    });
  }

  private getFormFrequency() {
    return this.fb.group({
      daysFrequency: [null, [Validators.required]],
      cutOffTime: [null, [Validators.required]],
      cutOffDay: [],
      cutOffDaySecond: [],
      mails: this.fb.array([]),
    });
  }
}
