<section id="error-modal-container">
  <div class="modal">
    <img [src]="img" alt="Error bank" width="256px" height="256px" />
    <h1 class="title">
      {{title}}
    </h1>
    <p class="desc">
      {{description}}
    </p>
    <div class="button-container">
      <ng-container *ngFor="let action of actions">
        <deuna-button [label]="action.text || ''" [deUnaClass]="getActionStyle(action)"
          (eventClick)="onHandleAction(action)"></deuna-button>
      </ng-container>
    </div>
  </div>
</section>