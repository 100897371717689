import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MutationResult } from 'apollo-angular';
import { GraphqlMerchantService } from 'src/app/services/graphql/graphql-merchant.service';
import { ResponseUploadFile } from 'src/app/shared/interfaces/query-branchs';
import { MerchantItem } from '../../../../shared/interfaces/query-merchants';
import { assetUrl } from '@deuna/bp-sh-helpers-ng';

@Component({
  selector: 'app-file-update-side',
  templateUrl: './file-update-side.component.html',
  styleUrls: ['./file-update-side.component.scss'],
})
export class FileUpdateSideComponent implements OnInit {
  @ViewChild('fileInput', {
    read: ElementRef,
  })
  filetmp!: ElementRef;
  @Input() extension: string = '';
  @Input() merchant!: MerchantItem;
  @Output() reloadList = new EventEmitter();
  fileName: string = '';
  file!: File;
  stepLoad: number = 0;
  accept: string = '';
  status: string = 'new';
  title: string = '';
  message: string = '';
  titleButton: string = 'Cargar Excel';
  @Input() excelFile: string = assetUrl(
    'xlsx/Sucursales_DeUna_Negocios-ok.xlsx'
  );

  constructor(private graphqlMerchantService: GraphqlMerchantService) {}

  ngOnInit(): void {
    if (this.extension === 'xlsx') {
      this.accept =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
    }
  }

  readFile() {
    this.title = '';
    this.message = '';
    if (this.filetmp.nativeElement.files.length > 0) {
      this.file = this.filetmp.nativeElement.files[0];
      this.fileName = this.file?.name;
      const extension: string = this.fileName.slice(
        ((this.fileName.lastIndexOf('.') - 1) >>> 0) + 2
      );
      this.filetmp.nativeElement.value = '';
      if (this.extension !== extension) {
        this.changeStatus(
          'error',
          'Cargar nuevamente',
          'Formato incorrecto',
          'Verifica que tu archivo tenga formato .XLSX'
        );
        return;
      }
      this.status = 'upload';
      this.stepLoad = 10;
      setTimeout(() => {
        this.stepLoad = 50;
        this.uploadFile();
      }, 1000);
    }
  }

  uploadFile() {
    this.stepLoad = 90;
    this.graphqlMerchantService
      .uploadFileBranchs(this.file, this.merchant.id)
      .subscribe({
        next: (res: MutationResult<ResponseUploadFile>) => {
          const data = res.data;
          if (data) {
            const valid = data.createBranchOfficeFile.success;
            if (valid) {
              this.stepLoad = 100;
              this.changeStatus(
                'success',
                'Cargar nuevamente',
                'El archivo se cargó correctamente'
              );
              this.reloadList.emit();
            } else {
              const { title, message } =
                data.createBranchOfficeFile.errorMessage;
              this.changeStatus('error', 'Cargar nuevamente', title, message);
            }
          }
        },
        error: () => {
          this.changeStatus(
            'error',
            'Cargar nuevamente',
            'Error... :(',
            'No se pudo cargar el archivo'
          );
        },
      });
  }

  changeStatus(
    status: string,
    titleButton: string,
    title: string,
    message: string = ''
  ) {
    this.status = status;
    this.titleButton = titleButton;
    this.title = title;
    this.message = message;
  }
}
