import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'deunam-console-merchants-ng',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'deunam-console-merchants-ng';
  hasGlobalError: boolean = false;

  constructor(
    private primengConfig: PrimeNGConfig,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.primengConfig.ripple = true;
  }

  onHandleHasError(value: boolean) {
    this.hasGlobalError = value;
    this.cdr.detectChanges();
  }
}
