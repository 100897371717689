import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { assetUrl } from '@deuna/bp-sh-helpers-ng';
import { ClientType } from 'src/app/constants/merchant-constants';
import { clientRoute } from 'src/app/constants/routes';
import { ConfigurationFlowPymeCreation } from 'src/app/services/config/pymes/configuration-flow-pyme-creation.service';

const enum descriptionStatus {
  NEW = 'Archivo de creación masiva de negocios',
  PROCESS_VALIDATION = 'Validando archivo, esto puedo tardar unos minutos.',
  VALIDATE_FILE = 'Archivo validado, procede a crear tu comercios.'
}
@Component({
  selector: 'app-step-1-pymes',
  templateUrl: './step-1.page.html',
  styleUrls: ['./step-1.page.scss'],
})
export class Step1Page implements OnInit {

  loading: boolean = false;
  constructor(
    private readonly flowPymeCreation: ConfigurationFlowPymeCreation,
    private readonly router: Router,
  ) { }

  textLloading = ''

  dragOver = false;
  files: File[] = [];

  totalSize: number = 0;

  totalSizePercent: number = 0;
  uploadedFiles: any[] = [];

  clientTypes = ClientType;
  backRoute:string = clientRoute;
  status: string = 'new';
  accept: string = '';
  title: string = 'Carga el archivo de creación de Pymes';
  message: string = '';
  filetmp!: ElementRef;
  fileName: string = '';
  file!: File;
  titleButton: string = 'Cargar Excel';
  messageStage: string = descriptionStatus.NEW;
  uploadErrorTitle: string = '';
  uploadErrorMessage: string = '';
  uploadErrorDetails: string[] = [];
  isUpload = false;
  isValidate = false;
  isSuccess = false;
  imageSucces: string = assetUrl('png/nuevo_negocio.png');

  excelFile: string = assetUrl(
    'xlsx/Template_creacion_masiva_pymes.xlsx'
  );

  @Output() reloadList = new EventEmitter();
  @Input() extension: string = '';

  ngOnInit(): void {
    this.loading = false;
    this.backRoute = `${this.backRoute}/pymes/list`;
  }

  createMassive() {
    this.messageStage = descriptionStatus.PROCESS_VALIDATION;
    this.status = 'process';
    setTimeout(() => {
      this.status = 'validate'
      this.isValidate = true;
      this.isSuccess = true;
      this.messageStage = descriptionStatus.VALIDATE_FILE;
      this.flowPymeCreation.openSideApp()
      this.router.navigate([this.backRoute]);
    }, 3000);
  }

  onFileUploaded(_event) {
    this.status = 'upload';
  }

  onFileReset() {
    this.status = 'new'
    this.isUpload = false;
    this.isValidate = false;
  }
}
