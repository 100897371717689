import { Validators } from '@angular/forms';
import { REGEX_WITHOUT_SPECIAL_CHARACTERS } from 'src/app/constants/merchant-constants';

export const formSearch = {
  identification: [
    '',
    [
      Validators.required,
      Validators.maxLength(13),
      Validators.minLength(13),
      Validators.pattern(REGEX_WITHOUT_SPECIAL_CHARACTERS),
    ],
  ],
  clientType: [
    '',
    [Validators.required, Validators.pattern(REGEX_WITHOUT_SPECIAL_CHARACTERS)],
  ],
  identificationType: ['', [Validators.required]],
  cifCode: [null],
};

export const form000 = {
  businessName: [
    null,
    [Validators.required, Validators.pattern(REGEX_WITHOUT_SPECIAL_CHARACTERS)],
  ],
  tradename: [
    null,
    [Validators.required, Validators.pattern(REGEX_WITHOUT_SPECIAL_CHARACTERS)],
  ],
  identification: [
    null,
    [Validators.required, Validators.pattern(REGEX_WITHOUT_SPECIAL_CHARACTERS)],
  ],
  identificationType: [
    null,
    [Validators.required, Validators.pattern(REGEX_WITHOUT_SPECIAL_CHARACTERS)],
  ],
  clientAccountId: [
    null,
    [Validators.required, Validators.pattern(REGEX_WITHOUT_SPECIAL_CHARACTERS)],
  ],
  clientType: [
    null,
    [Validators.required, Validators.pattern(REGEX_WITHOUT_SPECIAL_CHARACTERS)],
  ],
};
