import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConfigurationDataService } from 'src/app/services/config/merchant/configuration-data.service';
import { ICatalogue } from 'src/app/shared/interfaces/calatogue.interface';
import _ from 'lodash';

@Component({
  selector: 'app-card-billing-information',
  templateUrl: './card-billing-information.component.html',
})
export class CardBillingInformationComponent implements OnInit {
  @Input() isEditable: boolean = false;
  @Input() isFlowUpdate: boolean = false;
  loading: boolean = false;
  enableEdit: boolean = false;
  @Input() formCN013!: FormGroup;
  @Input() isCreationForm: boolean = false;
  formCN013TMP!: FormGroup;
  @Input() isCN013Created: boolean = false;
  @Output() toastEvent: EventEmitter<void> = new EventEmitter<void>();
  accountsTypes: Array<ICatalogue> = [];

  constructor(private configDataService: ConfigurationDataService) {}

  ngOnInit(): void {
    this.formCN013TMP = _.cloneDeep(this.formCN013);
    if (this.isCreationForm) return;
    this.setStateReadable();
  }

  handleEvent($event: boolean) {
    if ($event) {
      this.enableEdit = true;
      this.formCN013.enable();
    }
  }

  onCancelEdit() {
    this.enableEdit = false;
    this.setStateReadable();
    this.formCN013.patchValue(this.formCN013TMP.value);
  }

  async submit() {
    this.loading = true;
    this.formCN013.markAllAsTouched();
    if (!this.formCN013.valid) {
      this.loading = false;
      return;
    }
    this.isCN013Created
      ? await Promise.resolve(
          this.configDataService.updateConfiguration('CN013')
        ).then(() => {
          this.emitToast();
        })
      : await Promise.resolve(
          this.configDataService.createConfigurationNode('CN013')
        ).then(() => {
          this.emitToast();
        });

    this.loading = false;
    this.enableEdit = false;
    this.formCN013TMP = _.cloneDeep(this.formCN013);
    this.setStateReadable();
  }

  setStateReadable() {
    this.formCN013.disable();
  }

  emitToast() {
    this.toastEvent.emit();
  }
}
