import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-side-right',
  templateUrl: './side-right.component.html',
  styleUrls: ['./side-right.component.scss'],
})
export class SideRightComponent {
  @Input() title: string = '';
  @Input() subTitle?: string = '';
  @Input() showSide: boolean = false;
  @Output() closeSide = new EventEmitter();
  @Input() orderSubtitle?: string = '';

  clickClose() {
    this.closeSide.emit();
  }
}
