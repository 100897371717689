import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

interface FileFormat {
  extension: string;
  mime: string;
}

const allowedFileFormats: Record<string, FileFormat> = {
  XLSX: {
    extension: 'xlsx',
    mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  XLS: { extension: 'xls', mime: 'application/vnd.ms-excel' },
  CSV: { extension: 'csv', mime: 'text/csv' },
  ZIP: { extension: 'zip', mime: 'application/zip' },
  GZIP: { extension: 'gzip', mime: 'application/gzip' },
  RAR: { extension: 'rar', mime: 'application/x-rar-compressed' },
};

const statusThatAllowReset = ['upload', 'validate', 'create'];

@Component({
  selector: 'app-upload-file-sync',
  templateUrl: 'upload-file-sync.component.html',
  styleUrls: ['./upload-file-sync.component.scss'],
})
export class UploadFileSyncComponent {
  @ViewChild('fileInput', {
    read: ElementRef,
  })
  fileInputRef!: ElementRef;
  @Input() allowedExtensions: string[] = ['xls', 'xlsx'];
  @Input() description: string = '';
  @Input() titleButton: string = '';
  @Input() fileDescriptionText: string = '';
  @Input() showDownloadTemplate: boolean = false;
  @Input() maxSizeMB: number = 10;
  @Input() required: boolean = false;
  @Input() staticUrlDownload: string = '';
  @Input() staticTemplateMessage: string = '';
  @Input() staticDownloadMessage: string = '';
  @Input() status: string = 'new';
  @Input() errorTitle: string = '';
  @Input() errorMessage: string = '';
  @Input() details: string[] = [];
  @Input() stepLoad: number = 0;
  @Output() onFileUploaded: EventEmitter<File> = new EventEmitter<File>();
  @Output() onFileReset: EventEmitter<File> = new EventEmitter<File>();
  @Output() onFileProcess: EventEmitter<File> = new EventEmitter<File>();

  fileName: string = '';
  fileSizeMB: string = '0';
  accept: string = '';
  title: string = '';
  message: string = '';
  fileUploaded: boolean = false;
  fileSelected!: File | undefined;

  ngOnInit(): void {
    const extensions = this.allowedExtensions.map((ext) => ext.toLowerCase());
    this.accept = extensions
      .map((ext) => allowedFileFormats[ext.toUpperCase()])
      .map(({ mime }) => mime)
      .join(', ');
  }

  resetFile(event: MouseEvent) {
    if (statusThatAllowReset.includes(this.status)) {
      event?.preventDefault();
      this.onFileUploaded.emit(undefined);
      this.onFileReset.emit();
      this.fileSelected = undefined;
      this.changeStatus('new', 'Cargar nuevamente');
    }
  }

  formatSize(file: File) {
    const sizeInBytes = file.size;

    if (sizeInBytes < 1024) {
      this.fileSizeMB = `${sizeInBytes} B`; // Less than 1 KB, show in bytes
    } else if (sizeInBytes < 1024 * 1024) {
      this.fileSizeMB = `${(sizeInBytes / 1024).toFixed(2)} KB`; // Less than 1 MB, show in KB
    } else {
      this.fileSizeMB = `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`; // 1 MB or more, show in MB
    }
  }

  readFile() {
    this.title = '';
    this.message = '';
    let file: File | undefined;

    if (this.fileSelected) {
      file = this.fileSelected;
    } else {
      file = this.fileInputRef.nativeElement.files?.[0];
    }

    if (!file) {
      return;
    }

    this.fileName = file.name || '';
    this.formatSize(file);
    const { size } = file;

    const extension: string = this.fileName
      .slice(((this.fileName.lastIndexOf('.') - 1) >>> 0) + 2)
      .toLowerCase()
      .trim();
    this.fileInputRef.nativeElement.value = '';

    if (!this.allowedExtensions.includes(extension)) {
      this.changeStatus(
        'error',
        'Cargar nuevamente',
        `Formato incorrecto`,
        `Verifica que tu archivo tenga formato ${this.allowedExtensions.join(
          ', '
        )}`
      );
      this.onFileUploaded.emit(file);
      return;
    }
    if (size > Math.pow(this.maxSizeMB, 7)) {
      this.changeStatus(
        'error',
        'Cargar nuevamente',
        `El archivo excede el tamaño permitido de ${this.maxSizeMB}M`,
        `Verifica que tu archivo tenga el tamaño adecuado`
      );
      this.onFileUploaded.emit(file);
      return;
    }

    this.status = 'upload';
    this.onFileUploaded.emit(file);
  }

  changeStatus(
    status: string,
    titleButton: string,
    title: string = '',
    message: string = ''
  ) {
    this.status = status;
    this.titleButton = titleButton;
    this.errorTitle = title;
    this.errorMessage = message;
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    const file = event.dataTransfer?.files[0];
    if (!file) {
      return;
    }
    this.fileSelected = file;
    this.readFile()
  }
}
