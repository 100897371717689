<div class="header">
    <span>{{header}}</span>
    <span *appRoleBased="executorRoles">
        <span *ngIf="isFlowUpdate && isEditable" (click)="emitEvent()" style="cursor: pointer;" class="text-editable">Editar</span>
    </span>
</div>
<div class="sub-header" *ngIf="subHeader !== ''">{{subHeader}}</div>
<p-card styleClass="shadow-4 mt-4 p-5 mb-2" [style]="{'border-radius': '0px'}">
    <ng-template pTemplate="content" styleClass="p-card-content">
        <ng-content>
        </ng-content>
    </ng-template>
</p-card>