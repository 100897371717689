import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { assetUrl } from '@deuna/bp-sh-helpers-ng';
import { OnlyNumberDirective } from '../../../../../../shared/directives/only-number.directive';
import { clientTypeOptions } from '../../../../../../constants/catalogos/mocks-catalogo';
import { ConfigurationFlowCreationService } from '../../../../../../services/config/commercial-group/configuration-flow-creation.service';
import { ICatalogue } from '../../../../../../shared/interfaces/calatogue.interface';
import { clientRoute } from '../../../../../../constants/routes';
import {
  ClientType,
  IdentificationType,
} from '../../../../../../constants/merchant-constants';
import { environment } from '../../../../../../../environments/environment';
import { GraphqlCommercialGroupService } from 'src/app/services/graphql/graphql-commercial-group.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-search-commercial-group',
  templateUrl: './search.page.html',
  styleUrls: ['./search.page.scss'],
  viewProviders: [OnlyNumberDirective],
})
export class SearchPage implements OnInit {
  relativeRoute = clientRoute;
  searchClientImg = assetUrl('png/commercial-group-search.png');
  img_error: string = assetUrl('png/image-bank-error.png');
  loading: boolean = true;
  searchClientForm!: FormGroup;
  clientTypeOptions: Array<ICatalogue> = [];
  inputLabel: string = '';
  resultfields: string = '';
  environment = environment;

  constructor(
    private configFlow: ConfigurationFlowCreationService,
    private commercialGroupService: GraphqlCommercialGroupService,
    private router: Router
  ) {}

  clientType = ClientType;

  ngOnInit(): void {
    this.initData();
    this.initForm();
    this.searchClientForm.enable();
  }

  async onSubmit(): Promise<void> {
    this.searchClientForm.markAllAsTouched();
    this.loading = true;
  
    if (!this.searchClientForm.valid) {
      this.loading = false;
      return;
    }

    try {
      const identification = this.searchClientForm.get('identification')?.value;
      await this.validateCommercialGroupRuc(identification);
      await this.configFlow.getBpClient();
      this.router.navigate([`${clientRoute}/commercial-group/create/step-1`]);
    } catch (error: any) {
      this.initData();
      this.initForm();
    }
  }

  initForm() {
    this.loading = false;
    this.configFlow.getForm().search.reset();
    this.configFlow.getForm().CN000.reset();
    this.searchClientForm = this.configFlow.getForm().search;
    this.searchClientForm.get('clientType')?.setValue(ClientType.JURIDICAL);
    this.searchClientForm
      .get('identificationType')
      ?.setValue(IdentificationType.RUC);
  }

  initData() {
    this.inputLabel = 'Número de RUC';
    this.clientTypeOptions = [clientTypeOptions[0]];
  }

  private async validateCommercialGroupRuc(identification: string) {
    const { errors } = await lastValueFrom(this.commercialGroupService.validateCommercialGroupRuc(identification));
    if (errors) throw new Error('Commercial Group is not valid');
  }
}
