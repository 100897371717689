import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterList',
})
export class FilterListPipe implements PipeTransform {
  transform(objects: any[]): any {
    if (objects) {
      return objects.filter((object) => {
        return object.data.type === 1;
      });
    }
  }
}
