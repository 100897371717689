import { debounceTime, Subject, Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { assetUrl } from '@deuna/bo-sh-helpers-ng';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GraphqlMerchantService } from 'src/app/services/graphql/graphql-merchant.service';
import { ApolloQueryResult } from '@apollo/client/core';
import { ConfigurationFlowBranchService } from 'src/app/services/config/branch/configuration-flow-branch.service';
import { TitleCasePipe } from '@angular/common';
import { clientRoute } from 'src/app/constants/routes';
import { MenuItem } from 'primeng/api';
import { ICatalogue } from 'src/app/shared/interfaces/calatogue.interface';
import {
  IQueryMerchant,
  MerchantItem,
  ResponseMerchants,
  Meta,
} from 'src/app/shared/interfaces/query-merchants';
import { CataloguesService } from 'src/app/services/http/catalogues.service';

@Component({
  selector: 'app-list-merchant',
  templateUrl: './list-merchant.page.html',
  styleUrls: [],
  providers: [TitleCasePipe],
})
export class ListMerchant implements OnInit {
  relativeRoute = clientRoute;
  regions: Array<ICatalogue> = [];
  valueChanged: Subject<string> = new Subject<string>();
  inputSub!: Subscription;
  subGraphqlMerchantService!: Subscription;
  currentPage: number = 1;
  limitPage: number = 5;
  resultfields: string = '';
  showSideBar: boolean = false;
  tableStatus: 'LOADING' | 'COMPLETE' | 'EMPTY' = 'LOADING';
  paramsQuery!: IQueryMerchant;
  formFilterMerchant!: FormGroup;
  merchantsList!: Array<MerchantItem>;
  dataPagination!: Meta;
  viewFilters: boolean = false;
  listFilters!: Array<any>;
  imgNotFoundMerchants: string = assetUrl('png/checklist.png');
  avatar: string = assetUrl('svg/store-mall-directory.svg');
  searchFilter: string = assetUrl('png/Filter_Vector.png');
  activeItem: MenuItem = {};

  columns = [
    'Nombre',
    'Tipo de cliente',
    'N° de RUC',
    'Grupo empresarial',
    'Región',
    'Estado',
    'Última modificación',
  ];

  areAllSelectedRegion = false;

  statusList = [
    { name: 'Esperando datos', selected: false },
    { name: 'Estado', selected: false },
  ];

  areAllSelectedStatus = false;

  regionList = [
    { name: 'Sierra', selected: false },
    { name: 'Costa', selected: false },
    { name: 'Oriente', selected: false },
    { name: 'Galapagos', selected: false },
  ];

  constructor(
    private readonly fb: FormBuilder,
    private cataloguesService: CataloguesService,
    private servicesMerchantsGql: GraphqlMerchantService,
    private configFlowBranch: ConfigurationFlowBranchService
  ) {}

  ngOnInit(): void {
    this.cataloguesService.getRegions().subscribe((res) => {
      const data: Array<ICatalogue> = res;
      this.regions = data;
    });
    this.formFilterMerchant = this.fb.group({
      textSearchMerchant: [''],
    });
    this.createConfigGetInitial();
    this.getMerchantsByPage(true);
  }

  allSelectionRegion() {
    this.areAllSelectedRegion = !this.areAllSelectedRegion;
    this.regionList = this.regionList.map((item) => ({
      ...item,
      selected: this.areAllSelectedRegion,
    }));
  }

  allSelectionStatus() {
    this.areAllSelectedStatus = !this.areAllSelectedStatus;
    this.statusList = this.statusList.map((item) => ({
      ...item,
      selected: this.areAllSelectedStatus,
    }));
  }

  getRegion(code: string): string {
    const search = this.regions.find((x) => x.code === code);
    if (search) {
      return search.name;
    }
    return '';
  }

  deleteFilters() {
    this.areAllSelectedRegion = false;
    this.areAllSelectedStatus = false;
    this.statusList = this.statusList.map((item) => ({
      ...item,
      selected: this.areAllSelectedStatus,
    }));
    this.regionList = this.regionList.map((item) => ({
      ...item,
      selected: this.areAllSelectedRegion,
    }));
    this.listFilters = [];
    this.viewFilters = false;
    this.paramsQuery.region = [];
  }

  applyFilters() {
    this.listFilters = this.statusList
      .concat(this.regionList)
      .filter((x) => x.selected);
    if (this.listFilters.length > 0) {
      this.statusList.forEach((itemStatus) => {
        if (itemStatus.selected) {
          this.paramsQuery.region.push(itemStatus.name); // = this.paramsQuery.region + itemStatus.name + ',';
        }
      });
      this.regionList.forEach((item) => {
        if (item.selected) {
          this.paramsQuery.region.push(item.name); //= this.paramsQuery.region + item.name + ',';
        }
      });
      this.viewFilters = true;
    }
    this.getMerchantsByPage(false);
  }

  clearFilter(event: string) {
    this.statusList.forEach((itemStatus) => {
      if (itemStatus.name === event) {
        itemStatus.selected = false;
      }
    });
    this.regionList.forEach((item) => {
      if (item.name === event) {
        item.selected = false;
      }
    });
    this.listFilters.forEach((value, index) => {
      if (value.name === event) {
        this.listFilters.splice(index, 1);
      }
    });
    this.getMerchantsByPage(false);
  }

  createConfigGetInitial() {
    this.configFlowBranch.resetForm();
    this.paramsQuery = {
      page: this.currentPage,
      limit: this.limitPage,
      ID: '',
      name: '',
      status: '',
      region: [],
    };

    this.resultfields = ` id
    comercialName
    businessName
    identificationType
    identification
    region
    status
    lastModified 
    updateBy
    commercialGroup`;
  }

  ngAfterContentInit() {
    this.suscribeToInputChanges();
  }

  suscribeToInputChanges() {
    this.inputSub = this.formFilterMerchant.controls[
      'textSearchMerchant'
    ].valueChanges
      .pipe(debounceTime(1500))
      .subscribe((value) => {
        this.paramsQuery.page = 1;
        if (value.match('^[0-9]+$') != null) {
          this.paramsQuery.ID = value;
          this.paramsQuery.name = '';
        } else {
          this.paramsQuery.ID = '';
          this.paramsQuery.name = value;
        }
        this.getMerchantsByPage(false);
      });
  }

  goToPage(page: number) {
    this.paramsQuery.page = page;
    this.getMerchantsByPage(false);
  }

  changeToItems(page: number) {
    this.paramsQuery.page = 1;
    this.paramsQuery.limit = page;
    this.getMerchantsByPage(false);
  }

  getMerchantsByPage(flag: boolean) {
    this.subGraphqlMerchantService = this.servicesMerchantsGql
      .getMerchantsPage(this.paramsQuery, this.resultfields)
      .subscribe({
        next: (res: ApolloQueryResult<ResponseMerchants>) => {
          if (res.data.getAllClients) {
            const { items, meta } = res.data.getAllClients;
            this.merchantsList = items;
            this.dataPagination = meta;
            if (flag && this.merchantsList.length > 0) {
              this.tableStatus = 'COMPLETE';
            }
          }
        },
      });
  }

  openSideBar() {
    this.viewFilters = false;
    this.showSideBar = true;
  }

  ngOnDestroy() {
    this.subGraphqlMerchantService.unsubscribe();
    this.inputSub.unsubscribe();
  }
}
