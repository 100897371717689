import { Injectable } from '@angular/core';
import { ActionTypes } from '../types';
import { ErrorStateService } from '../state/global-error.state.service';
import { Router } from '@angular/router';
import { clientRoute } from 'src/app/constants/routes';

type ActionFnType = (value: string | undefined) => void;
@Injectable()
export class GlobalErrorActionService {
  private actionMap: Record<ActionTypes, ActionFnType | undefined> = {
    GO_BACK: this.goBackAction.bind(this),
    GO_HOME: this.goHomeAction.bind(this),
    NAVIGATE: this.navigateAction.bind(this),
    LOGOUT: this.goLogout.bind(this),
  };

  constructor(
    private errorStateService: ErrorStateService,
    private router: Router
  ) {}

  executeAction(
    actionType: ActionTypes,
    value: string | undefined = undefined
  ) {
    this.actionMap[actionType]?.(value);
  }

  private navigateAction(value: string | undefined) {
    this.errorStateService.cleanError();
    this.router.navigateByUrl(value ?? '');
  }

  private goBackAction() {
    this.errorStateService.cleanError();
  }

  private goHomeAction(value: string | undefined) {
    this.errorStateService.cleanError();
    this.router.navigate([clientRoute], { queryParams: { tab: value || '' } });
  }

  private goLogout(_value: string | undefined) {
    this.errorStateService.cleanError();
    this.router.navigate(['/login/out']);
  }
}
