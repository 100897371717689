import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { assetUrl } from '@deuna/bp-sh-helpers-ng';
import { OnlyNumberDirective } from '../../../../../shared/directives/only-number.directive';
import {
  clientTypeNatural,
  clientTypeOptions,
} from '../../../../../constants/catalogos/mocks-catalogo';
import { ConfigurationFlowCreationService } from '../../../../../services/config/merchant/configuration-flow-creation.service';
import { ICatalogue } from '../../../../../shared/interfaces/calatogue.interface';
import { clientRoute } from 'src/app/constants/routes';
import {
  ClientType,
  IdentificationType,
} from 'src/app/constants/merchant-constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search',
  templateUrl: './search.page.html',
  styleUrls: ['./search.page.scss'],
  viewProviders: [OnlyNumberDirective],
})
export class SearchPage implements OnInit {
  relativeRoute = clientRoute;
  searchClientImg = assetUrl('png/search-step.png');
  loading: boolean = true;
  searchClientForm!: FormGroup;
  clientTypeOptions: Array<ICatalogue> = [];
  inputLabel: string = '';
  resultfields: string = '';
  isAssociateGroupFlow: boolean = false;
  environment = environment;
  backRoute: string = clientRoute;

  hasError: boolean = false;
  img_error: string = assetUrl('png/image-bank-error.png');
  constructor(
    private configFlow: ConfigurationFlowCreationService,
    private router: Router
  ) {}

  clientType = ClientType;

  ngOnInit(): void {
    this.initData();
    this.initForm();
    this.changeOfType();
    this.configFlow.resetForm();
    this.searchClientForm.enable();
  }

  isIdentificationValid(): boolean {
    return !Object.keys(
      this.searchClientForm.get('identification')?.errors || {}
    ).filter((item) => item !== 'identificationValid').length;
  }

  async onSubmit(): Promise<void> {
    this.searchClientForm.markAllAsTouched();
    if (!this.searchClientForm.valid) {
      return;
    }

    this.loading = true;
    const cifCode: boolean = await this.configFlow.getBpClientData();
    this.loading = false;

    if (!cifCode) {
      this.hasError = true;
      return;
    }
    environment.enableCommercialGroup && this.isAssociateGroupFlow
      ? this.router.navigate([`${clientRoute}/commercial-group/associate`])
      : this.router.navigate([`${clientRoute}/create/step-1`]);
  }

  retry() {
    this.searchClientForm.get('identification')?.setValue('');
    this.hasError = false;
  }
  initForm() {
    this.searchClientForm = this.configFlow.getForm().search;
    this.configFlow.getForm().search.reset();
    this.configFlow.getForm().associationGroup.reset();
    this.loading = false;
    this.searchClientForm.get('clientType')?.setValue(ClientType.JURIDICAL);
    this.searchClientForm
      .get('identificationType')
      ?.setValue(IdentificationType.RUC);
  }

  initData() {
    this.inputLabel = 'Número de RUC';
    this.isAssociateGroupFlow =
      this.configFlow.getForm().checkAssociation.get('hasGroup')?.value ===
      'yes';
    const clientTypes = this.isAssociateGroupFlow
      ? clientTypeOptions
      : [...clientTypeOptions, clientTypeNatural];
    this.clientTypeOptions = clientTypes;
    this.backRoute = `${this.backRoute}/create/check-association`;
  }

  changeOfType() {
    this.searchClientForm.controls['identification'].clearValidators();
    this.searchClientForm.controls['identification'].setValidators(
      this.configFlow.validIdentification
    );
    this.searchClientForm.get('identification')?.updateValueAndValidity();
  }
}
