import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalErrorComponent } from './global-error.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { DeUnaButtonModule } from '@deuna/bo-sh-components-ng';
import { GlobalErrorActionService } from './actions/global-error.actions.service';
import { ErrorStateService } from './state/global-error.state.service';
import { ToastModule } from 'primeng/toast';

@NgModule({
  providers: [ErrorStateService, GlobalErrorActionService],
  declarations: [GlobalErrorComponent, ErrorModalComponent],
  imports: [CommonModule, DeUnaButtonModule, ToastModule],
  exports: [GlobalErrorComponent],
})
export class GlobalErrorComponentModule {}
