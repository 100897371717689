<app-card header="Frecuencia y horario">

    <ng-container *ngIf="isEditable else visualizeFrecuency">
       implementar cuando sea por edicion o creacion
    </ng-container>
    <ng-template #visualizeFrecuency>
        <deuna-input [masked]="false" styleClass="mt-4" [form]="formFrecuency" label="Frecuencia"
        name="daysFrequency" control="daysFrequency"></deuna-input>
    </ng-template>
    <ng-container *ngIf="isEditable else visualizeSendDay">   
          implementar cuando sea por edicion o creacion
    </ng-container>
    <ng-template #visualizeSendDay>
            <deuna-input [masked]="false" styleClass="mt-4" [form]="formFrecuency" label="Datos de envio" 
            name="cutOffDay" control="cutOffDay"></deuna-input>
    </ng-template>
    
    <ng-container *ngIf="isEditable else visualizeSendHour">
        implementar cuando sea por edicion o creacion
    </ng-container>
    <ng-template #visualizeSendHour>
          <deuna-input [masked]="false" styleClass="mt-4" [form]="formFrecuency" label="Hora de corte (El reporte llegará 1 hora después al negocio)" 
           name="cutOffTime" control="cutOffTime"></deuna-input>    
    </ng-template>



</app-card>