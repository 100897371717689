<deuna-loading
  [hidden]="!associating"
  [titulo]="'Asociando negocio a grupo'"
  [subtitulo]="'Por favor, espera un momento'"
></deuna-loading>

<div [hidden]="errorAssociating">
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="grid my-5">
    <div class="col-8 col-offset-2">
      <div class="mb-5">
        <div
          class="text-style-back"
          [routerLink]="[relativeRoute + '/info/' + merchantId]"
          [queryParams]="{ tab: 'groups' }"
        >
          Regresar
        </div>
      </div>
      <app-load-step [stepLoad]="50"></app-load-step>
    </div>
    <div class="col-8 col-offset-2">
      <app-search-group
        [formGroup]="formGroup"
        [(isValidSelection)]="isValidSelection"
      ></app-search-group>
    </div>
    <div class="col-8 col-offset-2">
      <div class="grid col-offset-6 pt-4">
        <div class="col-6">
          <deuna-button
            label="Cancelar"
            (eventClick)="onCancelAssociation()"
            deUnaClass="p-button-outlined"
          ></deuna-button>
        </div>
        <div class="col-6">
          <deuna-button label="Asociar" type="submit"> </deuna-button>
        </div>
      </div>
    </div>
  </form>
</div>

<div [hidden]="!errorAssociating">
  <app-error
    title="Ocurrió un problema al asociar este negocio al grupo"
    subtitle="Haz clic en ‘Volver al formulario’ para regresar a la pantalla anterior y no perder los datos ingresados."
    buttonBackText="Volver al inicio"
    buttonRetrytext="Volver al formulario"
    (onActionBackButton)="onActionBackButton()"
    (onActionRetryButton)="onActionRetryButton()"
  ></app-error>
</div>

<deuna-modal
  [displayModal]="showConfirmModal"
  (onAccept)="acceptModalSubmit()"
  (onHide)="onCancel()"
  [image]="modalImg"
  headerModalText="{{modalMessage}}"
  textModalText="{{modalTextMessage}}"
  labelAcceptButton="{{labelAcceptButton}}"
  labelCancelButton="{{labelCancelButton}}"
></deuna-modal>
