import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client';
import { assetUrl } from '@deuna/bp-sh-helpers-ng';
import { debounceTime, Subscription } from 'rxjs';
import {
  optionsFrequency,
  optionsLevelSend,
  optionsTypesSend,
} from '../../../../../constants/catalogos/mocks-catalogo';
import { GraphqlReportService } from '../../../../../services/graphql/graphql-report.service';
import { ICatalogue } from '../../../../../shared/interfaces/calatogue.interface';
import {
  IDeleteReport,
  IQueryReport,
  IReport,
  IResponseReports,
} from 'src/app/shared/interfaces/query-reports';
import { clientRoute } from 'src/app/constants/routes';

@Component({
  selector: 'app-tab-program-reports',
  templateUrl: './tab-program-reports.component.html',
  styleUrls: ['./tab-program-reports.component.scss'],
})
export class TabProgramReportsComponent implements OnInit {
  @Input() existsBranchs: boolean = false;
  @Output() closeSide = new EventEmitter();

  emptyReportActive: string = assetUrl('png/report-active.png');
  emptyReportInactive: string = assetUrl('png/report-inactive.png');
  trashIcon: string = assetUrl('svg/trash.svg');
  editIcon: string = assetUrl('svg/edit.svg');
  subActivatedRoute!: Subscription;
  inputSub!: Subscription;
  merchantId: string | null = null;
  formSearch!: FormGroup;
  loadList: boolean = true;
  currentPage: number = 1;
  limitPage: number = 7;
  itemCount: number = 0;
  totalItems: number = 0;
  itemsPerPage: number = 0;
  totalPages: number = 0;
  headers = [
    'Nombre del reporte',
    'Nivel de información',
    'Sucursales',
    'Frecuencia',
    'Hora de envío',
    'Creado por',
    'Última modificación',
    '',
    '',
  ];
  optionsFrequency: Array<ICatalogue> = optionsFrequency;
  valueChanged: any;
  reportList: Array<IReport> = [];
  paramsQuery: IQueryReport = {
    page: this.currentPage,
    limit: this.limitPage,
    merchantId: '',
    search: '',
  };

  resultfields: string = `id
  type
  merchantId
  createdBy
  updatedBy
  createDate
  updateDate
  daysFrequency
  cutOffDay
  level
  cutOffTime
  status
  branches
  `;

  dataPagination = {
    currentPage: 0,
    totalItems: 0,
    totalPages: 0,
    itemsPerPage: 7,
  };
  relativeRoute = clientRoute;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private readonly fb: FormBuilder,
    private gqlReportService: GraphqlReportService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.close();
    this.init();
  }
  close() {
    this.closeSide.emit();
  }

  redirectEdit(reportId: string, level: string) {
    if(level === 'Unificado') {
      this.router.navigate([`${this.relativeRoute}/report/update/${reportId}`], {
        queryParams: {
          tab: 'configuration'
        }
      });
    } else {
      this.router.navigate([
        `${clientRoute}/report/list-branch-reports`,
        this.merchantId,
        'reports',
        reportId,
      ], {
        queryParams: {
          tab: 'configuration'
        }
      });
    }
  }

  redirectDownloadable(event: Event, reportId: string, level: string) {
    if(level === 'Unificado') {
      event.stopPropagation();
      this.router.navigate([`${this.relativeRoute}/report/update/${reportId}`], {
        queryParams: {
          tab: 'downloadable'
        }
      });
    } else {
      this.router.navigate([
        `${clientRoute}/report/list-branch-reports`,
        this.merchantId,
        'reports',
        reportId,
      ], {
        queryParams: {
          tab: 'downloadable'
        }
      });
    }
  }

  init() {
    this.formSearch = this.fb.group({
      textSearch: [''],
    });
    this.subActivatedRoute = this.activatedRoute.paramMap.subscribe(
      (params) => {
        this.merchantId = params.get('merchantId');
        if (this.merchantId) {
          this.paramsQuery = {
            page: this.currentPage,
            limit: this.limitPage,
            merchantId: this.merchantId,
            search: '',
          };
          this.getReports();
        }
      }
    );
  }

  ngAfterContentInit() {
    this.suscribeToInputChanges();
  }

  suscribeToInputChanges() {
    this.inputSub = this.formSearch.controls['textSearch'].valueChanges
      .pipe(debounceTime(1500))
      .subscribe((value) => {
        this.paramsQuery.page = 1;
        this.paramsQuery.search = value;
        this.getReports();
      });
  }

  getReports() {
    this.loadList = true;
    this.gqlReportService
      .getReports(this.paramsQuery, this.resultfields)
      .subscribe({
        next: (res: ApolloQueryResult<IResponseReports>) => {
          const { items, meta } = res.data.listReport;
          if (items.length > 0) {
            this.dataPagination = meta;
          } else {
            this.dataPagination = {
              currentPage: 0,
              totalItems: 0,
              totalPages: 0,
              itemsPerPage: 7,
            };
          }
          this.reportList = items
            .filter((item) => item.status === 'ACTIVE')
            .map((item) => {
              const tmp = { ...item };

              let index = this.optionsFrequency.find(
                (x: ICatalogue) => x.code === tmp.daysFrequency
              );
              if (index) {
                tmp.daysFrequency = index.name;
              }
              index = optionsTypesSend.find(
                (x: ICatalogue) => x.code === tmp.type
              );
              if (index) {
                tmp.type = index.name;
              }
              index = optionsLevelSend.find(
                (x: ICatalogue) => x.code === tmp.level
              );
              if (index) {
                tmp.level = index.name.split(':')[0];
              }
              tmp.updatedBy = tmp.createdBy;
              tmp.cutOffTime =
                tmp.cutOffTime.length === 0 ? ['Múltiple'] : tmp.cutOffTime;
              return tmp;
            });
          this.loadList = false;
        },
        error: (err) => {
          console.log(err);
          this.router.navigate([`${clientRoute}/info`]);
        },
      });
  }

  redirectCreated() {
    this.router.navigate([
      `${clientRoute}/report/create/step-initial`,
      this.merchantId,
    ]);
  }

  goToSearchKeys(event: any) {
    this.valueChanged.next(event);
    this.getReports();
  }

  goToPageTable(page: number) {
    this.paramsQuery.page = page;
    this.getReports();
  }

  changeToItemsTable(page: number) {
    this.paramsQuery.page = 1;
    this.paramsQuery.limit = page;
    this.getReports();
  }

  deletedReport(event: Event, reportId: string) {
    event.stopPropagation();
    this.loadList = true;
    const deletedReport: IDeleteReport = {
      id: reportId,
    };
    this.gqlReportService.deleteReport(deletedReport).subscribe({
      next: (res) => {
        console.log(res);

        this.viewToast();
        this.getReports();
        this.loadList = false;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  viewToast() {
    this.messageService.add({
      key: 'br',
      severity: 'deuna',
      summary: 'Eliminado exitosamente',
      closable: false,
    });
    this.loadList = false;
  }
}
