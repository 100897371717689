<deuna-loading
  *ngIf="associating"
  [titulo]="'Asociando negocio a grupo'"
  [subtitulo]="'Por favor, espera un momento'"
></deuna-loading>

<form
  [formGroup]="formGroup"
  *ngIf="!associating"
  (ngSubmit)="onSubmit()"
  class="grid my-5"
>
  <div class="col-8 col-offset-2">
    <div class="mb-5">
      <div
        class="text-style-back"
        [routerLink]="[relativeRoute + '/create/search']"
        [queryParams]="{ tab: 'groups' }"
      >
        Regresar
      </div>
    </div>
    <app-load-step [stepLoad]="15"></app-load-step>
  </div>
  <div class="col-8 col-offset-2">
    <app-search-group
      [formGroup]="formGroup"
      [(isValidSelection)]="isValidSelection"
    ></app-search-group>
  </div>
  <div class="col-8 col-offset-2">
    <div class="grid col-offset-6 pt-4">
      <div class="col-6">
        <deuna-button
          label="Cancelar"
          (eventClick)="onCancel()"
          deUnaClass="p-button-outlined"
        ></deuna-button>
      </div>
      <div class="col-6">
        <deuna-button label="Siguiente" type="submit"> </deuna-button>
      </div>
    </div>
  </div>
</form>

<deuna-modal
  [displayModal]="showConfirmModal"
  (onAccept)="onCancelAssociation()"
  (onHide)="hideCancelAssociation()"
  [image]="exitImg"
  headerModalText="{{modalMessage}}"
  textModalText="{{modalTextMessage}}"
  labelAcceptButton="{{labelAcceptButton}}"
  labelCancelButton="{{labelCancelButton}}"
></deuna-modal>
