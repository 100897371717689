import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-card-check',
  templateUrl: './card-check.component.html',
  styleUrls: ['./card-check.component.scss'],
})
export class CardCheckComponent {
  @Input() icon: string = '';
  @Input() titleCard: string = '';
  @Input() subtitleCard: string = '';
  @Input() active: boolean = false;
  @Input() disabled: boolean = false;
}
