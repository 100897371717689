<div class="col-12 text-center mx-1" *ngIf="loadData">
    <p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
    <h2 class="mt-2">
        Cargando....
    </h2>
</div>
<ng-container *ngIf="!loadData">
    <app-empty-list *ngIf="!isAssociatedToGroup" title="Este negocio no está asociado a un grupo empresarial"
        (clickChip)="associateGroup()" [img]="emptyGroup" [subtitle]="titleAssociate"
        [button]="showAssociate" buttonText="+ Asociar"></app-empty-list>
    <div *ngIf="isAssociatedToGroup" class="col-10 col-offset-1">
        <app-card header="Datos del grupo" [isEditable]="isEditable" [isFlowUpdate]="isFlowUpdate">
            <div class="grid">
                <div class="col-4 with-icon">
                    <deuna-input 
                        [masked]="false" 
                        styleClass="mt-4" 
                        label="Nombre comercial" 
                        [form]="formGroup"
                        name="comercialName"
                        control="comercialName" 
                        [disabled]="false">
                    </deuna-input>
                        <img [src]="redirectIcon" alt="icon_redirect" (click)="goToGroup()"/>
                </div>
                <div class="col-4">
                    <deuna-input 
                        [masked]="false" 
                        styleClass="mt-4" 
                        label="N RUC" 
                        [form]="formGroup"
                        name="identification"
                        control="identification" 
                        [disabled]="true">
                    </deuna-input>
                </div>
                <div class="col-4">
                    <deuna-input 
                        [masked]="false" 
                        styleClass="mt-4" 
                        label="Razón social" 
                        [form]="formGroup"
                        name="businessName"
                        control="businessName" 
                        [disabled]="true">
                    </deuna-input>
                </div>
            </div>
        </app-card>
    </div>
</ng-container>