// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //backend: 'http://localhost:3002/merchant-api/internal/graphql',
  backend: 'https://backoffice.dev.deunalab.com/merchant-api/internal/graphql',
  //backend: 'https://0451-179-49-34-42.sa.ngrok.io/api/merchant/graphql/'
  enableBranchReport: true,
  enableNaturalMerchantCreation: true,
  enableCommercialGroup: true,
  enableDashboardAccounts: true,
  enableMassiveMerchantCreation: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 *
 * import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
 */
