import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ICatalogue } from 'src/app/shared/interfaces/calatogue.interface';
import _ from 'lodash';
import { CataloguesService } from 'src/app/services/http/catalogues.service';

@Component({
  selector: 'app-card-tax',
  templateUrl: './card-tax.component.html',
})
export class CardTaxComponent implements OnInit {
  @Input() isEditable: boolean = false;
  @Input() isFlowUpdate: boolean = false;
  loading: boolean = false;
  enableEdit: boolean = false;
  @Input() formCN016!: FormGroup;
  @Input() isCreationForm: boolean = true;
  formCN016TMP!: FormGroup;
  @Input() isCN014Created: boolean = false;
  @Output() toastEvent: EventEmitter<void> = new EventEmitter<void>();
  taxRegimeOptions: Array<ICatalogue> = [];

  constructor(private cataloguesService: CataloguesService) {}

  ngOnInit(): void {
    this.formCN016.enable();
    this.formCN016TMP = _.cloneDeep(this.formCN016);
    this.getDataCatalogs();
  }

  handleEvent($event: boolean) {
    if ($event) {
      this.enableEdit = true;
      this.formCN016.enable();
    }
  }

  getDataCatalogs() {
    this.cataloguesService.getTaxationRegime().subscribe((res) => {
      this.taxRegimeOptions = res;
    });
  }

  onCancelEdit() {
    this.enableEdit = false;
    this.setStateReadable();
    this.formCN016.patchValue(this.formCN016TMP.value);
  }

  async submit() {
    this.loading = true;
    this.formCN016.markAllAsTouched();
  }

  setStateReadable() {
    this.formCN016.disable();
  }
  emitToast() {
    this.toastEvent.emit();
  }
}
