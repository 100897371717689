import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  RouterModule,
  provideRouter,
  withComponentInputBinding,
} from '@angular/router';
import { CommonModule } from '@angular/common';
import { TagModule } from 'primeng/tag';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { SidebarModule } from 'primeng/sidebar';
import { DialogModule } from 'primeng/dialog';
import { GraphqlClientService } from '../../services/graphql/graphql-client.service';
import { ConfigurationFlowCreationService } from '../../services/config/merchant/configuration-flow-creation.service';
import { ConfigurationFlowCreationService as ConfigurationFlowCreationCommercialService } from '../../services/config/commercial-group/configuration-flow-creation.service';
import { CataloguesService } from '../../services/http/catalogues.service';
import { ConfigurationFlowBranchService } from '../../services/config/branch/configuration-flow-branch.service';
import { ConfigurationFlowReportService } from '../../services/config/report/configuration-flow-report.service';

import { GraphqlMerchantService } from '../../services/graphql/graphql-merchant.service';
import { OnlyNumberDirective } from '../../shared/directives/only-number.directive';
import {
  DeUnaButtonModule,
  DeUnaModalModule,
  DeUnaSideRightModule,
  DeUnaTableModule,
  DeUnaRadioButtonModule,
  DeUnaSelectModule,
  DeUnaLoadingModule,
  DeUnaInputModule,
  DeUnaTableRowModule,
  DeUnaCheckListModule,
  DeUnaPaginationModule,
  DeunaTabMenuModule,
  DeunaMenuModule,
  DeUnaTableCellModule,
  DeunaChipModule,
  DeUnaUploadFileModule,
  DeUnaInputActionModule,
  DeUnaInfoBannerModule,
  DeUnaCalendarModule,
} from '@deuna/bo-sh-components-ng';
import { ExitModalReportComponent } from './components/exit-modal-report/exit-modal-report.component';
import { GraphqlReportService } from 'src/app/services/graphql/graphql-report.service';
import { CardCommercialComponent } from './components/form-merchant/card-commercial/card-commercial.component';
import { CardAddressComponent } from './components/form-merchant/card-address/card-address.component';
import { CardGeneralComponent } from './components/form-merchant/card-general/card-general.component';
import { ConfigurationFormService } from 'src/app/services/config/merchant/configuration-form.service';
import { ConfigurationFormService as ConfigurationFormCommercialService } from 'src/app/services/config/commercial-group/configuration-form.service';
import { ConfigurationDataService } from '../../services/config/merchant/configuration-data.service';
import { CardNotificationComponent } from './components/form-merchant/card-notification/card-notification.component';
import { ToastModule } from 'primeng/toast';
import { SkeletonModule } from 'primeng/skeleton';
import { CardCollectionAccountsComponent } from './components/form-merchant/card-collection-accounts/card-collection-accounts.component';
import { CardCommissionAccountsComponent } from './components/form-merchant/card-commission-accounts/card-commission-accounts.component';
import { CardOperatingAccountsComponent } from './components/form-merchant/card-operating-accounts/card-operating-accounts.component';
import { CardBillingInformationComponent } from './components/form-merchant/card-billing-information/card-billing-information.component';
import { ConfigurationFlowReportFormService } from 'src/app/services/config/report/configuration-flow-form-report.service';
import { ConfigurationFlowReportDataService } from 'src/app/services/config/report/configuration-flow-data-report.service';
import { RoleBasedDirective } from 'src/app/shared/directives/role-based.directive';
import { MerchantStateService } from 'src/app/services/state/merchant-state.service';
import { CardNaturalComponent } from './components/form-merchant/card-natural/card-natural.component';
import { CardCollectionAccountsNaturalComponent } from './components/form-merchant/card-collection-accounts-natural/card-collection-accounts-natural.component';
import { CardTaxComponent } from './components/form-merchant/card-tax/card-tax.component';
import { CardCommissionAccountsNaturalComponent } from './components/form-merchant/card-commission-accounts-natural/card-commission-accounts-natural.component';
import { CardDataUsageComponent } from './components/form-merchant/card-data-usage/card-data-usage.component';
import { CardSourceSupportComponent } from './components/form-merchant/card-source-support/card-source-support.component';
import { ClientsRoutingModule } from './clients.routing';
import { GraphqlCommercialGroupService } from 'src/app/services/graphql/graphql-commercial-group.service';
import { AssociateCommercialGroupPage } from './pages/associate-commercial-group/associate-commercial-group.page';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SearchGroupComponent } from './components/commercial-group/search-group-card/search-group-card.component';
import { AssociateCommercialAnExistingMerchantGroupPage } from './pages/associate-commercial-group-an-existing-merchant/associate-commercial-group-an-existing-merchant.page';
import { ErrorComponentModule } from '../../shared/components/error-components/error/error.module';

@NgModule({
  providers: [
    CataloguesService,
    ConfigurationFlowCreationService,
    ConfigurationFlowCreationCommercialService,
    ConfigurationFormService,
    ConfigurationFormCommercialService,
    ConfigurationDataService,
    ConfigurationFlowBranchService,
    GraphqlMerchantService,
    GraphqlReportService,
    GraphqlCommercialGroupService,
    ConfigurationFlowReportService,
    ConfigurationFlowReportFormService,
    ConfigurationFlowReportDataService,
    MerchantStateService,
    GraphqlClientService,
    provideRouter([], withComponentInputBinding()),
  ],
  declarations: [
    ClientsRoutingModule.LAYOUTS,
    ClientsRoutingModule.PAGES,
    ClientsRoutingModule.COMPONENTS,
    OnlyNumberDirective,
    RoleBasedDirective,
    ExitModalReportComponent,
    CardCommercialComponent,
    CardAddressComponent,
    CardGeneralComponent,
    CardNaturalComponent,
    CardDataUsageComponent,
    CardSourceSupportComponent,
    CardNotificationComponent,
    CardCollectionAccountsComponent,
    CardCollectionAccountsNaturalComponent,
    CardCommissionAccountsComponent,
    CardCommissionAccountsNaturalComponent,
    CardOperatingAccountsComponent,
    CardBillingInformationComponent,
    CardTaxComponent,
    AssociateCommercialGroupPage,
    AssociateCommercialAnExistingMerchantGroupPage,
    SearchGroupComponent,
  ],
  imports: [
    ToastModule,
    SkeletonModule,
    CommonModule,
    DeUnaSideRightModule,
    ClientsRoutingModule,
    ReactiveFormsModule,
    CardModule,
    ProgressBarModule,
    InputTextModule,
    FormsModule,
    DropdownModule,
    TableModule,
    DeUnaLoadingModule,
    DeUnaButtonModule,
    DeUnaRadioButtonModule,
    DeUnaSelectModule,
    DeUnaInputModule,
    DeUnaInputActionModule,
    DeUnaInfoBannerModule,
    DeUnaCalendarModule,
    DeUnaCheckListModule,
    CheckboxModule,
    DialogModule,
    DeUnaCheckListModule,
    DeunaChipModule,
    DeUnaTableModule,
    DeUnaTableRowModule,
    TagModule,
    SidebarModule,
    DeunaMenuModule,
    DeUnaPaginationModule,
    DeUnaTableCellModule,
    DeunaTabMenuModule,
    ProgressBarModule,
    DeUnaModalModule,
    DeUnaUploadFileModule,
    AutoCompleteModule,
    ErrorComponentModule,
  ],
  exports: [RouterModule],
})
export class ClientsModule {}
