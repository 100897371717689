import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  optionsDays,
  optionsFrequency,
} from 'src/app/constants/catalogos/mocks-catalogo';
import { ICatalogue } from 'src/app/shared/interfaces/calatogue.interface';
import { assetUrl } from '@deuna/bp-sh-helpers-ng';
import { clientRoute } from 'src/app/constants/routes';
import { ConfigurationFlowReportService } from 'src/app/services/config/report/configuration-flow-report.service';
import { createDays, createHours } from 'src/app/shared/utils/helpers';
import { BannerTypes } from '@deuna/bp-sh-components-ng';

@Component({
  selector: 'app-report-sales-unified',
  templateUrl: './report-sales-unified.page.html',
  styleUrls: ['./report-sales-unified.page.scss'],
})
export class ReportSalesUnifiedPage implements OnInit {
  relativeRoute = clientRoute;
  formStepFrequency!: FormGroup;
  displayModal: boolean = false;
  loading: boolean = false;
  merchantId: string | null = null;
  subActivatedRoute!: Subscription;
  labelDay: string = '';
  trash: string = assetUrl('svg/trash.svg');
  optionsFrequency: Array<ICatalogue> = optionsFrequency;
  optionsHour: Array<ICatalogue> = [];
  optionsDays: Array<ICatalogue> = [];
  tmpOptionsDays: Array<ICatalogue> = [];
  tmpOptionsDays2: Array<ICatalogue> = [];
  control!: FormArray;
  bannerType = BannerTypes.BASIC_INFO;

  constructor(
    private configurationFlowReportService: ConfigurationFlowReportService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private readonly fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.optionsHour = createHours();
    this.tmpOptionsDays = createDays(30);
    this.tmpOptionsDays2 = createDays(15);
    const forms = this.configurationFlowReportService.getForm();
    this.formStepFrequency = forms.frequency;
    if (this.fields.length === 0) this.addRow();
    this.subActivatedRoute = this.activatedRoute.paramMap.subscribe(
      (params) => {
        this.merchantId = params.get('merchantId');
      }
    );
  }

  get fields(): FormArray {
    return this.formStepFrequency.get('mails') as FormArray;
  }

  hasError(i: number, error: string): boolean {
    return this.fields.controls[i].get('mail')!.hasError(error);
  }

  isValidMails(i: number) {
    return this.fields.controls[i].get('mail')!.invalid;
  }

  isTouched(i: number): boolean {
    return this.fields.controls[i].get('mail')!.touched;
  }

  isDirty(i: number): boolean {
    return this.fields.controls[i].get('mail')!.dirty;
  }

  addRow() {
    if (this.fields.length === 10) return;
    const row = this.fb.group({
      mail: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^[a-z0-9._%+-]{1,64}@[a-z0-9.-]{1,64}\.[a-z]{2,3}$/i
          ),
        ],
      ],
    });
    this.fields.push(row);
  }

  removeRow(index: number) {
    this.fields.removeAt(index);
  }

  async onSubmit() {
    this.formStepFrequency.markAllAsTouched();
    if (!this.formStepFrequency.valid) return;
    this.loading = true;
    const created = await this.configurationFlowReportService.saveForm();
    if (created) {
      this.router.navigate([`${clientRoute}/info`, this.merchantId], {
        queryParams: { tab: 'reportsProgram' },
      });
    }
    this.loading = false;
    this.configurationFlowReportService.resetForm();
  }

  onAccept() {
    this.router.navigate([`${clientRoute}/info`, this.merchantId], {
      queryParams: { tab: 'reportsProgram' },
    });
    this.configurationFlowReportService.resetForm();
  }

  onHide() {
    this.displayModal = false;
  }

  openModal() {
    this.displayModal = true;
  }

  changeFrequency(): void {
    const frequency = this.formStepFrequency.get('daysFrequency')?.value;
    this.formStepFrequency.get('cutOffDay')?.setValue(null);
    this.formStepFrequency.get('cutOffDaySecond')?.setValue(null);
    this.formStepFrequency.get('cutOffDaySecond')?.clearValidators();
    this.formStepFrequency.controls['cutOffDay'].clearValidators();
    this.labelDay = 'Día de envío';
    switch (frequency) {
      case '7':
        this.optionsDays = optionsDays;
        this.formStepFrequency.controls['cutOffDay'].setValidators([
          Validators.required,
        ]);
        break;
      case '15':
        this.labelDay = 'Día de 1ra quincena';
        this.optionsDays = this.tmpOptionsDays2;
        this.formStepFrequency.controls['cutOffDay'].setValidators([
          Validators.required,
        ]);
        break;
      case '30':
        this.optionsDays = this.tmpOptionsDays;
        this.formStepFrequency.controls['cutOffDay'].setValidators([
          Validators.required,
        ]);
        break;
    }
    this.formStepFrequency.get('cutOffDay')?.updateValueAndValidity();
  }

  changeDays2(): void {
    const days = this.formStepFrequency.get('cutOffDay')?.value;
    const second = +days.code + 15;
    this.formStepFrequency
      .get('cutOffDaySecond')
      ?.setValue(`${second} de cada mes`);
  }

  validationDay(): boolean {
    return (
      this.formStepFrequency.get('daysFrequency')?.value !== null &&
      this.formStepFrequency.get('daysFrequency')?.value !== '1'
    );
  }
}
