import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { clientRoute } from 'src/app/constants/routes';
import { ConfigurationFlowCreationService } from 'src/app/services/config/merchant/configuration-flow-creation.service';
import { IFormGroup } from 'src/app/shared/interfaces/form-group.interface';

import { assetUrl } from '@deuna/bo-sh-helpers-ng';

@Component({
  selector: 'app-associate-commercial-group',
  templateUrl: './associate-commercial-group.page.html',
  styleUrls: [],
})
export class AssociateCommercialGroupPage implements OnInit {
  constructor(
    private router: Router,
    private configFlowCreation: ConfigurationFlowCreationService
  ) {}
  relativeRoute = clientRoute;
  associating: boolean = false;
  showConfirmModal: boolean = false;
  modalMessage: string = `¿Quieres abandonar el proceso?`;
  isValidSelection: boolean = true;

  formGroup!: FormGroup;
  formConfigData!: IFormGroup;
  stepLoad: number = 0;

  exitImg = assetUrl('png/exit_logo.png');

  modalTextMessage: string = `Al seleccionar “Sí, cancelar”, se eliminará toda la información 
  registrada hasta el momento.`;
  labelAcceptButton: string = 'Sí, cancelar';
  labelCancelButton: string = 'No, continuar editando';
  ngOnInit(): void {
    this.initForm();
  }

  async initForm() {
    this.formConfigData = this.configFlowCreation.getForm();
    this.configFlowCreation.getForm().associationGroup.reset();
    this.formGroup = this.formConfigData.associationGroup;
    this.formGroup.enable();
  }

  onSubmit() {
    this.isValidSelection = true;
    const selectedItem = this.formGroup.value.commercialGroupId;

    if (this.formGroup.valid && selectedItem && selectedItem.nodeId) {
      this.formGroup.markAllAsTouched();
      this.router.navigate([`${clientRoute}/create/step-1`]);
    } else {
      this.isValidSelection = false;
    }
  }

  onCancel() {
    this.showConfirmModal = true;
  }

  hideCancelAssociation() {
    this.showConfirmModal = false;
  }

  onCancelAssociation() {
    this.router.navigate([`${clientRoute}/list`], {
      queryParams: { tab: 'merchants' },
    });
  }
}
